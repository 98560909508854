import React, { useState } from 'react'
import { SalesEntryPage } from './Partials/SalesEntryPage'
import { CustomPageTitle } from '../../components/CustomPageTitle'
import { CustomCardView } from '../../components/CustomCardView'

const Sales = () => {

    return (
        <div>
            <CustomPageTitle Heading={'Sales'} />

            <CustomCardView>
                <SalesEntryPage />
            </CustomCardView>

        </div>
    )
}

export default Sales