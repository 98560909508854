import React, { useState } from 'react'
import { CustomCardView } from '../../../../components/CustomCardView'
import { CustomPageTitle } from '../../../../components/CustomPageTitle'
import { ReturnEntryPage } from './ReturnEntryPage'

const SaleReturn = () => {

    return (
        <div>
            <CustomPageTitle Heading={'Sale Return '} />
           
            <CustomCardView>
                <ReturnEntryPage />
            </CustomCardView>

        </div>
    )
}

export default SaleReturn