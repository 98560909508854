import React, { useState } from 'react'
import { CustomCardView } from '../../../components/CustomCardView'
import { Col, Form } from 'antd';
import { CustomRow } from '../../../components/CustomRow';
import { CustomSelect } from '../../../components/Form/CustomSelect'
import dayjs from 'dayjs';
import Flex from '../../../components/Flex';
import Button from '../../../components/Form/CustomButton';
import { CustomDateRangePicker } from '../../../components/Form/CustomDateRangePicker'
import { TbArrowsExchange } from 'react-icons/tb';
import { CustomModal } from '../../../components/CustomModal';
import { CustomPageTitle } from '../../../components/CustomPageTitle';
import { CustomTable } from '../../../components/Form/CustomTable';
import { useNavigate } from 'react-router-dom';
import ButtonStandard from '../../../components/Form/CustomStandardButton';
import request from '../../../utils/request';

export const ViewPurchaseReturn = () => {

    const [form] = Form.useForm();

    const nevigate = useNavigate();

    const SEARCH_URL = 'sales/'

    const [dataSource, setDataSource] = useState([])

    const [choiceFull, setChoiceFull] = useState('')
    const [dateRange, setDateRange] = useState([]);

    const [show, setShow] = useState(false)
    const [modalContent, setModalContent] = useState(null);
    const [modalTitle, setModalTitle] = useState("");
    const [isModalOpen, setIsModalOpen] = useState(false);

    const showModal = () => {
        setIsModalOpen(true);
    };

    const handleOk = () => {
        setIsModalOpen(false);
    };

    const handleCancel = () => {
        setIsModalOpen(false);
    };

    const handleDateRangeChange = (values) => {
        setDateRange(values);
    };

    const month = [
        { label: 'This Month', value: 'This_month' },
        { label: 'Year', value: 'This_year' },
        { label: 'Quater', value: 'This_quorter' },
        { label: 'Custom', value: 'custom' },
    ]

    const handleMonthSelect = (value) => {
        if (value === 'custom') {
            setShow(true);
            setChoiceFull(value)
        } else {
            setShow(false);
        }

        const choice = {
            choice: value
        }

        DateSearch(choice)

    };

    const DateSearch = (values) => {
        request.post(`${SEARCH_URL}`, values)
            .then(function (response) {
                setDataSource(response.data?.sale)
            })
            .catch(function (error) {
                console.log(error);
            });

    }


    const onFinish = (values) => {
        const record = { ...values, range: dateRange, choice: choiceFull }

        DateSearch(record)

    }

    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };

    const columns = [
        {
            title: 'Sl No',
            render: (item, value, index) => index + 1,
        },
        {
            title: 'Date',
            dataIndex: 'date',
        },
        {
            title: 'Party Name',
            dataIndex: 'party_name',
        },
        {
            title: 'Total Amount',
            dataIndex: 'total_amount',
        },
        {
            title: 'Received Amount',
            dataIndex: 'received_amount',
        },
        {
            title: 'Action',
            render: (record) => {
                return (
                    <Flex spaceEvenly={'true'}>
                        <Button.Primary text={"Print"} />
                    </Flex>
                )
            }
        }

    ];

    const data = [
        {
            date: '2023-09-05',
            party_name: 'Party A',
            receipt_no: 'R12345',
            total_amount: 100.50,
            received_amount: 50,
        },
    ];


    return (
        <>
            <CustomPageTitle Heading={'View Purchase Return'} />
            <CustomCardView>
                <Form
                    form={form}
                    labelCol={{
                        span: 24,
                    }}
                    wrapperCol={{
                        span: 24,
                    }}
                    initialValues={
                        {
                            from_date: dayjs(),
                            to_date: dayjs(),
                        }
                    }
                    onFinish={onFinish}
                    onFinishFailed={onFinishFailed}
                    autoComplete="off">
                    <CustomRow space={[24, 24]} style={{ padding: '10px 0' }}>
                        <Col span={24} md={3}>
                            <b>Choose</b>&nbsp;&nbsp;
                        </Col>
                        <Col span={24} md={12}>
                            <CustomSelect
                                options={month}
                                name={'month'}
                                placeholder={'This Month'}
                                onChange={handleMonthSelect}
                                rules={[{ required: true, message: 'Please Select the Month' }]}
                            />
                        </Col>
                        <Col span={24} md={9}>
                            <Flex end={true}>
                                <ButtonStandard.Primary text={'Add Purchase Return'} style={{ marginRight: '10px' }} onClick={() => nevigate(`/addpurchasereturn`)} />
                            </Flex>
                        </Col>
                    </CustomRow>

                    <CustomRow space={[24, 24]} style={{ padding: '10px 0' }}>
                        {show ?
                            <>
                                <Col span={24} md={3} style={{ marginTop: '10px' }}>
                                    <b>Between</b>&nbsp;&nbsp; <TbArrowsExchange />
                                </Col>

                                <Col span={24} md={12}>
                                    <CustomDateRangePicker
                                        onChange={handleDateRangeChange}
                                        name={'range'}
                                        rules={[{ required: true, message: 'Please Select the Date' }]}
                                    />
                                </Col>

                                <Col span={24} md={6}>
                                    <Flex>
                                        <Button.Primary text={'Submit'} htmlType='submit' />
                                    </Flex>
                                </Col>
                            </>
                            : null}

                    </CustomRow>
                </Form>
                <br /><br />

                <CustomRow space={[12, 12]}>
                    <Col span={24} md={24}>
                        <CustomTable columns={columns} data={dataSource} />
                    </Col>
                    <CustomModal isVisible={isModalOpen} handleOk={handleOk} handleCancel={handleCancel} width={1000} modalTitle={modalTitle} modalContent={modalContent} />
                </CustomRow>

            </CustomCardView >

        </>
    )
}

