import React, { useEffect, useState } from "react";
import { Form, Button, message, Spin, } from "antd";
import { DownloadOutlined, } from "@ant-design/icons";
import Flex from "../../../../components/Flex";
import request from "../../../../utils/request";
import ExcelFile from "../../../../assets/partydetail.xlsx";
import { CustomUpload } from "../../../../components/Form/CustomUpload";
import { toast } from "react-toastify";
import { LoadingOutlined } from '@ant-design/icons';

export const DownloadView = ({ ExcelTrigger }) => {
    const [form] = Form.useForm();
    const [isloading, setIsloading] = useState(false)
    const onFinish = async (values) => {
        setIsloading(true)
        try {
            const formData = new FormData();
            if (values?.file) {
                values.file.forEach((file) => {
                    if (file.originFileObj !== undefined) {
                        formData.append("file", file.originFileObj);
                    }
                });
            } else {
                console.error("No images selected");
            }
            await handleExcelpost(formData);
            form.resetFields();
        } catch (error) {
            console.error(error);
            setIsloading(false)
        } finally {
            setIsloading(false)
        }
    };
    useEffect(() => {
        form.resetFields()
    }, [ExcelTrigger])
    const handleExcelpost = async (values) => {
        try {
            await request.post("party/party_excel_upload/", values);
            message.success("Upload Successfully");
        } catch (error) {
            if (error.response && error.response.status === 500) {
                message.warning(
                    "The Excel sheet format is incorrect. Please upload the correctly formatted Excel sheet."
                );
            }
            else if (error.response.status === 400) {
                if (error.response.data?.mobile_number) {
                    toast.warn(error.response.data.mobile_number[0])
                } else if (error.response.data?.gstin) {
                    toast.warn(error.response.data?.gstin[0])
                } else if (error.response.data?.email) {
                    toast.warn(error.response.data?.email[0])
                }
                else {
                    toast.warn('Something went wrong, please check all details')
                }
            }
            else {
                message.error("Upload Failed");
            }
        }
    };
    const handleDownloadClick = (values) => {
        const link = document.createElement('a');
        link.style.display = 'none';
        link.href = `${ExcelFile}`;
        link.download = 'partydetail.xlsx';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }
    return (
        <div style={{ padding: "10px" }}>
            <h1 style={{ fontSize: "24px", color: "#685E5E", textAlign: "center" }}>
                Upload & Download Excel Sheet
            </h1>
            <Form
                form={form}
                onFinish={onFinish}
                autoComplete="off"
            >
                <Flex column={'true'} alignCenter={'true'} gap={'20px'} style={{ margin: '30px' }}>
                    <CustomUpload text={'File Upload'} form={form} name={'file'} maxCount={1} accept='.xlsx,'
                        rules={[
                            {
                                required: true,
                                message: 'This is required field!',
                            }
                        ]}
                    />
                    <Flex gap={'10px'}>
                        {
                            isloading ?
                                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '30px', width: '60px', marginTop: '3px' }}>
                                    <Spin
                                        indicator={
                                            <LoadingOutlined
                                                style={{
                                                    fontSize: 20,
                                                }}
                                                spin
                                            />
                                        }
                                    />
                                </div> :
                                <Button type="primary" htmlType="submit">
                                    Upload
                                </Button>
                        }

                        <Button
                            type="default"
                            icon={<DownloadOutlined />}
                            onClick={handleDownloadClick}
                        >
                            Sample File
                        </Button>
                    </Flex>
                </Flex>
            </Form>
        </div>
    );
};