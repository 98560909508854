import React, { useState, useEffect } from 'react';
import { Layout, Tabs } from 'antd';
import { SaleReport } from '../../Reports/Sales';
import ReportQuotation from '../../Reports/Quotations/Partials/ReportQuotation';
import Salereturn from '../../Reports/SaleReturn/Partials/Salereturn';
import PurchseReturnReport from '../../Reports/PurchaseReturn/Partials/PurchseReturn';
import PurchseReport from '../../Reports/Purchase/Partials/Purchse';
import { CustomCardView } from '../../../components/CustomCardView';
import styled from 'styled-components';
import { FileOutlined } from '@ant-design/icons';

const { Content } = Layout;
const { TabPane } = Tabs;

const Details = () => {
  const [tabPosition, setTabPosition] = useState('top');
  const [activeTab, setActiveTab] = useState('');

  useEffect(() => {
    // Load the active tab from local storage if available
    const storedTab = localStorage.getItem('activeTab');
    if (storedTab) {
      setActiveTab(storedTab);
    }
  }, []);

  // Update the local storage when the active tab changes
  useEffect(() => {
    if (activeTab) {
      localStorage.setItem('activeTab', activeTab);
    }
  }, [activeTab]);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 768) {
        setTabPosition('top');
      } else {
        setTabPosition('left');
      }
    };

    // Initial check when the component mounts
    handleResize();

    // Listen for window resize events
    window.addEventListener('resize', handleResize);

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const handleTabChange = (key) => {
    setActiveTab(key);
  };

  const TabHeader = styled(Tabs)`
    :where(.css-dev-only-do-not-override-190m0jy).ant-tabs > .ant-tabs-nav .ant-tabs-nav-list,
    :where(.css-dev-only-do-not-override-190m0jy).ant-tabs > div > .ant-tabs-nav .ant-tabs-nav-list {
      position: relative;
      display: flex;
      transition: opacity 0.3s;
      margin-top: 66px;
    }
  `;

  return (
    <Layout>
      <Content style={{ margin: '24px 16px', padding: 0 }}>
        <CustomCardView>
          <TabHeader tabPosition={tabPosition} activeKey={activeTab} onChange={handleTabChange}>
            <TabPane tab="Sale Report" key="1">
              <SaleReport />
            </TabPane>
            <TabPane tab="Sale Return Report" key="2">
              <Salereturn />
            </TabPane>
            <TabPane tab="Purchase Report" key="4">
              <PurchseReport />
            </TabPane>
            <TabPane tab="Purchase Return Report" key="5">
              <PurchseReturnReport />
            </TabPane>
            <TabPane tab="Quotation Report" key="6">
              <ReportQuotation />
            </TabPane>
          </TabHeader>
        </CustomCardView>
      </Content>
    </Layout>
  );
};

export default Details;
