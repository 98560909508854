import React from 'react'
import { CustomCardView } from '../../components/CustomCardView'
import styled from 'styled-components'
import PasswordRest from './Partials/PasswordRest'
import { CustomPageTitle } from '../../components/CustomPageTitle'

const PasswordStyle = styled.div`
    width:70%;
    background:#fff;
    margin:auto;
    padding: 40px;
    @media screen and (max-width: 600px) {
        width:100%;
    }
`

const Resetpassword = () => {
    return (
        <PasswordStyle>
            <CustomPageTitle Heading={'Reset Password'} /><br />
            <PasswordRest />
        </PasswordStyle>
    )
}

export default Resetpassword