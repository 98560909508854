import React, { Fragment, useEffect, useState } from 'react'
import { CustomRow } from '../../../../components/CustomRow';
import { Col } from 'antd';
import { FiEdit, FiPlus } from 'react-icons/fi'
import { THEME } from '../../../../theme/index'
import Flex from '../../../../components/Flex';
import { CustomModal } from '../../../../components/CustomModal';
import { AddExpenseForm } from '../../Expense/Partials/AddExpenseForm';
import { useDispatch, useSelector } from 'react-redux';
import { getExpense, getExpenseError, getExpenseStatus, selectAllExpense } from '../../ExpenseSlice';
import { CustomPopconfirm } from '../../../../components/CustomPopConfirm';
import { MdDelete } from 'react-icons/md';
import { CommonLoading } from '../../../../components/CommonLoading';
import request from '../../../../utils/request';
import { toast } from 'react-toastify';
import { CustomPageTitle } from '../../../../components/CustomPageTitle';
import { useNavigate } from 'react-router-dom';
import { TableIconHolder } from '../../../../components/CommonStyled';
import Label from '../../../../components/Form/Label';
import { CustomInput } from '../../../../components/Form/CustomInput';
import ButtonStandard from '../../../../components/Form/CustomStandardButton';
import { CustomTandardTable } from '../../../../components/Form/CustomStandardTable';



export const ViewExpenseTable = () => {

    const navigate = useNavigate();
    const dispatch = useDispatch()

    const [dataSource, setDataSource] = useState([])
    const [searchTexts, setSearchTexts] = useState([]);   //---------Seach Bar --------

    // ======  Modal Open ========
    const [isModalOpen, setIsModalOpen] = useState(false);

    // ======  Modal Title and Content ========
    const [modalTitle, setModalTitle] = useState("");
    const [modalContent, setModalContent] = useState(null);

    // ----------  Form Reset UseState ---------
    const [formReset, setFormReset] = useState(0);
    const [modelwith, setModelwith] = useState(0);
    const [profileFormUpdate, setProfileFormUpdate] = useState(0)
    const [trigger, setTrigger] = useState(0)

    const DEL_Expense_URL = 'expense/edit_expense'

    const RestForm = () => {
        setFormReset(formReset + 1)
    }

    const handleSearchs = (value) => {
        setSearchTexts(value);
    };

    const FormUpdate = () => {
        setProfileFormUpdate(profileFormUpdate + 1)
    }

    useEffect(() => {
        dispatch(getExpense())
    }, [])


    const expense = useSelector(selectAllExpense)
    const expenseStatus = useSelector(getExpenseStatus)
    const expenseError = useSelector(getExpenseError)

    useEffect(() => {
        setDataSource(expense)
    }, [expense])

    // ===== Modal Functions Start =====

    const showModal = () => {
        setIsModalOpen(true);
    };

    const handleOk = () => {
        setIsModalOpen(false);
        RestForm()
    };

    const handleCancel = () => {
        setIsModalOpen(false);
        RestForm()
    };
    // ===== Modal Functions End =====

    const UpdateExpense = (record) => {
        setTrigger(trigger + 1)
        setModelwith(800)
        setModalTitle("Update Expense");
        setModalContent(<AddExpenseForm updateRecord={record} Formcancel={handleOk} Trigger={trigger} formname={'AddEmployeeUpdateForm'} FormUpdateExpense={FormUpdate} />);
        showModal();
    };


    const handleConfirm = (record) => {
        DeleteExpense(record);
    }

    const DeleteExpense = (record) => {
        request.delete(`${DEL_Expense_URL}/${record?.id}/`)
            .then((response) => {
                if (response.status === 204) {
                    dispatch(getExpense())
                    toast.info("Deleted Successfully")
                }
                if (response.status === 226) {
                    toast.warn('Expense Details Already exists,Can`t be Deleted!')
                }
            }).catch(error => {
                if (error.response.status === 304) {
                    toast.warn('Limit Exceeds, Please subscribe to plan')
                } 
            });
    };


    const TableColumn = [
        {
            title: 'SI No',
            render: (value, item, index) => index + 1,
        },
        {
            title: 'Date',
            dataIndex: 'date',
        },
        {
            title: 'Expense Name',
            dataIndex: 'expense_name',
            filteredValue: searchTexts ? [searchTexts] : null,
            onFilter: (value, record) => {
                return String(record.expense_name).toLowerCase().includes(value.toLowerCase()) ||
                    String(record.expense_name).includes(value.toUpperCase());
            },
        },
        {
            title: 'Description',
            dataIndex: 'expense_description',
        },
        {
            title: 'Category Name',
            dataIndex: 'category_name',
        },
        {
            title: 'Category Type',
            dataIndex: 'category_type',
        },
        {
            title: 'Amount',
            dataIndex: 'amount',
        },
        {
            title: 'Action',
            render: (record) => {
                return (
                    <Flex center={"true"} gap={'10px'}>

                        <TableIconHolder color={THEME.blue} size={'22px'} onClick={() => UpdateExpense(record)} >
                            <FiEdit />
                        </TableIconHolder>

                        <CustomPopconfirm
                            record={record}
                            confirm={handleConfirm}
                            title={"Delete the Category"}
                            description={"Are you sure to delete this Category ?"}>
                            <TableIconHolder color={THEME.red} size={'22px'}>
                                <MdDelete />
                            </TableIconHolder>
                        </CustomPopconfirm>
                    </Flex>
                )
            }
        }

    ]


    let content;

    if (expenseStatus === 'loading') {
        content = <CommonLoading />
    } else if (expenseStatus === 'succeeded') {
        const rowKey = (dataSource) => dataSource.id;
        content = <CustomTandardTable columns={TableColumn} data={dataSource} rowKey={rowKey} />
    } else if (expenseStatus === 'failed') {
        const rowKey = (dataSource) => dataSource.id;
        content = <CustomTandardTable columns={TableColumn} data={dataSource} rowKey={rowKey} />
    }

    return (
        <Fragment>
            <CustomRow space={[24, 24]}>
                <Col span={24} md={15}>
                    <CustomPageTitle Heading={'Expense'} />
                </Col>
                <Col span={24} md={9}>
                    <CustomRow space={[24, 24]}>
                        <Col span={24} md={16}>
                           
                        </Col>
                        <Col span={24} md={8} style={{ float: 'right' }}>
                            <ButtonStandard.Primary icon={<FiPlus style={{ marginRight: '5px', fontSize: '20px' }} />} text={'Add'}
                                onClick={() => navigate('/addexpense')} />
                        </Col>
                    </CustomRow>
                </Col>
            </CustomRow>
            <div style={{ display: 'flex', alignItems: 'center', marginTop: '50px' }}>
                <Label style={{ marginRight: '20px' }}>Search by Expense Name :</Label>
                <CustomInput
                    value={searchTexts}
                    placeholder="Search"
                    onSearch={handleSearchs}
                    onChange={(e) => handleSearchs(e.target.value)}
                />
            </div>
            {content}
            <CustomModal isVisible={isModalOpen} handleOk={handleOk} handleCancel={handleCancel} width={modelwith} modalTitle={modalTitle} modalContent={modalContent} />
        </Fragment>
    )
}
