import styled from "styled-components";
import { THEME } from "../../../theme";

export const StyledLoginMenu = styled.div`

max-width: 800px;
box-shadow: 0px 4px 10px 0px #0000002E;
margin-top: 100px !important;
margin: auto;
background-color: #fff;

.Imageleft {
    padding: 20px;
    }

& img{
    width: 100%;
    border-radius: 10px 0px 0px 10px;

    /* @media screen and (max-width: 768px) {
        display: none !important;
    } */
}

.dDHQxW {
    background: white;
    box-shadow: rgba(0, 0, 0, 0.03) 0px 0px 5px 5px;
    margin: auto;
    max-width: 100%;
    padding: 0px !important;
    /* border-radius: 10px; */
}

.cOGUEI {
    margin: auto;
    padding: 0px !important; 
}
 @media screen and (max-width: 767px) {
    .Imageleft{
        display: none !important;
    }
    .Imagelffeft {
        display: none !important;
    }
}
`

export const StyledRegisterMenu = styled.div`

max-width: 600px;
margin-top: 100px !important;
margin: auto;

& img{
    width: 100%;
    border-radius: 10px 0px 0px 10px;

    /* @media screen and (max-width: 768px) {
        display: none !important;
    } */
}

.cOGUEI {
    margin: auto;
    padding: 0px !important; 
}

.dDHQxW {
    background: #e3f1ff;
    box-shadow: rgba(0, 0, 0, 0.03) 0px 0px 5px 5px;
    margin: auto;
    max-width: 100%;
    padding: 0px !important;
    border-radius: 10px;
}


`

export const StyledLogin = styled.div`
/* padding: 20px;
.ant-btn {
    height: 32px !important;
} */
    padding: 20px;
    background: linear-gradient(270deg, #503F83 -177.09%, #FFFFFF 108.12%),
    linear-gradient(0deg, #CBCBCB, #CBCBCB);
    box-shadow: 0px 4px 10px 0px #00000026;
    /* margin: 0 20px; */
    @media screen and (max-width: 500px) {
        margin: 0;
    }


.ant-btn {
    height: 32px !important;
}
.ant-checkbox .ant-checkbox-inner {
    width: 20px;
    height: 20px;
    margin-right:10px;
}
.ant-checkbox .ant-checkbox-inner:after {
    width: 5px;
    height: 10px;
}
& img  {
    width: 50px;
    border-radius: 0 !important;
    /* @media screen and (max-width: 768px) {
        display: initial;
    } */
}
& h1 {
    color: #000;
    font-size: 20px;
    position: relative;
    margin: 30px 0;
    /* ::after {
        content: '';
        position: absolute;
        width: 20px;
        height: 2px;
        color: red;
        top: 2px;
    } */
}
& p  {
    cursor: pointer;
}
& h2 {
    color: #000;
    font-size: 18px;
    margin: 10px 0;
}
h1::before {
    content: ''; 
    position: absolute;
    width: 40px;
    height: 2px;
    background-color: #000; 
    top: 100%;
    margin: 10px 0;
}
.GGG {
    background: #503F83 !important;
}
/* ------ Input style ---------- */

.ant-input {
    background-color: transparent !important;
    /* border-width: 0 !important; */
    border-radius: 0px ;
    border-bottom: 2px solid;
    border-top: 1px solid #ff000000;
    border-right: 1px solid #ff000000;
    border-left: 1px solid #ff000000;
    :focus{
        box-shadow : 0 0 0 2px rgb(5 145 255 / 0%);
    }
}
.ant-input:focus, :where(.css-dev-only-do-not-override-pr0fja).ant-input-status-error:not(.ant-input-disabled):not(.ant-input-borderless).ant-input-focused {
    border-bottom: 2px solid;
    border-top: 1px solid #ff000000;
    border-right: 1px solid #ff000000;
    border-left: 1px solid #ff000000;
    box-shadow: 0 0 0 2px rgb(255 38 5 / 0%) !important;
}

.ant-input-status-error:not(.ant-input-disabled):not(.ant-input-borderless).ant-input {
    border-bottom: 2px solid;
    border-top: 1px solid #ff000000;
    border-right: 1px solid #ff000000;
    border-left: 1px solid #ff000000; 
}
.ant-input:hover {
    border-bottom: 2px solid !important;
    border-top: 1px solid #ff000000 !important;
    border-right: 1px solid #ff000000 !important;
    border-left: 1px solid #ff000000 !important;
}

/* ---- password input ------  */

.ant-input-affix-wrapper {
    background-color: transparent !important;
    /* border-width: 0 !important; */
    border-radius: 0px ;
    border-bottom: 2px solid;
    border-top: 1px solid #ff000000 !important;
    border-right: 1px solid #ff000000 !important;
    border-left: 1px solid #ff000000 !important;
    transition: all 0.0s !important;
    :focus{
        box-shadow : 0 0 0 2px rgb(5 145 255 / 0%);
    } 
}

/* .ant-input:focus {
    border-bottom: 2px solid #ff000000;
} */



.ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):hover {
    border-radius: 0px ;
    border-bottom: 2px solid !important;
    border-top: 1px solid #ff000000;
    border-right: 1px solid #ff000000;
    border-left: 1px solid #ff000000;
}

/* .ant-input-affix-wrapper:focus {
    box-shadow: 0 0 0 2px rgb(5 145 255 / 0%);
} */

.ant-input-affix-wrapper-status-error:not(.ant-input-affix-wrapper-disabled):not(.ant-input-affix-wrapper-borderless).ant-input-affix-wrapper {
    border-bottom: 2px solid;
    border-top: 1px solid #ff000000;
    border-right: 1px solid #ff000000;
    border-left: 1px solid #ff000000;
}
.ant-input-affix-wrapper:focus {
    box-shadow: 0 0 0 2px rgb(255 38 5 / 0%) !important ;
    border-top: 1px solid #ff000000;
    border-right: 1px solid #ff000000;
    border-left: 1px solid #ff000000;
}
 /* --------- checkbox -------- */
 .ant-checkbox .ant-checkbox-inner {
    border-radius: 0 !important;
    border: 1px solid #00000073;
 }

`
export const Checkinpu = styled.div`
.ant-input:hover {
    border-width: 0 !important;
}
.ant-input:focus {
    border: 0px solid #ff000000 !important;
}
.ant-input-wrapper .ant-input-suffix:hover:not(.ant-input-affix-wrapper-disabled):hover {
    border:1px solid rgb(219 219 219 / 0%) !important;
}
.ant-input-affix-wrapper-status-error:not(.ant-input-affix-wrapper-disabled):not(.ant-input-affix-wrapper-borderless).ant-input-affix-wrapper-focused {
    box-shadow: 0 0 0 2px rgb(255 38 5 / 0%) !important;
}
`

export const StyledConnect = styled.div`
border-radius: 30px !important;
`

export const StyledLoginLeft = styled.div`

/* background: ${THEME.primary_2}; */
margin: auto;
display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    border-radius:10px !important;
    color: #fff;
    font-size: 20px;


& p{
    /* margin: auto; */
    /* justify-content: center;
    display: flex;
    font-family:"Space Mono", sans-serif !important;
    background-color: ${THEME.primary_2}; ;
    align-items: center;
    text-align: center;
    height: 400px;
    font-size: 20px; */
}
`
