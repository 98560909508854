import React from 'react'
import PurchseReturnReport from './Partials/PurchseReturn'

export const PurchseReturntMain = () => {
    return (
        <div>
            <PurchseReturnReport />
        </div>
    )
}
