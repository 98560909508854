import React, { Fragment } from 'react'
import { CustomPageTitle } from '../../../components/CustomPageTitle'
import { ViewExpenseTable } from './Partials/ViewExpenseTable'




export const ViewExpense = () => {
  return (
    <ViewExpenseTable />
  )
}
