import { Col, Form } from 'antd';
import React, { Fragment, useEffect } from 'react'
import { CustomTextArea } from '../../../../components/Form/CustomTextArea';
import { CustomInputNumber } from '../../../../components/Form/CustomInputNumber';
import Flex from '../../../../components/Flex';
import Button from '../../../../components/Form/CustomButton';
import { CustomRow } from '../../../../components/CustomRow';
import { CustomInput } from '../../../../components/Form/CustomInput';
import request from '../../../../utils/request';
import { toast } from 'react-toastify';
import { BiReset } from 'react-icons/bi';


const AddService = ({ servicedata, FormUpdate, Formcancel, Trigger, ItemUpdates, CloseProduct, SaleTrigger, CloseService, QuotationServiceTrigger }) => {

    const [form] = Form.useForm()

    const ADD_SERVICE_URL = 'service/add_service/'

    const EDIT_SERVICE_URL = 'service/edit_service'

    const onFinish = (values) => {
        if (servicedata) {
            UpdateService(servicedata.id, values)
        } else {
            AddService(values)
        }

    }

    const onFinishFailed = (errorInfo) => {
        toast.error("Added Failed")
    };
    const onReset = () => {
        form.resetFields();
    }

    useEffect(() => {
        form.resetFields()
    }, [SaleTrigger, QuotationServiceTrigger])


    useEffect(() => {
        form.setFieldsValue(servicedata)
    }, [servicedata, Trigger])

    const AddService = (values) => {
        request.post(`${ADD_SERVICE_URL}`, values)
            .then(function (response) {
                if (response.status === 201) {
                    toast.success("Service Added Successfully")
                    form.resetFields();
                    if (ItemUpdates) {
                        ItemUpdates()
                    }
                    if (CloseProduct) {
                        CloseProduct()
                    }
                    if (CloseService) {
                        CloseService()
                    }
                }

                if (response.status === 226) {
                    toast.warn('Service Name Details Already exists!')
                }
            })
            .catch(function (error) {
                if (error.response.status === 303) {
                    toast.warn("Add Business Profile")
                }
                else if (error.response.status === 302) {
                    toast.warn("limit exceeded!, Please subscribe your plan!")
                }
                else if (error.response.status === 410) {
                    toast.warn("Your Subscription Over!, Please Add Subscribe your plan!")
                }
                else {
                    toast.error("Something Went Wrong")
                }
            });
    }


    const UpdateService = (id, values) => {
        request.put(`${EDIT_SERVICE_URL}/${id}/`, values)
            .then(function (response) {
                if (response.status === 201) {
                    toast.info("Service Updated Successfully")
                    if (FormUpdate) {
                        FormUpdate()
                    }
                    if (Formcancel) {
                        Formcancel()
                    }
                }
                if (response.status === 226) {
                    toast.warn('Service Name Details Already exists!')
                }
            })
            .catch(function (error) {
                if (error.response.status === 400) {
                    toast.error("Something Went Wrong")
                } else if (error.response.status === 302) {
                    toast.error("Please subscribe your plan!")
                } else {
                    toast.error("Update Failed")
                }
            });
    }

    return (
        <Fragment>
            <Form
                form={form}
                labelCol={{
                    span: 24,
                }}
                wrapperCol={{
                    span: 24,
                }}
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                autoComplete="off">

                <CustomRow space={[24, 24]}>
                    <Col span={24} md={12}>
                        <CustomInput label={'Service Name'} name={'service_name'} placeholder={'Enter Service Name'}
                            rules={[
                                {
                                    required: true,
                                    message: 'Please Enter Service Name!',
                                }
                            ]} />
                    </Col>
                    <Col span={24} md={12}>
                        <CustomInput label={'Service Code'} name={'service_code'} placeholder={'Enter Service Code'}
                            rules={[
                                {
                                    required: true,
                                    message: 'Please Enter Service Code!',
                                }
                            ]} />
                    </Col>
                    <Col span={24} md={12}>
                        <CustomTextArea label={'Description'} name={'description'} placeholder={'Enter Description'}
                           />
                    </Col>
                    <Col span={24} md={12}>
                        <CustomInputNumber label={'Rate'} name={'rate'} placeholder={'Enter Rate'}
                            rules={[
                                {
                                    required: true,
                                    message: 'Please enter rate',
                                },
                            ]} />
                    </Col>
                </CustomRow>


                {
                    servicedata ? (<Flex center={'true'} gap={'20px'} margin={'20px 0px'}>
                        <Button.Primary text={'Update'} htmlType={'submit'} />
                        <Button.Danger text={'Cancel'} onClick={() => Formcancel()} />
                    </Flex>) :
                        <Flex center={'true'} gap={'20px'} margin={'20px 0px'}>
                            <Button.Primary text={'Save'} htmlType={'submit'} />
                            <Button.Danger text={'Reset'} icon={<BiReset style={{ marginRight: '5px' }} />} onClick={() => onReset()} />
                        </Flex>
                }
            </Form>
        </Fragment>
    )
}

export default AddService