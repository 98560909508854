import { Card, Col, Form } from 'antd'
import React, { Fragment, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { CustomRow } from '../../../../components/CustomRow'
import Flex from '../../../../components/Flex'
import Button from '../../../../components/Form/CustomButton'
import { CustomInputNumber } from '../../../../components/Form/CustomInputNumber'
import { CustomMultiSelect } from '../../../../components/Form/CustomMultiSelect'
import { selectVariation, } from '../../ProductSlice'
import { useState } from 'react'
import { CustomInput } from '../../../../components/Form/CustomInput'
import { CustomSelect } from '../../../../components/Form/CustomSelect'
import { CustomCheckBox } from '../../../../components/Form/CustomCheckBox'
import request from '../../../../utils/request'
import { CustomUpload2 } from '../../../../components/Form/CustomUpload2'
import dayjs from 'dayjs'
import { CustomDatePicker } from '../../../../components/Form/CustomDatePicker'
import { toast } from 'react-toastify'

const TableProduct = ({ HandleProduct, handleOk, AddVariationsSubmit, SetDynamicTable, SetDynamicEditTable, handlerecord, trigger, productdata }) => {
  
    const [selectedOptions, setSelectedOptions] = useState([]);
    const [selectedVar, setSelectedVar] = useState([]);
    const [ImageInitialValue, setImageInitialValue] = useState([]);
    const [ischeck, setIscheck] = useState(false)

    const [vartrigger, setVarTrigger] = useState(0);
    const [defaultSelected, setDefaultSelected] = useState([])
    const [allVar, setAllVar] = useState([])

    const [imageUrl, setImageUrl] = useState([])
    const [selectedDate, setSelectedDate] = useState(null);



    const [form] = Form.useForm();      // ----- Define Form
    const AllVariations = useSelector(selectVariation);
    const VariationOptn = AllVariations?.map((item) => ({ label: item.variations_name, value: item.variations_name }));

    const [newData, setNewData] = useState([])
    const [recordVarity, setRecordVarity] = useState([])



    const CallVarity = () => {
        request.get('product/add_variations/')
            .then(resp => {
                setAllVar(resp.data)
            })
            .catch(error => console.log(error, 'error '))
    }

    useEffect(() => {
        if (handlerecord) {
            CallVarity()
            // ===== to set fields values =====
            form.setFieldsValue(handlerecord)
            const formattedDate = dayjs(handlerecord?.product_expiry, 'YYYY-MM-DD')
            { handlerecord.product_expiry && form.setFieldsValue({ product_expiry: formattedDate }) }
            // default selected variation name
            const varityNames = handlerecord?.varity.map(value => value.variation_name);
            setDefaultSelected(varityNames)
            form.setFieldsValue({ variation_option: varityNames })

            //default selected variation value
            setNewData(handlerecord?.varity)

            //default hidden variation name
            add(varityNames)
        }
    }, [handlerecord])


    useEffect(() => {
        if (handlerecord) {
            const varityNames = handlerecord?.varity.map(value => value.variation_name);
            const filteredArray1 = allVar.filter(item => varityNames.includes(item.variations_name));
            setRecordVarity(filteredArray1)
        }
    }, [allVar])


    useEffect(() => {
        if (handlerecord) {
            const setFields = {};
            recordVarity.forEach(item => {
                item.variation_conn.forEach(variation => {
                    newData.forEach(varity => {
                        if (varity.variation_value === variation.variations_value) {
                            setFields[`variations_value${item.id}`] = variation.variations_value;
                        }
                    })
                })
            });
            form.setFieldsValue(setFields)
        }
    }, [recordVarity])


    const onReset = () => {
        if (handlerecord) {
            handleOk()
        } else {
            form.resetFields();
        }
    };

    const handleDate = (date) => {
        setSelectedDate(date);
    }
    const alternateImage = () => {
        const alternateImg = handlerecord?.varity_images?.map(img => ({
            id: img.id,
            variantproduct_image: img.variantproduct_image
        }))
        return alternateImg
    }
    const onFinish = (values) => {
        const formData = new FormData();
        let result = {
            item_hsn: values?.item_hsn,
            item_code: values?.item_code,
            id: handlerecord?.id || null,
            mrp: values?.mrp || null,
            buy_rate: values?.buy_rate || null,
            sale_rate: values?.sale_rate || null,
            dis_percentage: values?.dis_percentage || null,
            gst_percentage: values?.gst_percentage || null,
            available_qty: values?.available_qty || null,
            variant_stock_maintain: ischeck ? 'true' : 'false',
            product_expiry: selectedDate || null,
            variantproduct_image: values?.variantproduct_image || null,
            key: values.key,
            varity_images: imageUrl.length > 0 ? imageUrl : alternateImage() || null,
            varity: Object.entries(values)
                .filter(([key]) => key.startsWith('variations_name'))
                .map(([key, variation_name]) => {
                    const index = key.match(/\d+/)[0];
                    const variationValueKey = `variations_value${index}`;
                    const variantID = handlerecord?.varity?.map((item, i) => {
                        return item.id ? item.id : null
                    })

                    return {
                        variation_name,
                        variation_value: values[variationValueKey],
                        id: variantID
                    };
                }),
        }
        if (handlerecord) {
            SetDynamicEditTable(result)
            handleOk()
            setIscheck(false)
        }
        else {
            SetDynamicTable(result)
            form.resetFields()
            setImageUrl([])
            setIscheck(false)
        }
        setSelectedVar([])
    };
    const onFinishFailed = (errorInfo) => {
        toast.warn("Please fill the details!")
    };

    const handlevarRefresh = (value) => {
        form.setFieldsValue({ variations_value: setVarTrigger(vartrigger + 1) })

        if (value) {
            form.setFieldsValue({ variations_value: '' })
        }
    }

    const add = (currentState, value) => {

        setSelectedOptions(currentState)
        const newState = [];

        currentState?.forEach(variation => {
            const existingIndex = newState.findIndex(item => item.variations_name === variation);

            if (existingIndex === -1) {
                // If the variation doesn't exist, add it
                const variationObject = AllVariations.find(item => item.variations_name === variation);
                if (variationObject) {
                    newState.push(variationObject);
                }
            } else {
                // If the variation exists, remove it
                newState.splice(existingIndex, 1);
                form.resetFields(`variations_value${existingIndex}`)

            }

        });
        const setFields = {};
        newState?.forEach(item => {
            setFields[`variations_name${item.id}`] = item.variations_name;
        });

        form.setFieldsValue(setFields);
        setSelectedVar(newState)
        setRecordVarity(newState)
        handlevarRefresh()

    }

    const hanndleSubOption = (variation) => {
        
    }

    const handleCheck = () => {
        setIscheck(!ischeck)
    }

    const getBase64 = (file) =>
        new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => {
                resolve(reader.result);
            }
            reader.onerror = (error) => {
                reject(error)
            };
        });


    const handleChange = async (img) => {
        if (img.fileList.length > 0) {
            const ImageObj = await Promise.all(img.fileList.map(async (value) => {

                // Assuming getBase64 returns a Promise
                const base64Result = await getBase64(value.originFileObj);

                // Now, you can use base64Result
                const newObj = {
                    id: imageUrl.length + 1,
                    variantproduct_image: base64Result
                }
                return newObj
            }));
            setImageUrl(ImageObj);
        }
    }

    return (
        <Fragment>

            <Card>
                <Form
                    form={form}
                    name={'productAdd'}
                    labelCol={{
                        span: 24,
                    }}
                    wrapperCol={{
                        span: 24,
                    }}
                    onFinish={onFinish}
                    onFinishFailed={onFinishFailed}
                    autoComplete="off"
                >
                    <CustomRow space={[24, 24]}>
                        <Col span={24} md={12}>
                            <CustomMultiSelect
                                maxTagCount={'responsive'}
                                minWidth={'120px'}
                                showSearch
                                label={'Variation Name'}
                                name={'variation_option'}
                                defaultValue={defaultSelected}
                                options={VariationOptn}
                                onChange={add}
                                placeholder={'Select'}
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please Select variation!',
                                    }
                                ]}
                            />
                        </Col>

                        {handlerecord ?
                            recordVarity.map((item) => {
                                const subOption = item.variation_conn.map(option => ({ title: option.variations_value, value: option.variations_value }));

                                return (

                                    <Col span={24} md={12} key={item?.id}>
                                        <CustomInput label={'Variation Name'} display={'none'} name={`variations_name${item.id}`} />
                                        <CustomSelect
                                            style={{
                                                width: 120
                                            }}
                                            rules={[
                                                {
                                                    required: true,
                                                    message: 'This is a required field',
                                                }
                                            ]}
                                            label={`Select ${item?.variations_name}`}
                                            name={`variations_value${item.id}`}
                                            onChange={hanndleSubOption}
                                            options={subOption}
                                        />
                                    </Col>

                                )
                            })
                            :
                            selectedVar?.map((item) => {

                                const subOption = item?.variation_conn ? item.variation_conn.map(option => ({ title: option.variations_value, value: option.variations_value })) : []

                                return (

                                    <Col span={24} md={12} key={item?.id}>
                                        <CustomInput label={'User Name'} display={'none'} name={`variations_name${item.id}`} />
                                        <CustomSelect
                                            style={{
                                                width: 120
                                            }}
                                            rules={[
                                                {
                                                    required: true,
                                                    message: 'This is a required field',
                                                }
                                            ]}
                                            label={`Select ${item?.variations_name}`}
                                            name={`variations_value${item.id}`}
                                            options={subOption}
                                        />
                                    </Col>

                                )
                            })
                        }
                        <Col span={24} md={12}>
                            <CustomInput
                                label={'Item hsn'}
                                name={'item_hsn'}
                                placeholder={'Item Hsn'}
                            />
                            <CustomInput label={'key'} display={'none'} name={`key`} />

                        </Col>

                        <Col span={24} md={12}>
                            <CustomInput
                                label={'Item Code'}
                                name={'item_code'}
                                placeholder={'Item Code'}
                                rules={[
                                    {
                                        required: true,
                                        message: 'This is a required field',
                                    }
                                ]}
                            />
                        </Col>
                        <Col span={24} md={12}>
                            <CustomInputNumber
                                label={'Mrp'}
                                name={'mrp'}
                                placeholder={'Mrp'}
                            />
                        </Col>
                        <Col span={24} md={12}>
                            <CustomInputNumber
                                label={'Buy Rate'}
                                name={'buy_rate'}
                                placeholder={'Buy Rate'}
                            />
                        </Col>
                        <Col span={24} md={12}>
                            <CustomInputNumber
                                label={'Sale Rate'}
                                name={'sale_rate'}
                                placeholder={'Sale Rate'}
                            />
                        </Col>
                        <Col span={24} md={12}>
                            <CustomInputNumber
                                label={'Discount Percentage'}
                                name={'dis_percentage'}
                                placeholder={'DisCount Percentage'}
                                min={0}
                                max={100}
                            />
                        </Col>
                        <Col span={24} md={12}>
                            <CustomInputNumber
                                label={'Gst Percentage'}
                                name={'gst_percentage'}
                                placeholder={'Gst Percentage'}
                                min={0}
                                max={100}
                            />
                        </Col>
                        <Col span={24} md={12}>
                            <CustomInputNumber
                                label={'Available Quantity'}
                                name={'available_qty'}
                                placeholder={'Available Quantity'}
                            />
                        </Col>

                        <Col span={24} md={12}>
                            <CustomDatePicker
                                label={'Product Expiry'}
                                name={'product_expiry'}
                                placeholder={'Product Expiry'}
                                onChange={handleDate}
                            />
                        </Col>
                        {
                            (handlerecord?.varity_images.length > 0) &&
                            <Col span={24} md={20}>
                                Previously Selected Product Variant Images
                                {handlerecord?.varity_images?.map((imgObj) => (
                                    <div key={imgObj.id} >
                                        <img src={imgObj.variantproduct_image} style={{ height: '80px', width: '80px' }} />
                                    </div>
                                ))}
                            </Col>
                        }
                        <Col span={24} md={15}>
                            <CustomUpload2 maxCount={3} multiple={'true'} onChange={handleChange} form={form} initialValue={ImageInitialValue} label={'Product Variation Image (Multi Select)'}
                                name={'variantproduct_image'} listType='picture-card' accept='.png,.jpeg,.jpg'
                            />
                        </Col>
                        <Col span={24} md={9}>
                            <CustomCheckBox
                                label={'Stock Maintain'}
                                name={'variant_stock_maintain'}
                                checked={ischeck}
                                onChange={handleCheck}
                            />
                        </Col>

                    </CustomRow>
                    <Flex center={'true'} gap={'20px'} margin={'20px 0px'}>
                        <Button.Success text={handlerecord ? 'Update' : 'Add'} htmlType={'submit'} />
                        <Button.Danger text={'Cancel'} onClick={() => onReset()} />

                    </Flex>

                </Form>
            </Card>
        </Fragment>
    )
}

export default TableProduct