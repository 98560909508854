import { Col, Form } from 'antd'
import React, { useEffect, useState } from 'react'
import { CustomRow } from '../../../../components/CustomRow'
import Button from '../../../../components/Form/CustomButton'
import Flex from '../../../../components/Flex'
import { CustomTextArea } from '../../../../components/Form/CustomTextArea'
import { CustomInput } from '../../../../components/Form/CustomInput'
import request from '../../../../utils/request'
import { toast } from 'react-toastify'
import { BiReset } from 'react-icons/bi'
import { STATES } from '../../../../assets/CommonData'
import { CustomSelect } from '../../../../components/Form/CustomSelect'
import { RiFileExcel2Fill } from 'react-icons/ri'
import { CustomModal } from '../../../../components/CustomModal'
import { DownloadView } from './ExcelUpload'
import Label from '../../../../components/Form/Label'
export const AddPartyForm = ({ Partydata, FormClose, FornUpdate, Trigger, UpdateSalesParty, SaleHeaderTrigger, GetSaleCustomer, handleOk, purchaseTrigger, formOk, UpdateQuotationParty, QuotationPartyTrigger }) => {
    const [form] = Form.useForm();
    const ADD_PARTY_URL = 'party/add_party/'
    const EDIT_PARTY_URL = 'party/edit_party'
    // ======  Modal Open ========
    const [isModalOpen, setIsModalOpen] = useState(false);
    // ======  Modal Title and Content ========
    const [modalTitle, setModalTitle] = useState("");
    const [modalContent, setModalContent] = useState(null);
    // ----------  Form Reset UseState ---------
    const [modelwith, setModelwith] = useState(0);
    const [trigger, setTrigger] = useState(0)
    // ===== Modal Functions Start =====
    const showModal = () => {
        setIsModalOpen(true);
    };
    const handleOks = () => {
        setIsModalOpen(false);
    };
    const handleCancel = () => {
        setIsModalOpen(false);
    };
    const onFinish = (values) => {
        if (Partydata) {
            UpdateParty(Partydata.id, values)
        } else {
            AddParty(values)
        }
    }
    const onFinishFailed = (errorInfo) => {
        toast.error("Added Failed")
    }
    const StatesOptions = STATES?.map((state) => ({
        label: state?.name,
        value: state?.name
    }))
    const handleSelectState = (value) => {
    };
    useEffect(() => {
        form.resetFields()
    }, [purchaseTrigger, SaleHeaderTrigger, QuotationPartyTrigger])
    useEffect(() => {
        form.setFieldsValue(Partydata)
    }, [Partydata, Trigger, UpdateSalesParty, UpdateQuotationParty])
    const onReset = () => {
        form.resetFields()
        if (UpdateSalesParty) {
            UpdateSalesParty()
        }
        if (UpdateQuotationParty) {
            UpdateQuotationParty()
        }
    }
    const AddParty = (values) => {
        request.post(`${ADD_PARTY_URL}`, values)
            .then(function (response) {
                form.resetFields();
                if (response.status === 201) {
                    toast.success("Party Added Successfully")
                    if (GetSaleCustomer) {
                        GetSaleCustomer()
                        handleOk()
                    }
                    if (UpdateSalesParty) {
                        UpdateSalesParty()
                    }
                    if (UpdateQuotationParty) {
                        UpdateQuotationParty()
                    }
                }
                if (response.status === 226) {
                    toast.warn('Already used Mobile Number!')
                }
            })
            .catch(function (error) {
                if (error.response && error.response.status === 400) {
                    if (error.response.data) {
                        if (error.response.data.gstin) {
                            toast.warn(error.response.data.gstin[0]);
                        } else if (error.response.data.mobile_number) {
                            toast.warn(error.response.data.mobile_number[0]);
                        } else {
                            toast.error('Invalid input.');
                        }
                    } else {
                        toast.error('Invalid input.');
                    }
                }
                else if (error.response.status === 303) {
                    toast.warn("Add Business Profile")
                }
                else if (error.response.status === 304) {
                    toast.warn("Party limit exceeded, Please subscribe your Plan!")
                }
                else if (error.response.status === 408) {
                    toast.warn("Your plan is over. Please subscribe to the plan!")
                } else {
                    toast.error("Something Went Wrong")
                }
            });
    }
    const UpdateParty = (id, values) => {
        request.put(`${EDIT_PARTY_URL}/${id}/`, values)
            .then(function (response) {
                if (response.status === 201) {
                    toast.info("Party Updated Successfully")
                    if (FornUpdate) {
                        FornUpdate()
                    }
                    if (FormClose) {
                        FormClose()
                    }
                }
                if (response.status === 226) {
                    toast.warn('Already used Mobile Number!')
                }
            })
            .catch(function (error) {
                if (error.response && error.response.status === 400) {
                    if (error.response.data) {
                        if (error.response.data.gstin) {
                            toast.warn(error.response.data.gstin[0]);
                        } else if (error.response.data.mobile_number) {
                            toast.warn(error.response.data.mobile_number[0]);
                        } else {
                            toast.error('Invalid input.');
                        }
                    } else {
                        toast.error('Invalid input.');
                    }
                }
                else if (error.response.status === 303) {
                    toast.warn("Add Business Profile")
                }
                else if (error.response.status === 304) {
                    toast.warn("Party limit exceeded, Please subscribe your Plan!")
                }
                else if (error.response.status === 408) {
                    toast.warn("Please subscribe your plan!")
                } else {
                    toast.error("Something Went Wrong")
                }
            });
    }
    const DownLoadExcel = (record) => {
        setTrigger(trigger + 1)
        setModelwith(500)
        setModalContent(<DownloadView  ExcelTrigger={trigger} />);
        showModal();
    };
    return (
        <Form
            form={form}
            labelCol={{
                span: 24,
            }}
            wrapperCol={{
                span: 24,
            }}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            autoComplete="off"
        >
            {Partydata ? null :
                <>
                <Flex end={'true'}>
                <Label>Bulk Upload</Label>
                </Flex>
                    <Flex end={'true'} style={{margin:'5px -5px'}}>
                        <Button.Success text={<RiFileExcel2Fill style={{ fontSize: '30px' }} onClick={DownLoadExcel} />} />
                    </Flex>
                </>
            }
            <CustomRow space={[12, 12]}>
                <Col span={24} md={12}>
                    <CustomInput name={'party_name'} label={'Party Name *'} placeholder={'Enter Party Name'} rules={[
                        {
                            required: true,
                            message: 'Please Enter Party Name !',
                        }
                    ]} />
                </Col>
                <Col span={24} md={12}>
                    <CustomInput name={'mobile_number'} label={'Mobile Number *'} placeholder={'Enter Mobile Number'} maxLength={10}
                        minLength={10}
                        onKeyPress={(event) => {
                            if (!/[0-9]/.test(event.key)) {
                                event.preventDefault();
                            }
                        }}
                        rules={[
                            {
                                required: true,
                                message: 'This is Required Field!',
                            },
                            {
                                min: 10,
                                required: true,
                                message: 'Please Enter 10 Digit Mobile Number !',
                            }
                        ]} />
                </Col>
                <Col span={24} md={12}>
                    <CustomInput name={'gstin'} label={'GSTIN'} placeholder={'Enter GSTIN'} />
                </Col>
                <Col span={24} md={12}>
                    <CustomInput label={'Email ID'} placeholder={'Enter EmailID'} name={'email'} type={'email'} />
                </Col>
                <Col span={24} md={12}>
                    <CustomSelect
                        options={StatesOptions}
                        showSearch={true}
                        onChange={handleSelectState}
                        label={'State Of Supply'}
                        name={'state_of_party'}
                        placeholder={'Enter State Of Supply'}
                    />
                </Col>
                <Col span={24} md={12}></Col>
                <Col span={24} md={12}>
                    <CustomTextArea label={'Billing Address'} name={'billing_address'} placeholder={'Enter Address'} />
                </Col>
                <Col span={24} md={12}>
                    <CustomTextArea label={'Shipping Address'} name={'shipping_address'} placeholder={'Enter Address'} />
                </Col>
            </CustomRow>
            {
                Partydata ? (
                    <Flex center={'true'} gap={'20px'} margin={'20px 0'}>
                        <Button.Primary text={'Update'} htmlType={'submit'} />
                        <Button.Danger text={'Cancel'} onClick={() => FormClose()} />
                    </Flex>
                ) :
                    <Flex center={'true'} gap={'20px'} margin={'20px 0px'}>
                        <Button.Primary text={'Save'} htmlType={'submit'} />
                        <Button.Danger text={'Reset'} icon={<BiReset style={{ marginRight: '5px' }} />} onClick={() => onReset()} />
                    </Flex>
            }
            <CustomModal isVisible={isModalOpen} handleOk={handleOks} handleCancel={handleCancel} width={modelwith} modalTitle={modalTitle} modalContent={modalContent} />
        </Form>
    )
}




