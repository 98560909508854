

import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import request from "../../utils/request";

const initialState = {
    unit: [],
    brand:[]
}

// ======   unit    =========
export const getUnit = createAsyncThunk(
    "Unit/Get",
    async () => {
        try {
            const response = await request.get('product/add_unit/');
            return [...response.data];
        }
        catch (error) {
            throw error;
        }
    }
);

// ======== brand ==========

export const getBrand = createAsyncThunk(
    "Brand/Get",
    async () => {
        try {
            const response = await request.get('product/add_brand/');
            return [...response.data];
        }
        catch (error) {
            throw error;
        }
    }
);

const AddModuleReducer = createSlice({
    name: 'AddModules',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getUnit.fulfilled, (state, action) => {
                state.status = 'succeeded'
                state.unit = action.payload;
            })
            .addCase(getBrand.fulfilled, (state, action) => {
                state.status = 'succeeded'
                state.brand = action.payload;
            })
    }
})


export const getAllUnit = (state) => state.addModules.unit
// export const getAllBrand = (state) => state.addModulesbrand.brand
export const getAllBrand = (state) => state.addModules.brand
export const getStatus = (state) => state.addModules.status
export const getError = (state) => state.addModules.error


export const { reducer } = AddModuleReducer

export default AddModuleReducer.reducer
