import React, { useEffect, useState } from 'react'
import { CustomCardView } from '../../../components/CustomCardView'
import { Col, Form } from 'antd';
import { CustomRow } from '../../../components/CustomRow';
import { CustomSelect } from '../../../components/Form/CustomSelect'
import dayjs from 'dayjs';
import Flex from '../../../components/Flex';
import Button from '../../../components/Form/CustomButton';
import { CustomDateRangePicker } from '../../../components/Form/CustomDateRangePicker'
import { TbArrowsExchange } from 'react-icons/tb';
import { CustomModal } from '../../../components/CustomModal';
import { CustomPageTitle } from '../../../components/CustomPageTitle';
import { CustomTable } from '../../../components/Form/CustomTable';
import { useNavigate } from 'react-router-dom';
import request from '../../../utils/request';
import ButtonStandard from '../../../components/Form/CustomStandardButton';
import { toast } from 'react-toastify';
import { TableIconHolder } from '../../../components/CommonStyled';
import { AiOutlineEye } from 'react-icons/ai';
import { THEME } from '../../../theme';
import { PrintView } from './PrintView';
import { getpurchase, getQuotation, getQuotationError, getQuotationStatus, selectAllQuotation, } from '../QuotationSlice';
import { useDispatch, useSelector } from 'react-redux';
import { CommonLoading } from '../../../components/CommonLoading';
import { ViewQuotationBill } from './ViewQuotationBill';
import { CustomSwitch } from '../../../components/Form/CustomSwitch';
import { CustomInput } from '../../../components/Form/CustomInput';
import Label from '../../../components/Form/Label';
import { CustomTandardTable } from '../../../components/Form/CustomStandardTable';

export const ViewQuotationPage = () => {

    const [form] = Form.useForm();

    const dispatch = useDispatch();

    const SEARCH_URL = 'quotation/quotation_search/'

    const [viewSale, setViewSale] = useState([])

    const [choiceFull, setChoiceFull] = useState('')
    const [dateRange, setDateRange] = useState([]);

    const [show, setShow] = useState(false)
    const [modalContent, setModalContent] = useState(null);
    const [modalTitle, setModalTitle] = useState("");
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [modalWidth, setModalWidth] = useState(null);

    const [showProductTable, setShowProductTable] = useState(false);

    const [searchTexts, setSearchTexts] = useState([])


    const showModal = () => {
        setIsModalOpen(true);
    };

    const nevigate = useNavigate();

    const handleOk = () => {
        setIsModalOpen(false);
    };

    const handleCancel = () => {
        setIsModalOpen(false);
    };

    const handleDateRangeChange = (values) => {
        setDateRange(values);
    };

    const AllQuotation = useSelector(selectAllQuotation)
    const QuotationStatus = useSelector(getQuotationStatus)
    const QutationError = useSelector(getQuotationError)

    const handleMonthSelect = (value) => {
        form.setFieldsValue({ 'range': null })
        if (value === 'custom') {
            setShow(true);
            setChoiceFull(value)
        } else {
            setShow(false);
        }

        const choice = {
            choice: value
        }

        DateSearch(choice)
    };

    const DateSearch = (values) => {
        request.post(`${SEARCH_URL}`, values)
            .then(function (response) {
                setViewSale(response.data?.sale)
            })
            .catch(function (error) {
                console.log(error);
            });

    }

    const month = [
        { label: 'This Month', value: 'This_month' },
        { label: 'Year', value: 'This_year' },
        { label: 'Quater', value: 'This_quorter' },
        { label: 'Custom', value: 'custom' },
    ]

    const onFinish = (values) => {

        const record = { ...values, range: dateRange, choice: choiceFull }
        DateSearch(record)
    }

    const onFinishFailed = (errorInfo) => {
        toast.warn("Please fill the details!")
    };

    const PurchasePrint = async (record) => {
        try {
            const response = await request.get(`quotation/print_quotation/${record?.id}/`);
            setModalWidth(1200)
            setModalTitle("Quotation Details");
            setModalContent(<ViewQuotationBill record={response.data} />);
            showModal();
            return response.data;
        }
        catch (error) {
            throw error;
        }
    };


    useEffect(() => {
        dispatch(getQuotation());
    }, []);


    // useEffect(() => {
    //     setViewSale(AllQuotation?.sale)
    // }, [AllQuotation])

    const columns = [
        {
            title: 'Sl No',
            render: (item, value, index) => index + 1,
        },
        {
            title: 'Date',
            dataIndex: 'invoice_date',
        },
        {
            title: 'Party Name',
            dataIndex: 'party_name',
            filteredValue: searchTexts ? [searchTexts] : null,
            onFilter: (value, record) => {
                return String(record.party_name).toLowerCase().includes(value.toLowerCase()) ||
                    String(record.party_name).includes(value.toUpperCase());
            },
        },
        {
            title: 'Receipt No',
            dataIndex: 'ref_no',
            onFilter: (value, record) => {
                return String(record.ref_no).toLowerCase().includes(value.toLowerCase()) ||
                    String(record.ref_no).includes(value.toUpperCase());
            },
        },
        {
            title: 'Total Amount',
            dataIndex: 'grand_total',
        },
        // {
        //     title: 'Received Amount',
        //     dataIndex: 'received_amt',
        // },
        {
            title: 'Action',
            render: (record) => {
                return (
                    <Flex spaceEvenly={'true'}>
                        <TableIconHolder color={THEME.green} size={'22px'} onClick={() => PurchasePrint(record)}>
                            <AiOutlineEye />
                        </TableIconHolder>
                    </Flex>
                )
            }
        }

    ];

    const services = [
        {
            title: 'Sl No',
            render: (item, value, index) => index + 1,
        },
        {
            title: 'Date',
            dataIndex: 'invoice_date',
        },
        {
            title: 'Party Name',
            dataIndex: 'party_name',
            filteredValue: searchTexts ? [searchTexts] : null,
            onFilter: (value, record) => {
                return String(record.party_name).toLowerCase().includes(value.toLowerCase()) ||
                    String(record.party_name).includes(value.toUpperCase());
            },
        },
        {
            title: 'Bill No',
            dataIndex: 'ref_no',
            onFilter: (value, record) => {
                return String(record.ref_no).toLowerCase().includes(value.toLowerCase()) ||
                    String(record.ref_no).includes(value.toUpperCase());
            },
        },

        {
            title: 'Total Amount',
            dataIndex: 'grand_total',
        },
        {
            title: 'Action',
            render: (record) => {
                return (
                    <Flex spaceEvenly={'true'}>
                        <TableIconHolder color={THEME.green} size={'22px'} onClick={() => PurchasePrint(record)}>
                            <AiOutlineEye />
                        </TableIconHolder>
                    </Flex>
                )
            }
        }
    ];

    useEffect(() => {
        GetPurchase()
    }, [])

    const GetPurchase = () => {
        request.get('quotation/quotation_search/')
            .then(function (response) {
                console.log(response.data);
                setViewSale(response.data?.sale)
            })
            .catch(function (error) {
                if (error.response.status === 303) {
                    toast.error("Add Business Profile")
                }
            });
    }



    let content;
    let content1;

    if (viewSale) {
        const productData = viewSale.filter((item) => item.item_choice === 'Product');
        const serviceData = viewSale.filter((item) => item.item_choice === 'Service');
    

        if (QuotationStatus === 'loading') {
            content = <CommonLoading />
        } else if (QuotationStatus === 'succeeded') {
            const rowKey = (viewSale) => viewSale.id;
            content = <CustomTandardTable columns={columns} data={productData} rowKey={rowKey} />
        } else if (QuotationStatus === 'failed') {
            const rowKey = (viewSale) => viewSale.id;
            content = <CustomTandardTable columns={columns} data={productData} rowKey={rowKey} />
        }

        if (QuotationStatus === 'loading') {
            content1 = <CommonLoading />
        } else if (QuotationStatus === 'succeeded') {
            const rowKey = (viewSale) => viewSale.id;
            content1 = <CustomTandardTable columns={services} data={serviceData} rowKey={rowKey} />
        } else if (QuotationStatus === 'failed') {
            const rowKey = (viewSale) => viewSale.id;
            content = <CustomTandardTable columns={columns} data={productData} rowKey={rowKey} />
        }
    }

    const handleServiceSwitch = (isChecked) => {
        setShowProductTable(isChecked);
    }

    const handleSearchs = (value) => {
        setSearchTexts(value);
    };


    return (
        <>
            <CustomPageTitle Heading={'View Quotation'} />
            <CustomCardView>
                <Form
                    form={form}
                    labelCol={{
                        span: 24,
                    }}
                    wrapperCol={{
                        span: 24,
                    }}
                    initialValues={
                        {
                            start_date: dayjs(),
                            end_date: dayjs(),
                            payment_type: 'Cash'
                        }
                    }
                    onFinish={onFinish}
                    onFinishFailed={onFinishFailed}
                    autoComplete="off">
                    <CustomRow space={[24, 24]} style={{ padding: '10px 0' }}>
                        <Col span={24} md={3} style={{ marginTop: '10px' }}>
                            <b>Choose</b>&nbsp;&nbsp;
                        </Col>
                        <Col span={24} md={12}>
                            <CustomSelect
                                options={month}
                                name={'month'}
                                value={dateRange}
                                placeholder={'This Month'}
                                onChange={handleMonthSelect}
                                rules={[{ required: true, message: 'Please Select the Month' }]}
                            />
                        </Col>
                        <Col span={24} md={9} style={{ float: 'inline-end' }}>
                            <Flex end={true} >
                                <ButtonStandard.Primary text={'Add Quotation'} style={{ marginRight: '10px' }} onClick={() => nevigate(`/quotation`)} />
                            </Flex>
                        </Col>
                    </CustomRow>

                    <CustomRow space={[24, 24]} style={{ padding: '10px 0' }}>
                        {show ?
                            <>
                                <Col span={24} md={3} style={{ marginTop: '10px' }}>
                                    <b>Between</b>&nbsp;&nbsp;<TbArrowsExchange />
                                </Col>

                                <Col span={24} md={12}>
                                    <CustomDateRangePicker
                                        onChange={handleDateRangeChange}
                                        name={'range'}
                                        value={dateRange}
                                        rules={[{ required: true, message: 'Please Select the Date' }]}
                                    />
                                </Col>

                                <Col span={24} md={6}>
                                    <Flex>
                                        <Button.Primary text={'Submit'} htmlType='submit' />
                                    </Flex>
                                </Col>
                            </>
                            : null}

                    </CustomRow>
                </Form>
                <br /><br />

                <Flex alignCenter spaceBetween>
                    <CustomSwitch onChange={handleServiceSwitch} leftLabel={'Product'} rightLabel={'Service'} />


                </Flex>
                <div style={{ display: 'flex', alignItems: 'center', marginTop: '50px' }}>
                    <Label style={{ marginRight: '20px' }}>Search by Party Name :</Label>
                    <CustomInput
                        value={searchTexts}
                        // label="Search by Party Name"
                        placeholder="Search by Party Name"
                        onSearch={handleSearchs}
                        onChange={(e) => handleSearchs(e.target.value)}
                    />
                </div>


                <CustomRow space={[12, 12]}>
                    <Col span={24} md={24}>
                        {showProductTable ? content1 : content}
                    </Col>
                    <CustomModal isVisible={isModalOpen} handleOk={handleOk} handleCancel={handleCancel} width={modalWidth} modalTitle={modalTitle} modalContent={modalContent} />
                </CustomRow>

            </CustomCardView >

        </>
    )
}

