import React, { Fragment, useEffect, useState } from 'react'
import { CustomModal } from '../../../../components/CustomModal';
import { Card, Col, Divider, Form, Image } from 'antd';
import { CustomRow } from '../../../../components/CustomRow';
import { CustomInput } from '../../../../components/Form/CustomInput';
import Flex from '../../../../components/Flex';
import Button from '../../../../components/Form/CustomButton';
import { CustomTextArea } from '../../../../components/Form/CustomTextArea';
import { CustomAddSelect } from '../../../../components/Form/CustomAddSelect';
import { toast } from 'react-toastify';
import request from '../../../../utils/request'
import { BiReset } from 'react-icons/bi';
import { BsPlus } from 'react-icons/bs'
import { CustomSelect } from '../../../../components/Form/CustomSelect';
import AddProductUnit from '../../../AddSubModule/ProductUnit/ProductUnit';
import ProductBrand from '../../../AddSubModule/Brand/ProductBrand';
import { CustomCheckBox } from '../../../../components/Form/CustomCheckBox';
import { CustomUpload } from '../../../../components/Form/CustomUpload';
import { CustomTable } from '../../../../components/Form/CustomTable';
import { useDispatch, useSelector } from 'react-redux';
import { getAllBrand, getBrand } from '../../../AddSubModule/UnitSlice';
import { getCategory, getSubCategory, getTableProducts, getVariation, selectAllCategory, selectAllSubCategory, selectVariation, setVariationsAdd, ViewVariationsValues } from '../../ProductSlice';
import AddCategory from '../../../AddSubModule/Category/AddCategory';
import { Variation } from '../../../AddSubModule/AddVariations/Variation';
import TableProduct from './VariationAdd';
import { IoMdRemoveCircle, IoMdRemoveCircleOutline } from 'react-icons/io'
import { RemoveBtn } from './style';
import { AiFillPlusCircle } from 'react-icons/ai';
import { CommonLoading } from '../../../../components/CommonLoading';
import { CustomInputNumber } from '../../../../components/Form/CustomInputNumber';



const AddProduct = ({ productdata, handleProductGet, Formcancel, FormUpdateProduct, Trigger, CloseProduct, ItemUpdates, SaleTrigger, GetPurchaseTable, purchaseOk, productTrigger }) => {
    console.log(productdata, 'productdata');
    const [form] = Form.useForm()

    // const ADD_PRODUCT_URL = 'product/add_productes/'
    const ADD_PRODUCT_URL = 'product/add_productes_form/'

    const UNIT_GET_URL = 'product/add_unit/'

    const EDIT_PRODUCT_URL = 'product/form_data_edit_productes'

    const SELECTCATEGORY = 'product/get_subcategory'

    const [varData, setVarData] = useState([])

    const [selectSubcatery, setSelectSubcatery] = useState([])
    const [varValues, setVarValues] = useState({})
    const [VarValueId, setVarValueId] = useState([])

    const [unitArray, setUnitArray] = useState([])

    const [formUpdate, setFormUpdate] = useState(0);

    const [formUpdatebrand, setFormUpdatebrand] = useState(0);

    const [formUpdateCategory, setFormUpdateCategory] = useState(0);

    const [formUpdatesubCatry, setFormUpdatesubCatry] = useState(0);


    const [checked, setChecked] = useState(false)

    // ======  Modal Open ========
    const [isModalOpen, setIsModalOpen] = useState(false);

    // ======  Modal Title and Content ========
    const [modalTitle, setModalTitle] = useState("");
    const [modalContent, setModalContent] = useState(null);

    // ----------  Form Reset UseState ---------
    const [formReset, setFormReset] = useState(0);
    const [modelwith, setModelwith] = useState(0);

    const [trigger, setTrigger] = useState(0);

    const [productImage, setProductImage] = useState([])

    const [initialTrigger, setInitialTrigger] = useState(0)
    const [isLoading, setIsLoading] = useState(false);


    useEffect(() => {
        form.resetFields()
    }, [productdata, SaleTrigger])

    const settingTableData = () => {
        const tableData = productdata?.product.map((value, index) => ({
            ...value,
            key: index
        }));

        setDynamicTableData(tableData);
    }

    useEffect(() => {
        if (productdata?.product) {
            settingTableData()
        }
    }, [productdata]);

    //======    useEffect to SetFields  ======
    useEffect(() => {
        if (productdata) {
            handlesubpost(productdata.main_category)
            form.setFieldsValue(productdata)
            setChecked(productdata.stock_maintain)
            setInitialTrigger(initialTrigger + 1)
        }
    }, [productdata, Trigger, FormUpdateProduct])

    // =============  Dynamic Table Data

    // For Showing on Table 
    const [dynamicTableData, setDynamicTableData] = useState([])

    const tableProducts = useSelector(getTableProducts)

    // ===== Modal Functions Start =====

    const showModal = () => {
        setIsModalOpen(true);
        // productdata(true)
    };

    const handleOk = () => {
        setIsModalOpen(false);
    };

    const handleCancel = () => {
        setIsModalOpen(false);
    };

    const onReset = () => {
        form.resetFields();
        setDynamicTableData([])
        setChecked(false)
    }



    const dispatch = useDispatch();
    const VariationsValues = useSelector(ViewVariationsValues)

    const adding = (values) => {
        const NewValue = { ...values, product: dynamicTableData, manage_stock: checked ? true : false }
        if (NewValue.product.length > 0) {
            const formData = new FormData();
            formData.append('product_name', NewValue?.product_name);
            formData.append('item_unit', NewValue?.item_unit);
            formData.append('brand_name', NewValue?.brand_name);
            formData.append('category', NewValue?.category);
            formData.append('sub_category', NewValue?.sub_category || null);
            formData.append('description', NewValue?.description || null);
            formData.append('main_category', NewValue?.main_category || null)
            formData.append('stock_maintain', checked)
            formData.append('alert_qnty', checked ? NewValue?.alert_qnty : 0)
            NewValue.product_image && NewValue.product_image.forEach((prodImg, prodIndex) => {
                formData.append(`product_image[${prodIndex + 1}]`, prodImg?.originFileObj)
            })
            formData.append(`[productImage_count]`, NewValue.product_image?.length || 0);
            formData.append(`[product_count]`, NewValue.product.length);
            NewValue?.product.forEach((element, index) => {
                formData.append(`product[${index + 1}][available_qty]`, element?.available_qty || null)
                formData.append(`product[${index + 1}][buy_rate]`, element?.buy_rate || null)
                formData.append(`product[${index + 1}][dis_percentage]`, element?.dis_percentage || null)
                formData.append(`product[${index + 1}][gst_percentage]`, element?.gst_percentage || null)
                formData.append(`product[${index + 1}][item_code]`, element?.item_code)
                formData.append(`product[${index + 1}][item_hsn]`, element?.item_hsn || null)
                formData.append(`product[${index + 1}][mrp]`, element?.mrp || null)
                formData.append(`product[${index + 1}][sale_rate]`, element?.sale_rate || null)
                formData.append(`product[${index + 1}][variantproduct_image_count]`, element.variantproduct_image?.length || 0);
                formData.append(`product[${index + 1}][variation_count]`, element.varity.length);
                formData.append(`product[${index + 1}][variant_stock_maintain]`, element.variant_stock_maintain);
                formData.append(`product[${index + 1}][product_expiry]`, element.product_expiry);
                element?.variantproduct_image?.forEach((image, imgIndex) => {
                    formData.append(`product[${index + 1}][variantproduct_image][${imgIndex + 1}]`, image.originFileObj);
                })
                element?.varity.forEach((variation, variationIndex) => {
                    formData.append(`product[${index + 1}][varity][${variationIndex + 1}][variation_name]`, variation.variation_name);
                    formData.append(`product[${index + 1}][varity][${variationIndex + 1}][variation_value]`, variation.variation_value);
                });
            })
            AddProduct(formData)
        } else {
            toast.warning('Please Add Product Variation')
        }
    }

    const updating = (values) => {
        const NewValue = { ...values, product: dynamicTableData, manage_stock: checked ? true : false }


        if (NewValue.product.length > 0) {
            const formData = new FormData();
            formData.append('product_name', NewValue?.product_name);
            formData.append('item_unit', NewValue?.item_unit);
            formData.append('brand_name', NewValue?.brand_name);
            formData.append('category', NewValue?.category);
            formData.append('sub_category', NewValue?.sub_category || null);
            formData.append('description', NewValue?.description || null);
            formData.append('main_category', NewValue?.main_category || null)
            formData.append('stock_maintain', checked)
            formData.append('alert_qnty', checked ? NewValue?.alert_qnty : 0)
            formData.append('id', productdata.id)
            NewValue.product_image && NewValue.product_image.forEach((prodImg, prodIndex) => {
                formData.append(`product_image[${prodIndex + 1}]`, prodImg?.originFileObj)
            })
            formData.append(`[productImage_count]`, NewValue.product_image?.length || 0);
            formData.append(`[product_count]`, NewValue.product.length);
            NewValue?.product.forEach((element, index) => {
                formData.append(`product[${index + 1}][available_qty]`, element?.available_qty || null)
                formData.append(`product[${index + 1}][buy_rate]`, element?.buy_rate || null)
                formData.append(`product[${index + 1}][dis_percentage]`, element?.dis_percentage || null)
                formData.append(`product[${index + 1}][gst_percentage]`, element?.gst_percentage || null)
                formData.append(`product[${index + 1}][item_hsn]`, element?.item_hsn || null)
                formData.append(`product[${index + 1}][mrp]`, element?.mrp || null)
                formData.append(`product[${index + 1}][sale_rate]`, element?.sale_rate || null)
                formData.append(`product[${index + 1}][item_code]`, element?.item_code)
                formData.append(`product[${index + 1}][variantproduct_image_count]`, element.variantproduct_image?.length || 0);
                formData.append(`product[${index + 1}][variation_count]`, element.varity.length);
                formData.append(`product[${index + 1}][variant_stock_maintain]`, element.variant_stock_maintain);
                formData.append(`product[${index + 1}][product_expiry]`, element.product_expiry);
                formData.append(`product[${index + 1}][id]`, element.id);
                element?.variantproduct_image?.length > 0 && element?.variantproduct_image?.forEach((image, imgIndex) => {
                    formData.append(`product[${index + 1}][variantproduct_image][${imgIndex + 1}]`, image.originFileObj);
                })
                element?.varity.forEach((variation, variationIndex) => {
                    formData.append(`product[${index + 1}][varity][${variationIndex + 1}][variation_name]`, variation.variation_name);
                    formData.append(`product[${index + 1}][varity][${variationIndex + 1}][variation_value]`, variation.variation_value);
                    formData.append(`product[${index + 1}][varity][${variationIndex + 1}][id]`, variation.id || null);
                });
            })
            UpdateProduct(formData)
        } else {
            toast.warning('Please Add Product Variation')
        }
    }


    const onFinish = (values) => {

        if (productdata) {
            updating(values)
        } else {
            adding(values)
        }
    };
    const onSubmit = () => {
        form.submit();
    }


    const onFinishFailed = (errorInfo) => {
        toast.warn('Please Fill the Details!')
    };


    const UpdateProduct = (values) => {
        request.put(`${EDIT_PRODUCT_URL}/${productdata.id}/`, values)
            .then(function (response) {
                toast.info("Product Updated Successfully")
                if (FormUpdateProduct) {
                    FormUpdateProduct()
                }
                if (Formcancel) {
                    Formcancel()
                }
                if (handleProductGet) {
                    handleProductGet();
                }
            })
            .catch(function (error) {
                if (error.response.status === 400) {
                    toast.error("Something Went Wrong")
                } else if (error.response.status === 406) {
                    toast.warning("This variant is used in billing section")
                    settingTableData()
                }
                else {
                    toast.error("Update Failed")
                }
            });
    }

    const AddProduct = (values) => {
        setIsLoading(true)
        request.post(`${ADD_PRODUCT_URL}`, values)
            .then(function (response) {
                setDynamicTableData([])
                onReset()
                if (response.status === 226) {
                    toast.warn('This Product already added')
                    setIsLoading(false)
                } else {
                    if (GetPurchaseTable) {
                        GetPurchaseTable()
                        purchaseOk()
                    }
                    if (CloseProduct) {
                        CloseProduct()
                    }
                    if (ItemUpdates) {
                        ItemUpdates()
                    }

                    toast.success("Product Added Successfully")
                    setIsLoading(false)
                }

            })
            .catch(function (error) {
                console.error(error, 'errorrr');
                setIsLoading(false)
                if (error.response?.status === 302) {
                    toast.warn(error.response.data.message)
                }


            });
    }


    const onViewRow = () => {
        setTrigger(trigger + 1)
        setModelwith(500)
        setModalTitle("Add Unit");
        setModalContent(<AddProductUnit handleClose={handleOk} FormUpdateCall={FormUpdateCall} formReset={FormReset} GetUnit={GetUnit} />);
        showModal();
    };

    const onViewBrand = () => {
        setTrigger(trigger + 1)
        setModelwith(500)
        setModalTitle("Add Brand");
        setModalContent(<ProductBrand handleClose={handleOk} formReset={FormReset} FormUpdateBrand={FormUpdateCallBrand} handleGetbarnd={handleGetbarnd} />);
        showModal();
    }

    const onViewCategory = () => {
        setTrigger(trigger + 1)
        setModelwith(500)
        setModalTitle("Add Category");
        setModalContent(<AddCategory handlesubpost={handlesubpost} handleClose={handleOk} formReset={FormReset} FormUpdatecatry={FormUpdateCategory} UpdateSunCategory={FormUpdateSubCategory} />);
        showModal();
    }

    const handleAddvariations = () => {
        dispatch(getVariation())
    }

    const variationAdd = (record) => {
        setTrigger(trigger + 1)
        setModelwith(600)
        setModalTitle("Add Variations");
        setModalContent(<Variation handleClose={handleOk} formReset={FormReset} productdata={record} handleAddvariations={handleAddvariations} />);
        showModal();
    }

    const onEditVariant = (record) => {
        setTrigger(trigger + 1)
        setModelwith(800)
        setModalTitle("Update Category");
        setModalContent(<TableProduct handleOk={handleOk} SetDynamicEditTable={SetDynamicEditTable} SetDynamicTable={SetDynamicTable} handleClose={handleOk} formReset={FormReset} trigger={trigger} FormUpdatecatry={FormUpdateCategory} UpdateSunCategory={FormUpdateSubCategory} productdata={productdata} handlerecord={record} />);
        showModal();
    }

    useEffect(() => {
        GetUnit()
    }, [formUpdate])

    const GetUnit = () => {
        request.get(`${UNIT_GET_URL}`)
            .then(function (response) {
                setUnitArray(response.data);
            })
            .catch(function (error) {
                console.log(error)
            });
    }

    //   =========== unit =========

    const UnitOption = unitArray?.map((item) => ({
        label: `${item.unit_name} (${item.unit_short_name})`,
        value: item.unit_name
    }));

    const FormUpdateCall = () => {
        setFormUpdate(formUpdate + 1)
    }

    const FormReset = () => {
        setFormReset(formReset + 1)
    }

    //   =========== brand =========


    const AllBrands = useSelector(getAllBrand)

    const FormUpdateCallBrand = () => {
        setFormUpdatebrand(formUpdatebrand + 1)
    }

    useEffect(() => {
        dispatch(getBrand())
    }, [formUpdatebrand])


    const BrandOption = AllBrands?.map((item) => ({ label: item.brand_name, value: item.brand_name }));
    const handleGetbarnd = () => {
        dispatch(getBrand())
    }

    //   =========== category =========

    const AllCategory = useSelector(selectAllCategory)

    const FormUpdateCategory = () => {
        setFormUpdateCategory(formUpdateCategory + 1)
    }

    useEffect(() => {
        dispatch(getCategory())
    }, [formUpdateCategory])


    const CategoryOption = AllCategory?.map((item) => ({ label: item.category, value: item.category }));
    const [selectedid, setSelectedid] = useState({})

    useEffect(() => {
        form.setFieldsValue({ id: selectedid?.id })
    }, [selectedid])

    //   =========== sub category =========

    const AllSubCategory = useSelector(selectAllSubCategory)

    const FormUpdateSubCategory = () => {
        handlesubpost();
        setFormUpdatesubCatry(formUpdatesubCatry + 1)
    }

    useEffect(() => {
        dispatch(getSubCategory())
    }, [formUpdatesubCatry])

    let SubCategoryOption = selectSubcatery.map((item) => ({ label: item.subcategory, value: item.subcategory }));


    const handleChecked = () => {
        setChecked(!checked)
    }

    // =========== variations ===========

    const AllVariations = useSelector(selectVariation);

    useEffect(() => {
        dispatch(getVariation())
    }, [])


    // ========== Table Datas ============

    // =========================  Other Functions End  =========================


    const [dataSource, setDataSource] = useState([]);


    useEffect(() => {
        setDataSource(VariationsValues)
    }, [VariationsValues])




    // ==============  Add Row Component  ================

    // -----------------------  Delete Row Function

    const handlesubpost = (id) => {

        request.get(`${SELECTCATEGORY}/${id}/`,)
            .then(function (response) {
                setSelectSubcatery(response.data)
                getSubCategory()
            })
            .catch(function (error) {
                console.log(error, 'error');
            });

    }

    useEffect(() => {
        handleVariationValuepost()
    }, [])
    const handleVariationValuepost = (id) => {

        request.get(`product/add_variations/`,)
            .then(function (response) {
                setVarData(response.data)
                setVarValues(response.data)
                setVarValueId(response.data)
            })
            .catch(function (error) {
                console.log(error, 'error');
            });

    }

    const handleChange = (event) => {
        const send = AllCategory.find(val => val.category === event)
        form.setFieldsValue({ main_category: send?.id })
        form.setFieldsValue({ category: event })
        handlesubpost(send.id);
    };


    useEffect(() => {
        form.setFieldsValue({
            id: VarValueId.object?.id
        });
    }, [VarValueId])

    // ---------- SET VALUE TO DYNAMIC DATA ------

    const SetDynamicTable = (value) => {



        setDynamicTableData((prev) => {
            if (!Array.isArray(prev)) {
                // If prev is not an array, create a new array with the current and new value
                return [prev, { ...value, key: 0 }];
            }

            // If prev is an array, create a new array with the previous elements and the new value
            // return [...prev, value];
            const maxKey = Math.max(...prev.map(item => item.key), 0);
            return [...prev, { ...value, key: maxKey + 1 }];
        });


    }
    const SetDynamicEditTable = (value) => {
        setDynamicTableData((prev) => {

            if (!Array.isArray(prev)) {
                // If prev is not an array, create a new array with the current and new value
                return [{ ...value, key: 0 }];
            }

            const rowIndexToUpdate = dynamicTableData.findIndex((item) => item.key === value.key);


            if (rowIndexToUpdate !== -1) {
                // Create a copy of the previous array
                const updatedDynamicTable = [...prev];

                // Update the values for the row at the specified index
                updatedDynamicTable[rowIndexToUpdate] = { ...value };

                return updatedDynamicTable;
            }


            // Find the index of the row to update based on the key

            // If the row doesn't exist, simply add it to the end of the array
            const maxKey = Math.max(...prev.map((item) => item.key), 0);
            return [...prev, { ...value, key: maxKey + 1 }];
        });

    };



    const RowRemove = (index) => {

        const newArr = [];

        for (let i = 0; i < dynamicTableData.length; i++) {
            if (i !== index) {
                newArr.push(dynamicTableData[i]);
            }
        }
        if (productdata?.product) {
            setDynamicTableData(newArr)
        }
        else {
            setDynamicTableData(newArr)
        }


    }

    const columns = [

        {
            title: 'Sl.No',
            render: (value, item, index) => index + 1,
        },
        {
            title: 'Variant',
            render: (record) => {
                return (
                    <CustomRow>
                        {
                            record?.varity?.map((item) => (
                                <Col span={24}>
                                    <h1>{item?.variation_name} : {item?.variation_value}</h1>
                                </Col>
                            ))
                        }

                    </CustomRow>
                )

            }

        },
        {
            title: 'Variant Images',
            dataIndex: 'varity_images',
            render: (text, record, index) => {

                return (
                    <div style={{ display: 'flex', flexDirection: 'row', gap: '5px' }}>
                        {record?.varity_images?.map((imgObj) => (
                            <div key={imgObj.id} style={{ height: '40px', width: '40px', }}>
                                <img src={imgObj.variantproduct_image} style={{ height: '40px', width: '40px' }} />
                            </div>
                        ))}
                    </div>
                )
            }
        },
        {
            title: 'Mrp',
            dataIndex: 'mrp',
        },
        {
            title: 'Buy Rate',
            dataIndex: 'buy_rate',
        },
        {
            title: 'Sale Rate',
            dataIndex: 'sale_rate',
        },
        {
            title: 'Available Quantity',
            dataIndex: 'available_qty',
        },

        {
            title: 'Action',
            render: (text, record, index) => {
                return (
                    <Flex gap={'true'} center={'true'}>
                        {productdata ?
                            <RemoveBtn>
                                <AiFillPlusCircle style={{ fontSize: '23px', marginRight: '10px', color: 'green' }} onClick={() => onEditVariant(record)} />
                            </RemoveBtn> : null}

                        <RemoveBtn>
                            <IoMdRemoveCircle style={{ fontSize: '25px', color: 'red' }} onClick={() => RowRemove(index)} />
                        </RemoveBtn>
                    </Flex>


                )

            }
        }
    ]

    const handleproductImage = (img) => {
        setProductImage((prevData) => [...prevData, img.file]);
    }

    const handlechangeUnit = (value) => {
        const selectedUnit = unitArray?.find(item => item.unit_name === value)
        form.setFieldsValue({ item_unit: selectedUnit.unit_short_name })
    }

    const handlechangeBrand = (value) => {
        form.setFieldsValue({ brand_name: value })
    }
    const rowKey = (dataSource) => dataSource.key;
    return (
        <Fragment>
            <Form
                form={form}
                labelCol={{
                    span: 24,
                }}
                wrapperCol={{
                    span: 24,
                }}
                name='product'
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}

                autoComplete="off">
                <CustomRow space={[24, 24]} >
                    <Col span={24} md={8} >
                        <div style={{ margin: '50px 0px' }}>
                            <h3 style={{ fontSize: '18px' }}>Product Name & Categories</h3><br />
                            <p style={{ color: 'gray' }}>Select categories and Sub categories from here</p>
                        </div>

                    </Col>

                    <Col span={24} md={16}>
                        <Card>
                            <CustomRow space={[24, 24]}>
                                <Col span={24} md={20}>
                                    <CustomInput label={'Product Name'} name={'product_name'} placeholder={'Enter Product Name'}
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Please Enter Product Name!',
                                            }
                                        ]} />
                                </Col>

                                <Col span={24} md={20}>
                                    {
                                        productdata ?
                                            <CustomSelect
                                                options={UnitOption || []}
                                                onButtonClick={onViewRow}
                                                onChange={handlechangeUnit}
                                                showSearch={true}
                                                label={'Item Unit'}
                                                name={'item_unit'} placeholder={'Select Unit'}
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: 'Please Select Unit!',
                                                    }
                                                ]} /> :
                                            <CustomAddSelect
                                                options={UnitOption || []}
                                                onButtonClick={onViewRow}
                                                onChange={handlechangeUnit}
                                                showSearch={true}
                                                label={'Item Unit'}
                                                name={'item_unit'} placeholder={'Select Unit'}
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: 'Please Select Unit!',
                                                    }
                                                ]} />
                                    }


                                </Col>
                                <Col span={24} md={20}>
                                    {
                                        productdata ?
                                            <CustomSelect options={BrandOption} onChange={handlechangeBrand} onButtonClick={onViewBrand}
                                                showSearch={true}
                                                label={'Brand'}
                                                name={'brand_name'} placeholder={'Select Brand'}
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: 'Please Select Brand!',
                                                    }
                                                ]}
                                            /> :
                                            <CustomAddSelect options={BrandOption} onChange={handlechangeBrand} onButtonClick={onViewBrand}
                                                showSearch={true}
                                                label={'Brand'}
                                                name={'brand_name'} placeholder={'Select Brand'}
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: 'Please Select Brand!',
                                                    }
                                                ]}
                                            />
                                    }
                                </Col>
                                <Col span={24} md={20}>
                                    {productdata ?
                                        <CustomSelect
                                            rules={[
                                                {
                                                    required: true,
                                                    message: 'Please Select Category!',
                                                }
                                            ]}
                                            options={CategoryOption} onButtonClick={onViewCategory}
                                            showSearch={true} label={'Category'} onChange={handleChange}
                                            name={'category'} placeholder={'Select Category'} /> :
                                        <CustomAddSelect
                                            rules={[
                                                {
                                                    required: true,
                                                    message: 'Please Select Category!',
                                                }
                                            ]}
                                            options={CategoryOption} onButtonClick={onViewCategory}
                                            showSearch={true} label={'Category'} onChange={handleChange}
                                            name={'category'} placeholder={'Select Category'} />
                                    }
                                    <CustomInput name={'main_category'} display={'none'} />

                                </Col>
                                <Col span={24} md={20}>
                                    <CustomSelect label={'Sub category'} name={'sub_category'}
                                        placeholder={'Select'} options={SubCategoryOption} />
                                </Col>
                                <Col span={24} md={20}>
                                    <CustomCheckBox checked={checked} onChange={handleChecked} label={'Manage Stock ?'} name={'stock_maintain'} />
                                    <br />
                                    {
                                        checked &&
                                        <CustomInputNumber
                                            rules={[
                                                {
                                                    required: true,
                                                    message: 'Please Enter Alert quantity!',
                                                }
                                            ]}
                                            label={'Alert quantity'} name={'alert_qnty'}
                                            placeholder={'quantity'} />
                                    }

                                </Col>
                                <Col span={24} md={20}>
                                    <CustomTextArea label={'Product Description'} name={'description'}
                                        placeholder={'Enter Product Description'} />
                                </Col>
                                {(productdata?.product_m.length > 0) &&
                                    <Col span={24} md={20}>
                                        Previously Selected Product Images
                                        {productdata?.product_m?.map((imgObj) => (
                                            <div key={imgObj.id} >
                                                <img onClick={() => console.log('pressed')} src={imgObj.product_image} style={{ height: '80px', width: '80px' }} />
                                            </div>
                                        ))}
                                    </Col>
                                }
                                <Col>
                                    <CustomUpload multiple={'true'} maxCount={3} onChange={handleproductImage} form={form} label={'Product Image (Multi Select)'}
                                        name={'product_image'} listType='picture-card' accept='.png,.jpeg,.jpg'
                                    />
                                </Col>

                            </CustomRow>

                        </Card>
                    </Col>
                </CustomRow>

            </Form>

            <br />
            <Divider />
            <Flex alignCenter gap={'10px'}>
                <h3>Add Variations :</h3>
                <Button.Primary icon={<BsPlus />} onClick={() => variationAdd()} />
            </Flex>
            <CustomRow space={[24, 24]}>
                <Col span={24} md={8} >
                    <div style={{ margin: '50px 0px' }}>
                        <h3 style={{ fontSize: '18px' }}>Product Variation Name</h3><br />
                        <p style={{ color: 'gray' }}>Select Variation Name from here</p>
                    </div>
                </Col>
                <Col span={24} sm={24} md={24} lg={16}>
                    <TableProduct SetDynamicTable={SetDynamicTable} productdata={dynamicTableData} />
                </Col>
            </CustomRow>

            <br />
            <CustomTable columns={columns} data={dynamicTableData} rowKey={rowKey} />
            {productdata ?
                (<Flex center={'true'} gap={'20px'} margin={'20px 0px'}>
                    {
                        isLoading ?
                            <CommonLoading /> :
                            <>
                                <Button.Primary text={'Update'} onClick={() => onSubmit()} />
                                <Button.Danger text={'Cancel'} onClick={() => Formcancel()} />
                            </>
                    }

                </Flex>)
                :
                <Flex center={'true'} gap={'20px'} margin={'20px 0px'}>
                    {
                        isLoading ?
                            <CommonLoading /> :
                            <>
                                <Button.Primary text={'Save'} onClick={() => onSubmit()} />
                                <Button.Danger text={'Reset'} icon={<BiReset style={{ marginRight: '5px' }} />} onClick={() => onReset()} />
                            </>
                    }

                </Flex>
            }
            <CustomModal isVisible={isModalOpen} handleOk={handleOk} handleCancel={handleCancel} width={modelwith} modalTitle={modalTitle} modalContent={modalContent} />

        </Fragment>
    )
}

export default AddProduct