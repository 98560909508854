import React, { useState } from 'react'
import { PurchaseEntryPage } from './Partials/PurchaseEntryPage'
import { CustomPageTitle } from '../../components/CustomPageTitle'
import { CustomCardView } from '../../components/CustomCardView'

const Purchase = () => {

    return (
        <div>
            <CustomPageTitle Heading={'Purchase'} />
            <CustomCardView>
                <PurchaseEntryPage />
            </CustomCardView>

        </div>
    )
}

export default Purchase