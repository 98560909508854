import { Col } from 'antd'
import React, { useEffect, useState } from 'react'
import { FiEdit, FiPlus } from 'react-icons/fi'
import { MdDelete } from 'react-icons/md'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import { CommonLoading } from '../../../../components/CommonLoading'
import { TableIconHolder } from '../../../../components/CommonStyled'
import { CustomModal } from '../../../../components/CustomModal'
import { CustomPageTitle } from '../../../../components/CustomPageTitle'
import { CustomPopconfirm } from '../../../../components/CustomPopConfirm'
import { CustomRow } from '../../../../components/CustomRow'
import Flex from '../../../../components/Flex'
import ButtonStandard from '../../../../components/Form/CustomStandardButton'
import { THEME } from '../../../../theme'
import request from '../../../../utils/request'
import ProductBrand from '../../../AddSubModule/Brand/ProductBrand'
import { getAllBrand, getBrand, getError, getStatus } from '../../../AddSubModule/UnitSlice'
import { getUnit, getUnitError, getUnitStatus, selectViewUnit } from '../../ProductSlice'
import Label from '../../../../components/Form/Label'
import { CustomInput } from '../../../../components/Form/CustomInput'
import { CustomTandardTable } from '../../../../components/Form/CustomStandardTable'

const ViewBrand = () => {

    const DEL_BARND = 'product/edit_brand'
    const navigate = useNavigate();

    const [dataSource, setDataSource] = useState([])
    const [searchTexts, setSearchTexts] = useState([]);   //---------Seach Bar --------

    // ======  Modal Open ========
    const [isModalOpen, setIsModalOpen] = useState(false);


    // ======  Modal Title and Content ========
    const [modalTitle, setModalTitle] = useState("");
    const [modalContent, setModalContent] = useState(null);

    // ----------  Form Reset UseState ---------
    const [formReset, setFormReset] = useState(0);
    const [modelwith, setModelwith] = useState(0);
    const [updateservice, setUpdateservice] = useState(0);
    const [profileFormUpdate, setProfileFormUpdate] = useState(0)
    const [trigger, setTrigger] = useState(0)


    const dispatch = useDispatch()

    const ViewUnitTableData = useSelector(getAllBrand)
    const GetUnitStatus = useSelector(getStatus)
    const GetUnitError = useSelector(getError)


    // ===== Modal Functions Start =====

    const showModal = () => {
        setIsModalOpen(true);
    };

    const handleOk = () => {
        setIsModalOpen(false);
        FormRest()

    };

    const handleCancel = () => {
        setIsModalOpen(false);

    };
    const FormUpdate = () => {
        setProfileFormUpdate(profileFormUpdate + 1)
    }

    const handleGetUnit = () => {
        dispatch(getUnit())
    }

    const handleSearchs = (value) => {
        setSearchTexts(value);
    };

    const FormRest = () => {
        setFormReset(formReset + 1)
    }

    // ===== Modal Functions End =====

    const UpdateBrand = (record) => {
        setTrigger(trigger + 1)
        setModelwith(500)
        setModalTitle("Update Brand");
        setModalContent(<ProductBrand brandrecord={record}  UnitFormUpdate={FormUpdate} brandhandleOk={handleOk} />);
        showModal();
    };

    // ========== Delete post ==========

    const handleConfirm = (record) => {
        FormUpdate()
        DeleteBrand(record)
    }
    const DeleteBrand = (record) => {
        request.delete(`${DEL_BARND}/${record?.id}/`)
            .then((response) => {
                dispatch(getBrand())
                toast.info("Deleted Successfully")
            }).catch(error => {
                if (error.response.status === 400) {
                    toast.error("Something Went Wrong")
                } else if (error.response.status === 302) {
                    toast.warn(error.response.data.message)
                }
            });
    };

    const columns = [
        {
            title: 'SI No',
            render: (value, item, index) => index + 1,
        },
        {
            title: 'Brand Name',
            dataIndex: 'brand_name',
            filteredValue: searchTexts ? [searchTexts] : null,
            onFilter: (value, record) => {
                return String(record.brand_name).toLowerCase().includes(value.toLowerCase()) ||
                    String(record.brand_name).includes(value.toUpperCase());
            },
        },
        {
            title: 'Action',
            render: (record, i) => {
                return (
                    <Flex center={"true"} gap={'10px'}>

                        <TableIconHolder color={THEME.blue} size={'22px'} onClick={() => { UpdateBrand(record) }}>
                            <FiEdit />
                        </TableIconHolder>

                        <CustomPopconfirm
                            record={record}
                            confirm={handleConfirm}
                            title={"Delete the brand List"}
                            description={"Are you sure to delete this brand ?"}>
                            <TableIconHolder color={THEME.red} size={'22px'}>
                                <MdDelete />
                            </TableIconHolder>
                        </CustomPopconfirm>
                    </Flex>
                );
            },
        }
    ]
    useEffect(() => {
        setDataSource(ViewUnitTableData)
    }, [ViewUnitTableData])


    useEffect(() => {
        dispatch(getBrand())
    }, [profileFormUpdate])


    let content;

    if (GetUnitStatus === 'loading') {
        content = <CommonLoading />
    } else if (GetUnitStatus === 'succeeded') {
        const rowKey = (dataSource) => dataSource.id;
        content = <CustomTandardTable columns={columns} data={dataSource} rowKey={rowKey} />
    } else if (GetUnitStatus === 'failed') {
        content = <h2>{GetUnitError}</h2>
    }

    return (
        <div>
            <CustomRow space={[24, 24]}>
                <Col span={24} md={15}>
                    <CustomPageTitle Heading={'Brand'} />
                </Col>
                <Col span={24} md={9}>
                    <CustomRow space={[24, 24]}>
                        <Col span={24} md={16}>
                           
                        </Col>
                        <Col span={24} md={8} style={{ float: 'right' }}>
                            <ButtonStandard.Primary icon={<FiPlus style={{ marginRight: '5px', fontSize: '20px' }} />} text={'Add'}
                                onClick={() => navigate('/AddBrand')} />
                        </Col>
                    </CustomRow>
                </Col>
            </CustomRow>
            <div style={{ display: 'flex', alignItems: 'center', marginTop: '50px' }}>
                <Label style={{ marginRight: '20px' }}>Search by Brand Name :</Label>
                <CustomInput
                    value={searchTexts}
                    placeholder="Search"
                    onSearch={handleSearchs}
                    onChange={(e) => handleSearchs(e.target.value)}
                />
            </div>
            {content}
            <CustomModal isVisible={isModalOpen} handleOk={handleOk} handleCancel={handleCancel} width={modelwith} modalTitle={modalTitle} modalContent={modalContent} />

        </div>
    )
}

export default ViewBrand