import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import request from "../../utils/request";

const initialState = {
    quotation: [],
    status: 'idle',  // 'idle' | 'loading' | 'succeeded' | 'failed'  
    error: null
}

export const getQuotation = createAsyncThunk(
    "Sales/Get",
    async () => {
        try {
            const response = await request.get('sales/saledetails_search/');
            return response.data;
        }
        catch (error) {
            throw error;
        }
    }
);

const quoationSlice = createSlice({
    name: 'quotation',
    initialState,
    reducers: {
    },
    extraReducers: (builder) => {
        builder
            .addCase(getQuotation.pending, (state, action) => {
                state.status = 'loading'
            })
            .addCase(getQuotation.fulfilled, (state, action) => {
                state.status = 'succeeded'
                state.quotation = action.payload;
            })
            .addCase(getQuotation.rejected, (state, action) => {
                state.status = 'failed'
                state.error = action.error.message
            })
          
    }
})


export const selectAllQuotation = (state) => state.quotations.quotation
export const getQuotationStatus = (state) => state.quotations.status
export const getQuotationError = (state) => state.quotations.error

export const { reducer } = quoationSlice;

export default quoationSlice.reducer


