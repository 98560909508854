import { Col } from 'antd'
import React from 'react'
import { CustomCardView } from '../../../components/CustomCardView'
import { CustomPageTitle } from '../../../components/CustomPageTitle'
import { CustomRow } from '../../../components/CustomRow'
import Flex from '../../../components/Flex'
import AddProductUnit from './ProductUnit'

const ProductUnits = () => {
    return (
        <div>
            <CustomPageTitle Heading={'Add Unit'} />
            <Flex center={'true'}>
                <CustomRow>

                    <Col span={24} md={20}>
                        <CustomCardView>

                            <AddProductUnit />
                        </CustomCardView>
                    </Col>

                </CustomRow>
            </Flex>



        </div>
    )
}

export default ProductUnits