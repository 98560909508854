import React, { useEffect, useState } from 'react';
import { Col, Form, Typography } from 'antd';
import { CustomRow } from '../../../components/CustomRow';
import { CustomInput } from '../../../components/Form/CustomInput';
import Flex from '../../../components/Flex';
import ButtonStandard from '../../../components/Form/CustomStandardButton';
import { CustomInputPassword } from '../../../components/Form/CustomInputPassword';
import { toast } from 'react-toastify';
import {baseRequest} from '../../../utils/request';
import { useLocation, useNavigate } from 'react-router-dom';
import { CustomPageTitle } from '../../../components/CustomPageTitle';
import { CustomOtp } from '../../../components/Form/CustomOtp';
import googlelogin from '../../../Images/google login.png'
import styled from 'styled-components';

const LeftSide = styled.div`
  width: 100%;

  @media (max-width: 768px) {    
    display: none;   
  }
`;

const PasswordForm = () => {
    const [form] = Form.useForm();
    const [showOtpField, setShowOtpField] = useState(false);
    const [otpButtonLabel, setOtpButtonLabel] = useState('Generate OTP');
    const [otpData, setOtpData] = useState({});

    const navigate = useNavigate();
    const location = useLocation();
    const { email, givenname } = location.state || {};

    const isVerifiedFromOAuth = email;

    const [isMobileNumberVerified, setIsMobileNumberVerified] = useState(false);
    const [isMobileNumberVerifieds, setIsMobileNumberVerifieds] = useState(false);

    useEffect(() => {
        form.setFieldsValue({ email: email });
        form.setFieldsValue({ name: givenname });
        // form.setFieldsValue({ signup_type: email });


    }, []);

    const URLS = 'user/register';
    const get_number = 'user/send_otp_for_verify_phone_number';
    const verify_number = 'user/verify_phone_number';
    const regenerate_otp = 'user/regenerate_otp_phone_number';

    const onFinish = (values) => {
        const authType = isVerifiedFromOAuth ? 'Oauth' : 'Normal';
       
        const signup = { ...values, signup_type: authType }

        const newvalue = {
            email: signup?.email,
            mobile_number: signup?.mobile_number,
            password: signup?.password,
            name: signup?.name,
            signup_type: signup?.signup_type
        }
        HandlePostPerson(newvalue);
    };

    const getNumber = async (values) => {
        const phoneNumber = values.mobile_number;

        try {
            let response;
            if (otpButtonLabel === 'Generate OTP') {
                response = await baseRequest.post(`${get_number}/${phoneNumber}/`, values);

                if (response.status === 201) {
                    toast.success('Number Verified Successfully');
                }

                if (response.status === 410) {
                    toast.success('Please Verify Mobile Number');
                }

                if (response.status === 400 && response.data.error === 'Mobile number already added') {
                    throw new Error('Mobile number already added');
                }
            } else {
                response = await baseRequest.patch(`${regenerate_otp}/${phoneNumber}/`, values);

                if (response.status === 201) {
                    form.resetFields();
                    toast.success('OTP Sent Successfully');
                    setShowOtpField(true);
                }

                if (
                    response.status === 400 &&
                    response.data.error === 'Maximum OTP generation attempts exceeded. Please try again later.'
                ) {
                    throw new Error('Maximum OTP generation attempts exceeded. Please try again later.');
                }
            }

            setShowOtpField(true);
            setOtpButtonLabel('Regenerate OTP');
            setOtpData(response.data);
            console.log(response.data,'hhh');
        } catch (error) {
            toast.error('Something Went Wrong');
        }
    };

    const handleFinish = (values) => {
        verifyNumber(values);
    };

    const verifyNumber = async (values) => {
        const phoneNumber = values.mobile_number;
        const Otp = values.otp;

        try {
            const response = await baseRequest.post(`${verify_number}/${phoneNumber}/`, {
                otp: Otp,
            });

            if (response.status === 200) {
                setIsMobileNumberVerified(true); // OTP verified successfully
                toast.success("Number Verified Successfully");
                setShowOtpField(false);
                setIsMobileNumberVerifieds(true);
            } else {
                setIsMobileNumberVerified(false); // OTP verification failed
                toast.error("Wrong OTP");
            }
        } catch (error) {
            setIsMobileNumberVerified(false); // OTP verification failed
            toast.error("Wrong OTP");
        }
    };

    const HandlePostPerson = (values) => {


        baseRequest
            .post(URLS, values)
            .then((response) => {
                if (response.status === 201) {
                    form.resetFields();
                    toast.success('Your Profile Added Successfully');
                    navigate('/signin', {
                        state: { email },
                    });
                } else {
                    form.resetFields();
                    toast.warning('Email Id Already exists!');
                }
            })
            .catch((error) => {
                if (error.response) {
                    if (error.response.status === 400) {
                        if (error.response.data) {
                            toast.warn(error.response.data?.email[0]);
                        }
                    } else {
                        toast.error('Something Went Wrong');
                    }
                } else if (error.baseRequest) {
                    toast.error('baseRequest Error');
                } else {
                    toast.error('Connection Error');
                }
            });
    };

    const onFinishFailed = () => { };

    return (
        <Form
            form={form}
            labelCol={{
                span: 24,
            }}
            wrapperCol={{
                span: 24,
            }}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            autoComplete="off"
        >
            <br></br>
            <Flex center>
                <CustomPageTitle Heading={'ENTER YOUR DETAILS'} />
            </Flex>
            <CustomRow space={[24, 24]} style={{ marginTop: '40px' }}>
                <Col span={24} md={12}>
                    <Flex center>
                        <LeftSide>
                            <img width={"100%"} height={600} src={googlelogin} alt="Login" />
                        </LeftSide>
                    </Flex>
                </Col>
                <Col span={24} md={12}>
                    <CustomRow space={[24, 24]}>
                        <Col span={24} md={24}>
                            <CustomInput
                                label={'Name'}
                                placeholder={'Name'}
                                name={'name'}
                                disabled
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please Enter Name !',
                                    },
                                ]}
                            />
                        </Col>
                        <Col span={24} md={24}>
                            <CustomInput
                                label={'Email'}
                                placeholder={'Email ID'}
                                type={'email'}
                                name={'email'}
                                disabled

                                rules={[
                                    {
                                        required: true,
                                        message: 'Please Enter Email ID !',
                                    },
                                ]}
                            />
                            <CustomInput

                                name={'signup_type'} display={'none'}


                            />
                        </Col>
                        <Col span={24} md={24}>
                            <CustomInput
                                label={'Mobile No'}
                                name={'mobile_number'}
                                placeholder={'Enter Phone No'}
                                maxLength={10}
                                onKeyPress={(event) => {
                                    if (!/[0-9]/.test(event.key)) {
                                        event.preventDefault();
                                    }
                                }}
                            />
                            {isMobileNumberVerified ? (
                                <Typography.Text style={{ color: 'green', marginLeft: '10px' }}>
                                    &#10004; Verified
                                </Typography.Text>
                            ) : (
                                <Typography.Text style={{ color: 'red', marginLeft: '10px' }}>
                                    &#10008; Not Verified
                                </Typography.Text>
                            )}
                        </Col>
                        {showOtpField && (
                            <Col span={24} md={12}>
                                <CustomOtp label={'Fill your OTP here 👇'} name={'otp'} maxLength={6}
                                    onKeyPress={(event) => {
                                        if (!/[0-9]/.test(event.key)) {
                                            event.preventDefault();
                                        }
                                    }}
                                />
                            </Col>
                        )}
                        <Col span={24} md={24}>
                            <Flex>
                                {isMobileNumberVerifieds ?
                                    null
                                    : <ButtonStandard.Primary
                                        text={otpButtonLabel}
                                        onClick={() => getNumber(form.getFieldsValue())}
                                    />
                                }
                                {showOtpField && (
                                    <ButtonStandard.Primary
                                        text={'Verify OTP'}
                                        onClick={(value) => handleFinish(form.getFieldsValue())}
                                    />
                                )}
                            </Flex>
                        </Col>
                        <Col span={24} md={24}>
                            <CustomInputPassword
                                label={'Password'}
                                placeholder={'Password'}
                                type={'password'}
                                name={'password'}
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please Enter Password!',
                                    },
                                ]}
                            />
                        </Col>
                        <Flex>{/* or connect with */}</Flex>
                    </CustomRow>
                    <br></br>
                    <Flex center>
                        <ButtonStandard.Primary text={'Sign Up'} htmlType={'submit'} />
                    </Flex>
                </Col>
            </CustomRow>
        </Form>
    );
};

export default PasswordForm;
