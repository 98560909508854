import { combineReducers } from "redux"
import authReducer from '../modules/Auth/authSlice'
import profileReducer from "../modules/BusinessProfile/BusinessSlice"
import unitReducers from '../modules/AddSubModule/UnitSlice'
import productReducers from '../modules/Product/ProductSlice'
import expenseReducers from '../modules/AddExpense/ExpenseSlice'
import serviceReducers from '../modules/Services/ServiceSlice'
import partyReducers from '../modules/AddParty/PartySlice'
import emailReducer from '../modules/Auth/googleSlice'
import reportSlice from '../modules/Reports/ReportSlice'
import UnpaidSlice from "../modules/UnpaidBills/UnpaidSlice"
import SaleReducer from '../modules/Sales/SaleSlice'
import PurchaseReucer from '../modules/Purchase/PurchaseSlice'
import QuotationReducer from '../modules/Quotation/QuotationSlice'
import NotificationReducer from '../modules/Notifications/NotificationSlice'

const initialState = {
    auth : {},
    profiles: {},
    addModules: {},
    products: {},
    expense: {},
    services: {},
    addparty: {},
    email: {},
    reportdetails: {},
    unpaid: {},
    sales: {},
    purchase: {},
    quotations: {},
    notifications: {}
}

const appReducer = combineReducers({
    auth: authReducer,
    profiles: profileReducer,
    addModules: unitReducers,
    // addModulesbrand: brandReducer,
    products: productReducers,
    expense: expenseReducers,
    services: serviceReducers,
    addparty: partyReducers,
    email: emailReducer,
    // user: userReducer,
    reportdetails: reportSlice,
    // expensedetls: expenseDetailsReducer,
    unpaid: UnpaidSlice,
    sales: SaleReducer,
    purchase: PurchaseReucer,
    quotations: QuotationReducer,
    notifications: NotificationReducer
  });
// const rootReducer = combineReducers({
//     auth: authReducer,
//     profiles: profileReducer,
//     addModules: unitReducers,
//     addModulesbrand: brandReducer,
//     products: productReducers,
//     expense: expenseReducers,
//     services: serviceReducers,
//     addparty: partyReducers,
//     email: emailReducer,
//     user: userReducer,
//     reportdetails: reportSlice,
//     expensedetls: expenseDetailsReducer,
//     unpaid: UnpaidSlice,
//     sales: SaleReducer,
//     purchase: PurchaseReucer,
//     quotations: QuotationReducer,
//     notifications: NotificationReducer
// })

const rootReducer = (state, action) => {
    console.log(state,action,'store');
    if (action.type === 'auth/logOut') {
      state = initialState;
    }
    return appReducer(state, action);
  };

export default rootReducer;

//   const rootReducer = (state, action) => {
//     console.log(state,action,'store');
//     if (action.type === 'auth/logOut') {
//       state = undefined;
//     }
//     return appReducer(state, action);
//   };
//   export default rootReducer;