import React from 'react'
import { ReportSale } from './Partials/SaleReport'

export const SaleReport = () => {
    return (
        <div>
            <ReportSale />
        </div>
    )
}
