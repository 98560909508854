
export const DayBookData = [
    {
        key: '1',
        h1: 'Sale',
        Amount: '',
        p: '',
        Total: '',
    },
    {
        key: '2',
        h1: 'Sale Return',
        Amount: '',
        p: '',
        Total: '',
    },
    {
        key: '3',
        h1: 'Purchase',
        Amount: '',
        p: '',
        Total: '',
    },
    {
        key: '4',
        h1: 'Purchase Return',
        Amount: '',
        p: '',
        Total: '',
    },
    {
        key: '',
        h1: 'Expense',
        Amount: '',
        p: '',
        Total: '',
    },
]

export const data = [
    {
        date: '2023-09-01',
        billno: '001',
        partyname: 'John Doe',
        transactions: 'Purchase',
        billamount: 100.00,
        balance: 50.00,
    },
    {
        date: '2023-09-01',
        billno: '001',
        partyname: 'John Doe',
        transactions: 'Purchase',
        billamount: 100.00,
        balance: 50.00,
    },
    {
        date: '2023-09-01',
        billno: '001',
        partyname: 'John Doe',
        transactions: 'Purchase',
        billamount: 100.00,
        balance: 50.00,
    },
    {
        date: '2023-09-01',
        billno: '001',
        partyname: 'John Doe',
        transactions: 'Purchase',
        billamount: 100.00,
        balance: 50.00,
    },
    {
        date: '2023-09-01',
        billno: '001',
        partyname: 'John Doe',
        transactions: 'Purchase',
        billamount: 100.00,
        balance: 50.00,
    },

    {
        date: '2023-09-01',
        billno: '001',
        partyname: 'John Doe',
        transactions: 'Purchase',
        billamount: 100.00,
        balance: 50.00,
    },
    {
        date: '2023-09-01',
        billno: '001',
        partyname: 'John Doe',
        transactions: 'Purchase',
        billamount: 100.00,
        balance: 50.00,
    },

     {
        date: '2023-09-01',
        billno: '001',
        partyname: 'John Doe',
        transactions: 'Purchase',
        billamount: 100.00,
        balance: 50.00,
    },
    {
        date: '2023-09-01',
        billno: '001',
        partyname: 'John Doe',
        transactions: 'Purchase',
        billamount: 100.00,
        balance: 50.00,
    },
    {
        date: '2023-09-01',
        billno: '001',
        partyname: 'John Doe',
        transactions: 'Purchase',
        billamount: 100.00,
        balance: 50.00,
    },

     {
        date: '2023-09-01',
        billno: '001',
        partyname: 'John Doe',
        transactions: 'Purchase',
        billamount: 100.00,
        balance: 50.00,
    },
    {
        date: '2023-09-01',
        billno: '001',
        partyname: 'John Doe',
        transactions: 'Purchase',
        billamount: 100.00,
        balance: 50.00,
    },
    {
        date: '2023-09-01',
        billno: '001',
        partyname: 'John Doe',
        transactions: 'Purchase',
        billamount: 100.00,
        balance: 50.00,
    },
    {
        date: '2023-09-01',
        billno: '001',
        partyname: 'John Doe',
        transactions: 'Purchase',
        billamount: 100.00,
        balance: 50.00,
    },
    {
        date: '2023-09-01',
        billno: '001',
        partyname: 'John Doe',
        transactions: 'Purchase',
        billamount: 100.00,
        balance: 50.00,
    },
];




