import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import request from "../../utils/request";

const initialState = {
    expense: [],
    expenseDetails : [],
    expensecategoryname: [],
    status: 'idle',  // 'idle' | 'loading' | 'succeeded' | 'failed'  
    error: null
}

export const getExpense = createAsyncThunk(
    "Expense/Get",
    async () => {
        try {
            const response = await request.get('expense/view_expense/');
            return [...response.data];
        }
        catch (error) {
            throw error;
        }
    }
);

export const getExpenseDetails = createAsyncThunk(
    "ExpenseDetails/Get",
    async () => {
        try {
            const response = await request.get('expense/expense_details/');
            return [...response.data];
        }
        catch (error) {
            throw error;
        }
    }
);

export const getExpenseCategoryName = createAsyncThunk(
    "ExpenseCategory/Get",
    async () => {
        try {
            const response = await request.get('expense/view_category/');
            return [...response.data];
        }
        catch (error) {
            throw error;
        }
    }
);

const expenseReducers = createSlice({
    name: 'expense',
    initialState,
    reducers: {
    },
    extraReducers: (builder) => {
        builder
            .addCase(getExpense.pending, (state, action) => {
                state.status = 'loading'
            })
            .addCase(getExpense.fulfilled, (state, action) => {
                state.status = 'succeeded'
                state.expense = action.payload;
            })
            .addCase(getExpense.rejected, (state, action) => {
                state.status = 'failed'
                state.error = action.error.message
            })
            .addCase(getExpenseDetails.fulfilled, (state, action) => {
                state.status = 'succeeded'
                state.expenseDetails = action.payload;
            })
            .addCase(getExpenseCategoryName.fulfilled, (state, action) => {
                state.status = 'succeeded'
                state.expensecategoryname = action.payload;
            })
    }
})

export const selectAllExpense = (state) => state.expense.expense
export const ExpenseDetails = (state) => state.expense.expenseDetails
// export const ExpenseDetails = (state) => state.expensedetls.expenseDetails
export const getExpenseStatus = (state) => state.expense.status
export const getExpenseError = (state) => state.expense.error
export const selectAllExpenseCategoryName = (state) => state.expense.expensecategoryname

export const { reducer } = expenseReducers;

export default expenseReducers.reducer


