import { Col, Form } from 'antd'
import React, { useEffect, useState } from 'react'
import { CustomRow } from '../../../../components/CustomRow'
import Button from '../../../../components/Form/CustomButton'
import Flex from '../../../../components/Flex'
import { CustomTextArea } from '../../../../components/Form/CustomTextArea'
import { CustomInput } from '../../../../components/Form/CustomInput'
import { CustomDatePicker } from '../../../../components/Form/CustomDatePicker'
import dayjs from 'dayjs'
import { toast } from 'react-toastify'
import request from '../../../../utils/request'
import { useDispatch, useSelector } from 'react-redux'
import { getExpense, getExpenseCategoryName, selectAllExpenseCategoryName } from '../../ExpenseSlice'
import { CustomAddSelect } from '../../../../components/Form/CustomAddSelect'
import { CustomSelect } from '../../../../components/Form/CustomSelect'
import { CustomModal } from '../../../../components/CustomModal'
import { CategoryNameModal } from './CategoryNameModal'
import { CustomInputNumber } from '../../../../components/Form/CustomInputNumber'


export const AddExpenseForm = ({ updateRecord, Formcancel, FormUpdateExpense, Trigger }) => {

    const [selectedDate, setSelectedDate] = useState(dayjs().format('YYYY-MM-DD'));
    const [expCategory, setExpCategory] = useState(null)

    const [expenDetails, setExpenDetails] = useState({})

    const dispatch = useDispatch()

    const [isModalOpen, setIsModalOpen] = useState(false);

    // ======  Modal Title and Content ========
    const [modalTitle, setModalTitle] = useState("");
    const [modalContent, setModalContent] = useState(null);

    // ----------  Form Reset UseState ---------
    const [modelwith, setModelwith] = useState(0);

    const [trigger, setTrigger] = useState(0);

    // ===== Modal Functions Start =====

    const showModal = () => {
        setIsModalOpen(true);
    };

    const handleOk = () => {
        setIsModalOpen(false);
    };

    const handleCancel = () => {
        setIsModalOpen(false);
    };

    // ----- Define Form

    const [form] = Form.useForm();

    const categorytype = [
        {
            label: "Direct Expense",
            value: "Direct Expense"
        },
        {
            label: "Indirect Expense",
            value: "Indirect Expense"
        }
    ]

    const ADD_Expense_URL = '/expense/add_expense/'

    const EDIT_Expense_URL = '/expense/edit_expense'

    useEffect(() => {
        form.setFieldsValue({ date: dayjs() })
    }, [])
    

    useEffect(() => {
        if (updateRecord) {
            setExpenseDetails()
        }
    }, [updateRecord, Trigger])

    useEffect(() => {
        dispatch(getExpenseCategoryName())
    }, [])

    const ExpCategoryName = useSelector(selectAllExpenseCategoryName)

    const excategoryname = ExpCategoryName?.map((value) => (
        {
            label: value.category_name,
            value: value.category_name
        }
    ))

    const onChangeCategoryname = (value) => {
        const SelectedExpCategory = ExpCategoryName?.find((item) => item.category_name === value)
        form.setFieldsValue({ category: SelectedExpCategory?.id })
        form.setFieldsValue({ category_name: value })
        setExpCategory(value)
    }

    const setExpenseDetails = () => {
        form.setFieldsValue(updateRecord);
        const ExpensetDate = dayjs(updateRecord?.date)
        setExpCategory(updateRecord.category_name)
        form.setFieldsValue({ date: ExpensetDate })
    }

    const onReset = () => {
        form.resetFields()
        form.setFieldsValue({ date: dayjs() })
        form.setFieldsValue({ expense_no: expenDetails.expense_no })
    }
    const handleOnChange = (e) => {
        setSelectedDate(e);
    }


    const onFinish = (values) => {
        const newValues = {
            ...values,
            category_name: expCategory,
            date: values?.date === null ? '' : dayjs(selectedDate).format('YYYY-MM-DD') ? dayjs(values?.date).format('YYYY-MM-DD') : dayjs(values?.date).format('YYYY-MM-DD'),
        }
        if (updateRecord) {
            UpdateExpense(updateRecord?.id, newValues)
        } else {
            AddExpense(newValues)
        }

    };

    const CategoryName = () => {
        setTrigger(trigger + 1)
        setModelwith(500)
        setModalTitle("Add Category Name");
        setModalContent(<CategoryNameModal handleOk={handleOk} trigger={trigger} />);
        showModal();
    }

    const UpdateExpense = (id, values) => {
        console.log(values,'values');
        request.put(`${EDIT_Expense_URL}/${id}/`, values)
            .then(function (response) {
                dispatch(getExpense())

                if (FormUpdateExpense) {
                    FormUpdateExpense()
                }
                if (Formcancel) {
                    Formcancel()
                }
                if (response.status === 226) {
                    toast.warn('Please Add Business Profile!')
                }
                else {
                    toast.info("Expense Updated Successfully")
                }
            })
            .catch(function (error) {
                console.log(error,'error');
                if (error.response.status === 304) {
                    toast.warn("Expense limit exceeded, Please subscribe your Plan!")
                }
            });
    }

    const AddExpense = (values) => {
        request.post(`${ADD_Expense_URL}`, values)
            .then(function (response) {

                if (response.status === 226) {
                    toast.warn('Please Add Business Profile!')
                }
                else {
                    toast.success("Expense Added Successfully")
                    form.resetFields();
                    form.setFieldsValue({ date: dayjs() })
                    GetDetailss()
                    setExpCategory(null)

                }
            })
            .catch(function (error) {
                if (error.response.status === 302) {
                    toast.warn("This Expense Already used!")
                }
                else if (error.response.status === 304) {
                    toast.warn("Expense limit exceeded, Please subscribe your Plan!")
                }
                else if (error.response.status === 408) {
                    toast.warn('Your plan is over. Please subscribe to the plan!');
                }
                else {
                    toast.error("Something Went Wrong")
                }

            });
    }

    useEffect(() => {
        GetDetailss()
    }, [])

    useEffect(() => {
        if (updateRecord) {
            form.setFieldsValue({ category_name: updateRecord?.category_name })
        }
        else {
            form.setFieldsValue({ expense_no: expenDetails.expense_no })
        }

    }, [expenDetails])


    const GetDetailss = () => {
        request.get('expense/expense_details/')
            .then(function (response) {
                setExpenDetails(response.data)
            })
    }

    const onFinishFailed = () => {
        toast.warn("Please fill the details!")
    }

    return (
        <Form
            form={form}
            labelCol={{ span: 24 }}
            wrapperCol={{ span: 24 }}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}>
            <CustomRow space={[12, 12]}>

                <Col span={24} md={12}>
                    <CustomInput name={'expense_name'} label={'Expense Name'} placeholder={'Enter Expense Name'} rules={[
                        {
                            required: true,
                            message: 'Please enter Expense Name !',
                        }
                    ]} />
                    <CustomInput name={'id'} display={'none'} />

                </Col>

                <Col span={24} md={12}>
                    <CustomInput label={'Expense No'} name={'expense_no'} disabled={true} />
                </Col>


                <Col span={24} md={12}>
                    <CustomDatePicker
                        label={'Date'}
                        name={'date'}
                        onChange={handleOnChange}
                        rules={[
                            {
                                required: true,
                                message: 'Please Select Date !',
                            }
                        ]}
                    />
                </Col>

                <Col span={24} md={12}>
                    <CustomSelect label={'Expense Category Type'} name={'category_type'} placeholder={'Select Expense Category Type'} options={categorytype}
                        rules={[
                            {
                                required: true,
                                message: 'Please enter Expense Category Type!',
                            }
                        ]} />
                </Col>

                <Col span={24} md={12}>
                    <CustomInputNumber name={'amount'} label={'Amount'} placeholder={'Enter Amount'} rules={[
                        {
                            required: true,
                            message: 'Please enter Amount !',
                        }
                    ]} />
                </Col>

                <Col span={24} md={12}>

                    <CustomAddSelect value={expCategory} label={'Expense Category Name'} name={'category_name'} placeholder={'Select Expense Category Name'} options={excategoryname} onButtonClick={CategoryName} onChange={onChangeCategoryname}
                        rules={[
                            {
                                required: true,
                                message: 'Please enter Expense Category Name!',
                            }
                        ]}
                    />
                    <CustomInput name={'category'} display={'none'}/>



                </Col>
                <Col span={24} md={12}>
                    <CustomTextArea label={'Description'} name={'expense_description'} placeholder={'Enter Description'}
                    
                    />
                </Col>

            </CustomRow>

            {updateRecord ?
                (<Flex center={'true'} gap={'20px'} margin={'20px 0px'}>
                    <Button.Primary text={'UPDATE'} htmlType={'submit'} />
                    <Button.Danger text={'CANCEL'} onClick={() => Formcancel()} />
                </Flex>)
                :
                (<Flex center={'true'} gap={'20px'} margin={'20px 0px'}>
                    <Button.Primary text={'SAVE'} htmlType={'submit'} />
                    <Button.Danger text={'RESET'} onClick={() => onReset()} />
                </Flex>)}
            <CustomModal isVisible={isModalOpen} handleOk={handleOk} handleCancel={handleCancel} width={modelwith} modalTitle={modalTitle} modalContent={modalContent} />
        </Form>
    )
}
