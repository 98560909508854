import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import request from "../../utils/request";

const initialState = {
    party: [],
    daybook: [],
    status: 'idle',  // 'idle' | 'loading' | 'succeeded' | 'failed'  
    error: null
}


// Party
export const getParty = createAsyncThunk(
    "Party/Get",
    async (_,{rejectWithValue}) => {
        try {
            const response = await request.get('party/add_party/');
            return [...response.data];
        }
        catch (error) {
            
            throw error;
        }
    }
);
// Daybook
export const getDaybook = createAsyncThunk(
    "Daybook/Get",
    async () => {
        try {
            const response = await request.get('report/daybook/');
            return [...response.data];
        }
        catch (error) {
            throw error;
        }
    }
);

const partySlice = createSlice({
    name: 'partys',
    initialState,
    reducers: {
    },
    extraReducers: (builder) => {
        builder
            .addCase(getParty.pending, (state, action) => {
                state.status = 'loading'
            })
            .addCase(getParty.fulfilled, (state, action) => {
                state.status = 'succeeded'
                state.party = action.payload;
            })
            .addCase(getParty.rejected, (state, action) => {
                state.status = 'failed'
                state.error = action.error.message
            })
            .addCase(getDaybook.pending, (state, action) => {
                state.status = 'loading'
            })
            .addCase(getDaybook.fulfilled, (state, action) => {
                state.status = 'succeeded'
                state.daybook = action.payload;
            })
            .addCase(getDaybook.rejected, (state, action) => {
                state.status = 'failed'
                state.error = action.error.message
            })
    }
})

export const selectAllParty = (state) => state.addparty.party
export const getPartyStatus = (state) => state.addparty.status
export const getPartyError = (state) => state.addparty.error

export const selectAllDaybook = (state) => state.addparty.daybook
export const getDaybookStatus = (state) => state.addparty.status
export const getDaybookError = (state) => state.addparty.error

export const { reducer } = partySlice;

export default partySlice.reducer


