import PageNotFound from '../components/PageNotFound'
import UserSignin from "../../modules/Auth/Components/UserSignin";
import ViewTable from '../../modules/BusinessProfile/ViewBusiness/Partials/ViewTable';
import AddServiceMain from '../../modules/Services/AddService';
import ServiceTable from '../../modules/Services/ViewService';
import AddProductName from '../../modules/Product/AddProduct';
import { ViewProductTable } from '../../modules/Product/ViewProduct';
import { Party } from '../../modules/AddParty/Party';
import { ViewParty } from '../../modules/AddParty/ViewParty';
import Sales from '../../modules/Sales';
import SaleReturn from '../../modules/Sales/SaleReturn/Partials';
import { ViewSaleReturn } from '../../modules/Sales/SaleReturn/Partials/ViewSaleReturn';
import { AddExpense } from '../../modules/AddExpense/Expense';
import { ViewExpense } from '../../modules/AddExpense/ViewExpense';
import Purchase from '../../modules/Purchase';
import PurchaseReturn from '../../modules/PurchaseReturn';
import Quotation from '../../modules/Quotation';
import { ViewQuotationPage } from '../../modules/Quotation/Partials/ViewQuotationPage';
import { SaleUnpaileBill } from '../../modules/UnpaidBills/Sale';
import { SaleReturnUnpaileBill } from '../../modules/UnpaidBills/SaleReturn';
import { PurchaseUnpaidBill } from '../../modules/UnpaidBills/Purchase';
import { PurchaseReturnUnpaid } from '../../modules/UnpaidBills/PurchaseReturn';
import { DayBook } from '../../modules/DayBook';
import { SaleReport } from '../../modules/Reports/Sales';
import { SaleReturnReport } from '../../modules/Reports/SaleReturn';
import { PurchseReportMain } from '../../modules/Reports/Purchase';
import { PurchseReturntMain } from '../../modules/Reports/PurchaseReturn';
import { QuotationReportMain } from '../../modules/Reports/Quotations';
import { ViewPurchase } from '../../modules/Purchase/Partials/ViewPurchase';
import { ViewPurchaseReturn } from '../../modules/PurchaseReturn/Partials/ViewPurchaseReturn';
import { RegisterMenu } from '../../modules/LoginPage/Partials/RegisterMenu';
import AddBusinessProfile from '../../modules/BusinessProfile/AddBusiness';
import { Tab } from '../../modules/Tab/Index';
import UnpaidBills from '../../modules/UnpaidBills/UnpaidBillsTab/Partials/UnpaidBills';
import PasswordForm from '../../modules/Auth/Components/PasswordForm';
import { SalesDashboard } from '../../modules/Dashboard/Partials/SalesDashboard';
import ViewUnit from '../../modules/Product/ViewProduct/Partials/ViewUnit';
import ProductUnits from '../../modules/AddSubModule/ProductUnit/Index';
import ViewBrand from '../../modules/Product/ViewProduct/Partials/ViewBrand';
import AddBrand from '../../modules/AddSubModule/Brand';
import { AddCategorywithCard } from '../../modules/AddSubModule/Category';
import { ViewCategory } from '../../modules/Product/ViewProduct/Partials/ViewCategory';
import ViewVariations from '../../modules/Product/ViewProduct/Partials/ViewVariations';
import { AddVariationwithCard } from '../../modules/AddSubModule/AddVariations';
import { ViewSale } from '../../modules/Sales/Partials/ViewSale';
import { ExpenseCategory } from '../../modules/AddExpense/Expense/Partials/CategoryExpense';
import { CatdWithCard } from '../../modules/AddExpense/Expense/Partials/CatWithCard';
import { NetWorkError } from '../../components/NetWorkError';
import ProductStock from '../../modules/Product/ViewProduct/Partials/Stock';
import ForgotPassword from '../../modules/Auth/Components/ForgotPassword';
import ConfirmPassword from '../../modules/Auth/Components/ConfirmPassword';
import Resetpassword from '../../modules/ResetPassword';




export const anonymous = [
    {
        routePath: '/signin',
        Component: UserSignin,
    },
    {
        routePath: '/register',
        Component: RegisterMenu,
    },
    {
        routePath: '/password',
        Component: PasswordForm,
    },
    // forgot password pages =========
    {
        routePath: '/forgotPassword',
        Component: ForgotPassword,
    },
    {
        routePath: '/confirmPassword',
        Component: ConfirmPassword,
    },
]

export const adminAuthenticated = [

    {       // ----------- Page Not Fonund
        routePath: '*',
        Component: PageNotFound,
    },
    {       // ----------- Network Error
        routePath: 'networkerror',
        Component: NetWorkError,
    },
    {
        routePath: 'addprofile',
        Component: AddBusinessProfile,
    },
    {
        routePath: 'viewprofile',
        Component: ViewTable,
    },
    {
        routePath: 'service',
        Component: AddServiceMain,
    },
    {
        routePath: 'viewservice',
        Component: ServiceTable,
    },
    {
        routePath: 'addproduct',
        Component: AddProductName,
    },
    {
        routePath: 'product',
        Component: ViewProductTable,
    },
    {
        routePath: 'stock',
        Component: ProductStock,
    },
    {
        routePath: 'addparty',
        Component: Party,
    },
    {
        routePath: 'party',
        Component: ViewParty,
    },
    {
        routePath: 'addexpense',
        Component: AddExpense,
    },
    {
        routePath: 'expense',
        Component: ViewExpense,
    },
    {
        routePath: 'addsale',
        Component: Sales,
    },
    {
        routePath: 'viewsale',
        Component: ViewSale,
    },
    {
        routePath: 'addsalereturn/:id',
        Component: SaleReturn,
    },
    {
        routePath: 'viewsalereturn',
        Component: ViewSaleReturn,
    },
    {
        routePath: 'addpurchase',
        Component: Purchase,
    },
    {
        routePath: 'purchasereturn/:id',
        Component: PurchaseReturn,
    },
    {
        routePath: 'quotation',
        Component: Quotation,
    },
    {
        routePath: 'Viewquotation',
        Component: ViewQuotationPage,
    },
    {
        routePath: 'viewpurchase',
        Component: ViewPurchase,
    },
    {
        routePath: 'viewpurchasereturn',
        Component: ViewPurchaseReturn,
    },
    {
        routePath: 'reporttab',
        Component: Tab,
    },
    {
        routePath: 'unpaidtab',
        Component: UnpaidBills,
    },

    // =====  Unpaid =======
    {
        routePath: 'saleunpaid',
        Component: SaleUnpaileBill,
    },
    {
        routePath: 'salereturnunpaid',
        Component: SaleReturnUnpaileBill,
    },
    {
        routePath: 'purchseunpaid',
        Component: PurchaseUnpaidBill,
    },
    {
        routePath: 'purchsereturnunpaid',
        Component: PurchaseReturnUnpaid,
    },
    {
        routePath: 'daybook',
        Component: DayBook,
    },
    {
        routePath: 'salereport',
        Component: SaleReport,
    },
    {
        routePath: 'salereturnreport',
        Component: SaleReturnReport,
    },
    {
        routePath: 'purchsereport',
        Component: PurchseReportMain,
    },
    {
        routePath: 'purchsereturnreport',
        Component: PurchseReturntMain,
    },
    {
        routePath: 'quotationreport',
        Component: QuotationReportMain,
    },

    //===============View-EDit Unit ============
    {
        routePath: 'AddUnit',
        Component: ProductUnits,
    },
    {
        routePath: 'ViewUnit',
        Component: ViewUnit,
    },

    //=====================View-Edit Brand ==========


    {
        routePath: 'AddBrand',
        Component: AddBrand,
    },
    {
        routePath: 'ViewBrand',
        Component: ViewBrand,
    },

    //=====================View-Edit Brand ==========

    {
        routePath: 'ViewCategory',
        Component: ViewCategory,
    },
    {
        routePath: 'AddCategory',
        Component: AddCategorywithCard,
    },
    {
        routePath: 'ViewVariations',
        Component: ViewVariations,
    },
    {
        routePath: 'AddVariations',
        Component: AddVariationwithCard,
    },
    {
        routePath: '',
        Component: SalesDashboard,
    },
    {
        routePath: 'expense_category',
        Component: ExpenseCategory,
    },
    {
        routePath: 'addcatexpense',
        Component: CatdWithCard,
    },
    {
        routePath: 'resetpassword',
        Component: Resetpassword,
    },
]