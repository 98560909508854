import { Col, Form } from 'antd'
import React, { useEffect, useState } from 'react'
import { CustomTextArea } from '../../../components/Form/CustomTextArea'
import { CustomRow } from '../../../components/CustomRow'
import { CustomInput } from '../../../components/Form/CustomInput'
import Flex from '../../../components/Flex'
import Button from '../../../components/Form/CustomButton'
import request from '../../../utils/request'
import { toast } from 'react-toastify'
import { CustomCheckBox } from '../../../components/Form/CustomCheckBox'
import { CustomSelect } from '../../../components/Form/CustomSelect'
import { CustomModal } from '../../../components/CustomModal'

const AddCategory = ({ handleClose, formReset, FormUpdatecatry, UpdateSunCategory, categoryrecord, handlesubpost }) => {

    const [form] = Form.useForm()

    const [checked, setChecked] = useState(false)
    const ADDCATEGORY = 'product/add_category/'
    const ADDSUBCATEGORY = 'product/add_subcategory/'
    const SubCat = 'product/add_category/'

    // ======  Modal Open ========
    const [isModalOpen, setIsModalOpen] = useState(false);

    // ======  Modal Title and Content ========
    const [modalTitle, setModalTitle] = useState("");
    const [modalContent, setModalContent] = useState(null);
    const [modelwith, setModelwith] = useState(0);
    const [subData, setSubData] = useState([]);

    useEffect(() => {
        if (categoryrecord) {
            form.setFieldsValue(categoryrecord)
        }

    }, [categoryrecord])

    const onReset = () => {
        form.resetFields();
    }

    // ===== Modal Functions Start =====

    const showModal = () => {
        setIsModalOpen(true);
    };

    const handleOk = () => {
        setIsModalOpen(false);
    };

    const handleCancel = () => {
        setIsModalOpen(false);
    };
    useEffect(() => {
        form.resetFields();
    }, [formReset])

    const onFinish = (values) => {
        const categoryValues = {
            category: values.category,
            category_code: values.category_code,
            category_description: values.category_description
        }

        const subCategoryValues = {
            subcategory: values.category,
            subcategory_code: values.category_code,
            subcategory_description: values.category_description,
            main_category: values.main_category
        }
        {
            checked ?
                AddsubCategoryPost(subCategoryValues)
                :
                AddCategoryPost(categoryValues)
        }

    };

    const onFinishFailed = (errorInfo) => {
        toast.warn("Please fill the details!")
    };

    const AddCategoryPost = (values) => {
        request.post(`${ADDCATEGORY}`, values)
            .then(function (response) {

                if (response.status == 201) {
                    form.resetFields();
                    toast.success('Category Added Successfully')
                    if (FormUpdatecatry) {
                        FormUpdatecatry()
                    }
                    if (handleClose) {
                        handleClose()
                    }
                    if (UpdateSunCategory) {
                        UpdateSunCategory()
                    }
                    if (handlesubpost) {
                        handlesubpost()
                    }
                    if (GetSubParentCategory) {
                        GetSubParentCategory()
                    }
                }
                else if (response.status == 226) {
                    toast.warn('Category Already Added')
                }

                else {
                    toast.error('Failed');
                }
            })
            .catch(function (error) {
                if (error.response.status === 406) {
                    toast.warn("Please Add business Profile!")
                } 
                else if(error.response.status === 302){
                    toast.warn("Limit Exceeded!")
                }
                else{
                    toast.error('Failed')
                }
            });
            ;
    }

    const AddsubCategoryPost = (values) => {
        request.post(`${ADDSUBCATEGORY}`, values)
            .then(function (response) {

                if (response.status == 201) {
                    form.resetFields();
                    toast.success('Category Added Successfully')
                    if (FormUpdatecatry) {
                        FormUpdatecatry()
                    }
                    if (handleClose) {
                        handleClose()
                    }
                    if (GetSubParentCategory) {
                        GetSubParentCategory()
                    }
                }
                else if (response.status == 226) {
                    toast.warn('Category Already Added')
                }

                else {
                    toast.error('Failed');
                }
            })
            .catch(function (error) {
                toast.error('Failed');
            });
    }

    const handleChecked = () => {
        setChecked(!checked)
    }

    useEffect(() => {
        GetSubParentCategory()
    }, [])

    const GetSubParentCategory = (id) => {
        request.get(`${SubCat}`)
            .then(function (response) {
                setSubData(response.data);
            })
            .catch(function (error) {
                console.log(error)
            });
    }
    const parentcategory = subData?.map((item) => ({
        label: item.category, value: item.id,
    }))

    useEffect(() => {
        form.setFieldsValue({ main_category: subData?.id })
    }, [subData])

    return (
        <Form
            name='AddCategory'
            form={form}
            labelCol={{
                span: 24,
            }}
            wrapperCol={{
                span: 24,
            }}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            autoComplete="off">
            <CustomRow space={[24, 24]}>



                <Col span={24} md={24}>
                    <CustomInput label={'Category'} placeholder={'Enter Name'} name={'category'}
                        rules={[
                            {
                                required: true,
                                message: 'Please Enter Name!',
                            }
                        ]}
                    />
                </Col>

                <Col span={24} md={24}>
                    <CustomInput label={'Category Code'} placeholder={'Code'} name={'category_code'}
                      rules={[
                        {
                            required: true,
                            message: 'Please Enter Category Code!',
                        }
                    ]}
                    />
                </Col>

                <Col span={24} md={24}>
                    <CustomTextArea label={'Description'} name={'category_description'}
                        placeholder={'Description'} />

                </Col>

                <Col span={24} md={24}>
                    <CustomCheckBox checked={checked} onChange={handleChecked} label={'Add as sub taxonomy'} />
                </Col>
                {
                    checked ?
                        (
                            <Col span={24} md={24}>
                                <CustomSelect label={'Select parent category'}
                                    options={parentcategory} name={'main_category'}
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please Select Parent Category!',
                                        }
                                    ]}
                                />
                            </Col>
                        ) : null
                }

            </CustomRow>

            <Flex center gap={'20px'} style={{ margin: '30px' }}>
                <Button.Primary text={'Add'} htmlType={'submit'} />
                <Button.Danger text={'Reset'} onClick={onReset} />
            </Flex>
            <CustomModal isVisible={isModalOpen} handleOk={handleOk} handleCancel={handleCancel} width={modelwith} modalTitle={modalTitle} modalContent={modalContent} />

        </Form>
    )
}

export default AddCategory