import { Col, Form } from 'antd'
import React, { useEffect } from 'react'
import Button from '../../../../components/Form/CustomButton'
import { CustomRow } from '../../../../components/CustomRow'
import Flex from '../../../../components/Flex'
import { CustomInput } from '../../../../components/Form/CustomInput'
import { toast } from 'react-toastify'
import request from '../../../../utils/request'
import { useDispatch } from 'react-redux'
import { getExpenseCategoryName } from '../../ExpenseSlice'

export const CategoryNameModal = ({ handleOk, trigger, catrecord, GetExpenseCategory }) => {
    
    const [form] = Form.useForm()
    const dispatch = useDispatch()
    const CATEGORY_NAME_URL = 'expense/add_category/'
    const EDIT_CAT_URL = 'expense/edit_category'

    useEffect(() => {
        form.resetFields();
    }, [trigger])

    const onReset = () => {
        form.resetFields();
    }

    const onClose = () => {
        handleOk()
    }

    useEffect(() => {
        form.setFieldsValue(catrecord)
    }, [catrecord, trigger])

    const onFinish = (values) => {
        if (catrecord) {
            UpdateCategory(catrecord.id, values)
        }
        else {
            AddCategoryName(values)
        }

    };

    const AddCategoryName = (values) => {
        request.post(`${CATEGORY_NAME_URL}`, values)
            .then(function (response) {
                if ( response.status == 201) {
                    toast.success('Category Name Saved Successfully');
                    dispatch(getExpenseCategoryName())
                    form.resetFields();
                    if (handleOk) {
                        handleOk()
                    }
                } else if(response.status == 226){
                    toast.warn('Add the Business Profile');
                } else {
                    toast.error('Somthing went Failed')
                }

            })
            .catch(function (error) {
                if (error.response.status == 302) {
                    toast.warn('Category Already Exists');

                }
                else if (error.response.status == 408) {
                    toast.warn('Your plan is over. Please subscribe to the plan!');
                }
                else if (error.response.status == 304) {
                    toast.warn('Category Limit Exceeds,Please Subscribe the Plan');
                }
                else {
                    toast.error('Failed')
                }
            });
    }

    const UpdateCategory = (id, values) => {
        request.put(`${EDIT_CAT_URL}/${id}/`, values)
            .then(function (response) {
                GetExpenseCategory()
                form.resetFields()
                onClose()
                toast.info("Category Updated Successfully")
            })
            .catch(function (error) {
                if (error.response.status == 302) {
                    toast.warn('Category Already Exists');
                }
                else if (error.response.status === 304) {
                    toast.warn("Expense limit exceeded, Please subscribe your Plan!")

                }
                else if (error.response.status === 408) {
                    toast.warn('Your plan is over. Please subscribe to the plan!');

                } 
                else {
                    toast.error('Failed')
                }
            });
    }

    const onFinishFailed = (errorInfo) => {
       toast.warn("Please fill the Details!")

    };

    return (
        <Form
            name='AddCustName'
            form={form}
            labelCol={{
                span: 24,
            }}
            wrapperCol={{
                span: 24,
            }}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            autoComplete="off">
            <CustomRow space={[24, 24]}>

                <Col span={24} md={24}>
                    <CustomInput label={'Expense Category Name'} placeholder={'Enter Expense Category Name'} name={'category_name'}
                        rules={[
                            {
                                required: true,
                                message: 'Please Enter Expense Category Name!',
                            }
                        ]}
                    />
                </Col>

            </CustomRow>
            {
                catrecord ? (<Flex center gap={'20px'} style={{ margin: '30px' }}>
                    <Button.Primary text={'Update'} htmlType={'submit'} />
                    <Button.Danger text={'Cancel'} onClick={onClose} />

                </Flex>) : (<Flex center gap={'20px'} style={{ margin: '30px' }}>
                    <Button.Primary text={'Add'} htmlType={'submit'} />
                    <Button.Danger text={'Reset'} onClick={onReset} />

                </Flex>)
            }

        </Form>
    )
}
