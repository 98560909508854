import { Card, Checkbox, Col } from 'antd'
import React, { Fragment, useEffect } from 'react'
import { CustomRow } from '../../../components/CustomRow'
import Flex from '../../../components/Flex'
import { CustomInput } from '../../../components/Form/CustomInput'
import { CustomInputNumber } from '../../../components/Form/CustomInputNumber'


export const QuotationFormFooter = ({ BalanceOnChange, BalanceOnChangeService, serviceClick }) => {
    
    const HandleOnchange = (val) => {
        if (serviceClick) {
            BalanceOnChangeService(val)
            BalanceOnChange(0)
        } else {
            BalanceOnChangeService(0)
            BalanceOnChange(val)
        }
    }


    return (
        <Fragment>
        <CustomRow gutter={[24, 24]} >
            <Col lg={8} md={8} span={24}>
            </Col>
            <Col lg={0} md={0} span={0}></Col>
            <Col lg={16} md={16} span={24}>
                <Card>
                    <CustomRow space={[24, 24]}>
                        {serviceClick ?
                            (
                                <>
                                    <Col span={24} md={12}>
                                        <CustomInput label={'Total Tax'} disabled name={'tax_total'} />
                                    </Col>

                                    <Col span={24} md={12}>
                                        <CustomInput label={'Total Discount'} disabled name={'discount_total'} />
                                    </Col>
                                </>
                            ) : (
                                <>

                                    <Col span={24} md={8}>
                                        <CustomInput label={'Total Tax'} disabled name={'tax_total'} />
                                    </Col>
                                    <Col span={24} md={8}>
                                        <Flex alignCenter={true}>
                                            <CustomInput label={'Total Quantity'} disabled name={'qty_total'} />
                                        </Flex>
                                    </Col>

                                    <Col span={24} md={8}>
                                        <CustomInput label={'Total Discount'} disabled name={'discount_total'} />
                                    </Col>
                                </>
                            )}


                        <Col span={24} md={24}>
                            <Flex end>
                                <CustomInput label={'Sub Total'} disabled name={'table_total'} display={'none'} />
                            </Flex>
                        </Col>
                    </CustomRow>
                </Card>
            </Col>

            <Col lg={10} md={10} span={24}></Col>

            <Col lg={14} md={20} span={24}>
                <CustomRow gutter={[12, 12]} style={{ marginTop: '20px', gap: '10px' }}>
                    <Col span={24}>
                        <CustomRow gutter={[12, 12]}>
                            <Col sm={12} span={24} style={{
                                display: 'flex',
                                alignItems: 'end',
                            }}>
                                <CustomRow gutter={[12, 12]}>
                                </CustomRow>
                            </Col>

                            <Col sm={12} span={24}>
                                <CustomInputNumber precision={2} name={'grand_total'} label={'Total'} placed={'end'} disabled />
                            </Col>
                        </CustomRow>
                    </Col>
                 

                </CustomRow>

            </Col >
        </CustomRow >
    </Fragment>
    )
}