import React from 'react'
import PurchseReport from './Partials/Purchse'

export const PurchseReportMain = () => {
    return (
        <div>
            <PurchseReport />
        </div>
    )
}
