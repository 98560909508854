import React, { useEffect, useState } from 'react'
import ViewProfile from '../../AddBusiness/Partials/ViewProfile';
import AddProfile from '../../AddBusiness/Partials/AddProfile';
import Flex from '../../../../components/Flex';
import { TableIconHolder } from '../../../../components/CommonStyled';
import { CustomModal } from '../../../../components/CustomModal';
import { MdDelete } from "react-icons/md";
import { FiEdit, FiPlus } from "react-icons/fi";
import { AiOutlineEye } from "react-icons/ai";
import { useDispatch, useSelector } from 'react-redux';
import { getProfile, getProfileError, getProfileStatus, selectAllProfile } from '../../BusinessSlice';
import { CommonLoading } from '../../../../components/CommonLoading';
import request from '../../../../utils/request';
import { toast } from 'react-toastify';
import { CustomPageTitle } from '../../../../components/CustomPageTitle';
import { useNavigate } from 'react-router-dom';
import { CustomRow } from '../../../../components/CustomRow';
import { Col } from 'antd';
import Button from '../../../../components/Form/CustomButton';
import { CustomTable } from '../../../../components/Form/CustomTable';
import { CustomPopconfirm } from '../../../../components/CustomPopConfirm';
import { THEME } from '../../../../theme';

const ViewTable = () => {

    const navigate = useNavigate();

    const DEL_PROFILE_URL = 'business_owner/business_detail_edit'

    // ======  Modal Open ========
    const [isModalOpen, setIsModalOpen] = useState(false);

    // ======  Modal Title and Content ========
    const [modalTitle, setModalTitle] = useState("");
    const [modalContent, setModalContent] = useState(null);

    // ----------  Form Reset UseState ---------
    const [trigger, setTrigger] = useState(0)
    const [modelwith, setModelwith] = useState(0);

    const [dataSource, setDataSource] = useState([])
    const [profiletFormReset, setProfiletFormReset] = useState(0);
    const [profileFormUpdate, setProfileFormUpdate] = useState(0);    //  --  FormUpdate

    // ===== Modal Functions Start =====

    const showModal = () => {
        setIsModalOpen(true);
    };

    const handleOk = () => {
        setIsModalOpen(false);
        ProfileFormCancelRest()
    };

    const handleCancel = () => {
        setIsModalOpen(false);
        ProfileFormCancelRest()
    };

    // =========== delete ======

    const handleConfirm = (record) => {
        DeleteProfile(record);
    };

    const DeleteProfile = (record) => {
        request.delete(`${DEL_PROFILE_URL}/${record?.id}/`)
            .then((response) => {
                dispatch(getProfile())
                toast.info("Deleted Successfully")
            }).catch(error => {
                if (error.response.status === 401) {
                    toast.warning('This profile used for Transactions')
                }
                else {
                    toast.error("Something Went Wrong")
                }
            });
    };


    // ========== Profile get =========

    useEffect(() => {
        dispatch(getProfile())
    }, [])

    const profile = useSelector(selectAllProfile)

    const dispatch = useDispatch();

    const selectAllProfiles = useSelector(selectAllProfile)
    const GetProfileStatus = useSelector(getProfileStatus)
    const GetProfileError = useSelector(getProfileError)

    useEffect(() => {
        setDataSource(selectAllProfiles)
    }, [selectAllProfiles])


    useEffect(() => {
        dispatch(getProfile())
    }, [profileFormUpdate])

    const onViewProfile = (record) => {
        setTrigger(trigger + 1)
        setModelwith(800)
        setModalTitle("View Profile");
        setModalContent(<ViewProfile Profiledata={record} Trigger={trigger} />);
        showModal();
    };
    const onEditProfile = (record) => {
        setTrigger(trigger + 1)
        setModelwith(800)
        setModalTitle("Update Profile");
        setModalContent(<AddProfile
            Profiledata={record}
            handleOks={handleOk} FormUpdate={FormUpdate} Trigger={trigger}
            profiletFormReset={profiletFormReset} ProfileFormExternalClose={ProfileFormExternalClose}
        />);
        showModal();
    };

    const ProfileFormCancelRest = () => {
        setProfiletFormReset(profiletFormReset + 1)
    }

    const ProfileFormExternalClose = () => {
        handleOk();
    }

    const FormUpdate = () => {
        setProfileFormUpdate(profileFormUpdate + 1)
    }

    const columns = [
        {
            title: 'Sl No',
            render: (item, value, index) => index + 1,
        },
        {
            title: <p>Business&nbsp;Name</p>,
            dataIndex: 'business_name',
        },
        {
            title: <p>GST&nbsp;IN</p>,
            dataIndex: 'gst_no',
            render: (text) => (
                <span dangerouslySetInnerHTML={{ __html: text }} />
            ),
        },
        {
            title: 'Email',
            dataIndex: 'email',
        },
        {
            title: <p>Phone&nbsp;No</p>,
            dataIndex: 'phone_no',
        },
        {
            title: 'Action',
            render: (record) => {
                return (
                    <Flex center={"true"} gap={'10px'}>

                        <TableIconHolder color={THEME.green} size={'22px'} onClick={() => onViewProfile(record)}>
                            <AiOutlineEye />
                        </TableIconHolder>

                        <TableIconHolder color={THEME.blue} size={'22px'} onClick={() => onEditProfile(record)}>
                            <FiEdit />
                        </TableIconHolder>

                        <CustomPopconfirm
                            record={record}
                            confirm={handleConfirm}
                            title={"Delete the business profile List"}
                            description={"Are you sure to delete this business profile ?"}>
                            <TableIconHolder color={THEME.red} size={'22px'}>
                                <MdDelete />
                            </TableIconHolder>
                        </CustomPopconfirm>
                    </Flex>
                )
            }
        },
    ];

    let content;

    if (GetProfileStatus === 'loading') {
        content = <CommonLoading />
    } else if (GetProfileStatus === 'succeeded') {
        const rowKey = (dataSource) => dataSource.id;
        content = <CustomTable columns={columns} data={dataSource} rowKey={rowKey} />
    } else if (GetProfileStatus === 'failed') {
        content = <h2>{GetProfileError}</h2>
    }

    return (
        <div>
            <CustomRow space={[24, 24]}>
                <Col span={24} md={15}>
                    <CustomPageTitle Heading={'Business Profile'} />
                </Col>
                <Col span={24} md={9}>
                    <CustomRow space={[24, 24]}>
                        <Col span={24} md={16}>
                            
                        </Col>
                        {
                            Object.keys(profile).length === 0 ? (
                                <Flex gap={'20px'} center={"true"} margin={'10px 0'}>
                                    <Button.Primary icon={<FiPlus style={{ marginRight: '5px', fontSize: '20px' }} />} text={'Add'} onClick={() => navigate('/addprofile')} />
                                </Flex>
                            ) : ("")
                        }
                    </CustomRow>
                </Col>
            </CustomRow>
            {content}
            <CustomModal isVisible={isModalOpen} handleOk={handleOk} handleCancel={handleCancel} width={modelwith} modalTitle={modalTitle} modalContent={modalContent} />
        </div>
    )
}

export default ViewTable