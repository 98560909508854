import React, { useEffect, useState } from 'react'
import { FiEdit, FiPlus } from 'react-icons/fi';
import { CustomPopconfirm } from '../../../../components/CustomPopConfirm';
import { MdDelete } from 'react-icons/md';
import Flex from '../../../../components/Flex';
import { CustomModal } from '../../../../components/CustomModal';
import AddProduct from '../../AddProduct/Partials/AddProduct';
import { THEME } from '../../../../theme';
import { useDispatch, useSelector } from 'react-redux';
import { addToTable, getProduct, getProductError, getProductStatus, selectAllProduct } from '../../ProductSlice';
import { CommonLoading } from '../../../../components/CommonLoading';
import request from '../../../../utils/request';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { CustomPageTitle } from '../../../../components/CustomPageTitle';
import { CustomRow } from '../../../../components/CustomRow';
import { Col, Table, Tag } from 'antd';
import { CustomTable } from '../../../../components/Form/CustomTable';
import { TableIconHolder } from '../../../../components/CommonStyled';
import ButtonStandard from '../../../../components/Form/CustomStandardButton';
import Label from '../../../../components/Form/Label';
import { CustomInput } from '../../../../components/Form/CustomInput';
import { CustomTandardTable } from '../../../../components/Form/CustomStandardTable';
import { AiOutlineCloudUpload } from 'react-icons/ai';

const ProductTable = () => {

    const navigate = useNavigate();
    const dispatch = useDispatch();

    const DEL_PRODUCT_URL = 'product/edit_productes'
    const UPLOAD_ONLINE_URL = 'product/product_push_online'
    const [searchTexts, setSearchTexts] = useState('');   //---------Seach Bar --------

    // ======  Modal Open ========
    const [isModalOpen, setIsModalOpen] = useState(false);

    // ======  Modal Title and Content ========
    const [modalTitle, setModalTitle] = useState("");
    const [modalContent, setModalContent] = useState(null);

    // ----------  Form Reset UseState ---------
    const [formReset, setFormReset] = useState(0);
    const [modelwith, setModelwith] = useState(0);
    const [profileFormUpdate, setProfileFormUpdate] = useState(0)
    const [triggers, setTriggers] = useState(0)

    const [dataSource, setDataSource] = useState([])
    const [currentPage, setCurrentPage] = useState(1)




    // ===== Modal Functions Start =====

    const showModal = () => {
        setIsModalOpen(true);
        FormUpdate()
    };

    const handleOk = () => {
        setIsModalOpen(false);
        RestForm()
        FormUpdate()
    };

    const handleSearchs = (value) => {
        setSearchTexts(value);
    };

    const handleCancel = () => {
        setIsModalOpen(false);
        RestForm()
    };

    const handleConfirm = (record) => {
        DeleteProduct(record);
    }


    const DeleteProduct = (record) => {
        request.delete(`${DEL_PRODUCT_URL}/${record?.id}/`)
            .then((response) => {
                dispatch(getProduct())
                toast.info("Deleted Successfully")
            }).catch(error => {
                if (error.response.status === 406) {
                    toast.warn('THIS PRODUCT ALREADY USED FOR BILLING')
                } else if (error.response.status === 302) {
                    toast.warn(error.response.data.message)
                }
            });
    };

    const handleUpload =(record)=>{
        const value={
            push_online:record.push_online ? false : true
        }
        request.patch(`${UPLOAD_ONLINE_URL}/${record?.id}/`,value)
        .then((response) => {
            dispatch(getProduct())
            toast.info("Uploaded Successfully")
        }).catch(error => {
            console.log(error,'error');
        });
    }

    const onEditProduct = (record) => {
        dispatch(addToTable(record.product))
        setProfileFormUpdate(profileFormUpdate + 1)
        setModelwith(1000)
        setModalTitle("Update Product");
        setModalContent(<AddProduct handleProductGet={handleProductGet} productdata={record} Formcancel={handleOk} Trigger={profileFormUpdate} FormUpdateProduct={FormUpdate} />);
        showModal();
    };

    // ========== Product get =========


    const AllProduct = useSelector(selectAllProduct)
    const GetProductStatus = useSelector(getProductStatus)


    useEffect(() => {
        dispatch(getProduct())
    }, [profileFormUpdate])

    const handleProductGet = () => {
        dispatch(getProduct())
    }

    useEffect(() => {
        setDataSource(AllProduct)
    }, [AllProduct])

    const FormUpdate = () => {
        setProfileFormUpdate(profileFormUpdate + 1)
    }

    const RestForm = () => {
        setFormReset(formReset + 1)
    }


    const columns = [
        {
            title: 'Sl No',
            render: (item, value, index) => index + 1,
        },
        {
            title: 'Item Name',
            dataIndex: 'product_name',
            filteredValue: searchTexts ? [searchTexts] : null,
            onFilter: (value, record) => {
                return String(record.product_name).toLowerCase().includes(value.toLowerCase()) ||
                    String(record.product_name).includes(value.toUpperCase());
            },
        },
        {
            title: 'Item Unit',
            dataIndex: 'item_unit',

        },
        {
            title: 'Brand Name',
            dataIndex: 'brand_name',
        },
        {
            title: 'Category',
            dataIndex: 'category',
        },
        {
            title: 'Status',
            render: (record) => {
                console.log(record, 'kdhfkhsg');
                return (
                    <>
                        {record.push_online ?
                            <Tag color='green'>
                                Online
                            </Tag> :
                            <Tag color='red'>
                                Offline
                            </Tag>
                        }

                    </>
                )
            }
        },
        {
            title: 'Action',
            render: (record) => {
                return (
                    <Flex center={"true"} gap={'10px'}>

                        <TableIconHolder color={THEME.blue} size={'22px'} onClick={() => onEditProduct(record)}>
                            <FiEdit />
                        </TableIconHolder>

                        <CustomPopconfirm
                            record={record}
                            confirm={handleConfirm}
                            title={"Delete the Product"}
                            description={"Are you sure to delete this Product ?"}>
                            <TableIconHolder color={THEME.red} size={'22px'}>
                                <MdDelete />
                            </TableIconHolder>
                        </CustomPopconfirm>
                        {
                            record.push_online ?
                            <CustomPopconfirm
                            okText={'Upload'}
                            record={record}
                            confirm={handleUpload}
                            title={"Remove from Online"}
                            description={"Are you sure to remove the product from online ?"}>
                            <TableIconHolder color={THEME.green} size={'22px'}>
                                <AiOutlineCloudUpload />
                            </TableIconHolder>
                        </CustomPopconfirm> :
                          <CustomPopconfirm
                          okText={'Upload'}
                          record={record}
                          confirm={handleUpload}
                          title={"Upload to Online"}
                          description={"Are you sure to upload the product to online ?"}>
                          <TableIconHolder color={THEME.green} size={'22px'}>
                              <AiOutlineCloudUpload />
                          </TableIconHolder>
                      </CustomPopconfirm>
                        }
                        

                    </Flex>
                )
            }
        },
    ];

    let content;

    if (GetProductStatus === 'loading') {
        content = <CommonLoading />
    } else if (GetProductStatus === 'succeeded') {
        const rowKey = (dataSource) => dataSource.id;
        content = <CustomTandardTable columns={columns} data={dataSource} rowKey={rowKey} />
    }
    else if (GetProductStatus === 'failed') {
        const rowKey = (dataSource) => dataSource.id;
        content = <CustomTable columns={columns} data={dataSource} rowKey={rowKey} />
    }


    return (
        <div>
            <CustomRow space={[24, 24]}>
                <Col span={24} md={15}>
                    <CustomPageTitle Heading={'View Product'} />
                </Col>
                <Col span={24} md={9}>
                    <CustomRow space={[24, 24]}>
                        <Col span={24} md={16}>

                        </Col>
                        <Col span={24} md={8} style={{ float: 'right' }}>
                            <ButtonStandard.Primary icon={<FiPlus style={{ marginRight: '5px', fontSize: '20px' }} />} text={'Add'}
                                onClick={() => navigate('/addproduct')} />
                        </Col>
                    </CustomRow>
                </Col>
            </CustomRow>
            <div style={{ display: 'flex', alignItems: 'center', marginTop: '50px' }}>
                <Label style={{ marginRight: '20px' }}>Search by Product Name :</Label>
                <CustomInput
                    value={searchTexts}
                    placeholder="Search"
                    onSearch={handleSearchs}
                    onChange={(e) => handleSearchs(e.target.value)}
                />
            </div>
            {content}
            <CustomModal isVisible={isModalOpen} handleOk={handleOk} handleCancel={handleCancel} width={modelwith} modalTitle={modalTitle} modalContent={modalContent} />
        </div>
    )
}

export default ProductTable