import React from 'react'
import { CustomPageTitle } from '../../../components/CustomPageTitle'
import PurchaseUnpaid from './Partials/PurchaseUnpaid'

export const PurchaseUnpaidBill = () => {
    return (
        <div>
            <CustomPageTitle Heading={'Purchase Bill'} />
            <PurchaseUnpaid />
        </div>
    )
}
