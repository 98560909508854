import React, { useEffect, useState } from 'react'
import { CustomModal } from '../../../../components/CustomModal'
import SaleReturnPay from './SaleReturnPay';
import Flex from '../../../../components/Flex';
import Button from '../../../../components/Form/CustomButton';
import { CustomTable } from '../../../../components/Form/CustomTable';
import { CustomPageTitle } from '../../../../components/CustomPageTitle';
import request from '../../../../utils/request';

const SaleReturnUnpaid = () => {

    // ======  Modal Open ========
    const [isModalOpen, setIsModalOpen] = useState(false);

    // ======  Modal Title and Content ========
    const [modalTitle, setModalTitle] = useState("");
    const [modalContent, setModalContent] = useState(null);

    // ----------  Form Reset UseState ---------
    const [formReset, setFormReset] = useState(0);
    const [modelwith, setModelwith] = useState(0);

    const [dataSource,setDataSource] = useState([])

    const [trigger, setTrigger] = useState(0)

    // ===== Modal Functions Start =====

    const showModal = () => {
        setIsModalOpen(true);
    };

    const handleOk = () => {
        setIsModalOpen(false);
    };

    const handleCancel = () => {
        setIsModalOpen(false);
    };


    const onPayMethod = (record) => {
        setTrigger( trigger + 1)
        setModelwith(800)
        setModalTitle("Pay Bill");
        setModalContent(<SaleReturnPay SaleReturnRecord={record} FormUpdate={FormUpdate} trigger={trigger} />);
        showModal();
    };

    useEffect(() => {
        GetSaleReturnUnpaid()
    }, [])

    const GetSaleReturnUnpaid = () => {
        request.get(`sale_pay/get_sale_return_unpaid_bills/`)
            .then(function (response) {
                setDataSource(response.data)
            })
            
    }

    const FormUpdate = () => {
        GetSaleReturnUnpaid()
        handleOk()
    }

    const columns = [
        {
            title: 'Sl No',
            render: (item, value, index) => index + 1,
        },
        {
            title: 'Return Number',
            dataIndex: 'return_no',
        },
        {
            title: 'Date',
            dataIndex: 'return_date',
        },
        {
            title: 'Party Name',
            dataIndex: 'party_name',
        },
        {
            title: 'Mobile Number',
            dataIndex: 'mobile_number',
        },
        {
            title: 'Total Amount',
            dataIndex: 'grand_total',
        },
        {
            title: 'Paid Amount',
            dataIndex: 'paid_amt',
        },
        {
            title: 'Balance Amount',
            dataIndex: 'balance',
        },
        {
            title: 'Action',
            render: (record) => {
                return (
                    <Flex center={"true"} gap={'10px'}>
                        <Button.Danger text={'Pay'} onClick={() => onPayMethod(record)} />
                    </Flex>
                )
            }
        },
    ];

    return (
        <div>
            <CustomPageTitle Heading={'Sale Return '} />
            <CustomTable columns={columns} data={dataSource} />
            <CustomModal isVisible={isModalOpen} handleOk={handleOk} handleCancel={handleCancel} width={modelwith} modalTitle={modalTitle} modalContent={modalContent} />
        </div>
    )
}

export default SaleReturnUnpaid