import { createSlice } from "@reduxjs/toolkit";
const initialState = {
    jwt: null,
    username: null,
    // email:null,
}
const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        setCredentials: (state, action) => {
            const { username, jwt,email } = action.payload
            console.log(action,'setCredentialsaction');
            state.username = username
            state.jwt = jwt
            // state.email = email
        },
        logOut: (state, action) => {
            console.log(state,'logoutstate');
            state.username = null
            state.jwt = null
            // state.email = null
        }
    }
})

export const { setCredentials, logOut } = authSlice.actions
export const selectCurrentUser = (state) => state.auth.username
export const selectCurrentJwt = (state) => state.auth.jwt
export const selectCurrentEmail = (state) => state.auth.email
export default authSlice.reducer