import React, { useEffect, useState } from 'react'
import { CustomModal } from '../../../../components/CustomModal';
import Flex from '../../../../components/Flex';
import { BsPrinter } from 'react-icons/bs';
import { AiOutlineEye } from 'react-icons/ai';
import { THEME } from '../../../../theme';
import Button from '../../../../components/Form/CustomButton';
import { Col, Form } from 'antd';
import { CustomRow } from '../../../../components/CustomRow';
import styled from 'styled-components';
import { CustomPageTitle } from '../../../../components/CustomPageTitle';
import { BiFilterAlt } from 'react-icons/bi';
import { CustomDateRangePicker } from '../../../../components/Form/CustomDateRangePicker';
import { CustomSelect } from '../../../../components/Form/CustomSelect';
import { TbArrowsExchange } from 'react-icons/tb';
import dayjs from 'dayjs';
import ViewPurchseReport from './ViewPurchse';
import { useNavigate } from 'react-router-dom';
import { CustomTable } from '../../../../components/Form/CustomTable';
import { TableIconHolder } from '../../../../components/CommonStyled';
import ButtonStandard from '../../../../components/Form/CustomStandardButton';
import request from '../../../../utils/request';
import { toast } from 'react-toastify';
import { ViewPurchaseBill } from '../../../Purchase/Partials/ViewPurchaseBill';
import { FcPrint } from 'react-icons/fc';

const Filter = styled.div`
display: flex;
align-items: center;
cursor: pointer;
padding: 10px;
font-size: 25px;
color: #1677ff;
font-weight: 600;
margin: 0 0 20px 0;
& svg {
    font-size: 25px
}
`

const PurchseReport = () => {

    const [form] = Form.useForm();

    const nevigate = useNavigate();

    const SEARCH_URL = 'purchase/purchasedetails_search/'

    const [dataSource, setDataSource] = useState([])

    const [choiceFull, setChoiceFull] = useState('')
    const [dateRange, setDateRange] = useState([]);

    const [modalWidth, setModalWidth] = useState(0)
    const [showdetailsON, setShowdetailsON] = useState(false)
    const [show, setShow] = useState(false)

    // ======  Modal Open ========
    const [isModalOpen, setIsModalOpen] = useState(false);

    // ======  Modal Title and Content ========
    const [modalTitle, setModalTitle] = useState("");
    const [modalContent, setModalContent] = useState(null);


    // ===== Modal Functions Start =====

    const showModal = () => {
        setIsModalOpen(true);
    };

    const handleOk = () => {
        setIsModalOpen(false);
    };

    const handleCancel = () => {
        setIsModalOpen(false);
    };


    const handleDateRangeChange = (values) => {
        setDateRange(values);
    };

    const SalesDetails = (record) => {
        setModalWidth(600)
        setModalTitle("Views");
        setModalContent(<ViewPurchseReport   purchaseview={record}/>);
        showModal();
    };


    const month = [
        { label: 'This Month', value: 'This_month' },
        { label: 'Year', value: 'This_year' },
        { label: 'Quater', value: 'This_quorter' },
        { label: 'Custom', value: 'custom' },
    ]

    const handleMonthSelect = (value) => {
        form.setFieldsValue({ range: null })
        if (value === 'custom') {
            setShow(true);
            setChoiceFull(value)
        } else {
            setShow(false);
        }

        const choice = {
            choice: value
        }

        DateSearch(choice)

    };

    const DateSearch = (values) => {
        request.post(`${SEARCH_URL}`, values)
            .then(function (response) {
                setDataSource(response.data?.purchase)
                if (response.status == 226) {
                    toast.warn('Add the Business Profile');
                } else {
                    toast.error("Something Went Wrong")
                }
            })
            .catch(function (error) {
                console.log(error);
            });

    }

    useEffect(() => {
        GetTableView()
    }, [])

    const GetTableView = (values) => {
        request.get(`${SEARCH_URL}`)
            .then(function (response) {
                setDataSource(response.data?.purchase)
            })
            .catch(function (error) {
                console.log(error);
            });

    }

    const handleChange = () => {
        setShowdetailsON(!showdetailsON)
    }

    const onFinish = (values) => {
        const record = { ...values, range: dateRange, choice: choiceFull }

        DateSearch(record)


    }

    const onFinishFailed = (errorInfo) => {
        toast.warn("Please fill the details!")

    };

    const PurchasePrint = async (record) => {
        try {
            const response = await request.get(`purchase/get_print_purchase_order/${record?.id}/`);
            setModalWidth(1200)
            setModalTitle("Purchase Details");
            setModalContent(<ViewPurchaseBill record={response.data} />);
            showModal();
            return response.data;
        }
        catch (error) {
            throw error;
        }
    };

    const columns = [
        {
            title: 'Sl No',
            render: (item, value, index) => index + 1,
        },
        {
            title: 'Date',
            dataIndex: 'bill_date',
        },
        {
            title: 'Party Name',
            dataIndex: 'party_name',
        },
        {
            title: 'Total Amount',
            dataIndex: 'grand_total',
        },
        {
            title: 'Paid Amount',
            dataIndex: 'paid_amt',
        },
        {
            title: 'Action',
            render: (record) => {
                return (
                    <Flex spaceEvenly={'true'}>
                        <TableIconHolder color={THEME.green} size={'22px'} onClick={() => PurchasePrint(record)}>
                            <FcPrint />
                        </TableIconHolder>
                    </Flex>
                )
            }
        }


    ];

    const data = [
        {
            date: '2023-09-05',
            party_name: 'Party A',
            receipt_no: 'R12345',
            total_amount: 100.50,
            received_amount: 50,
        },
    ];
    return (
        <div>
            <CustomPageTitle Heading={'Purchase Report'} /><br />
            <Form
                form={form}
                labelCol={{
                    span: 24,
                }}
                wrapperCol={{
                    span: 24,
                }}
                initialValues={
                    {
                        from_date: dayjs(),
                        to_date: dayjs(),
                    }
                }
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                autoComplete="off">

                <CustomRow space={[24, 24]}>
                    <Col span={24} md={5}>
                        <Filter onClick={handleChange}><BiFilterAlt />&nbsp;&nbsp;Filter</Filter>
                    </Col>
                    <Col span={24} md={15}></Col>
                    <Col span={24} md={4}>
                        <Flex end={true} >
                            <ButtonStandard.Primary text={'Add Purchase'} style={{ marginRight: '10px' }} onClick={() => nevigate(`/addpurchase`)} />
                        </Flex>
                    </Col>
                </CustomRow>

                <CustomRow space={[24, 24]} style={{ marginBottom: '20px' }}>

                    {showdetailsON &&
                        <>
                            <Col span={24} md={3} style={{ marginTop: '10px' }}>
                                <b>Choose</b>&nbsp;:
                            </Col>
                            <Col span={24} md={12}>
                                <CustomSelect
                                    options={month}
                                    name={'month'}
                                    placeholder={'This Month'}
                                    onChange={handleMonthSelect}
                                    rules={[{ required: true, message: 'Please Select' }]}
                                />
                            </Col>
                            <Col span={24} md={7}></Col>
                            <Col span={24} md={24}>
                                {show ?
                                    <CustomRow space={[24, 24]} style={{ margin: '20px 0' }}>
                                        <Col span={24} md={4} style={{ marginTop: '10px' }}>
                                            <Flex spaceBetween alignCenter>
                                                <b>Between</b>
                                                <TbArrowsExchange />
                                            </Flex>
                                        </Col>

                                        <Col span={24} md={12}>
                                            <CustomDateRangePicker
                                                onChange={handleDateRangeChange}
                                                name={'range'}
                                                value={dateRange}
                                                rules={[{ required: true, message: 'Please Select the Date' }]}
                                            />
                                        </Col>

                                        <Col span={24} md={6}>
                                            <Flex>
                                                <Button.Primary text={'Submit'} htmlType='submit' />
                                            </Flex>
                                        </Col>
                                    </CustomRow> : null}
                            </Col>
                        </>
                    }
                </CustomRow>

            </Form>

            <CustomTable columns={columns} data={dataSource} />

            <CustomModal isVisible={isModalOpen} handleOk={handleOk} handleCancel={handleCancel} width={modalWidth} modalTitle={modalTitle} modalContent={modalContent} />
        </div>
    )
}

export default PurchseReport