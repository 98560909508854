import React, { useEffect, useState } from 'react'
import { CustomCardView } from '../../../components/CustomCardView'
import { Col, Form } from 'antd';
import { CustomRow } from '../../../components/CustomRow';
import { CustomSelect } from '../../../components/Form/CustomSelect'
import dayjs from 'dayjs';
import Flex from '../../../components/Flex';
import Button from '../../../components/Form/CustomButton';
import { CustomDateRangePicker } from '../../../components/Form/CustomDateRangePicker'
import { TbArrowsExchange, TbTruckReturn } from 'react-icons/tb';
import { CustomModal } from '../../../components/CustomModal';
import { CustomPageTitle } from '../../../components/CustomPageTitle';
import { CustomTable } from '../../../components/Form/CustomTable';
import { useNavigate } from 'react-router-dom';
import request from '../../../utils/request';
import ButtonStandard from '../../../components/Form/CustomStandardButton';
import { toast } from 'react-toastify';
import { TableIconHolder } from '../../../components/CommonStyled';
import { AiOutlineEye } from 'react-icons/ai';
import { THEME } from '../../../theme';
import { getSales, getSalesError, getSalesStatus, selectAllSales } from '../SaleSlice';
import { useDispatch, useSelector } from 'react-redux';
import { CommonLoading } from '../../../components/CommonLoading';
import { ViewSaleBill } from './ViewSaleBill';
import { FiEdit } from 'react-icons/fi';
import { CustomSwitch } from '../../../components/Form/CustomSwitch';
import { getService, getServiceError, getServiceStatus, selectAllService } from '../../Services/ServiceSlice';
import { CustomInput } from '../../../components/Form/CustomInput';
import Label from '../../../components/Form/Label';
import { SalesEntryPage } from './SalesEntryPage';
import { CustomTandardTable } from '../../../components/Form/CustomStandardTable';


export const ViewSale = () => {

    const [form] = Form.useForm();

    const dispatch = useDispatch();

    const nevigate = useNavigate();

    const SEARCH_URL = 'sales/saledetails_search/'

    const [trigger, setTrigger] = useState(0)

    const [viewSale, setViewSale] = useState([])

    const [choiceFull, setChoiceFull] = useState('')
    const [dateRange, setDateRange] = useState([]);
    const [show, setShow] = useState(false)
    const [modalContent, setModalContent] = useState(null);
    const [modalTitle, setModalTitle] = useState("");
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [modalWidth, setModalWidth] = useState(null);

    const [showProductTable, setShowProductTable] = useState(false);
    const [searchTexts, setSearchTexts] = useState([]);         //---------Seach Bar --------
    const [saleRetunCheck, setSaleRetunCheck] = useState({});         //---------Sale Retun Check --------

    const showModal = () => {
        setIsModalOpen(true);
    };

    const handleOk = () => {
        setIsModalOpen(false);
    };

    const handleCancel = () => {
        setIsModalOpen(false);
    };

    const handleDateRangeChange = (values) => {
        setDateRange(values);
    };

    const AllSales = useSelector(selectAllSales)
    const SaleStatus = useSelector(getSalesStatus)
    const SaleError = useSelector(getSalesError)

    const handleMonthSelect = (value) => {
        form.setFieldsValue({ 'range': null })
        if (value === 'custom') {
            setShow(true);
            setChoiceFull(value)
        } else {
            setShow(false);
        }
        const choice = {
            choice: value
        }
        DateSearch(choice)
    };

    const DateSearch = (values) => {
        request.post(`${SEARCH_URL}`, values)
            .then(function (response) {
                setViewSale(response.data?.sale)
            })
            .catch(function (error) {
                console.log(error);
            });
    }

    const month = [
        { label: 'This Month', value: 'This_month' },
        { label: 'Year', value: 'This_year' },
        { label: 'Quater', value: 'This_quorter' },
        { label: 'Custom', value: 'custom' },
    ]

    const onFinish = (values) => {
        const record = { ...values, range: dateRange, choice: choiceFull }
        DateSearch(record)

    }

    const onFinishFailed = (errorInfo) => {
        toast.warn("Please fill the details!")
    };

    const onCancelModal = () => {
        handleOk()
    }

    const SaleRetunBillPost = (RecordData) => {
        request.get(`sales/sale_return_pro_detail/${RecordData?.RecordData.id}/`, RecordData)
            .then(function (response) {
                nevigate(`/addsalereturn/${RecordData?.RecordData.id}`)
            })
            .catch(function (error) {
                console.log(error);
            });
    }

    const SaleConfirmations = (RecordData) => {
        return (
            <>
                <Flex center>
                    <h1 style={{ fontSize: '20px', color: '#685e5e' }}>Do you Want to Sales Return ?</h1>
                </Flex>
                <Flex gap={'15px'} center={'true'} style={{ margin: '20px 20px' }}>
                    <Button.Success text={'Yes'} onClick={() => SaleRetunBillPost(RecordData)} />
                    <Button.Danger text={'No'} onClick={onCancelModal} />
                </Flex>
            </>
        )
    }

    const SaleReturnConfirmations = (RecordData) => {
        return (
            <>
                <Flex center>
                    <h1 style={{ fontSize: '20px', color: '#685e5e' }}>Do you Want to View Sales Return ?</h1>
                </Flex>
                <Flex gap={'15px'} center={'true'} style={{ margin: '20px 20px' }}>
                    <Button.Success text={'View'} onClick={() => SaleRetunBillPost(RecordData)} />
                    <Button.Danger text={'Cancel'} onClick={onCancelModal} />
                </Flex>
            </>
        )
    }

    const SalesPrint = async (record) => {
        try {
            const response = await request.get(`sales/get_print_sale/${record.id}/`);
            setModalWidth(1200)
            setModalTitle("Sale Details");
            setModalContent(<ViewSaleBill record={response.data} />);
            showModal();
            return response.data;
        }
        catch (error) {
            throw error;
        }
    };

    const SalesReturnConfrmation = (record) => {
        setTrigger(trigger + 1)
        setModalTitle("Sale Return Confirmation");
        setModalWidth(400)
        setModalContent(<SaleConfirmations RecordData={record} />);
        showModal();
    };

    const SalesReturnView = (record) => {
        setTrigger(trigger + 1)
        setModalTitle("Sale Return View Confirmation");
        setModalWidth(400)
        setModalContent(<SaleReturnConfirmations RecordData={record} />);
        showModal();
    };

    const handleSearchs = (value) => {
        setSearchTexts(value);
    };

    useEffect(() => {
        dispatch(getSales());
    }, []);

    useEffect(() => {
        setViewSale(AllSales?.sale)
    }, [AllSales])

    const columns = [
        {
            title: 'Sl No',
            render: (item, value, index) => index + 1,
        },
        {
            title: 'Date',
            dataIndex: 'invoice_date',
        },
        {
            title: 'Party Name',
            dataIndex: 'party_name',
            filteredValue: searchTexts ? [searchTexts] : null,
            onFilter: (value, record) => {
                return String(record.party_name).toLowerCase().includes(value.toLowerCase()) ||
                    String(record.party_name).includes(value.toUpperCase());
            },
        },
        {
            title: 'Receipt No',
            dataIndex: 'invoice_no',
        },
        {
            title: 'Total Amount',
            dataIndex: 'grand_total',
        },
        {
            title: 'Received Amount',
            dataIndex: 'received_amt',
        },
        {
            title: 'Action',
            render: (record) => {
                return (
                    <Flex flexStart={'true'} gap={'15px'}>
                        <TableIconHolder color={THEME.green} size={'22px'} onClick={() => SalesPrint(record)}>
                            <AiOutlineEye />
                        </TableIconHolder>

                        {(!record.covert_salereturn) ?
                            <TableIconHolder color={THEME.blue} size={'22px'} onClick={() => ProductEditOnchange(record)}>
                                <FiEdit />
                            </TableIconHolder>
                            : null
                        }
                        {record.covert_salereturn === false && record.balance === "0.00" ?
                            <>
                                <TableIconHolder color={THEME.green} size={'22px'} onClick={() => SalesReturnConfrmation(record)}>
                                    <TbTruckReturn />
                                </TableIconHolder>
                            </>
                            : null
                        }

                        {record.covert_salereturn === true ?
                            <>
                                <TableIconHolder color={THEME.red} size={'22px'} onClick={() => SalesReturnView(record)}>
                                    <TbTruckReturn />
                                </TableIconHolder>
                            </> : null
                        }


                    </Flex>
                )
            }
        }
    ];


    const services = [
        {
            title: 'Sl No',
            render: (item, value, index) => index + 1,
        },
        {
            title: 'Date',
            dataIndex: 'invoice_date',
        },
        {
            title: 'Party Name',
            dataIndex: 'party_name',
        },
        {
            title: 'Bill No',
            dataIndex: 'invoice_no',
        },

        {
            title: 'Total Amount',
            dataIndex: 'grand_total',
        },

        {
            title: 'Received Amount',
            dataIndex: 'received_amt',
        },
        {
            title: 'Action',
            render: (record) => {
                return (
                    <Flex flexStart={'true'} gap={'15px'}>
                        <TableIconHolder color={THEME.green} size={'22px'} onClick={() => SalesPrint(record)}>
                            <AiOutlineEye />
                        </TableIconHolder>

                        <TableIconHolder color={THEME.blue} size={'22px'} onClick={() => ServiceEditOnchange(record)}>
                            <FiEdit />
                        </TableIconHolder>
                    </Flex>
                )
            }
        }
    ];

    useEffect(() => {
        GetSale()
    }, [])

    const GetSale = () => {
        request.get('sales/saledetails_search/')
            .then(function (response) {
                setViewSale(response.data?.sale)
                // setSaleRetunCheck(response.data)
            })
            .catch(function (error) {
                if (error?.response?.status === 303) {
                    toast.error("Add Business Profile")
                }
            });
    }


    const HandleSalesGet = () => {
        GetSale()
        handleOk()
    }

    // ============= Product Edit 

    const ProductEditOnchange = (record) => {
        setTrigger(trigger + 1)
        showModal();
        setModalTitle("Update Product Sale");
        setModalWidth(1200)
        setModalContent(<SalesEntryPage SaleRecord={record} SaleFormUpdate={HandleSalesGet} SaleTrigger={trigger} />);
    }

    // =============  Service Edit
    const ServiceEditOnchange = (record) => {
        setTrigger(trigger + 1)
        showModal();
        setModalTitle("Update Service ");
        setModalWidth(1200)
        setModalContent(<SalesEntryPage SaleRecord={record} SaleFormUpdate={HandleSalesGet} SaleTrigger={trigger} />);
    }


    let content;
    let content1;

    if (viewSale) {
        const productData = viewSale.filter((item) => item.item_choice === 'Product');
        const serviceData = viewSale.filter((item) => item.item_choice === 'Service');

        if (SaleStatus === 'loading') {
            content = <CommonLoading />
        } else if (SaleStatus === 'succeeded') {
            const rowKey = (viewSale) => viewSale.id;
            content = <CustomTandardTable  columns={columns} data={productData} rowKey={rowKey} />
        } else if (SaleStatus === 'failed') {
            content = <h2>{SaleError}</h2>
        }

        if (SaleStatus === 'loading') {
            content1 = <CommonLoading />
        } else if (SaleStatus === 'succeeded') {
            const rowKey = (viewSale) => viewSale.id;
            content1 = <CustomTandardTable  columns={columns} data={serviceData} rowKey={rowKey} />
        } else if (SaleStatus === 'failed') {
            content1 = <h2>{SaleError}</h2>
        }
    }


    const handleServiceSwitch = (isChecked) => {
        setShowProductTable(isChecked);
    }

    return (
        <>
            <CustomPageTitle Heading={'View Sale'} />
            <CustomCardView>
                <Form
                    form={form}
                    labelCol={{
                        span: 24,
                    }}
                    wrapperCol={{
                        span: 24,
                    }}
                    initialValues={
                        {
                            start_date: dayjs(),
                            end_date: dayjs(),
                            payment_type: 'Cash'
                        }
                    }
                    onFinish={onFinish}
                    onFinishFailed={onFinishFailed}
                    autoComplete="off">
                    <CustomRow space={[24, 24]} style={{ padding: '10px 0' }}>
                        <Col span={24} md={3} style={{ marginTop: '10px' }}>
                            <b>Choose</b>&nbsp;&nbsp;
                        </Col>
                        <Col span={24} md={12}>
                            <CustomSelect
                                options={month}
                                name={'month'}
                                value={dateRange}
                                placeholder={'This Month'}
                                onChange={handleMonthSelect}
                                rules={[{ required: true, message: 'Please Select the Month' }]}
                            />
                        </Col>
                        <Col span={24} md={9} style={{ float: 'inline-end' }}>
                            <Flex end={true} >
                                <ButtonStandard.Primary text={'Add Sale'} style={{ marginRight: '10px' }} onClick={() => nevigate(`/addsale`)} />
                            </Flex>
                        </Col>
                    </CustomRow>
                    <CustomRow space={[24, 24]} style={{ padding: '10px 0' }}>
                        {show ?
                            <>
                                <Col span={24} md={3} style={{ marginTop: '10px' }}>
                                    <b>Between</b>&nbsp;&nbsp;<TbArrowsExchange />
                                </Col>
                                <Col span={24} md={12}>
                                    <CustomDateRangePicker
                                        onChange={handleDateRangeChange}
                                        name={'range'}
                                        value={dateRange}
                                        rules={[{ required: true, message: 'Please Select the Date' }]}
                                    />
                                </Col>
                                <Col span={24} md={6}>
                                    <Flex>
                                        <Button.Primary text={'Submit'} htmlType='submit' />
                                    </Flex>
                                </Col>
                            </>
                            : null}
                    </CustomRow>
                </Form>
                {/* <Flex spaceBetween={true}>
                    <CustomSwitch onChange={handleServiceSwitch} leftLabel={'Product'} rightLabel={'Service'} />
                </Flex> */}

                <div style={{ display: 'flex', alignItems: 'center', marginTop: '50px' }}>
                    <Label style={{ marginRight: '20px' }}>Search by Party Name :</Label>
                    <CustomInput
                        value={searchTexts}
                        placeholder="Search by Party Name"
                        onSearch={handleSearchs}
                        onChange={(e) => handleSearchs(e.target.value)}
                    />
                </div>
                <CustomRow space={[12, 12]}>
                    <Col span={24} md={24}>

                        {showProductTable ? content1 : content}

                    </Col>
                    <CustomModal isVisible={isModalOpen} handleOk={handleOk} handleCancel={handleCancel} width={modalWidth} modalTitle={modalTitle} modalContent={modalContent} />
                </CustomRow>
            </CustomCardView >
        </>
    )
}