import React, { Fragment, useEffect, useState } from 'react'
import { CustomRow } from '../../../../components/CustomRow';
import { Col, Form } from 'antd';
import { FiEdit, FiPlus } from 'react-icons/fi'
import { AddPartyForm } from '../../Party/Partials/AddPartyForm';
import { THEME } from '../../../../theme';
import Flex from '../../../../components/Flex';
import { CustomModal } from '../../../../components/CustomModal';
import { useDispatch, useSelector } from 'react-redux';
import { getParty, getPartyError, getPartyStatus, selectAllParty } from '../../PartySlice';
import { CustomPopconfirm } from '../../../../components/CustomPopConfirm';
import { MdDelete } from 'react-icons/md';
import { CommonLoading } from '../../../../components/CommonLoading';
import { toast } from 'react-toastify';
import request from '../../../../utils/request';
import { useNavigate } from 'react-router-dom';
import { CustomPageTitle } from '../../../../components/CustomPageTitle';
import { TableIconHolder } from '../../../../components/CommonStyled';
import { CustomTable } from '../../../../components/Form/CustomTable';
import { CustomCardView } from '../../../../components/CustomCardView';
import Label from '../../../../components/Form/Label';
import { CustomInput } from '../../../../components/Form/CustomInput';
import ButtonStandard from '../../../../components/Form/CustomStandardButton';
import { CustomTandardTable } from '../../../../components/Form/CustomStandardTable';

export const ViewPartyTable = () => {
    const navigate = useNavigate();
    const [form] = Form.useForm();
    const DEL_PARTY_URL = 'party/edit_party'
    const [dataSource, setDataSource] = useState([])
    const [searchTexts, setSearchTexts] = useState([]);   //---------Seach Bar --------
    // ======  Modal Open ========
    const [isModalOpen, setIsModalOpen] = useState(false);
    // ======  Modal Title and Content ========
    const [modalTitle, setModalTitle] = useState("");
    const [modalContent, setModalContent] = useState(null);
    // ----------  Form Reset UseState ---------
    const [formReset, setFormReset] = useState(0);
    const [modelwith, setModelwith] = useState(0);
    const [updateservice, setUpdateservice] = useState(0);
    const [trigger, setTrigger] = useState(0)
    // ===== Modal Functions Start =====
    const showModal = () => {
        setIsModalOpen(true);
    };
    const handleOk = () => {
        setIsModalOpen(false);
        // FormRest()
    };
    const handleCancel = () => {
        setIsModalOpen(false);
        FormRest()
    };
    // ===== Modal Functions End =====
    const UpdateParty = (record) => {
        setTrigger(trigger + 1)
        setModelwith(800)
        setModalTitle("Update Party");
        setModalContent(<AddPartyForm Partydata={record} Trigger={trigger} FormClose={handleOk} FornUpdate={FornUpdate} />);
        showModal();
    };
    const FormRest = () => {
        setFormReset(formReset + 1)
    }
    // ======== Get Party ===========
    const dispatch = useDispatch()
    const AllSelect = useSelector(selectAllParty)
    const PartyStatus = useSelector(getPartyStatus)
    const PartyError = useSelector(getPartyError)
    useEffect(() => {
        dispatch(getParty())
    }, [updateservice])
    useEffect(() => {
        setDataSource(AllSelect)
    }, [AllSelect])
    const handleSearchs = (value) => {
        setSearchTexts(value);
    };
    const FornUpdate = () => {
        setUpdateservice(updateservice + 1)
    }
    // ========== Delete post ==========
    const handleConfirm = (record) => {
        DeleteParty(record)
    }
    const DeleteParty = (record) => {
        request.delete(`${DEL_PARTY_URL}/${record?.id}/`)
            .then((response) => {
                if (response.status === 204) {
                    dispatch(getParty())
                    toast.info("Deleted Successfully")
                }
                if (response.status === 226) {
                    toast.warn('Party has a transaction,Can`t be Deleted!')
                }
            }).catch(error => {
                if (error.response.status === 400) {
                    toast.error("Something Went Wrong")
                }
                else if (error.response.status === 304) {
                    toast.warn('Please subscribe your plan')
                }
                else {
                    toast.error("Failed")
                }
            });
    };
    const columns = [
        {
            title: 'SI No',
            render: (value, item, index) => index + 1,
        },
        {
            title: <p>Party&nbsp;Name</p>,
            dataIndex: 'party_name',
            filteredValue: searchTexts ? [searchTexts] : null,
            onFilter: (value, record) => {
                return String(record.party_name).toLowerCase().includes(value.toLowerCase()) ||
                    String(record.party_name).includes(value.toUpperCase());
            },
        },
        {
            title: <p>Mobile&nbsp;Number</p>,
            dataIndex: 'mobile_number',
        },
        {
            title: 'GSTIN',
            dataIndex: 'gstin',
        },
        {
            title: 'Action',
            render: (record, i) => {
                return (
                    <Flex center={"true"} gap={'10px'}>
                        <TableIconHolder color={THEME.blue} size={'22px'} onClick={() => { UpdateParty(record) }}>
                            <FiEdit />
                        </TableIconHolder>
                        <CustomPopconfirm
                            record={record}
                            confirm={handleConfirm}
                            title={"Delete the party List"}
                            description={"Are you sure to delete this party ?"}>
                            <TableIconHolder color={THEME.red} size={'22px'}>
                                <MdDelete />
                            </TableIconHolder>
                        </CustomPopconfirm>
                    </Flex>
                );
            },
        }
    ]
    let content;
    if (PartyStatus === 'loading') {
        content = <CommonLoading />
    } else if (PartyStatus === 'succeeded') {
        const rowKey = (dataSource) => dataSource.id;
        content = <CustomTandardTable columns={columns} data={dataSource} rowKey={rowKey} />
        
    } else if (PartyStatus === 'failed') {
        const rowKey = (dataSource) => dataSource.id;
        content = <CustomTandardTable columns={columns} data={dataSource} rowKey={rowKey} />
    }
    return (
        <Fragment>
            <CustomCardView>
                <CustomRow space={[24, 24]}>
                    <Col span={24} md={15}>
                        <CustomPageTitle Heading={'Party'} />
                    </Col>
                    <Col span={24} md={9}>
                        <CustomRow space={[24, 24]}>
                            <Col span={24} md={16}>
                                
                            </Col>
                            <Col span={24} md={8} style={{ float: 'right' }}>
                                <Flex end={'true'} style={{ marginRight: '-30px' }}>
                                    <ButtonStandard.Primary style={{ marginRight: '-22px' }} icon={<FiPlus style={{ marginRight: '5px', fontSize: '20px' }} />} text={'Add'}
                                        onClick={() => navigate('/addparty')} />
                                </Flex>
                            </Col>
                        </CustomRow>
                    </Col>
                </CustomRow>
                <div style={{ display: 'flex', alignItems: 'center', marginTop: '50px' }}>
                    <Label style={{ marginRight: '20px' }}>Search by Party Name :</Label>
                    <CustomInput
                        value={searchTexts}
                        placeholder="Search"
                        onSearch={handleSearchs}
                        onChange={(e) => handleSearchs(e.target.value)}
                    />
                </div>
                {content}
                <CustomModal isVisible={isModalOpen} handleOk={handleOk} handleCancel={handleCancel} width={modelwith} modalTitle={modalTitle} modalContent={modalContent} />
            </CustomCardView>
        </Fragment>
    )
}