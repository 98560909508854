import React, { Fragment, useEffect, useState } from 'react'
import Flex from '../../../../components/Flex';
import { BsPrinter } from 'react-icons/bs';
import { AiOutlineEye } from 'react-icons/ai';
import { CustomPageTitle } from '../../../../components/CustomPageTitle';
import { Col, Form } from 'antd';
import { BiFilterAlt } from 'react-icons/bi';
import { CustomRow } from '../../../../components/CustomRow';
import { CustomDateRangePicker } from '../../../../components/Form/CustomDateRangePicker';
import { TbArrowsExchange } from 'react-icons/tb';
import { CustomSelect } from '../../../../components/Form/CustomSelect';
import Button from '../../../../components/Form/CustomButton';
import styled from 'styled-components';
import { THEME } from '../../../../theme';
import dayjs from 'dayjs';
import { useNavigate } from 'react-router-dom';
import { CustomModal } from '../../../../components/CustomModal';
import { ViewQuotation } from './ViewQuotation';
import { CustomTable } from '../../../../components/Form/CustomTable';
import { TableIconHolder } from '../../../../components/CommonStyled';
import request from '../../../../utils/request';
import { ViewQuotationBill } from '../../../Quotation/Partials/ViewQuotationBill';
import { FcPrint } from 'react-icons/fc';
import { toast } from 'react-toastify';


const Filter = styled.div`
display: flex;
align-items: center;
cursor: pointer;
padding: 10px;
font-size: 25px;
color: #1677ff;
font-weight: 600;
margin: 0 0 20px 0;
& svg {
    font-size: 25px
}
`

const ReportQuotation = () => {

  const [form] = Form.useForm();

  const nevigate = useNavigate();
  const SEARCH_URL = 'quotation/quotation_search/'

  const [dataSource, setDataSource] = useState([])

  const [choiceFull, setChoiceFull] = useState('')
  const [dateRange, setDateRange] = useState([]);

  const [modalWidth, setModalWidth] = useState(0)
  const [showdetailsON, setShowdetailsON] = useState(false)
  const [show, setShow] = useState(false)
  

  // ======  Modal Open ========
  const [isModalOpen, setIsModalOpen] = useState(false);

  // ======  Modal Title and Content ========
  const [modalTitle, setModalTitle] = useState("");
  const [modalContent, setModalContent] = useState(null);


  // ===== Modal Functions Start =====

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };


  const handleDateRangeChange = (values) => {
    setDateRange(values);
  };

  const SalesDetails = (record) => {
    setModalWidth(600)
    setModalTitle("Views");
    setModalContent(<ViewQuotation />);
    showModal();
  };


  const month = [
    { label: 'This Month', value: 'This_month' },
    { label: 'Year', value: 'This_year' },
    { label: 'Quater', value: 'This_quorter' },
    { label: 'Custom', value: 'custom' },
  ]

  const handleMonthSelect = (value) => {
    form.setFieldsValue({ range: null })
    if (value === 'custom') {
      setShow(true);
      setChoiceFull(value)
    } else {
      setShow(false);
    }

    const choice = {
      choice: value
    }

    DateSearch(choice)

  };

  const DateSearch = (values) => {
    request.post(`${SEARCH_URL}`, values)
      .then(function (response) {
        setDataSource(response.data?.sale)
        if (response.status == 226) {
          toast.warn('Add the Business Profile');
      } else {
          toast.error("Something Went Wrong")
      }
      })
      .catch(function (error) {
        console.log(error);
      });

  }

  useEffect(() => {
    GetTableView()
}, [])

const GetTableView = (values) => {
    request.get(`${SEARCH_URL}`)
        .then(function (response) {
            setDataSource(response.data?.sale)
        })
        .catch(function (error) {
            console.log(error);
        });

}
  const handleChange = () => {
    setShowdetailsON(!showdetailsON)
  }

  const onFinish = (values) => {
    const record = { ...values, range: dateRange, choice: choiceFull }

    DateSearch(record)
  }

  const onFinishFailed = (errorInfo) => {
    toast.warn("Please fill the details!")
  };

  const QuotationPrint = async (record) => {
    try {
        const response = await request.get(`quotation/print_quotation/${record?.id}/`);
  
        setModalWidth(1200)
        setModalTitle("Quotation Details");
        setModalContent(<ViewQuotationBill record={response.data} />);
        showModal();
        return response.data;
    }
    catch (error) {
        throw error;
    }
};

  const columns = [
    {
      title: 'Sl No',
      render: (item, value, index) => index + 1,
    },
    {
      title: 'Party Name',
      dataIndex: 'party_name',
    },
    {
      title: 'Item Choice ',
      dataIndex: 'item_choice',
    },
    {
      title: 'Total Amount',
      dataIndex: 'grand_total',
    },
    {
      title: 'Action',
      render: (record) => {
          return (
              <Flex spaceEvenly={'true'}>
                  <TableIconHolder color={THEME.green} size={'22px'} onClick={() => QuotationPrint(record)}>
                      <FcPrint />
                  </TableIconHolder>
              </Flex>
          )
      }
  }

  ];

  return (
    <Fragment>
      <CustomPageTitle Heading={'Quotation Report'} /><br />
      <Form
        form={form}
        labelCol={{
          span: 24,
        }}
        wrapperCol={{
          span: 24,
        }}
        initialValues={
          {
            from_date: dayjs(),
            to_date: dayjs(),
          }
        }
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        autoComplete="off">

        <CustomRow space={[24, 24]}>
          <Col span={24} md={5}>
            <Filter onClick={handleChange}><BiFilterAlt />&nbsp;&nbsp;Filter</Filter>
          </Col>
        </CustomRow>

        <CustomRow space={[24, 24]} style={{ marginBottom: '20px' }}>

          {showdetailsON &&
            <>
              <Col span={24} md={3} style={{ marginTop: '10px' }}>
                <b>Choose</b>&nbsp;:
              </Col>
              <Col span={24} md={12}>
                <CustomSelect
                  options={month}
                  name={'month'}
                  placeholder={'This Month'}
                  onChange={handleMonthSelect}
                  rules={[{ required: true, message: 'Please Select' }]}
                />
              </Col>
              <Col span={24} md={7}></Col>
              <Col span={24} md={24}>
                {show ?
                  <CustomRow space={[24, 24]} style={{ margin: '20px 0' }}>
                    <Col span={24} md={4} style={{ marginTop: '10px' }}>
                      <Flex spaceBetween alignCenter>
                        <b>Between</b>
                        <TbArrowsExchange />
                      </Flex>
                    </Col>

                    <Col span={24} md={12}>
                      <CustomDateRangePicker
                        onChange={handleDateRangeChange}
                        name={'range'}
                        rules={[{ required: true, message: 'Please Select the Date' }]}
                      />
                    </Col>

                    <Col span={24} md={6}>
                      <Flex>
                        <Button.Primary text={'Submit'} htmlType='submit' />
                      </Flex>
                    </Col>
                  </CustomRow> : null}
              </Col>
            </>
          }
        </CustomRow>

      </Form>

      <CustomTable columns={columns} data={dataSource} />

      <CustomModal isVisible={isModalOpen} handleOk={handleOk} handleCancel={handleCancel} width={modalWidth} modalTitle={modalTitle} modalContent={modalContent} />

    </Fragment>
  )
}

export default ReportQuotation
