import styled from "styled-components";
import { THEME } from "../../../theme";


export const Cards = styled.div`
  background-color: ${THEME.white}; 
  margin:10px;
  box-shadow: 0px 8px 10px rgba(0, 0, 0, 0.25);
  border-radius:5px;
  height: 120px;
  color:var(--light-color);
  padding: 30px 15px;
  cursor:pointer;
  transition: all 0.5s;

  
  /* & svg{
      font-size: 35px;
      color:white;

  } */
  & h1{
      font-size: 18px;
    padding: 0 10px;
    text-align: end;
    color:black;
  }
  & h4{
      font-size: 18px;
      padding:20px 0px;
      color:black;
  }
  &:hover {
    transform: translateY(-7px) scale(1.005) translateZ(0);
    /* box-shadow: 0 24px 36px rgba(0,0,0,0.11), */
      /* 0 24px 46px var(--box-shadow-color); */
      background-color:var(--light-color);
      & h4,h1,h2,p,svg{
        /* color: '';   */
      }
  }
  
  &:hover .overlay {
    transform: scale(4) translateZ(0);
  }
  
  &:active {
    transform: scale(1) translateZ(0);
    box-shadow: 0 15px 24px rgba(0,0,0,0.11),
      /* 0 15px 24px var(--box-shadow-color); */
  }
  
  & h1 {
    z-index: 1;
    transition: color 0.3s ease-out;
  }
  .overlay {
    width: 118px;
    position: absolute; 
    height: 118px;
    border-radius: 50%;
    /* background: var(--bg-color); */
    top: 70px;
    left: 50px;
    z-index: 0;
    transition: transform 4s ease-out;
  }
    
  `;