import { Col } from 'antd';
import React, { Fragment, useEffect, useState } from 'react';
import request from '../../../../utils/request';
import { useDispatch } from 'react-redux';
import { CustomRow } from '../../../../components/CustomRow';
import { CustomTextArea } from '../../../../components/Form/CustomTextArea';
import { CustomInput } from '../../../../components/Form/CustomInput';
import { CustomModal } from '../../../../components/CustomModal';
import { CustomDatePicker } from '../../../../components/Form/CustomDatePicker';
import { CustomAddSelect } from '../../../../components/Form/CustomAddSelect';
import { AddPartyForm } from '../../../AddParty/Party/Partials/AddPartyForm';
import { CustomSelect } from '../../../../components/Form/CustomSelect';


export const ReturnFormHeader = ({ setSelectedDate, setSelectedSale, trigger, totalProductData }) => {

    const [dataSource, setDataSource] = useState([]);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [modalTitle, setModalTitle] = useState("");
    const [modalContent, setModalContent] = useState(null);
    const [width, setWidth] = useState(0)

    //=========Find Select part Fields ============//
    const [findParty, setFindParty] = useState(false)
    //=======
    const HEADER_PARTY_URL = 'party/add_party/'

    const showModal = () => {
        setIsModalOpen(true);
    };

    const handleOk = () => {
        setIsModalOpen(false);
    };

    const handleCancel = () => {
        setIsModalOpen(false);
    };

    const handleSalesParty = () => {
        handleCancel();
        GetSaleParty();
    };

    useEffect(() => {
        if (trigger) {
            GetSaleParty();
        }
    }, [trigger]);

    useEffect(() => {
        GetSaleParty();
    }, []);

    const GetSaleParty = () => {
        request.get(`${HEADER_PARTY_URL}`)
            .then(function (response) {
                setDataSource(response.data);
            })
            .catch(function (error) {
                // toast.error('Failed')
            });
    };

    //=====================Customer name ===================================

    const SaleCompanies = dataSource?.map((com) => ({ label: com.party_name, value: com.party_name }));

    const handleSelectCompany = (value) => {
        const SelectedCompanyDetails = dataSource?.find((mem) => mem.party_name === value);
        setSelectedSale(SelectedCompanyDetails);
        setFindParty(value)
    };

    //===================Date onChange fn======================//

    const handleOnChange = (date) => {
        setSelectedDate(date);
    };

    return (
        <Fragment>
            <CustomRow space={[24, 24]}>
                <Col span={24} md={16}>
                    <CustomRow space={[24, 24]}>
                        <Col span={24} md={12}>
                            <CustomInput
                                label={'Party Name'} name={'party_name'}
                                placeholder={'Party Name'} rules={[
                                    {
                                        required: true,
                                        message: 'Please Fill Details!',
                                    },
                                ]} disabled={true} />
                            <CustomInput name={'partyid'} display={'none'} />
                        </Col>

                        <Col span={24} md={12}>

                            <CustomInput name={'sale_id'} disabled={true} display={'none'} />

                            <CustomInput label="Mobile Number" InputLabelProps={{ shrink: true }}
                                name="mobile_number" rules={[
                                    {
                                        required: true,
                                        message: 'Please Fill Details!'
                                    },
                                ]} disabled={true} />
                        </Col>

                        <Col span={24} md={12}>
                            <CustomInput label={'State'} InputLabelProps={{ shrink: true }} placeholder={'State'} name={'state_of_supply'} disabled={true} />
                        </Col>
                        <Col span={24} md={12}>
                            <CustomInput label={'Invoice No'} InputLabelProps={{ shrink: true }} placeholder={'State'} name={'invoice_no'} disabled={true} />
                        </Col>

                        <Col span={24} md={12}>
                            <CustomTextArea label='Billing Address'
                                name="billing_address" placeholder="Billing Address" disabled={true} />
                        </Col>

                        <Col span={24} md={12}>
                            <CustomTextArea label={'Shipping Address'} placeholder={'Shipping Address'}
                                name={'shipping_address'} disabled={true} />

                        </Col>
                        {totalProductData?.GSTIN === null ? null :
                            <Col span={24} md={12}>
                                <CustomInput label={'GST IN'} name={'GSTIN'}
                                    InputLabelProps={{ shrink: true }} InputProps={{ readOnly: true, }} disabled={true}
                                    variant="filled"
                                />
                            </Col>
                        }


                    </CustomRow>
                </Col>

                <Col span={24} md={8}>
                    <CustomRow space={[24, 24]}>
                        <Col span={24} md={24}>
                            <CustomInput label={'Return No'} name={'return_no'} disabled={true} InputLabelProps={{ shrink: true }} InputProps={{ readOnly: true, }} rules={[
                                {
                                    required: true,
                                    message: 'Please Fill Details!',
                                },
                            ]}
                                variant="filled"
                            />
                        </Col>
                        <Col span={24} md={24}>
                            {
                                totalProductData.received_amt ?
                                    <CustomDatePicker label={'Return Date'} onChange={handleOnChange} name={'return_date'} rules={[
                                        {
                                            required: true,
                                            message: 'Please Fill Details!',
                                        },
                                    ]} /> :
                                    <CustomInput label={'Return Date'} name={'return_date'} disabled={true} />
                            }

                        </Col>

                        <Col span={24} md={24}>
                            <CustomInput label={'Invoice Date'} name={'invoice_date'} disabled={true} />
                        </Col>



                    </CustomRow>
                </Col>

            </CustomRow>
            <CustomModal isVisible={isModalOpen} handleOk={handleOk} handleCancel={handleCancel} width={width} modalTitle={modalTitle} modalContent={modalContent} />
        </Fragment>
    );
};
