import React from 'react'
import AddProduct from './Partials/AddProduct'
import { CustomPageTitle } from '../../../components/CustomPageTitle'
import Flex from '../../../components/Flex'

const AddProductName = () => {
    return (
        <div>
            <Flex alignCenter>
                <CustomPageTitle Heading={'Products'} />
            </Flex>
                <AddProduct />
        </div>
    )
}

export default AddProductName