import React, { useState } from 'react'
import Label from '../../../components/Form/Label';
import { CustomInput } from '../../../components/Form/CustomInput';
import { Table } from 'antd';
import styled from 'styled-components';

const FrequentAmt = ({ Frequentpro }) => {


    const [searchTexts, setSearchTexts] = useState([]);   //---------Seach Bar --------

    const Overhid = styled.div`
    height: 250px;
    overflow-y: auto;
  `
    const handleSearchs = (value) => {
        setSearchTexts(value);
    };
    const columns = [
        {
            title: 'SI No',
            render: (value, item, index) => index + 1,
        },
        {
            title: 'Name',
            dataIndex: 'item_name',
            key: 'item_name',
            filteredValue: searchTexts ? [searchTexts] : null,
            onFilter: (value, record) => {
                return String(record.item_name).toLowerCase().includes(value.toLowerCase()) ||
                    String(record.item_name).includes(value.toUpperCase());
            },
        },
        {
            title: 'Item Code',
            dataIndex: 'item_code',
        },
        {
            title: 'HSN',
            dataIndex: 'item_hsn',
        },
        {
            title: 'Quantity',
            dataIndex: 'quantity',
        },

    ]
    return (
        <div>
            <div style={{ display: 'flex', alignItems: 'center', marginTop: '10px' }}>
                <Label style={{ marginRight: '20px' }}>Search by Product :</Label>
                <CustomInput
                    value={searchTexts}
                    placeholder="Search by Product"
                    onSearch={handleSearchs}
                    onChange={(e) => handleSearchs(e.target.value)}
                />
            </div>
            <Overhid>
                <Table columns={columns} dataSource={Frequentpro} style={{ marginTop: "10px" }} />
            </Overhid>
        </div>
    )
}

export default FrequentAmt