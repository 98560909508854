import React, { useEffect, useState } from 'react'
import { StyledNotification } from './style'
import { useDispatch, useSelector } from 'react-redux'
import { getNotifications, selectAllNotifications } from '../NotificationSlice'
import Flex from '../../../components/Flex'
import { AiFillStar } from 'react-icons/ai'
import { CustomRow } from '../../../components/CustomRow';
import { Col } from 'antd'



export const Notifications = () => {

    const [expiry, setExpiry] = useState([])
    const [alert, setAlert] = useState([])

    const dispatch = useDispatch()


    const columns = [
        {
            title: 'SI No',
            render: (value, item, index) => index + 1,
        },
        {
            title: 'Products',
            dataIndex: 'product_name',
            key: 'product_name'
        },
        {
            title: 'Item Code',
            dataIndex: 'item_code',
            key: 'item_code'
        }
    ]


    useEffect(() => {
        dispatch(getNotifications())
    }, [])

    const Notify = useSelector(selectAllNotifications)

    useEffect(() => {
        setAlert(Notify)
        setExpiry(Notify)
    }, [Notify])

    return (
        <StyledNotification>
            <div className='notifyAlert'>
                <h1>Expiry Details </h1><br />
                {expiry.expiry_result?.message?.map(item => (
                    <div key={item.id}>

                        <Flex spaceEvenly={true} alignCenter={true}>
                            <div className='notifIcon'>
                                <AiFillStar />
                            </div>
                            {item.color === 'red' ? (

                                <p style={{ display: 'flex', justifyContent: 'center' }} ><span style={{ color: 'red' }}>{item.expiry_date}</span></p>

                            ) : (
                                <p ><span style={{ color: 'green' }}>{item.expiry_date}</span></p>
                            )}
                            <p>{item.amount}&nbsp;&nbsp;<span>is expiring </span></p>
                        </Flex>

                    </div>
                ))}
                <br></br>

                <h1>Alert Details </h1><br />
                {alert?.alert_result?.message?.map(item => (
                    <div key={item.id}>
                      
                        <CustomRow space={[24, 24]} style={{ marginBottom: '14px' }} >
                            <Col span={5} md={5}>
                                <div className='notifIcon'>
                                    <AiFillStar />
                                </div>
                            </Col>
                            <Col span={14} md={14}>
                                {item.colour === 'yellow' ? (
                                    <p style={{ display: 'flex', justifyContent: 'center' }} ><span style={{ color: 'blue' }}>{item.product_name}</span></p>
                                ) : (
                                    <p ><span style={{ color: 'red' }}>{item.product_name}</span></p>
                                )}
                            </Col>
                            <Col span={5} md={5}>
                            <p>{item.item_code}&nbsp;&nbsp;</p>
                            </Col>
                        </CustomRow>
                    </div>
                ))}

            </div>

        </StyledNotification>
    )
}
