import { setCredentials } from '../modules/Auth/authSlice';
import { store } from '../store';

export const SetCredentialsFunction = (data) => {

  console.log('====================================');
  console.log(data,' dispatch before');
  console.log('====================================');
  const credentialsData = {
    ...data,
  };

  // Dispatch the setCredentials action with the credentials data
  store.dispatch(setCredentials(credentialsData));
};