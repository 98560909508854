import React from 'react'
import PurchaseRetunUnpaid from './Partials/PurchaseRetunUnpaid'
import { CustomPageTitle } from '../../../components/CustomPageTitle'

export const PurchaseReturnUnpaid = () => {
    return (
        <div>
            <CustomPageTitle Heading={'Purchase Return Bill'} />
            <PurchaseRetunUnpaid />
        </div>
    )
}
