import { Card } from "antd";
import styled from "styled-components";

export const StyledSaleCardDash = styled.div`

border-radius: 1rem !important;
box-shadow: 0 0 5px 5px rgba(0,0,0,0.03);
/* height: 200px; */
background-color: #fff;
padding: 20px ;
height:100%;

& h2{
color: #545454;
font-size: 24px !important;
padding-top: 10px;
}

& h4{
    font-size: 14px !important;
    color: #52c41a !important;
}

& svg{
    font-size: 25px;
    color: #ff3594;
}

& p{
    font-size: 10px !important;
}


`

export const StyledSaleCardLeft = styled(Card)`

border-radius: 1rem !important;
margin-top: 30px;
height: 340px;
box-shadow: 0 0 5px 5px rgba(0,0,0,0.03);

& h3{
    font-size: 16px;
}

& h2{
    font-size: 34px;
    color: #545454 !important;
}

& h5{
font-size: 14px;
}

& span{
    /* color: #52c41a !important; */
    /* margin-left: 10px; */
}

/* & p{
    color:#8c8c8c !important
} */

.jiHFpn {
background-color: #FF2B7A!important;
border-color: #FF2B7A!important
}

.bGgunC {
    background-color: #13c2c2 !important;
    border-color: #13c2c2 !important
}

& h6{
    /* color:#FF2B7A !important; */
}
`

export const StyledSaleCardRight = styled.div`

margin-top: 30px;
height: 340px;
/* width: 100%; */
box-shadow: 0 0 5px 5px rgba(0,0,0,0.03);
background-color: #fff ;

`

export const StyledAreaChart = styled.div`

/* width: 100%;
height: 100%; */
margin-top: 20px !important;
`

export const StyledProgress = styled.div`

margin-top: 30px;

.ant-progress .ant-progress-inner {
    height: 3px !important;
}
`
export const StyledSaleCardRightPro = styled.div`

margin-top: 30px;
height: 340px;
/* width: 100%; */
box-shadow: 0 0 5px 5px rgba(0,0,0,0.03);
background-color: #fff ;

`
export const StyledFromDash = styled.div`

cursor: pointer;

`