import React, { useEffect, useState } from 'react'
import { CustomPageTitle } from '../../../../components/CustomPageTitle'
import Flex from '../../../../components/Flex'
import { TableIconHolder } from '../../../../components/CommonStyled'
import { THEME } from '../../../../theme'
import { CategoryNameModal } from './CategoryNameModal'
import { FiEdit, FiPlus } from 'react-icons/fi'
import { CustomPopconfirm } from '../../../../components/CustomPopConfirm'
import { MdDelete } from 'react-icons/md'
import { toast } from 'react-toastify'
import request from '../../../../utils/request'
import { CustomModal } from '../../../../components/CustomModal'
import { CustomRow } from '../../../../components/CustomRow'
import { Col } from 'antd'
import { useNavigate } from 'react-router-dom'
import styled from 'styled-components'
import ButtonStandard from '../../../../components/Form/CustomStandardButton'
import Label from '../../../../components/Form/Label'
import { CustomInput } from '../../../../components/Form/CustomInput'
import { CustomTandardTable } from '../../../../components/Form/CustomStandardTable'

const Tablewidth = styled.div`
width: 60%;
@media screen and (max-width: 1100px) {
    width: 100%;
}
`

export const ExpenseCategory = () => {

    const navigate = useNavigate()

    const DEL_PARTY_URL = 'expense/edit_category'

    const [dataSource, setDataSource] = useState([])
    const [searchTexts, setSearchTexts] = useState([]);   //---------Seach Bar --------

    // ======  Modal Open ========
    const [isModalOpen, setIsModalOpen] = useState(false);

    // ======  Modal Title and Content ========
    const [modalTitle, setModalTitle] = useState("");
    const [modalContent, setModalContent] = useState(null);

    // ----------  Form Reset UseState ---------
    const [modelwith, setModelwith] = useState(0);
    const [trigger, setTrigger] = useState(0)

    const SEARCH_URL = 'expense/view_category/'

    useEffect(() => {
        GetExpenseCategory()
    }, [])

    const GetExpenseCategory = (values) => {
        request.get(`${SEARCH_URL}`)
            .then(function (response) {
                setDataSource(response.data)
            })
            .catch(function (error) {
                console.log(error);
            });

    }

    // ===== Modal Functions Start =====

    const showModal = () => {
        setIsModalOpen(true);
    };

    const handleOk = () => {
        setIsModalOpen(false);
    };

    const handleCancel = () => {
        setIsModalOpen(false);
    };

    const handleSearchs = (value) => {
        setSearchTexts(value);
    };

    const handleConfirm = (record) => {
        DeleteParty(record)
    }

    const UpdateExpenseCat = (record) => {
        setTrigger(trigger + 1)
        setModelwith(500)
        setModalTitle("Update Expense Category");
        setModalContent(<CategoryNameModal catrecord={record} handleOk={handleOk} GetExpenseCategory={GetExpenseCategory} trigger={trigger} />);
        showModal();
    };

    const DeleteParty = (record) => {
        request.delete(`${DEL_PARTY_URL}/${record?.id}/`)
            .then((response) => {
                toast.info("Deleted Successfully")
                GetExpenseCategory()
            }).catch(error => {
                if (error.response.status == 302) {
                    toast.warn('Category Already Used, it Cannot be deleted');
                }
                else if (error.response.status == 408) {
                    toast.warn('Your plan is over. Please subscribe to the plan!');
                }
                else if (error.response.status == 304) {
                    toast.warn('Please subscribe your plan');
                }
                else {
                    toast.error('Failed')
                }
            });
    };

    const TableColumn = [
        {
            title: 'SI No',
            render: (value, item, index) => index + 1,
        },
        {
            title: 'Expense Category Name',
            dataIndex: 'category_name',
            filteredValue: searchTexts ? [searchTexts] : null,
            onFilter: (value, record) => {
                return String(record.category_name).toLowerCase().includes(value.toLowerCase()) ||
                    String(record.category_name).includes(value.toUpperCase());
            },
        },
        {
            title: 'Action',
            render: (record) => {
                return (
                    <Flex center={"true"} gap={'10px'}>

                        <TableIconHolder color={THEME.blue} size={'22px'} onClick={() => UpdateExpenseCat(record)} >
                            <FiEdit />
                        </TableIconHolder>

                        <CustomPopconfirm
                            record={record}
                            confirm={handleConfirm}
                            title={"Delete the Category"}
                            description={"Are you sure to delete this Category ?"}>
                            <TableIconHolder color={THEME.red} size={'22px'}>
                                <MdDelete />
                            </TableIconHolder>
                        </CustomPopconfirm>
                    </Flex>
                )
            }
        }
    ]

    return (
        <div>
            <CustomRow>
                <Col span={24} md={20}>
                    <CustomPageTitle Heading={'Expense Category'} />
                </Col>
                <Col span={24} md={4} style={{ float: 'right' }}>
                    <ButtonStandard.Primary icon={<FiPlus style={{ marginRight: '5px', fontSize: '20px' }} />} text={'Add'}
                        onClick={() => navigate('/addcatexpense')} />
                </Col>
            </CustomRow>
            <div style={{ display: 'flex', alignItems: 'center', marginTop: '50px' }}>
                <Label style={{ marginRight: '20px' }}>Search by Product Name :</Label>
                <CustomInput
                    value={searchTexts}
                    placeholder="Search"
                    onSearch={handleSearchs}
                    onChange={(e) => handleSearchs(e.target.value)}
                />
            </div>
            <Tablewidth>
                {/* <CustomTable columns={TableColumn} data={dataSource} /> */}
                <CustomTandardTable columns={TableColumn} data={dataSource} />
            </Tablewidth>
            <CustomModal isVisible={isModalOpen} handleOk={handleOk} handleCancel={handleCancel} width={modelwith} modalTitle={modalTitle} modalContent={modalContent} />
        </div>
    )
}
