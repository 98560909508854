import React, { useState } from 'react'
import { Table } from 'antd'
import styled from 'styled-components';
import Label from '../../../components/Form/Label';
import { CustomInput } from '../../../components/Form/CustomInput';

export const AddFromDash = ({ creditparty }) => {

  const [searchTexts, setSearchTexts] = useState([]);   //---------Seach Bar --------

  const Overhid = styled.div`
  height: 250px;
  overflow-y: auto;
`
  const handleSearchs = (value) => {
    setSearchTexts(value);
  };
  const columns = [
    {
      title: 'SI No',
      render: (value, item, index) => index + 1,
    },
    {
      title: 'Party Name',
      dataIndex: 'party_name',
      key: 'party_name',
      filteredValue: searchTexts ? [searchTexts] : null,
      onFilter: (value, record) => {
        return String(record.party_name).toLowerCase().includes(value.toLowerCase()) ||
          String(record.party_name).includes(value.toUpperCase());
      },
    },
    {
      title: 'Amount to be paid',
      dataIndex: 'balance',
      key: 'balance'
    }
  ]

  
  return (
    <div>
      <div style={{ display: 'flex', alignItems: 'center', marginTop: '10px' }}>
        <Label style={{ marginRight: '20px' }}>Search by Party Name :</Label>
        <CustomInput
          value={searchTexts}
          placeholder="Search by Party Name"
          onSearch={handleSearchs}
          onChange={(e) => handleSearchs(e.target.value)}
        />
      </div>
      <Overhid>
        <Table columns={columns} dataSource={creditparty} style={{ marginTop: "10px" }} />
      </Overhid>
    </div>
  )
}

