import { Card, Dropdown } from "antd";
import styled from "styled-components";

export const StyledNotification = styled.div`
    position: absolute;
    top: 80%;
    right: 10%;
    width: 350px;
    z-index: 1;
    color: var(--light-color);
    
    @media screen and (max-width: 426px) {
        right: -70px !important;
    }
    @media screen and (min-width: 427px) and (max-width: 700px) {
        right: 0 !important;
    }

    .notifyAlert {
        background-color: white;
        border: 1px solid rgb(219, 205, 205);
        box-shadow: rgb(0 0 0 / 6%) 0px 8px 10px;
        padding: 20px;
        margin: 10px;
        border-radius: 0 10%;
    }

    & h1 {
        text-align: center;
        border-bottom: 1px solid rgb(112 103 103 / 20%);
        padding-bottom: 15px;
    }
    
    .notifIcon {
        background: #e8e8e8;
        padding: 5px 5px 0px 5px;
        border-radius: 50%;
        margin-right: 12px;
        & svg {
            color: #000;
        }
    }

    & img{
    margin: auto;
    border-radius: 50%;
    width: 40px;
    }

    & h6{
    color: #441f69;
    }

    & p{
    color: blueviolet
    }

    & span{
        color: #6e0e46;
    }
`

export const StyledNotificationCard = styled(Card) `

background-color: #f5faf6;
box-shadow: 5px 5px 10px 0px rgba(0, 0, 0, 0.2);
transition: ease;
/* transform: scale(-2); */

`
export const SyledDropDownMenu = styled(Dropdown)`
margin: 50px; 

/* width: 200px; */
/* background-color: red; */
/* box-shadow: 5px 5px 10px 0px rgba(0, 0, 0, 0.2); */
`

