import React from 'react'
import AddService from './Partials/AddService'
import Flex from '../../../components/Flex'
import { CustomLableBack } from '../../../components/CustomLableBack'
import { CustomPageTitle } from '../../../components/CustomPageTitle'
import { CustomCardView } from '../../../components/CustomCardView'

const AddServiceMain = () => {
    return (
        <div>
            <Flex alignCenter>
                {/* <CustomLableBack /> */}
                <CustomPageTitle Heading={'Service'} />
            </Flex>
            <CustomCardView>
                <AddService />
            </CustomCardView>
        </div>
    )
}

export default AddServiceMain