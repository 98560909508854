import { Col, Form } from 'antd'
import React, { useEffect, useState } from 'react'
import { CustomRow } from '../../../components/CustomRow'
import { CustomInput } from '../../../components/Form/CustomInput'
import Flex from '../../../components/Flex'
import Button from '../../../components/Form/CustomButton'
import { toast } from 'react-toastify'
import request from '../../../utils/request'

const ProductBrand = ({ FormUpdateBrand, handleClose, formReset, brandrecord, UnitFormUpdate, brandhandleOk, handleGetbarnd }) => {

    const [form] = Form.useForm()

    const BRANDADD = 'product/add_brand/'
    const BRANDEDIT = 'product/edit_brand'

    const onReset = () => {
        form.resetFields();
    }

    const onFinish = (values) => {
        if (brandrecord) {
            EditBrandPost(values)
        }
        else {
            AddBrandPost(values)
        }

    };

    const onFinishFailed = (errorInfo) => {
        toast.warn("Please fill the details!")
    };


    useEffect(() => {
        form.resetFields();
    }, [formReset])

    const AddBrandPost = (values) => {
        request.post(`${BRANDADD}`, values)
            .then(function (response) {
                if (response.status == 201) {
                    form.resetFields();
                    toast.success('Brand Added Successfully')
                    if (FormUpdateBrand) {
                        FormUpdateBrand()
                    }
                    if (handleClose) {
                        handleClose()
                    }
                    if (handleGetbarnd) {
                        handleGetbarnd();
                    }
                }
                else if (response.status == 226) {
                    toast.warn('Brand Already Added')
                }
                else {
                    toast.error('Failed');
                }
            })
            .catch(function (error) {
                if (error.response.status === 406) {
                    toast.warn("Please Add business Profile!")
                } 
                else if(error.response.status === 302){
                    toast.warn("Limit Exceeded!")
                }
                else{
                    toast.error('Failed')
                }
            });
    }

    
    const EditBrandPost = (values) => {
        request.patch(`${BRANDEDIT}/${brandrecord.id}/`, values)
            .then(function (response) {
                if (response.status == 201) {
                    form.resetFields();
                    toast.success('Brand Added Successfully')
                    if (FormUpdateBrand) {
                        FormUpdateBrand()
                    }
                    if (UnitFormUpdate) {
                        UnitFormUpdate();
                    }
                    if (brandhandleOk) {
                        brandhandleOk()
                    }
                    if (handleClose) {
                        handleClose()
                    }
                }
                else if (response.status == 226) {
                    toast.warn('Brand Already Added')
                }
                else {
                    toast.error('Failed');
                }
            })
            .catch(function (error) {
                if (error.response.status === 406) {
                    toast.warn("Please Add business Profile!")
                } 
                else if(error.response.status === 302){
                    toast.warn("Limit Exceeded!")
                }
                else{
                    toast.error('Failed')
                }
            });
    }

    useEffect(() => {
        form.setFieldsValue(brandrecord)
    }, [brandrecord])

    return (
        <Form
            name='AddBrand'
            form={form}
            labelCol={{
                span: 24,
            }}
            wrapperCol={{
                span: 24,
            }}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            autoComplete="off">
            <CustomRow space={[24, 24]}>

                <Col span={24} md={24}>
                    <CustomInput label={'Brand name'} placeholder={'Enter Brand name'} name={'brand_name'}
                        rules={[
                            {
                                required: true,
                                message: 'Please Enter Brand name!',
                            }
                        ]}
                    />
                </Col>

            </CustomRow>

            <Flex center gap={'20px'} style={{ margin: '30px' }}>
                <Button.Primary text={'Add'} htmlType={'submit'} />
                <Button.Danger text={'Reset'} onClick={onReset} />
            </Flex>
        </Form>
    )
}

export default ProductBrand