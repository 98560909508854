import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import request from "../../utils/request";

const initialState = {
    salereport: [],
    status: 'idle',  // 'idle' | 'loading' | 'succeeded' | 'failed'  
    error: null
}

export const getsaleReports = createAsyncThunk(
    "SaleReport/Get",
    async () => {
        try {
            const response = await request.get('sales/saledetails_search/');
            return [...response.data];
        }
        catch (error) {
            throw error;
        }
    }
);

const reportSlice = createSlice({
    name: 'reportsale',
    initialState,
    reducers: {
    },

    extraReducers: (builder) => {
        builder
            .addCase(getsaleReports.pending, (state, action) => {
                state.status = 'loading'
            })
            .addCase(getsaleReports.fulfilled, (state, action) => {
                state.status = 'succeeded'
                state.salereport = action.payload;
            })
            .addCase(getsaleReports.rejected, (state, action) => {
                state.status = 'failed'
                state.error = action.error.message
            })
    }
})

export const saleReports = (state) => state.reportdetails.salereport
export const getReportStatus = (state) => state.reportdetails.status
export const getReportError = (state) => state.reportdetails.error

export const { reducer } = reportSlice;

export default reportSlice.reducer


