import React, { Fragment, useEffect, useRef, useState } from 'react'
import Flex from '../../components/Flex'
import { Col, Row, Button as Buttons, Badge } from 'antd'
import { AntdStyledMenu, NavTopDraw } from './Style'
import { AiFillPlusCircle, AiOutlineBell, AiOutlineMenu } from 'react-icons/ai'
import { CustomSearchBar } from '../../components/Form/CustomSearchBar';
import { BsSearch } from 'react-icons/bs';
import Button from '../../components/Form/CustomButton'
import ButtonStandard from '../../components/Form/CustomStandardButton'
import { useNavigate } from 'react-router-dom'
import { Menu } from 'antd/es'
import { CustomModal } from '../../components/CustomModal'
import { logOut, selectCurrentUser } from '../../modules/Auth/authSlice'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'
import { Notifications } from '../../modules/Notifications/Partials/Notifications'
import ProfileStyle from './ProfileStyle'
import { getNotifications, selectAllNotifications } from '../../modules/Notifications/NotificationSlice'
import { CustomBadge } from '../../components/CustomBadge'
import { BiUser } from 'react-icons/bi'
import { getProfile } from '../../modules/BusinessProfile/BusinessSlice'

const Profiles = styled.div`
display: flex;
align-items: center;
margin: -5px 0 20px 0px;
margin-top: 2px;
gap: 10px;
padding: 5px 10px;
background-color: #fff;
box-shadow: rgb(0 0 0 / 14%) 0px 1px 2px 0px;
border-radius: 50%;

& svg {
    color: rgb(84, 84, 84);
}
:hover{
    & svg {
        color: #000;
    } 
}
`

const NotificationsAlert = styled.div`
display: flex;
align-items: center;
margin: -5px 0 20px 0px;
margin-top: 2px;
margin-right: 18px;
gap: 10px;
padding: 5px 10px;
background-color: #fff;
box-shadow: rgb(0 0 0 / 14%) 0px 1px 2px 0px;
border-radius: 50%;

& svg {
    color: rgb(84, 84, 84);
}
:hover{
    & svg {
        color: #000;
    } 
}
`

const ImageProfile = styled.div`

    margin-top: 5px;
    & img {
        width: 25px;
        margin:auto;
        border-radius: 50%;
    }
`
const ResponsiveContainer = styled.div`
    margin-right: 100px;

  @media (max-width: 766px) {
    margin-right: 0px;
  }
  @media (min-width: 767px) and (max-width: 900px) {
    margin-right: -125px !important;
  }
`;
const StyledTwoButton = styled(Flex)`
    margin-right: 35px;

    @media screen and (min-width: 901px) and (max-width: 1120px) {
    display: none;
    }
    @media screen and (max-width: 656px) {
    display: none;
    }
`
export const NavHeader = ({ updateCollapse, showDrawer }) => {
    const dispatch = useDispatch()
    // ======  Modal Open ========
    const [isModalOpen, setIsModalOpen] = useState(false);
    // ======  Modal Title and Content ========
    const [modalTitle, setModalTitle] = useState("");
    const [modalContent, setModalContent] = useState(null);
    const [isProfileOpen, setIsProfileOpen] = useState(false);
    const [isNotificationOpen, setIsNotificationOpen] = useState(false);
    const [dataSource, setDataSource] = useState([])
    const profileRef = useRef(null);
    const notificationRef = useRef(null);

    useEffect(() => {
        dispatch(getNotifications())
    }, [])

    const Notify = useSelector(selectAllNotifications)

    useEffect(() => {
        setDataSource(Notify)
    }, [Notify])

    const items = [
        {
            key: '1',
            label: <Notifications />
        },

    ];

    // For Profile
    useEffect(() => {
        const handleClickOutside = (e) => {
            if (profileRef.current && !profileRef.current.contains(e.target)) {
                // Click occurred outside the profile, so close it
                setIsProfileOpen(false);
            }
        };
        if (isProfileOpen) {
            document.addEventListener('click', handleClickOutside);
        }
        // Clean up the event listener when the component unmounts
        return () => {
            document.removeEventListener('click', handleClickOutside);
        };
    }, [isProfileOpen]);

    // For Notification
    useEffect(() => {
        const handleClickOutside = (e) => {
            if (notificationRef.current && !notificationRef.current.contains(e.target)) {
                // Click occurred outside the profile, so close it
                setIsNotificationOpen(false);
            }
        };
        if (isNotificationOpen) {
            document.addEventListener('click', handleClickOutside);
        }
        // Clean up the event listener when the component unmounts
        return () => {
            document.removeEventListener('click', handleClickOutside);
        };
    }, [isNotificationOpen]);

    const toggleProfile = () => {
        setIsProfileOpen(!isProfileOpen);
        dispatch(getProfile())
    };

    const toggleNotify = () => {
        setIsNotificationOpen(!isNotificationOpen);
    };

    const showModal = () => {
        setIsModalOpen(true);
    };
    const handleCancel = () => {
        setIsModalOpen(false);
    };
    const ProfileName = useSelector(selectCurrentUser)
    const navigate = useNavigate()
    const handleClick = () => {
        navigate('/addprofile');
    };
    const AdminLogOut = () => {
        setModalContent(<LogOutModal />);
        setModalTitle("Log Out");
        showModal();
    }
    const ViewNotification = () => {
        setModalContent(<Notifications />);
        setModalTitle("Notifications");
        showModal();
    }
    const LogOutModal = () => (
        <div>
            <h1 style={{ fontSize: '1.2rem' }}>Are you Sure You Want to Logout ?</h1>
            <br />
            <Flex gap={'20px'} W_100 center verticallyCenter>
                <Button.Success text={'Logout'} onClick={Signout} />
                <Button.Danger text={'Cancel'} onClick={handleOk} />
            </Flex>
        </div>
    )
    const Signout = () => {
        // localStorage.setItem('persist','');
        // dispatch(logOut());
        alert('fffffffffffffff')
    }
    const handleOk = () => {
        setIsModalOpen(false);
    };
    const userMenu = (
        <AntdStyledMenu>
            <Menu.Item style={{ color: '#545454' }} key="1" onClick={handleClick}>My Account</Menu.Item>
            <Menu.Item style={{ color: '#545454' }} key="2" >Connections</Menu.Item>
            <Menu.Item style={{ color: '#545454' }} key="3" onClick={AdminLogOut}>Log Out</Menu.Item>
        </AntdStyledMenu>
    );
   
    return (
        <Fragment>
            <NavTopDraw>
                <Row>
                    <Col span={4} md={4} className='DrawBtn'>
                        <Buttons type="text" onClick={showDrawer}>
                            <AiOutlineMenu style={{ fontSize: '20px' }} />
                        </Buttons>
                    </Col>
                    <Col span={4} md={10} className='SearchTop' >
                        {/* <Flex alignCenter spaceBetween className='SearchTop'>
                            <CustomSearchBar placeholder={'Search in app...'} prefix={<BsSearch style={{ fontSize: '16px', color: '#E1E1E1', cursor: 'pointer' }} />} />
                        </Flex> */}
                    </Col>
                    <Col span={16} md={14}>
                        <ResponsiveContainer>
                            <Flex flexEnd={true}>
                                <StyledTwoButton >
                                    <ButtonStandard.Danger className={'btnborder'} icon={<AiFillPlusCircle style={{ fontSize: '23px', marginRight: '10px' }} />} text={'Sales Bill'} onClick={() => navigate(`/addsale`)} />
                                    <ButtonStandard.Primary className={'btnborder'} icon={<AiFillPlusCircle style={{ fontSize: '23px', marginRight: '10px' }} />} text={'Purchase Bill'} onClick={() => navigate(`/addpurchase`)} />
                                </StyledTwoButton>

                               {/* <SyledDropDownMenu
                                    menu={{
                                        items
                                    }}>
                                    <div style={{ margin: '15px' }} className='NotificationAlert'>
                                        <AiOutlineBell style={{ fontSize: '20px', marginTop: "-5px", cursor: 'pointer' }} />
                                    </div>
                                </SyledDropDownMenu>  */}

                                <NotificationsAlert>
                                    <div ref={notificationRef}>
                                        <a onClick={toggleNotify} href="#">
                                            <Flex alignCenter>
                                                <div className='notificationalert'>
                                                    <AiOutlineBell style={{ fontSize: '20px' }} />
                                                </div>
                                            </Flex>
                                        </a>
                                        {isNotificationOpen && <Notifications />}
                                    </div>
                                </NotificationsAlert>

                                <Profiles>
                                    <div ref={profileRef}>
                                        <a onClick={toggleProfile} href="#open-profile">
                                            <Flex alignCenter>
                                                <ImageProfile>
                                                   <BiUser style={{ fontSize: '20px' }} />
                                                </ImageProfile>
                                            </Flex>
                                        </a>
                                        {isProfileOpen && <ProfileStyle setIsProfileOpen={setIsProfileOpen}/>}
                                    </div>
                                </Profiles>
                            </Flex>
                        </ResponsiveContainer>
                    </Col>
                    <CustomModal isVisible={isModalOpen} handleOk={handleOk} handleCancel={handleCancel}
                        width={400}
                        modalTitle={modalTitle} modalContent={modalContent} />
                </Row>
            </NavTopDraw>
        </Fragment>
    )
}