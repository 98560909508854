import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import request from "../../utils/request";

const initialState = {
    product: [],
    category: [],
    subcategory: [],
    variations: [],
    addvariations: [],
    unit: [],
    stock: [],
    resetvariations: null,
    status: 'idle',  // 'idle' | 'loading' | 'succeeded' | 'failed'  
    error: null,

    unitstatus: 'idle',  // 'idle' | 'loading' | 'succeeded' | 'failed'  
    uniterror: null,

    productTable: [],
}

export const getProduct = createAsyncThunk(
    "Product/Get",
    async () => {
        try {
            const response = await request.get('product/add_productes_form/');
            return [...response.data];
        }
        catch (error) {
            throw error;
        }
    }
);
export const getCategory = createAsyncThunk(
    "Category/Get",
    async () => {
        try {
            const response = await request.get('product/add_category/');
            return [...response.data];
        }
        catch (error) {
            throw error;
        }
    }
);
export const getUnit = createAsyncThunk(
    "Unit/Get",
    async () => {

        try {

            const response = await request.get('product/add_unit/');
            return [...response.data];

        }

        catch (error) {
            throw error;
        }
    }
);
export const getSubCategory = createAsyncThunk(
    "SubCategory/Get",
    async () => {
        try {
            const response = await request.get('product/add_subcategory/');
            return [...response.data];
        }
        catch (error) {
            throw error;
        }
    }
);

export const getVariation = createAsyncThunk(
    "Variations/Get",
    async () => {
        try {
            const response = await request.get('product/add_variations/');
            return [...response.data];
        }
        catch (error) {
            throw error;
        }
    }
);

// ======= Stock URL =======

export const getStock = createAsyncThunk(
    "Stock/Get",
    async () => {
        try {
            const response = await request.get('product/table_of_productes/');
            return [...response.data];
        }
        catch (error) {
            throw error;
        }
    }
);

const productSlice = createSlice({
    name: 'businessprofile',
    initialState,
    reducers: {
        setVariationsAdd: (state, action) => {
            state.addvariations = action.payload

        },
        reset: (state, action) => {
            // state.addvariations = null
            state.resetvariations = null
        },
        addToTable: (state, action) => {

            state.productTable = action.payload
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(getProduct.pending, (state, action) => {
                state.status = 'loading'
            })
            .addCase(getProduct.fulfilled, (state, action) => {
                state.status = 'succeeded'
                state.product = action.payload;
            })
            .addCase(getProduct.rejected, (state, action) => {
                state.status = 'failed'
                state.error = action.error.message
            })
            .addCase(getCategory.pending, (state, action) => {
                state.status = 'loading'
            })
            .addCase(getCategory.fulfilled, (state, action) => {
                state.status = 'succeeded'
                state.category = action.payload;
            })
            .addCase(getCategory.rejected, (state, action) => {
                state.status = 'failed'
                state.error = action.error.message
            })
            .addCase(getSubCategory.pending, (state, action) => {
                state.status = 'loading'
            })
            .addCase(getSubCategory.fulfilled, (state, action) => {
                state.status = 'succeeded'
                state.subcategory = action.payload;
            })
            .addCase(getSubCategory.rejected, (state, action) => {
                state.status = 'failed'
                state.error = action.error.message
            })
            .addCase(getVariation.fulfilled, (state, action) => {
                state.status = 'succeeded'
                state.variations = action.payload;
            })
            .addCase(getVariation.rejected, (state, action) => {
                state.status = 'failed'
                state.error = action.error.message
            })
            .addCase(getVariation.pending, (state, action) => {
                state.status = 'loading'
            })
            //========================Unit ==========================//

            .addCase(getUnit.pending, (state, action) => {
                state.unitstatus = 'loading'
            })
            .addCase(getUnit.fulfilled, (state, action) => {
                state.unitstatus = 'succeeded'
                state.unit = action.payload;
            })

            .addCase(getUnit.rejected, (state, action) => {
                state.unitstatus = 'failed'
                state.uniterror = action.error.message
            })

            //============= stock  ===========//

            .addCase(getStock.pending, (state, action) => {
                state.status = 'loading'
            })
            .addCase(getStock.fulfilled, (state, action) => {
                state.status = 'succeeded'
                state.stock = action.payload;
            })
            .addCase(getStock.rejected, (state, action) => {
                state.status = 'failed'
                state.error = action.error.message
            })

    }
})

export const { setVariationsAdd, reset, addToTable } = productSlice.actions
export const ViewVariationsValues = (state) => state.products.addvariations
export const selectAllProduct = (state) => state.products.product
export const selectAllCategory = (state) => state.products.category
export const selectAllCategoryStatus = (state) => state.products.status
export const selectAllCategoryError = (state) => state.products.error
export const selectAllSubCategory = (state) => state.products.subcategory
export const selectAllSubCategoryStatus = (state) => state.products.status
export const selectAllSubCategoryError = (state) => state.products.error
export const selectVariation = (state) => state.products.variations
export const selectAllVariationsStatus = (state) => state.products.status
export const selectAllVariationsError = (state) => state.products.error
export const selectViewUnit = (state) => state.products.unit
export const getUnitStatus = (state) => state.products.unitstatus
export const getUnitError = (state) => state.products.uniterror
export const getProductStatus = (state) => state.products.status
export const getProductError = (state) => state.products.error
export const getTableProducts = (state) => state.products.productTable

//============= stock  ===========//

export const AllStocks = (state) => state.products.stock
export const getStockStatus = (state) => state.products.status
export const getStockError = (state) => state.products.error


export const { reducer } = productSlice;

export default productSlice.reducer


