import { Col, Form } from 'antd';
import React, { Fragment, useEffect, useState } from 'react';
import request from '../../../utils/request';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { CustomRow } from '../../../components/CustomRow';
import { CustomTextArea } from '../../../components/Form/CustomTextArea';
import { CustomInput } from '../../../components/Form/CustomInput';
import { CustomModal } from '../../../components/CustomModal';
import { CustomSelect } from '../../../components/Form/CustomSelect';
import { CustomDatePicker } from '../../../components/Form/CustomDatePicker';
import { CustomAddSelect } from '../../../components/Form/CustomAddSelect';
import { AddPartyForm } from '../../AddParty/Party/Partials/AddPartyForm';
import { CustomInputNumber } from '../../../components/Form/CustomInputNumber';
import CustomInputs from '../../Services/AddService';


export const PurchaseFormHeader = ({ gstChecked, Editpurchaserecord, setBillNumber, setSelectedDate, setSelectedDates, setSelectedrevisedDates, setSelectedSale, trigger, setShipState, setInvoiceNumber }) => {

    const [dataSource, setDataSource] = useState([]);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [modalTitle, setModalTitle] = useState("");
    const [modalContent, setModalContent] = useState(null);
    const [width, setWidth] = useState(0)
    const [triggers, setTriggers] = useState(0);
    const [payterms, setPayterms] = useState(0)
    const [purchase, setPurchase] = useState(0)
    //=========Find Select party Fields ============//
    const [findParty, setFindParty] = useState(false)
    const [vartrigger, setVarTrigger] = useState(0);
    //=======

    const [form] = Form.useForm();


    const HEADER_PARTY_URL = 'party/add_party/'

    const dispatch = useDispatch()

    const onViewRow = () => {
        setPurchase(purchase + 1)
        setWidth(800)
        setModalTitle("Add Party");
        setModalContent(<AddPartyForm GetSaleCustomer={GetSaleCustomer} handleOk={handleOk} purchaseTrigger={purchase} />);
        showModal();
    };

    const showModal = () => {
        setIsModalOpen(true);
    };

    const handleOk = () => {
        setIsModalOpen(false);
    };

    const handleCancel = () => {
        setIsModalOpen(false);
    };


    useEffect(() => {
        if (trigger) {
            GetSaleCustomer();
        }
    }, [trigger]);

    useEffect(() => {
        GetSaleCustomer();
    }, []);

    const GetSaleCustomer = () => {
        request
            .get(`${HEADER_PARTY_URL}`)
            .then(function (response) {
                setDataSource(response.data);
                setBillNumber(response.data)
            })
            .catch(function (error) {
                
            });
    };


    //=====================Customer name ===================================

    const PurchaseProductOption = dataSource?.map((com) => ({ label: com.party_name, value: com.id }));

    const handleSelectCompany = (value) => {
        const SelectedCompanyDetails = dataSource?.find((mem) => mem.id === value);
        setSelectedSale(SelectedCompanyDetails);

        if (Editpurchaserecord) {
            setFindParty(false)

        }
        else {
            setFindParty(value)
        }
        handlevarRefresh()

    };

    const handlevarRefresh = (value) => {
        form.setFieldsValue({ party_name: setVarTrigger(vartrigger + 1) })

        if (value) {
            form.setFieldsValue({ party_name: '' })
        }
    }
    //===================Date onChange fn======================//
    const handleOnChange = (date) => {
        setSelectedDate(date);
    };

    return (
        <Fragment>
            <CustomRow space={[24, 24]} >
                <Col span={24} md={16}>
                    <CustomRow space={[24, 24]}>

                        {Editpurchaserecord ?
                            <Col span={24} md={12}>
                                <CustomSelect options={PurchaseProductOption}
                                    showSearch={true}
                                    onChange={handleSelectCompany} label={'Party Name'} name={'party_name'}
                                    placeholder={'Party Name'} rules={[
                                        {
                                            required: true,
                                            message: 'Please Fill Details!',
                                        },
                                    ]} />
                                <CustomInputNumber name={'party'} display={'none'} />
                                <CustomInputNumber name={'email'} display={'none'} />
                            </Col>
                            :
                            <Col span={24} md={12}>
                                <CustomAddSelect options={PurchaseProductOption} onButtonClick={onViewRow}
                                    showSearch={true} buttonLabel="Add Party"
                                    onChange={handleSelectCompany} label={'Party Name'} name={'party_name'}
                                    placeholder={'Party Name'} rules={[
                                        {
                                            required: true,
                                            message: 'Please Fill Details!',
                                        },
                                    ]} />
                                <CustomInputNumber name={'party'} display={'none'} />
                                <CustomInputNumber name={'email'} display={'none'} />
                            </Col>
                        }

                        {findParty && <>
                            <Col span={24} md={12}>
                                <CustomInput label="Mobile Number"
                                    name="mobile_no" placeholder="Mobile Number" rules={[
                                        {
                                            required: true,
                                            message: 'Please Fill Details!',
                                        },
                                    ]}
                                    InputLabelProps={{ shrink: true }}
                                    maxLength={10}
                                    minLength={10}
                                    onKeyPress={(event) => {
                                        if (!/[0-9]/.test(event.key)) {
                                            event.preventDefault();
                                        }
                                    }}

                                />
                            </Col>

                            <Col span={24} md={12}>
                                <CustomTextArea label={'Address'} placeholder={'Address'} name={'address'}
                                />
                            </Col>
                        </>
                        }
                        {gstChecked ? (null) : (
                            <Col span={24} md={12}>
                                <CustomInput label='GSTIN'
                                    name="gstin" placeholder="GSTIN" />
                            </Col>)
                        }
                        {Editpurchaserecord && <>
                            <Col span={24} md={12}>
                                <CustomInput label="Mobile Number"
                                    name="mobile_no" placeholder="Mobile Number" rules={[
                                        {
                                            required: true,
                                            message: 'Please Fill Details!',
                                        },
                                    ]}
                                    InputLabelProps={{ shrink: true }}
                                    maxLength={10}
                                    minLength={10}
                                    onKeyPress={(event) => {
                                        if (!/[0-9]/.test(event.key)) {
                                            event.preventDefault();
                                        }
                                    }}

                                />
                            </Col>

                            <Col span={24} md={12}>
                                <CustomTextArea label={'Address'} placeholder={'Address'} name={'address'}
                                />
                            </Col>
                        </>}

                    </CustomRow>
                </Col>

                <Col span={24} md={8}>
                    <CustomRow space={[24, 24]}>
                        <Col span={24} md={24}>
                            <CustomInput label={'Bill No'} name={'bill_no'} disabled={true} rules={[
                                {
                                    required: true,
                                    message: 'Please Fill Details!',
                                },
                            ]}

                            />
                        </Col>
                        <Col span={24} md={24}>
                            <CustomDatePicker label={'Bill Date'} onChange={handleOnChange} name={'bill_date'} rules={[
                                {
                                    required: true,
                                    message: 'Please Fill Details!',
                                },
                            ]} />
                        </Col>
                        {findParty &&
                            <Col span={24} md={24}>
                                <CustomInput label={'State of Supply'}
                                    InputLabelProps={{ shrink: true }}
                                    id="outlined-required"
                                    placeholder={'State of Supply'}
                                    name={'state_of_supply'} rules={[
                                        {
                                            required: true,
                                            message: 'Please Fill Details!',
                                        },
                                    ]}
                                />
                            </Col>
                        }

                        {Editpurchaserecord &&
                            <Col span={24} md={24}>
                                <CustomInput label={'State of Supply'}
                                    InputLabelProps={{ shrink: true }}
                                    id="outlined-required"
                                    placeholder={'State of Supply'}
                                    name={'state_of_supply'} rules={[
                                        {
                                            required: true,
                                            message: 'Please Fill Details!',
                                        },
                                    ]}
                                />
                            </Col>

                        }

                    </CustomRow>
                </Col>

            </CustomRow>
            <CustomModal isVisible={isModalOpen} handleOk={handleOk} handleCancel={handleCancel} width={width} modalTitle={modalTitle} modalContent={modalContent} />
        </Fragment>
    );
};
