import { Col, Form } from 'antd'
import React, { useEffect } from 'react'
import { CustomRow } from '../../../components/CustomRow';
import { CustomInput } from '../../../components/Form/CustomInput';
import { CustomTextArea } from '../../../components/Form/CustomTextArea';
import Flex from '../../../components/Flex';
import Button from '../../../components/Form/CustomButton';
import { toast } from 'react-toastify';
import request from '../../../utils/request';
import { useDispatch } from 'react-redux';
import { getCategory } from '../../Product/ProductSlice';

export const EditCategory = ({ categoryhandleOk, categoryrecord,categoryTrigger }) => {

    const [form] = Form.useForm()

    const dispatch = useDispatch()
    
    const EDIT__CATEGORY_URL = 'product/edit_category'

    useEffect(() => {
        form.resetFields();
    }, [])

    useEffect(() => {
        form.setFieldsValue(categoryrecord)
    }, [categoryrecord,categoryTrigger])

    const onClose = () => {
        categoryhandleOk();
    }

    const UpdateCategory = (values) => {
        console.log('sikkkkkk');
        request.patch(`${EDIT__CATEGORY_URL}/${categoryrecord.id}/`,values)
            .then(function (response) {
                toast.info("Category Updated Successfully")
                dispatch(getCategory())
                categoryhandleOk()
            })
            .catch(function (error) {
                if (error.response.status === 406) {
                    toast.warn("Please Add business Profile!")
                } 
                else if(error.response.status === 302){
                    toast.warn("Limit Exceeded!")
                }
            });
    }

    const onFinish = (values) => {
        UpdateCategory(values)
    };

    const onFinishFailed = (errorInfo) => {
        toast.warn("Please fill the details!")
    };

    return (
        <Form
            name='AddUnit'
            form={form}
            labelCol={{
                span: 24,
            }}
            wrapperCol={{
                span: 24,
            }}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            autoComplete="off">
            <CustomRow space={[24, 24]}>
                <Col span={24} md={24}>
                    <CustomInput label={'Category'} placeholder={'Enter Category'} name={'category'}
                        rules={[
                            {
                                required: true,
                                message: 'Please Enter Category!',
                            }
                        ]}
                    />
                </Col>

                <Col span={24} md={24}>
                    <CustomInput label={'Category Code'} placeholder={'Code'} name={'category_code'}
                    rules={[
                        {
                            required: true,
                            message: 'Please Enter Category Code!',
                        }
                    ]}
                    />
                </Col>

                <Col span={24} md={24}>
                    <CustomTextArea label={'Description'} name={'category_description'}
                        placeholder={'Description'} />
                </Col>
            </CustomRow>
            <Flex center gap={'20px'} style={{ margin: '30px' }}>
                <Button.Primary text={'UPDATE'} htmlType={'submit'} />
                <Button.Danger text={'CANCEL'} onClick={onClose} />
            </Flex>
        </Form>
    )
}
