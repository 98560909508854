import { Col, Form } from 'antd'
import React, { useEffect } from 'react'
import { CustomRow } from '../../../components/CustomRow'
import { CustomInput } from '../../../components/Form/CustomInput'
import Flex from '../../../components/Flex'
import Button from '../../../components/Form/CustomButton'
import request from '../../../utils/request'
import { toast } from 'react-toastify'
import { useDispatch } from 'react-redux'
import { getVariation } from '../../Product/ProductSlice'
import { IoMdRemoveCircle } from 'react-icons/io'

export const EditVariation = ({ variationrecord, formReset, variationhandleOk }) => {
    const [form] = Form.useForm()
    const dispatch = useDispatch()
    const EDIT__VARIATION_URL = 'product/edit_variations'

    const onReset = () => {
        form.resetFields();
    }

    const onFinish = (values) => {
        UpdateVariation(values)
    };

    const onFinishFailed = (errorInfo) => {
        toast.warn("Please fill the details!")
    };


    const UpdateVariation = (values) => {
        request.patch(`${EDIT__VARIATION_URL}/${variationrecord.id}/`, values)
            .then(function (response) {
                if (response.status === 200) {
                    toast.info("Variation Updated Successfully")
                    dispatch(getVariation())
                    variationhandleOk()

                }
                if (response.status === 226) {
                    toast.warn('This Variation Name Already Exists!')
                }
            })
            .catch(function (error) {
                if (error.response.status === 406) {
                    toast.warn("This Variation is Already Used!")
                } else if (error.response.status === 302) {
                    toast.warn(error.response.data.message)
                }
            });
    }

    useEffect(() => {
        form.resetFields();
    }, [formReset])

    useEffect(() => {
        form.setFieldsValue(variationrecord)
    }, [variationrecord])

    return (

        <Form
            form={form}
            labelCol={{
                span: 24,
            }}
            wrapperCol={{
                span: 24,
            }}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            autoComplete="off">
            <CustomRow space={[24, 24]}>

                <Col span={24} md={24}>
                    <CustomInput label={'Variation Name'} placeholder={'Name'} name={'variations_name'}
                        rules={[
                            {
                                required: true,
                                message: 'Please Enter unit!',
                            }
                        ]}
                    />
                </Col>
                <Col span={24} md={24}>

                    <Form.List name="variation_conn"  >
                        {(fields, { add, remove }) => (
                            <>
                                {fields.map(({ key, name, ...restField }) => (
                                    <CustomRow gutter={[24, 24]}>
                                        <Col span={10}>
                                            <CustomInput label={'variation value'} placeholder="variation value" name={[name, 'variations_value']}
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: 'This is required field!',
                                                    }
                                                ]}
                                            />
                                        </Col>
                                        <Col span={10} style={{ margin: '28px ' }}>
                                            {fields.length > 1 && (
                                                <IoMdRemoveCircle style={{ fontSize: '25px', color: 'red', cursor: 'pointer' }}
                                                    onClick={() => remove(name)}
                                                />
                                            )}
                                        </Col>
                                    </CustomRow>
                                ))}
                                <Form.Item style={{ margin: '20px 10px' }} >
                                    <Button type="dashed" onClick={() => add()} block >
                                        Add
                                    </Button>
                                </Form.Item>
                            </>
                        )}
                    </Form.List>
                </Col>

            </CustomRow>
            <Flex center gap={'20px'} style={{ margin: '30px' }}>
                <Button.Primary text={'UPDATE'} htmlType={'submit'} />
                <Button.Danger text={'CANCEL'} onClick={variationhandleOk} />
            </Flex>
        </Form>
    )
}
