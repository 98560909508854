import React, { useEffect, useState } from 'react'
import { CustomCardView } from '../../../components/CustomCardView'
import { Col, Form, Input } from 'antd';
import { CustomRow } from '../../../components/CustomRow';
import { CustomSelect } from '../../../components/Form/CustomSelect'
import dayjs from 'dayjs';
import Flex from '../../../components/Flex';
import Button from '../../../components/Form/CustomButton';
import { CustomDateRangePicker } from '../../../components/Form/CustomDateRangePicker'
import { TbArrowsExchange, TbTruckReturn } from 'react-icons/tb';
import { CustomModal } from '../../../components/CustomModal';
import { CustomPageTitle } from '../../../components/CustomPageTitle';
import { CustomTable } from '../../../components/Form/CustomTable';
import { Navigate, useNavigate } from 'react-router-dom';
import request from '../../../utils/request';
import ButtonStandard from '../../../components/Form/CustomStandardButton';
import { toast } from 'react-toastify';
import { TableIconHolder } from '../../../components/CommonStyled';
import { AiOutlineEye } from 'react-icons/ai';
import { THEME } from '../../../theme';
import { PrintView } from './PrintView';
import { getpurchase, getPurchaseError, getPurchaseStatus, selectAllPurchase, } from '../PurchaseSlice';
import { useDispatch, useSelector } from 'react-redux';
import { CommonLoading } from '../../../components/CommonLoading';
import { FiEdit } from 'react-icons/fi';
import { ViewPurchaseBill } from './ViewPurchaseBill';
import { PurchaseReturnEntryPage } from '../../PurchaseReturn/Partials/ReturnEntryPage';
import { PurchaseEntryPage } from './PurchaseEntryPage';
import { CustomInput } from '../../../components/Form/CustomInput';
import Label from '../../../components/Form/Label';
import { CustomTandardTable } from '../../../components/Form/CustomStandardTable';


export const ViewPurchase = () => {

    const [form] = Form.useForm();

    const dispatch = useDispatch();

    const SEARCH_URL = 'purchase/purchasedetails_search/'

    const [viewSale, setViewSale] = useState([])
    const [returnOver, setReturnOver] = useState({})

    const [choiceFull, setChoiceFull] = useState('')
    const [dateRange, setDateRange] = useState([]);

    const [show, setShow] = useState(false)
    const [modalContent, setModalContent] = useState(null);
    const [modalTitle, setModalTitle] = useState("");
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [modalWidth, setModalWidth] = useState(null);
    const [trigger, setTrigger] = useState(0)
    const [searchTexts, setSearchTexts] = useState([]);   //---------Seach Bar --------

    const showModal = () => {
        setIsModalOpen(true);
    };
    const navigate = useNavigate()

    const nevigate = useNavigate();

    const handleOk = () => {
        setIsModalOpen(false);
    };

    const handleCancel = () => {
        setIsModalOpen(false);
    };

    const handleDateRangeChange = (values) => {
        setDateRange(values);
    };


    const AllPurchase = useSelector(selectAllPurchase)
    const PurchaseStatus = useSelector(getPurchaseStatus)
    const PurchaseError = useSelector(getPurchaseError)


    const handleMonthSelect = (value) => {
        form.setFieldsValue({ 'range': null })
        if (value === 'custom') {
            setShow(true);
            setChoiceFull(value)
        } else {
            setShow(false);
        }

        const choice = {
            choice: value
        }

        DateSearch(choice)

    };

    const DateSearch = (values) => {
        request.post(`${SEARCH_URL}`, values)
            .then(function (response) {
                setViewSale(response.data?.purchase)
            })
            .catch(function (error) {
                console.log(error);
            });

    }

    const month = [
        { label: 'This Month', value: 'This_month' },
        { label: 'Year', value: 'This_year' },
        { label: 'Quater', value: 'This_quorter' },
        { label: 'Custom', value: 'custom' },
    ]
    const handleSearchs = (value) => {
        setSearchTexts(value);
    };

    const onFinish = (values) => {

        const record = { ...values, range: dateRange, choice: choiceFull }

        DateSearch(record)

    }

    const onFinishFailed = (errorInfo) => {
        toast.warn("please fill the details")
    };

    const PurchasePrint = async (record) => {

        try {
            const response = await request.get(`purchase/get_print_purchase_order/${record?.id}/`);
            setModalWidth(1200)
            setModalTitle("Purchase Details");
            setModalContent(<ViewPurchaseBill record={response.data} />);
            showModal();
            return response.data;
        }
        catch (error) {
            throw error;
        }
    };
    useEffect(() => {
        dispatch(getpurchase());
    }, []);




    const UpdateForm = () => {
        dispatch(getpurchase());
    }


    const onCancelNo = () => {
        handleOk()
    }

    const PurchaseBillPost = (recordData) => {
        navigate(`/purchasereturn/${recordData?.recordData.id}`)

    }

    const PurchaseConfirmYEs = (recordData) => {
        return (
            <>
                <Flex center>
                    <h1 style={{ fontSize: '20px', color: '#685e5e' }}>Do you Want to Purchase Return ?</h1>
                </Flex>
                <Flex gap={'15px'} center={'true'} style={{ margin: '20px 20px' }}>
                    <Button.Success text={'Yes'} onClick={() => PurchaseBillPost(recordData)} />
                    <Button.Danger text={'No'} onClick={onCancelNo} />
                </Flex>
            </>
        )
    }

    const PurchaseReturnViewConfirm = (recordData) => {
        return (
            <>
                <Flex center>
                    <h1 style={{ fontSize: '20px', color: '#685e5e' }}>Do you Want to View Purchase Return ?</h1>
                </Flex>
                <Flex gap={'15px'} center={'true'} style={{ margin: '20px 20px' }}>
                    <Button.Success text={'Yes'} onClick={() => PurchaseBillPost(recordData)} />
                    <Button.Danger text={'No'} onClick={onCancelNo} />
                </Flex>
            </>
        )
    }

    const EditPurchaseBill = (record) => {
        setTrigger(trigger + 1)
        setModalTitle("Update Puchase Details");
        setModalWidth(1200)
        setModalContent(<PurchaseEntryPage Editpurchaserecord={record} updateForm={UpdateForm} viewSale={viewSale} HandlePurchaseGet={HandlePurchaseGet} ViewhandleOk={handleOk} ViewEditTrigger={trigger} />);
        showModal();
    };

    const PurchaseReturnConfrmation = (record) => {
        setTrigger(trigger + 1)
        setModalTitle("Purchase Return Confirmation");
        setModalWidth(400)
        setModalContent(<PurchaseConfirmYEs recordData={record} />);
        showModal();
    };

    const PurchaseReturnView = (record) => {
        setTrigger(trigger + 1)
        setModalTitle("View Purchase Return Details");
        setModalWidth(450)
        setModalContent(<PurchaseReturnViewConfirm recordData={record} />);
        showModal();
    }


    useEffect(() => {
        GetPurchase()
    }, [])

    const GetPurchase = () => {
        request.get('purchase/purchasedetails_search/')
            .then(function (response) {
                setViewSale(response.data?.purchase)
            })
            .catch(function (error) {
                if (error.response.status === 303) {
                    toast.error("Add Business Profile")
                }
            });
    }

    const HandlePurchaseGet = () => {
        GetPurchase()
        handleOk()
    }


    const columns = [
        {
            title: 'Sl No',
            render: (item, value, index) => index + 1,
        },
        {
            title: 'Date',
            dataIndex: 'bill_date',
        },
        {
            title: 'Party Name',
            dataIndex: 'party_name',
            filteredValue: searchTexts ? [searchTexts] : null,
            onFilter: (value, record) => {
                return String(record.party_name).toLowerCase().includes(value.toLowerCase()) ||
                    String(record.party_name).includes(value.toUpperCase());
            },
        },
        {
            title: 'Bill No',
            dataIndex: 'bill_no',
        },
        {
            title: 'Total Amount',
            dataIndex: 'grand_total',
        },
        {
            title: 'Paid Amount',
            dataIndex: 'paid_amt',
        },
        {
            title: 'Action',
            render: (record, purchase) => {
                return (
                    <>
                        <Flex flexStart={'true'} gap={'15px'} >
                            <TableIconHolder color={THEME.green} size={'22px'} onClick={() => PurchasePrint(record)}>
                                <AiOutlineEye />
                            </TableIconHolder>
                            {record.is_returned === true ? null :
                                <TableIconHolder color={THEME.blue} size={'22px'} onClick={() => { EditPurchaseBill(record) }}>
                                    <FiEdit />
                                </TableIconHolder>
                            }
                            <Flex end={'true'} >
                                {record.is_returned === false && record.balance === "0.00" ?
                                    <TableIconHolder color={THEME.green} size={'22px'} onClick={() => { PurchaseReturnConfrmation(record) }}>
                                        <TbTruckReturn />
                                    </TableIconHolder>
                                    : null}
                                {record.is_returned === true ?
                                    <TableIconHolder color={THEME.red} size={'22px'} onClick={() => { PurchaseReturnView(record) }}>
                                        <TbTruckReturn />
                                    </TableIconHolder>
                                    : null
                                }
                            </Flex>
                        </Flex>

                    </>

                )
            }
        }

    ];


    let content;

    if (PurchaseStatus === 'loading') {
        content = <CommonLoading />
    } else if (PurchaseStatus === 'succeeded') {
        const rowKey = (viewSale) => viewSale.id;
        // content = <CustomTable columns={columns} data={viewSale} rowKey={rowKey} />
        content = <CustomTandardTable columns={columns} data={viewSale} rowKey={rowKey} />
    } else if (PurchaseStatus === 'failed') {
        content = <h2>{PurchaseError}</h2>
    }

    return (
        <>
            <CustomPageTitle Heading={'View Purchase'} />
            <CustomCardView>
                <Form
                    form={form}
                    labelCol={{
                        span: 24,
                    }}
                    wrapperCol={{
                        span: 24,
                    }}
                    initialValues={
                        {
                            start_date: dayjs(),
                            end_date: dayjs(),
                            payment_type: 'Cash'
                        }
                    }
                    onFinish={onFinish}
                    onFinishFailed={onFinishFailed}
                    autoComplete="off">
                    <CustomRow space={[24, 24]} style={{ padding: '10px 0' }}>
                        <Col span={24} md={3} style={{ marginTop: '10px' }}>
                            <b>Choose</b>&nbsp;&nbsp;
                        </Col>
                        <Col span={24} md={12}>
                            <CustomSelect
                                options={month}
                                name={'month'}
                                value={dateRange}
                                placeholder={'This Month'}
                                onChange={handleMonthSelect}
                                rules={[{ required: true, message: 'Please Select the Month' }]}
                            />
                        </Col>
                        <Col span={24} md={9} style={{ float: 'inline-end' }}>
                            <Flex end={true} >
                                <ButtonStandard.Primary text={'Add Purchase'} style={{ marginRight: '10px' }} onClick={() => nevigate(`/addpurchase`)} />
                            </Flex>
                        </Col>
                    </CustomRow>

                    <CustomRow space={[24, 24]} style={{ padding: '10px 0' }}>
                        {show ?
                            <>
                                <Col span={24} md={3} style={{ marginTop: '10px' }}>
                                    <b>Between</b>&nbsp;&nbsp;<TbArrowsExchange />
                                </Col>

                                <Col span={24} md={12}>
                                    <CustomDateRangePicker
                                        onChange={handleDateRangeChange}
                                        name={'range'}
                                        value={dateRange}
                                        rules={[{ required: true, message: 'Please Select the Date' }]}
                                    />
                                </Col>

                                <Col span={24} md={6}>
                                    <Flex>
                                        <Button.Primary text={'Submit'} htmlType='submit' />
                                    </Flex>
                                </Col>
                            </>
                            : null}

                    </CustomRow>
                </Form>
                <br /><br />

                <div style={{ display: 'flex', alignItems: 'center', marginTop: '50px' }}>
                    <Label style={{ marginRight: '20px' }}>Search by Party Name :</Label>
                    <CustomInput
                        value={searchTexts}
                        // label="Search by Party Name"
                        placeholder="Search by Party Name"
                        onSearch={handleSearchs}
                        onChange={(e) => handleSearchs(e.target.value)}
                    />
                </div>

                <CustomRow space={[12, 12]}>

                    <Col span={24} md={24}>

                        {/* <CustomTable columns={columns} data={data} /> */}
                        {content}
                    </Col>
                    <CustomModal isVisible={isModalOpen} handleOk={handleOk} handleCancel={handleCancel} width={modalWidth} modalTitle={modalTitle} modalContent={modalContent} />
                </CustomRow>

            </CustomCardView >

        </>
    )
}

