import React, { useState } from 'react'
import { CustomPageTitle } from '../../components/CustomPageTitle'
import { CustomCardView } from '../../components/CustomCardView'
import { QuotationEntryPage } from './Partials/QuotationEntryPage'

const Quotation = () => {
    return (
        <div>
            <CustomPageTitle Heading={'Quotation'} />
            <CustomCardView>
                <QuotationEntryPage />
            </CustomCardView>

        </div>
    )
}

export default Quotation
