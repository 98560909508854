import { Card, Col, Form } from "antd"
import React, { Fragment, useEffect, useState } from "react"
import { DeleteOutlined } from "@ant-design/icons";
import dayjs from 'dayjs'
import request from "../../../utils/request";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import Flex from "../../../components/Flex";
import { CustomModal } from "../../../components/CustomModal";
import { CustomRow } from "../../../components/CustomRow";
import Button from "../../../components/Form/CustomButton";
import { CustomInputNumber } from "../../../components/Form/CustomInputNumber";
import { CustomSelect } from "../../../components/Form/CustomSelect";
import { CustomSwitch } from "../../../components/Form/CustomSwitch";
import { CustomPageFormSubTitle } from "../../../components/CustomPageTitle";
import AddProduct from "../../Product/AddProduct/Partials/AddProduct";
import { CustomTandardTable } from "../../../components/Form/CustomStandardTable";
import { CustomInput } from "../../../components/Form/CustomInput";
import { PurchaseFormHeader } from "./PurchaseFormHeader";
import { PurchaseFormFooter } from "./PurchaseFormFooter";
import { ViewPurchaseBill } from "./ViewPurchaseBill";
import { getpurchase } from "../PurchaseSlice";




export const PurchaseEntryPage = ({ setSale, Editpurchaserecord, ViewhandleOk, ViewEditTrigger, HandlePurchaseGet }) => {

  const dispatch = useDispatch()

  const [proCount, setProCount] = useState(1);  //  -->  Product Count
  const [serviceCount, setServiceCount] = useState(1);  //  -->  Service Count

  const [invoiceNumber, setInvoiceNumber] = useState({})  //  -->  Invoice Number
  const [selectedDate, setSelectedDate] = useState(dayjs().format('YYYY-MM-DD'));  //  -->  Invoice Date

  const [gstChecked, setGstChecked] = useState(false)  //  -->  GST Checked
  const [serviceClick, setServiceClick] = useState(false)  // --> Service Checked

  const [clicked, setClicked] = useState(true)
  const [modalWidth, setModalWidth] = useState(0)


  const [getdata, setGetdata] = useState([]) // --> Product Data

  const [getdataservice, setGetdataservice] = useState([])  // --> Service Data

  const [selectedSale, setSelectedSale] = useState({})  // --> User Selected Data

  const [trigger, setTrigger] = useState(0);

  // ======  Modal Open ========
  const [isModalOpen, setIsModalOpen] = useState(false);

  // ======  Modal Title and Content ========
  const [modalTitle, setModalTitle] = useState("");
  const [modalContent, setModalContent] = useState(null);

  // =====  Modal Functions Start ===

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  // =====  Modal Functions End ===

  const [form] = Form.useForm();  //  --> Form Ref

  // -----------------  Balance Checking ------------
  const [balanceAmount, setBalanceAmount] = useState(0);
  const [balanceChangeAmount, setBalanceChangeAmount] = useState(0);
  const [balanceChange, setBalanceChange] = useState(false);

  // ======  Selected Party Details ====
  useEffect(() => {
    form.setFieldsValue({ party: selectedSale.id })
    form.setFieldsValue({ party_name: selectedSale.party_name })
    form.setFieldsValue({ gstin: selectedSale.gstin })
    form.setFieldsValue({ email: selectedSale.email })
    form.setFieldsValue({ company_name: selectedSale.customer_company_name })
    form.setFieldsValue({ address: selectedSale.billing_address })
    form.setFieldsValue({ mobile_no: selectedSale.mobile_number })
    form.setFieldsValue({ state_of_supply: selectedSale.state_of_party })
  }, [selectedSale])


  useEffect(() => {

    GetPurchaseForm()
    // form.setFieldsValue({ 'balance': balanceAmount })

  }, [])

  const ItemUpdates = () => {

  }


  //============Party Get, Product Get ,Invoice Number Show Get =====================//

  const GetPurchaseForm = () => {
    request.get(`purchase/purchase_order_details/`)
      .then(function (response) {
        setGetdata(response.data)
        setInvoiceNumber(response.data)
      })
      .catch(function (error) {
        console.log(error);
      });
  }
  //==========

  // -------------------------------------------------------------  Variant Array Start
  const variationsArray = [];

  getdata?.item?.forEach(item => {
    const productName = item.product_name;
    const variationListId = item.variation_list.id;
    const ItemCode = item.variation_list.item_code;
    const SaleRate = item.variation_list.sale_rate;
    const Unit = item.unit;
    const StockMaintain = item.variation_list.variant_stock_maintain;
    const DistCountPer = item.variation_list.dis_percentage;
    const TaxtPer = item.variation_list.gst_percentage;
    const MRP = item.variation_list.mrp;

    let Variant = [];
    item.variation_list.varity.forEach(variation => {
      const variationName = variation.variation_name;
      const variationValue = variation.variation_value;
      Variant.push({ variationName, variationValue })
    });

    variationsArray.push({
      productName,
      Variant,
      ItemCode,
      variationListId,
      SaleRate,
      Unit,
      StockMaintain,
      DistCountPer,
      TaxtPer,
      MRP,
    })

  });
  // -------------------------------------------------------------  Variant Array End

  const setValue = variationsArray.map(Val => ({
    label: `${Val.productName}/ ${Val.Variant.map((v) => `${v.variationName}:"${v.variationValue}"`).join('/')}`,
    value: Val.variationListId
  }))


  const setItemCode = variationsArray.map(Val => ({
    label: Val.ItemCode,
    value: Val.variationListId
  }))




  // =========================  Other Functions End  =========================

  // ======  Product State Start =====

  const ProductInitialData = [
    {
      key: 0,
      description: '',
      product: '',
      item_name: '',
      item_code: '',
      item_qty: '',
      item_unit_name: '',
      price_per_unit: '',
      dis_percentage: '',
      dis_amt: '',
      tax_percentage_name: '',
      tax_percentage_value: '',
      sub_total: '',
      cal_amt: '',
      stock_maintain: '',
    }
  ]

  const ProSecondaryData = [
    {
      qnty_total: '',
      dis_total: '',
      tax_total: '',
      sub_total: '',
    },
  ];

  const ProFooterCalData = [
    {
      grand_total: '',
      paid_amt: '',
      balance: '',
    },
  ];


  const [proTableData, setProTableData] = useState(ProductInitialData);
  const [proTabSecondaryData, setProTabSecondaryData] = useState(ProSecondaryData);
  const [ProTabFooterData, setProTabFooterData] = useState(ProFooterCalData);

  // ======  Product State End =====

  useEffect(() => {
    proTableData.forEach(record => {
      form.setFieldsValue({ [`description${record.key}`]: record.description });
      form.setFieldsValue({ [`item_name${record.key}`]: record.item_name });
      form.setFieldsValue({ [`item${record.key}`]: record.item });
      form.setFieldsValue({ [`item_code${record.key}`]: record.item_code });
      form.setFieldsValue({ [`item_qty${record.key}`]: record.item_qty });
      form.setFieldsValue({ [`item_unit_name${record.key}`]: record.item_unit_name });
      form.setFieldsValue({ [`price_per_unit${record.key}`]: record.price_per_unit });
      form.setFieldsValue({ [`mrp${record.key}`]: record.mrp });
      form.setFieldsValue({ [`dis_percentage${record.key}`]: record.dis_percentage });
      form.setFieldsValue({ [`dis_amt${record.key}`]: record.dis_amt });
      form.setFieldsValue({ [`tax_percentage_name${record.key}`]: record.tax_percentage_name });
      form.setFieldsValue({ [`tax_percentage_value${record.key}`]: record.tax_percentage_value });
      form.setFieldsValue({ [`sub_total${record.key}`]: record.sub_total });
      form.setFieldsValue({ [`cal_amt${record.key}`]: record.cal_amt });
      form.setFieldsValue({ [`stock_maintain${record.key}`]: record.stock_maintain });
    });

    form.setFieldsValue({ [`qnty_total`]: proTabSecondaryData[0].qnty_total });
    form.setFieldsValue({ [`dis_total`]: proTabSecondaryData[0].dis_total });
    form.setFieldsValue({ [`tax_total`]: proTabSecondaryData[0].tax_total });
    form.setFieldsValue({ [`sub_total`]: proTabSecondaryData[0].sub_total });

    form.setFieldsValue({ "grand_total": proTabSecondaryData[0].sub_total });
  }, [proTableData])

  useEffect(() => {
    if (Editpurchaserecord) {
      form.setFieldsValue({ bill_no: Editpurchaserecord?.bill_no })

    }
    else {
      form.setFieldsValue({ bill_no: invoiceNumber?.bill_no })

    }
  }, [invoiceNumber, Editpurchaserecord, ViewEditTrigger])


  useEffect(() => {

    form.setFieldsValue({ paid_amt: ProTabFooterData?.paid_amt })
  }, [ProTabFooterData])


  useEffect(() => {
    if (Editpurchaserecord) {
      form.setFieldsValue(Editpurchaserecord)

      setBalanceChangeAmount(Editpurchaserecord.paid_amt)

      const fromdatee = new Date(Editpurchaserecord?.bill_date)
      const dateFormat = 'YYYY-MM-DD';
      const FrmDateee = dayjs(fromdatee).format(dateFormat);

      form.setFieldsValue({
        bill_date: dayjs(FrmDateee),

      })
      
      form.setFieldsValue({
        qty_total: Editpurchaserecord?.qty_total,

      })

    }

  }, [Editpurchaserecord, ViewEditTrigger])


  //=================================================

  const BalanceOnChange = (value) => {
    const gandTotal = parseFloat(form.getFieldValue('grand_total'))
    if (value > gandTotal) {
      toast.warn('Paid amount cannot be greater than the grand total!')
    }

    setBalanceChangeAmount(value)
  }


  useEffect(() => {
    let GrandTotal = proTabSecondaryData[0].sub_total;
    let ReceivedAmt = balanceChangeAmount;
    let Balance = 0;

    if (ReceivedAmt == 0) {
      Balance = GrandTotal;
    }
    else {
      Balance = GrandTotal - ReceivedAmt;
    }

    if (Balance < 0) {
      setBalanceChange(true);
    } else if (Balance > GrandTotal) {
      setBalanceChange(true);

    }
    else {
      setBalanceChange(false);
    }

    setBalanceAmount(Balance)

  }, [proTableData, balanceChangeAmount, ViewEditTrigger])




  useEffect(() => {
    HandleTableCal();
  }, [gstChecked])

  useEffect(() => {

    form.setFieldsValue({ 'balance': balanceAmount })

  }, [balanceAmount])


  // ===============  Table Data Start ==================

  // ----------- product add -----------

  const onViewRow = () => {
    setModalWidth(1000)
    setTrigger(trigger + 1)
    setModalTitle("Add Product");
    setModalContent(<AddProduct SaleTrigger={trigger} ItemUpdates={ItemUpdates} CloseProduct={handleOk} />);
    showModal();
  };

  const handleGSTSwitch = (e) => {   //----------------------------  Switch For Product or Service
    setGstChecked(e)
  }

  useEffect(() => {

    if (Editpurchaserecord?.tax_choice === 'WithOut Tax') {
      setGstChecked(true)
      form.setFieldsValue({ gstin: Editpurchaserecord?.gstin })
    } else {
      setGstChecked(false)
    }

  }, [Editpurchaserecord, ViewEditTrigger])

  // ============  ProductColumns

  const ProductColumns = [
    {
      title: '#',
      render: (text, record, index) => {
        return (
          (
            <Flex alignCenter gap={'20px'} style={{ alignItems: 'center' }}>
              <h4>{index + 1}</h4>
              <Button
                style={{
                  display: 'flex',
                  padding: '10px',
                  height: 'auto',
                  fontSize: '16px',
                }}
                htmlType="button"
                danger
                onClick={() => onProductTabRowDelete(record.key)}
              >
                <DeleteOutlined />
              </Button>
            </Flex>
          )
        );
      },
    },
    {
      title: (
        <p>Product&nbsp;Name</p>
      ),
      dataIndex: 'item_name',
      key: 'item_name',
      render: (text, record, index) => {
        return (
          <>
            <CustomSelect
              rules={[
                {
                  required: true,
                  message: 'This is required field',
                },
              ]}
              minWidth={'230px'}
              name={`item${record.key}`}
              // onButtonClick={onViewRow}
              // buttonLabel="Add Product"
              options={setValue}
              onChange={(value) => handleOnChangeProduct(value, record)}
            />
            <CustomInputNumber
              minWidth={'150px'}
              name={`item_name${record.key}`}
              disabled
              display={'none'}
            />
            <CustomInput
              minWidth={'150px'}
              name={`stock_maintain${record.key}`}
              display={'none'}
              disabled
            />
          </>
        );
      },
    },
    {
      title: (
        <p>Item&nbsp;Code</p>
      ),
      dataIndex: 'item_code',
      render: (text, record) => (
        <CustomSelect
          rules={[
            {
              required: true,
              message: 'This is a required field',
            },
          ]}
          minWidth={'130px'}
          showSearch
          name={`item_code${record.key}`}
          onButtonClick={onViewRow}
          buttonLabel="Add Item"
          options={setItemCode}
          onChange={(value) => handleOnChangeItemCodeProduct(value, record)} />
      )
    },
    {
      title: 'Unit',
      dataIndex: 'item_unit_name',
      render: (text, record) => (
        <CustomInput
          minWidth={'150px'}
          placed={'end'}
          name={`item_unit_name${record.key}`} />
      )
    },
    {
      title: 'Quantity',
      dataIndex: 'item_qty',
      render: (text, record) => (
        <CustomInputNumber
          minWidth={'150px'}
          precision={2}
          placed={'end'}
          min={1.00}
          name={`item_qty${record.key}`}
          onChange={(value) => handleOnChangeQuantity(value, record)}
        />
      )
    },
    {
      title: 'Mrp',
      dataIndex: 'mrp',
      render: (text, record) => (
        <CustomInputNumber
          minWidth={'150px'}
          precision={2}
          placed={'end'}
          name={`mrp${record.key}`}
          onChange={(value) => handleOnChangeMrp(value, record)}
        />
      )
    },
    {
      title: 'Price',
      dataIndex: 'price_per_unit',
      key: 'price_per_unit',
      render: (text, record) => (
        <CustomInputNumber
          minWidth={'150px'}
          style={{ textAlign: 'center' }}
          name={`price_per_unit${record.key}`}
          precision={2}
          rules={[
            {
              required: true,
              message: 'This is a required field',
            },
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (parseFloat(value) <= 0) {
                  return Promise.reject('Price must be greater than 1');
                }
                return Promise.resolve();
              },
            }),
          ]}
          onChange={(value) => handleOnChangePrice(value, record)}
        />
      )
    },
    {
      title: 'Discount',
      children: [
        {
          title: '%',
          dataIndex: 'dis_percentage',
          key: 'dis_percentage',
          render: (text, record) => (
            <CustomInputNumber
              precision={2}
              minWidth={'80px'}
              placed={'end'}
              name={`dis_percentage${record.key}`}
              min={0.00}
              max={100.00}
              onChange={(value) => handleOnChangeDiscount(value, record)}
            />
          )
        },
        {
          title: 'Amount',
          dataIndex: 'dis_amt',
          key: 'dis_amt',
          render: (text, record) => (
            <CustomInputNumber
              precision={2}
              minWidth={'150px'}
              placed={'end'}
              name={`dis_amt${record.key}`}
              disabled
            />
          )
        },
      ],
    },
    {
      title: 'Tax',
      children: [
        {
          title: '%',
          dataIndex: 'tax_percentage_name',
          key: 'tax_percentage_name',
          render: (text, record) => (
            <CustomInputNumber
              minWidth={'80px'}
              placed={'end'}
              precision={2}
              rules={[
                {
                  required: true,
                  message: 'This is a required field',
                },
              ]}
              name={`tax_percentage_name${record.key}`}
              min={0.00}
              max={100.00}
              onChange={(value) => handleOnChangeTax(value, record)}
            />
          )
        },
        {
          title: 'Amount',
          dataIndex: 'tax_percentage_value',
          key: 'tax_percentage_value',
          render: (text, record) => (
            <CustomInputNumber
              minWidth={'150px'}
              precision={2}
              disabled
              placed={'end'}
              name={`tax_percentage_value${record.key}`}
            />
          )
        },
      ],
    },
    {
      title: (
        <p>Sub&nbsp;Total</p>
      ),
      dataIndex: 'sub_total',
      key: 'sub_total',
      render: (text, record) => (
        <CustomInputNumber precision={2}
          disabled
          minWidth={'150px'}
          placed={'end'}
          name={`sub_total${record.key}`}
        />
      )
    },
    {
      title: (
        <p>Total&nbsp;Amount</p>
      ),
      dataIndex: 'cal_amt',
      key: 'cal_amt',
      render: (text, record) => (
        <CustomInputNumber precision={2}
          disabled
          minWidth={'150px'}
          placed={'end'}
          name={`cal_amt${record.key}`}
        />
      )
    }
  ]

  // ===============  Table Data End ==================


  // ================== Product Table Functions Start ==================

  const AddRow = () => { // ----------------- Add Row Function
    const newData = {
      key: proCount,
      description: '',
      product: '',
      item_name: '',
      item_code: '',
      item_qty: '',
      item_unit_name: '',
      price_per_unit: '',
      dis_percentage: '',
      dis_amt: '',
      tax_percentage_name: '',
      tax_percentage_value: '',
      sub_total: '',
      cal_amt: '',
      stock_maintain: '',
    };
    setProTableData(pre => {
      return [...pre, newData]
    })
    setProCount(proCount + 1);
  }

  const onProductTabRowDelete = (key) => {  // -----------------------  Delete Row Function
    if (proTableData.length > 1) {
      setProTableData(prevState => {
        const newData = prevState.filter(item => item.key !== key);

        // ------ Variables 
        let totalQuantity = 0;
        let totalDiscount = 0;
        let totalTax = 0;
        let totalAmount = 0;

        newData.forEach(item => {
          if (item.item_qty !== '' || item.dis_amt !== '' || item.tax_percentage_value !== '' || item.cal_amt !== '') {
            totalQuantity += parseFloat(item.item_qty);
            totalDiscount += parseFloat(item.dis_amt);
            totalTax += parseFloat(item.tax_percentage_value);
            totalAmount += parseFloat(item.cal_amt);
          }
        });

        // update the grand_total value in the tableSecondaryData array
        setProTabSecondaryData([{
          qty_total: totalQuantity.toFixed(2),
          discount_total: totalDiscount.toFixed(2),
          tax_total: totalTax.toFixed(2),
          sub_total: totalAmount.toFixed(2),
        }]);

        return newData;
      });
    } else {
      console.log(`only ${proTableData.length} is available`)
    }
  };

  const CalculateTotal = (record) => {  // ----------------- 1. Calculate TotalAmount 
    setProTableData(prevState => {
      const newData = [...prevState];
      const index = newData.findIndex(item => record.key === item.key);
      const item = newData[index];

      // ------ Variables 
      let totalQuantity = 0;
      let totalDiscount = 0;
      let totalTax = 0;
      let totalAmount = 0;

      newData.forEach(item => {
        if (item.item_qty !== '' || item.dis_amt !== '' || item.tax_percentage_value !== '' || item.cal_amt !== '') {
          totalQuantity += parseFloat(item.item_qty);
          totalDiscount += parseFloat(item.dis_amt);
          totalTax += parseFloat(item.tax_percentage_value);
          totalAmount += parseFloat(item.cal_amt);
        }
      });

      // update the grand_total value in the tableSecondaryData array
      setProTabSecondaryData([{
        qnty_total: totalQuantity.toFixed(2),
        dis_total: totalDiscount.toFixed(2),
        tax_total: totalTax.toFixed(2),
        sub_total: totalAmount.toFixed(2),
      }]);

      return newData;
    });

  };

  // ============  Products OnChange Functions  ==============

  const HandleQty = (value, record) => {  //  OnCHANGE QTY

    setProTableData(prevState => {
      const newData = [...prevState];
      const index = newData.findIndex(item => record.key === item.key);
      const item = newData[index];

      item.item_qty = value;

      CalculateTotal({
        ...item,
        item_qty: record.item_qty,
      });

      HandleRowCal({
        ...item,
        item_qty: record.item_qty,
      })

      return newData;
    });
  };

  const HandlePrice = (value, record) => {  //  OnCHANGE PRICE
    setProTableData(prevState => {
      const newData = [...prevState];
      const index = newData.findIndex(item => record.key === item.key);
      const item = newData[index];

      item.price_per_unit = value || 0;

      CalculateTotal({
        ...item,
        price_per_unit: item.price_per_unit,
      })

      HandleRowCal({
        ...item,
        price_per_unit: item.price_per_unit,
      })

      return newData;
    });
  }

  const HandleMrp = (value, record) => {  //  OnCHANGE PRICE
    setProTableData(prevState => {
      const newData = [...prevState];
      const index = newData.findIndex(item => record.key === item.key);
      const item = newData[index];

      item.mrp = value || 0;

      return newData;
    });
  }

  const HandleDiscount = (value, record) => {  //  ONCHANGE DISCOUNT
    setProTableData(prevState => {
      const newData = [...prevState];
      const index = newData.findIndex(item => record.key === item.key);
      const item = newData[index];

      let Dis_per = 0; // Dis - Percentage

      if (value <= 100)  // Less Than 100
      {
        Dis_per = value;
      }
      else {  // Greater Than 100
        Dis_per = 100;
      }

      CalculateTotal({
        ...item,
        dis_percentage: Dis_per,
      })

      HandleRowCal({
        ...item,
        dis_percentage: Dis_per,
      })

      item.dis_percentage = Dis_per;

      return newData;
    })
  }

  const HandleTax = (value, record) => {  // ONCHANGE TAX
    setProTableData(prevState => {
      const newData = [...prevState];
      const index = newData.findIndex(item => record.key === item.key);
      const item = newData[index];


      let Tax_per = 0; // Tax - Percentage

      if (value <= 100)  // Less Than 100
      {
        Tax_per = value;
      }
      else {  // Greater Than 100
        Tax_per = 100;
      }

      CalculateTotal({
        ...item,
        tax_percentage_name: Tax_per,
      })

      HandleRowCal({
        ...item,
        tax_percentage_name: Tax_per,
      })

      item.tax_percentage_name = Tax_per;

      return newData;
    })
  }
  // ========== product =============

  const HandleProduct = (value, record) => {   // ONCHANGE PRODUCT 
    setProTableData(prevState => {
      const newData = [...prevState];
      const index = newData.findIndex(item => record.key === item.key);
      const item = newData[index];

      const setSelectedPurchase = variationsArray?.find(item => item.variationListId === value);

      let Product = '';
      let ProductName = '';
      let Quantity = '';
      let ItemCode = '';
      let Unit = '';
      let SalePrice = '';
      let Stock = '';
      let MRP = '';
      let DisCount = '';
      let TaxPer = '';

      if (setSelectedPurchase) {
        Product = setSelectedPurchase.variationListId;
        ProductName = setSelectedPurchase.productName;
        ItemCode = setSelectedPurchase.ItemCode;
        Unit = setSelectedPurchase.Unit;
        SalePrice = parseFloat(setSelectedPurchase.SaleRate);
        Quantity = 1;
        Stock = setSelectedPurchase.StockMaintain;
        MRP = setSelectedPurchase.MRP;
        DisCount = parseFloat(setSelectedPurchase.DistCountPer);
        TaxPer = parseFloat(setSelectedPurchase.TaxtPer);
      }

      const isItemAlreadyAdded = newData.some(
        (item, i) => i !== index && item.item === value
      );

      if (isItemAlreadyAdded) {
        Product = '';
        ProductName = '';
        ItemCode = '';
        Unit = '';
        SalePrice = 0;
        Quantity = 0;
        Stock = '';
        MRP = 0;
        DisCount = 0;
        TaxPer = 0;
        toast.warn("Product already added in the table.");
        // return newData;
      }

      item.item_name = ProductName;
      item.item = Product;
      item.item_code = ItemCode;
      item.item_unit_name = Unit;
      item.price_per_unit = SalePrice;
      item.item_qty = Quantity;
      item.stock_maintain = Stock;
      item.mrp = MRP;
      item.dis_percentage = DisCount;
      item.tax_percentage_name = TaxPer;

      CalculateTotal({
        ...item,
        price_per_unit: SalePrice,
        item_qty: Quantity,
      })

      HandleRowCal({
        ...item,
        price_per_unit: SalePrice,
        item_qty: Quantity,
      })

      return newData;
    });
  };

  const HandleItemCodeProduct = (value, record) => {   // ONCHANGE ITEM CODE
    setProTableData(prevState => {
      const newData = [...prevState];
      const index = newData.findIndex(item => record.key === item.key);
      const item = newData[index];

      const setSelectedPurchase = variationsArray?.find(item => item.variationListId === value);

      let Product = '';
      let ProductName = '';
      let Quantity = '';
      let ItemCode = '';
      let Unit = '';
      let SalePrice = '';
      let Stock = '';
      let MRP = '';
      let DisCount = '';
      let TaxPer = '';

      if (setSelectedPurchase) {
        Product = setSelectedPurchase.variationListId;
        ProductName = setSelectedPurchase.productName;
        ItemCode = setSelectedPurchase.ItemCode;
        Stock = setSelectedPurchase.StockMaintain;
        Unit = setSelectedPurchase.Unit;
        SalePrice = setSelectedPurchase.SaleRate;
        Quantity = 1;
        MRP = setSelectedPurchase.MRP;
        DisCount = parseFloat(setSelectedPurchase.DistCountPer);
        TaxPer = parseFloat(setSelectedPurchase.TaxtPer)
      }

      const isItemAlreadyAdded = newData.some(
        (item, i) => i !== index && item.item === value
      );

      if (isItemAlreadyAdded) {
        Product = '';
        ProductName = '';
        ItemCode = '';
        Unit = '';
        SalePrice = 0;
        Quantity = 0;
        Stock = '';
        MRP = 0;
        DisCount = 0;
        TaxPer = 0;
        toast.warn("Product already added in the table.");
        // return newData;
      }

      item.item_name = ProductName;
      item.item = Product;
      item.item_code = ItemCode;
      item.item_unit_name = Unit;
      item.price_per_unit = SalePrice;
      item.item_qty = Quantity;
      item.stock_maintain = Stock;
      item.mrp = MRP;
      item.dis_percentage = DisCount;
      item.tax_percentage_name = TaxPer;

      CalculateTotal({
        ...item,
        price_per_unit: SalePrice,
        item_qty: Quantity,
      })

      HandleRowCal({
        ...item,
        price_per_unit: SalePrice,
        item_qty: Quantity,
      })
      item.item_name = value

      return newData;
    });
  };


  // ===================  Whole Tax Row Calculation ============

  const HandleRowCal = (record) => {
    setProTableData(prevState => {
      const newData = [...prevState];
      const index = newData.findIndex(item => record.key === item.key);
      const item = newData[index];

      let sub_total = 0; // Sub - Total
      let grand_total = 0; // Grand - Total
      let tax_amount = 0; // Tax - Amount
      let dis_amount = 0; // Discount - Amount


      const price = record.price_per_unit || 0;
      const quantity = record.item_qty || 0;
      const Tax_per = record.tax_percentage_name || 0;
      const Dis_per = record.dis_percentage || 0;

      const OriginalAmount = calculateProductTableAmount(item); // Qty x Price

      if (gstChecked)  //  GST CHECKED
      {
        // --------------  TAX PERCENTAGE NOT EQUAL TO ZERO ---
        if (Tax_per != 0) {
          // --------------  DisCount NOT EQUAL TO ZERO ---
          if (Dis_per != 0) {

            // --- Discount Calculation
            let DisAmt = (OriginalAmount * Dis_per) / 100;  // --> Discount Amt

            // --- Tax Calculation
            let TaxPlusHun = (Tax_per + 100);  // --> Tax + 100

            let TaxIncludePrice = OriginalAmount - DisAmt;

            let ExcludingAmt = TaxIncludePrice * (100 / TaxPlusHun); // -- > Excluding Amount

            const TaxAmt = TaxIncludePrice - ExcludingAmt;

            dis_amount = DisAmt;
            tax_amount = TaxAmt;
            sub_total = ExcludingAmt;
            grand_total = TaxIncludePrice;
          }
          else // --------------  DisCount EQUAL TO ZERO ---
          {
            // --- Tax Calculation

            let TaxPlusHun = (Tax_per + 100); // --> Tax + 100

            let ExcludingAmt = OriginalAmount * (100 / TaxPlusHun); // -- > Excluding Amount

            let TaxAmt = OriginalAmount - ExcludingAmt;
            let TaxQtyAmt = TaxAmt * quantity

            dis_amount = 0;
            tax_amount = TaxQtyAmt;
            sub_total = ExcludingAmt;
            grand_total = OriginalAmount;
          }
        }
        else // --------------  TAX PERCENTAGE EQUAL TO ZERO ---
        {
          // --------------  DisCount NOT EQUAL TO ZERO ---
          if (Dis_per != 0) {

            // --- Discount Calculation
            const DisMinus = (OriginalAmount * Dis_per) / 100;
            const ApplyDiscount = OriginalAmount - DisMinus;

            dis_amount = DisMinus;
            tax_amount = 0;
            sub_total = OriginalAmount;
            grand_total = ApplyDiscount;
          }
          else // --------------  DisCount EQUAL TO ZERO ---
          {
            dis_amount = 0;
            tax_amount = 0;
            sub_total = OriginalAmount;
            grand_total = OriginalAmount;
          }
        }
      }
      else // --------------  TAX PERCENTAGE NOT EQUAL TO ZERO ---
      {
        // --------------  TAX PERCENTAGE NOT EQUAL TO ZERO ---
        if (Tax_per != 0) {
          // --------------  DisCount NOT EQUAL TO ZERO ---
          if (Dis_per != 0) {

            // --- Discount Calculation
            const DisMinus = (OriginalAmount * Dis_per) / 100;
            const ApplyDiscount = OriginalAmount - DisMinus;

            // --- Tax Calculation
            const taxAmt = (ApplyDiscount * Tax_per) / 100;
            const ApplyTax = ApplyDiscount + taxAmt;

            dis_amount = DisMinus;
            tax_amount = taxAmt;
            sub_total = OriginalAmount;
            grand_total = ApplyTax;
          }
          else // --------------  DisCount EQUAL TO ZERO ---
          {
            // --- Tax Calculation
            const taxAmt = (OriginalAmount * Tax_per) / 100;
            const ApplyTax = OriginalAmount + taxAmt;

            dis_amount = 0;
            tax_amount = taxAmt;
            sub_total = OriginalAmount;
            grand_total = ApplyTax;
          }
        }
        else  // --------------  TAX PERCENTAGE EQUAL TO ZERO ---
        {
          // --------------  DisCount NOT EQUAL TO ZERO ---
          if (Dis_per != 0) {

            // --- Discount Calculation
            const DisMinus = (OriginalAmount * Dis_per) / 100;
            const ApplyDiscount = OriginalAmount - DisMinus;

            dis_amount = DisMinus;
            tax_amount = 0;
            sub_total = OriginalAmount;
            grand_total = ApplyDiscount;
          }
          else // --------------  DisCount EQUAL TO ZERO ---
          {
            dis_amount = 0;
            tax_amount = 0;
            sub_total = OriginalAmount;
            grand_total = OriginalAmount;
          }
        }
      }
      item.item_qty = quantity;
      item.price_per_unit = price;
      item.dis_percentage = Dis_per;
      item.dis_amt = dis_amount;
      item.tax_percentage_name = Tax_per;
      item.tax_percentage_value = tax_amount;
      item.sub_total = sub_total;
      item.cal_amt = grand_total;

      CalculateTotal({
        ...item,
        item_qty: quantity,
        dis_amt: dis_amount,
        tax_percentage_value: tax_amount,
        cal_amt: sub_total,
      })

      return newData;
    })
  }

  // ===================  Whole Tax Table Calculation ============

  const HandleTableCal = () => {
    setProTableData(prevState => {
      const newData = prevState.map(item => {
        let sub_total = 0;
        let grand_total = 0;
        let tax_amount = 0;
        let dis_amount = 0;

        const price = item.price_per_unit || 0;
        const quantity = item.item_qty || 0;
        const Tax_per = item.tax_percentage_name || 0;
        const Dis_per = item.dis_percentage || 0;

        const OriginalAmount = calculateProductTableAmount(item);

        if (gstChecked) {
          if (Tax_per !== 0) {
            if (Dis_per != 0) {

              // --- Discount Calculation
              let DisAmt = (OriginalAmount * Dis_per) / 100;  // --> Discount Amt

              // --- Tax Calculation
              let TaxPlusHun = (Tax_per + 100);  // --> Tax + 100

              let TaxIncludePrice = OriginalAmount - DisAmt;

              let ExcludingAmt = TaxIncludePrice * (100 / TaxPlusHun); // -- > Excluding Amount

              const TaxAmt = TaxIncludePrice - ExcludingAmt;

              dis_amount = DisAmt;
              tax_amount = TaxAmt;
              sub_total = ExcludingAmt;
              grand_total = TaxIncludePrice;
            }
            else // --------------  DisCount EQUAL TO ZERO ---
            {
              // --- Tax Calculation

              let TaxPlusHun = (Tax_per + 100); // --> Tax + 100

              let ExcludingAmt = OriginalAmount * (100 / TaxPlusHun); // -- > Excluding Amount

              let TaxAmt = OriginalAmount - ExcludingAmt;
              let TaxQtyAmt = TaxAmt * quantity

              dis_amount = 0;
              tax_amount = TaxQtyAmt;
              sub_total = ExcludingAmt;
              grand_total = OriginalAmount;
            }
          } else {
            if (Dis_per !== 0) {
              let DisMinus = (OriginalAmount * Dis_per) / 100;
              let ApplyDiscount = OriginalAmount - DisMinus;

              dis_amount = DisMinus;
              tax_amount = 0;
              sub_total = OriginalAmount;
              grand_total = ApplyDiscount;
            } else {
              dis_amount = 0;
              tax_amount = 0;
              sub_total = OriginalAmount;
              grand_total = OriginalAmount;
            }
          }
        } else {
          if (Tax_per !== 0) {
            if (Dis_per !== 0) {
              let DisMinus = (OriginalAmount * Dis_per) / 100;
              let ApplyDiscount = OriginalAmount - DisMinus;

              let taxAmt = (ApplyDiscount * Tax_per) / 100;
              let ApplyTax = ApplyDiscount + taxAmt;

              dis_amount = DisMinus;
              tax_amount = taxAmt;
              sub_total = OriginalAmount;
              grand_total = ApplyTax;
            } else {
              let taxAmt = (OriginalAmount * Tax_per) / 100;
              let ApplyTax = OriginalAmount + taxAmt;

              dis_amount = 0;
              tax_amount = taxAmt;
              sub_total = OriginalAmount;
              grand_total = ApplyTax;
            }
          } else {
            if (Dis_per !== 0) {
              let DisMinus = (OriginalAmount * Dis_per) / 100;
              let ApplyDiscount = OriginalAmount - DisMinus;

              dis_amount = DisMinus;
              tax_amount = 0;
              sub_total = OriginalAmount;
              grand_total = ApplyDiscount;
            } else {
              dis_amount = 0;
              tax_amount = 0;
              sub_total = OriginalAmount;
              grand_total = OriginalAmount;
            }
          }
        }

        item.item_qty = quantity;
        item.price_per_unit = price;
        item.dis_percentage = Dis_per;
        item.dis_amt = dis_amount;
        item.tax_percentage_name = Tax_per;
        item.tax_percentage_value = tax_amount;
        item.sub_total = sub_total;
        item.cal_amt = grand_total;

        CalculateTotal({
          ...item,
          item_qty: quantity,
          dis_amt: dis_amount,
          tax_percentage_value: tax_amount,
          cal_amt: sub_total,
        });

        return item;
      });

      return newData;
    });
  };


  // ---------------- 1.TotalQuantity ONCHANGE Function

  const handleOnChangeQuantity = (value, record) => {  //  ----> QUANTITY ONCHANGE (PRODUCT TABLE)
    HandleQty(value, record);  //  1227
  };

  const handleOnChangeMrp = (value, record) => {  //  ----> MRP ONCHANGE 
    HandleMrp(value, record);  //  1227
  };

  const handleOnChangePrice = (value, record) => {   //  ----> PRICE ONCHANGE (PRODUCT TABLE)
    HandlePrice(value, record) //  1258
  }

  const handleOnChangeDiscount = (value, record) => {  //  ----> DISCOUNT ONCHANGE (PRODUCT TABLE)
    HandleDiscount(value, record)  //  1317
  }

  const handleOnChangeTax = (value, record) => {  //  -----> TAX ONCHANGE (PRODUCT TABLE)
    HandleTax(value, record)  // 1413
  }

  const handleOnChangeProduct = (value, record) => {  //  -----> PRODUCT ONCHANGE (PRODUCT TABLE)
    HandleProduct(value, record)
  }

  const handleOnChangeItemCodeProduct = (value, record) => {  //  -----> PRODUCT ONCHANGE (PRODUCT TABLE)
    HandleItemCodeProduct(value, record)
  }

  // -------------- Handle Total Row Amount  --------------
  const calculateProductTableAmount = (record) => {
    const quantity = parseFloat(record.item_qty) || 0;
    const sale_amount = parseFloat(record.price_per_unit) || 0;
    return quantity * sale_amount
  }

  // ================== Product Table Functions End ==================


  //======================= Record Data Set Dynamic Table (For Using Purchase Edit)




  // ====================  On Finish Function ============

  const onFinish = (values) => {
    const record = {
      ...values,
      bill_date: values?.bill_date === null ? '' : dayjs(selectedDate).format('YYYY-MM-DD') ? dayjs(values?.bill_date).format('YYYY-MM-DD') : dayjs(values?.bill_date).format('YYYY-MM-DD'),
      tax_choice: gstChecked ? 'With Tax' : 'Without Tax'
    };

    let result = {
      party_name: record.party_name,
      address: record.address,
      mobile_no: record.mobile_no,
      state_of_supply: record.state_of_supply,
      gstin: record.gstin,
      bill_date: record.bill_date,
      email: record.email,
      bill_no: record.bill_no,
      party: record.party,
      tax_choice: record.tax_choice,
      qnty_total: parseFloat(record.qnty_total).toFixed(2),
      dis_total: parseFloat(record.dis_total).toFixed(2),
      tax_total: parseFloat(record.tax_total).toFixed(2) || 0,
      amt_total: parseFloat(record.sub_total).toFixed(2),
      grand_total: parseFloat(record.grand_total).toFixed(2),
      paid_amt: record.paid_amt ? parseFloat(record.paid_amt).toFixed(2) : parseFloat(0).toFixed(2),
      balance: parseFloat(record.balance).toFixed(2),

      purchase_order: Object.entries(record)
        .filter(([key]) => key.startsWith('item_code'))
        .map(([key, item_code]) => {
          const index = key.match(/\d+/)[0];
          const itemNamekey = `item_name${index}`;
          const itemID = `item${index}`;
          const MRPS = `mrp${index}`;
          const item_qty = `item_qty${index}`;
          const unitKey = `item_unit_name${index}`;
          const pricePercentage = `price_per_unit${index}`;
          const discountPercentageKey = `dis_percentage${index}`;
          const discountAmtKey = `dis_amt${index}`;
          const taxPercentageKey = `tax_percentage_name${index}`;
          const taxamyKey = `tax_percentage_value${index}`;
          const SubTotalKey = `sub_total${index}`;
          const totaltaxantKey = `cal_amt${index}`;
          return {
            item_code,
            item_name: record[itemNamekey],
            item: record[itemID],
            mrp: record[MRPS],
            sub_total: !isNaN(parseFloat(record[SubTotalKey])) ? parseFloat(record[SubTotalKey]).toFixed(2) : 0,
            item_unit_name: record[unitKey],
            item_qty: record[item_qty],
            price_per_unit: record[pricePercentage] || 0,
            dis_percentage: !isNaN(parseFloat(record[discountPercentageKey])) ? parseFloat(record[discountPercentageKey]).toFixed(2) : 0,
            dis_amt: !isNaN(parseFloat(record[discountAmtKey])) ? parseFloat(record[discountAmtKey]).toFixed(2) : 0,
            tax_percentage_name: !isNaN(parseFloat(record[taxPercentageKey])) ? parseFloat(record[taxPercentageKey]).toFixed(2) : 0,
            tax_percentage_value: !isNaN(parseFloat(record[taxamyKey])) ? parseFloat(record[taxamyKey]).toFixed(2) : 0,
            cal_amt: !isNaN(parseFloat(record[totaltaxantKey])) ? parseFloat(record[totaltaxantKey]).toFixed(2) : 0,

          };
        }),
    };
    if (Editpurchaserecord) {
      EditPurchase(result)
    }
    else {
      AddPurchasePost(result);
    }

    // setCorrect()
  };

  //=======================Post Purchase =====================================

  const AddPurchasePost = (values) => {
    request.post('purchase/purchase_order/', values)
      .then(function (response) {
        if (response.status == 201) {
          toast.success('Successfully Billed Purchase Entry')
          form.resetFields();
          setInvoiceNumber(!invoiceNumber)
          setTrigger((trigger) => trigger + 1);
          handleClick(response.data.id)
          GetPurchaseForm()
          dispatch(getpurchase());
          setProTableData(ProductInitialData);
          setProTabSecondaryData(ProSecondaryData);
          setBalanceChangeAmount(0)
        }
        else if (response.status == 226) {                            // Please Add business profile
          if (response.data) {
            if (response.data.message) {
              toast.warn(response.data.message[0]);
            }
          }
        }
        else {
          form.resetFields();
          GetPurchaseForm()
          setProTableData(ProductInitialData);
          setProTabSecondaryData(ProSecondaryData);
          setProTableData(ProductInitialData);
          setProTabSecondaryData(ProSecondaryData);
          toast.success('Successfully Billed Purchase Entry')
        }
      })
      .catch(function (error) {
        if (error.response && error.response.status === 400) {             //=Gstin =
          if (error.response.data) {
            if (error.response.data.gstin) {
              toast.warn(error.response.data.gstin[0]);
            } else if (error.response.data.mobile_no) {
              toast.warn(error.response.data.mobile_no[0]);
            } else {
              toast.error('Failed.');
            }
          } else {
            // toast.error('Failed.');
          }
        }
        else if (error.response.status === 302) {
          toast.warn("Bill No Already Exits!")
        }
        else if (error.response.status === 304) {
          toast.warn("PurchaseOrder limit exceeded!, Please subscribe your plan!")
        }

        else if (error.response.status === 406) {
          toast.warn("This PurchaseOrder involve Transactions so cant be Submited!")
        }
        else {
          toast.error('Failed.');
        }

      });
  }

  const handleClick = (record) => {
    setModalWidth(400)
    setModalTitle("Purchase Return Print");
    setModalContent(<PrintModal record={record} />);
    showModal();
  }

  const PrintModal = (record) => (
    <div>
      <h1 style={{ fontSize: '1.2rem' }}>Are you Sure You Want to Print ?</h1>
      <br />
      <Flex gap={'20px'} W_100 center verticallyCenter>
        <Button.Success text={'Print'} onClick={() => printOk(record)} />
        <Button.Danger text={'Cancel'} onClick={handleOk} />
      </Flex>
    </div>
  )

  const printOk = async (record) => {
    try {
      const response = await request.get(`purchase/get_print_purchase_order/${record?.record}/`);
      setModalWidth(1200)
      setModalTitle("Purchase Details ");
      setModalContent(<ViewPurchaseBill record={response.data} />);
      showModal();
      return response.data;
    }
    catch (error) {
      console.error("Error in printOk:", error);
      throw error;
    }
  };

  useEffect(() => {
    dispatch(getpurchase())
  }, [])




  //=======================Edit Purchase =====================================

  const EditPurchase = (values, id) => {
    request.put(`purchase/edit_purchase_order/${Editpurchaserecord.id}/`, values)
      .then(function (response) {

        if (response.status == 201) {
          toast.info('Successfully Bill Updated')
          if (HandlePurchaseGet) {
            HandlePurchaseGet()
          }
          GetPurchaseForm()
          ViewhandleOk()
          setBalanceChangeAmount(0)
        }
        else if (response.status == 226) {                            // Please Add business profile
          if (response.data) {
            if (response.data.message) {
              toast.warn(response.data.message[0]);
            }
          }
        }

      })
      .catch(function (error) {
        if (error.response && error.response.status === 400) {             //=Gstin =
          if (error.response.data) {
            if (error.response.data.gstin) {
              toast.warn(error.response.data.gstin[0]);
            } else if (error.response.data.mobile_no) {
              toast.warn(error.response.data.mobile_no[0]);
            } else {
              toast.error('Failed.');
            }
          } else {
            // toast.error('Failed.');
          }
        }
        else if (error.response.status === 302) {
          toast.warn("Bill No Already Exits!")
        }
        else if (error.response.status === 304) {
          toast.warn("PurchaseOrder limit exceeded!,so cant be Submited, Please subscribe your plan!")
        }

        else if (error.response.status === 406) {
          toast.warn("This PurchaseOrder involve Transactions so cant be Submited!")
        }
        else if (error.response.status === 408) {
          toast.warn("Please subscribe your plan")
        }
        else {
          toast.error('Failed.');
        }

      });

  }

  const onFinishFailed = (errorInfo) => {
    toast.warn('Please Fill the Details!')
  };
  useEffect(() => {

    if (Editpurchaserecord?.purchase_order) {
      const tableData = Editpurchaserecord?.purchase_order.map((value, index) => ({
        ...value,
        key: index
      }));
      const newData = tableData.map(item => (
        {
          ...item,
          price_per_unit: parseInt(item.price_per_unit),
          cal_amt: parseInt(item.cal_amt),
          dis_amt: parseInt(item.dis_amt),
          dis_percentage: parseInt(item.dis_percentage),
          item_qty: parseInt(item.item_qty),
          sub_total: parseInt(item.sub_total),
          mrp: parseInt(item.mrp),
          tax_percentage_name: parseInt(item.tax_percentage_name),
          tax_percentage_value: parseInt(item.tax_percentage_value),

        }
      ))


      setProTableData(newData);
      setProCount(tableData.length)

    }
  }, [Editpurchaserecord, ViewEditTrigger]);



  // ==============  Add Row Component  ================

  const FooterComponent = () => {
    return (
      <div style={{ background: 'var(--light-color)', padding: '20px' }}>
        <CustomRow>
          <Col lg={4} sm={12} span={24}>
            <Button type="primary" style={{
              fontSize: '1rem',
              height: 'auto',
              fontFamily: 'Poppins',
              fontWeight: 500,
              letterSpacing: '1px',
            }}
              htmlType="button"
              onClick={AddRow}>
              Add Row
            </Button>
          </Col>
        </CustomRow>
      </div >
    )
  }

  // ==================  Table  ==================
  const onRest = () => {
    form.resetFields();
    form.setFieldsValue({ bill_no: invoiceNumber?.bill_no })
    setSelectedSale(!selectedSale)
    setTrigger((trigger) => trigger + 1);
    setProTableData(ProductInitialData);
    setProTabSecondaryData(ProSecondaryData);

    if (Editpurchaserecord) {
      ViewhandleOk()
    }
  }


  const handleServiceSwitch = () => {

    if (serviceClick !== true) {
      form.resetFields()
      form.setFieldsValue({ invoice_no: invoiceNumber?.invoice_no })
      setServiceClick(true)
    } else (
      setServiceClick(false)
    )
  }
  return (
    <Fragment>
      <Form name="purchase"
        labelCol={{
          span: 24,
        }}
        wrapperCol={{
          span: 24,
        }}
        form={form}
        initialValues={
          {
            bill_date: dayjs(),
          }
        }
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}>
        <Flex alignCenter centervertically>

        </Flex>
        <br />
        <PurchaseFormHeader gstChecked={gstChecked} trigger={trigger} setSale={setSale} setSelectedDate={setSelectedDate} Editpurchaserecord={Editpurchaserecord}
          setGetdata={setGetdata} selectedSale={selectedSale} setSelectedSale={setSelectedSale} />

        <Flex end={true} style={{ margin: '20px 0px' }}>
          <CustomSwitch leftLabel={'With GST'} rightLabel={'WithOut GST'} onChange={handleGSTSwitch} checked={gstChecked} />
        </Flex>

        <div style={{ margin: '20px 0' }}>

          {serviceClick ?
            ''
            : (<CustomTandardTable columns={ProductColumns.filter(Boolean)} data={proTableData} pagination={false} />)
          }
          {/* <CustomTandardTable columns={ServiceColumns.filter(Boolean)} data={tableData} pagination={false} /> */}
          <FooterComponent />
        </div>

        <div style={{ margin: '20px 0' }}>
          <PurchaseFormFooter BalanceOnChange={BalanceOnChange} clicked={clicked} serviceClick={serviceClick} />
        </div>

        <Card>
          <Flex center gap={'10px'}>


            {Editpurchaserecord ?
              <>
                <Button.Primary text={'Update'} htmlType="submit" disabled={balanceChange} />
                <Button.Danger text={'Cancel'} onClick={onRest} />
              </>

              :
              <>
                <Button.Primary text={'Submit'} htmlType="submit" disabled={balanceChange} />
                <Button.Danger text={'Cancel'} onClick={onRest} />
              </>

            }
          </Flex>
        </Card>
      </Form>

      <CustomModal isVisible={isModalOpen} handleOk={handleOk} handleCancel={handleCancel} width={modalWidth} modalTitle={modalTitle} modalContent={modalContent} />
    </Fragment>
  )
}