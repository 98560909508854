import { Card, Col } from 'antd'
import React, { Fragment } from 'react'
import { CustomRow } from '../../../../components/CustomRow'
import Flex from '../../../../components/Flex'
import { CustomInput } from '../../../../components/Form/CustomInput'
import { CustomInputNumber } from '../../../../components/Form/CustomInputNumber'


export const ReturnFormFooter = ({ BalanceOnChange, totalProductData, serviceClick }) => {

    const HandleOnchange = (val) => {
        // BalanceOnChangeService(0)
        BalanceOnChange(val)
    }

    return (
        <Fragment>
            <CustomRow gutter={[24, 24]} >
                <Col lg={8} md={8} span={24}>
                </Col>
                <Col lg={0} md={0} span={0}></Col>
                <Col lg={16} md={16} span={24}>
                    <Card>
                        <CustomRow space={[24, 24]}>
                            <Col span={24} md={8}>
                                <CustomInput label={'Total Tax'} disabled name={'tax_total'} />
                            </Col>
                            <Col span={24} md={8}>
                                <Flex alignCenter={true}>
                                    <CustomInput label={'Total Quantity'} disabled name={'qty_total'} />
                                </Flex>
                            </Col>

                            <Col span={24} md={8}>
                                <CustomInput label={'Total Discount'} disabled name={'discount_total'} />
                            </Col>
                            <Col span={24} md={24}>
                                <Flex end>
                                    <CustomInput label={'Sub Total'} disabled name={'table_total'} />
                                </Flex>
                            </Col>
                        </CustomRow>
                    </Card>
                </Col>

                <Col lg={10} md={10} span={24}></Col>

                <Col lg={14} md={20} span={24}>
                    <CustomRow gutter={[12, 12]} style={{ marginTop: '20px', gap: '10px' }}>
                        <Col span={24}>
                            <CustomRow gutter={[12, 12]}>
                                <Col sm={12} span={24} style={{
                                    display: 'flex',
                                    alignItems: 'end',
                                }}>
                                    <CustomRow gutter={[12, 12]}>
                                    </CustomRow>
                                </Col>

                                <Col sm={12} span={24}>
                                    <CustomInputNumber precision={2} name={'grand_total'} label={'Total'} placed={'end'} disabled />
                                </Col>
                            </CustomRow>
                        </Col>
                        <Col span={24}>
                            <CustomRow gutter={[12, 12]}>
                                <Col sm={6} span={0}></Col>

                                <Col sm={6} span={6} style={{ display: 'flex', width: '100%', alignItems: 'center', }}>
                                    <CustomRow gutter={[12, 12]}>
                                        <Col span={12}>
                                        </Col>
                                    </CustomRow>
                                </Col>
                                <Col md={12} span={18}>
                                    {/* {totalProductData?.covert_salereturn === true ?
                                        <CustomInputNumber
                                            precision={2}
                                            name={'received_amt'}
                                            label={'Received'}
                                            placed={'end'}
                                            disabled={true}
                                        /> :
                                        <CustomInputNumber
                                            precision={2}
                                            name={'received_amt'}
                                            label={'Received'}
                                            placed={'end'}
                                            onChange={(val) => HandleOnchange(val)}
                                        />  
                                    } */}
                                    {
                                        totalProductData?.received_amt &&
                                        <CustomInputNumber
                                            precision={2}
                                            name={'received_amt'}
                                            label={'Received'}
                                            placed={'end'}
                                            disabled={true}
                                        />
                                    }

                                    <CustomInputNumber
                                        precision={2}
                                        name={'paid_amt'}
                                        label={'Paid Amount'}
                                        placed={'end'}
                                        disabled={true}
                                    />
                                </Col>
                            </CustomRow>
                        </Col>
                        <Col span={24}>
                            <CustomRow gutter={[12, 12]}>
                                <Col sm={6} span={0}></Col>

                                <Col sm={6} span={6} style={{ display: 'flex', width: '100%', alignItems: 'center', }}>
                                    <CustomRow gutter={[12, 12]}>
                                        <Col span={12}>
                                        </Col>
                                        <Col span={12}>
                                        </Col>
                                    </CustomRow>
                                </Col>
                                {/* <Col md={12} span={18}>
                                    <CustomInputNumber
                                        precision={2}
                                        name={'balance'}
                                        label={'Balance'}
                                        placed={'end'}
                                        disabled={true}
                                    />
                                </Col> */}
                            </CustomRow>
                        </Col>
                    </CustomRow>
                </Col >
            </CustomRow >
        </Fragment>
    )
}