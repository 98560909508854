import React from 'react'
import AddProfile from './Partials/AddProfile'
import { CustomCardView } from '../../../components/CustomCardView'
import { CustomPageTitle } from '../../../components/CustomPageTitle'
import Flex from '../../../components/Flex'
import { CustomLableBack } from '../../../components/CustomLableBack'

const AddBusinessProfile = () => {
    return (
        <div>
            <Flex alignCenter>
                <CustomPageTitle Heading={'Business Profile'} />
            </Flex>
            <CustomCardView>
                <AddProfile />
            </CustomCardView>
        </div>
    )
}

export default AddBusinessProfile