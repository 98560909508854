import { Col, Form } from 'antd'
import React, { Fragment, useEffect, useState } from 'react'
import { CustomPageFormSubTitle, CustomPageTitle } from '../../../../components/CustomPageTitle'
import { CustomRow } from '../../../../components/CustomRow'
import { CustomInput } from '../../../../components/Form/CustomInput'
import { CustomTextArea } from '../../../../components/Form/CustomTextArea'
import Label from '../../../../components/Form/Label'
import styled from 'styled-components'


 const SignImageHolder = styled.div`
    margin-right:10px;
    width:250px;
    height:70px;
    /* border:1px solid; */
    position:relative;
     
     & img{
        position:absolute;
        height: 100%;
        width: 100%;
        object-fit:contain;
     }
`


const ViewProfile = ({ Profiledata, Trigger }) => {

  const [form] = Form.useForm()

  useEffect(() => {
    form.setFieldsValue(Profiledata)
  }, [Profiledata, Trigger])

  const ViewImg = styled.div`
      width: 262px;
      height: 91px;
      margin-left: -71px;
      position: relative;
  `                           
  return (
    <Fragment>
      <Form
        form={form}
        labelCol={{
          span: 24,
        }}
        wrapperCol={{
          span: 24,
        }}
        autoComplete="off">

        <CustomRow space={[24, 24]}>
          <Col span={24} md={12}>
            <Col span={24} md={24}>
              <CustomInput label={'Business Name'} name={'business_name'} placeholder={'Enter Business Name'}
                rules={[
                  {
                    required: true,
                    message: 'Please Enter Your Business!',
                  }
                ]} />
            </Col>

            <Col span={24} md={24}>
              <CustomInput label={'GST IN'} name={'gst_no'} placeholder={'Enter GST IN'}
                rules={[
                  {
                    required: true,
                    message: 'Please Enter GST IN!',
                  }
                ]} />
            </Col>

            <Col span={24} md={24}>
              <CustomInput label={'Phone No'} name={'phone_no'} placeholder={'Enter Phone No'}
                rules={[
                  {
                    required: true,
                    message: 'Please Enter Phone No!',
                  }
                ]} />
            </Col>

            <Col span={24} md={24}>
              <CustomInput label={'Email Id'} name={'email'} type={'email'} placeholder={'Enter Email Id'}
                rules={[
                  {
                    required: true,
                    message: 'Please Enter Email Id!',
                  }
                ]} />
            </Col>
          </Col>
          <Col span={24} md={12}>
            <Col span={24} md={24}>
              <Label>Company Logo</Label><br /><br />
              <SignImageHolder>
                <ViewImg>
                  <img src={Profiledata.company_logo} alt="companylogo" />
                </ViewImg>
              </SignImageHolder>
            </Col>
          </Col>
        </CustomRow><br />


        <CustomPageFormSubTitle Heading={'Business Details'} /><br />

        <CustomRow space={[24, 24]}>
          <Col span={24} md={12}>

            <Col span={24} md={24}>
              <CustomTextArea label={'Business Address'} name={'business_address'} placeholder={'Enter Business Address'}
                rules={[
                  {
                    required: true,
                    message: 'Please enter Business address',
                  },
                ]} />
            </Col>

            <Col span={24} md={24}>
              <CustomInput label={'Pincode'} name={'pincode'} placeholder={'Enter Pincode'}
                rules={[
                  {
                    required: true,
                    message: 'Please Enter Pincode!',
                  }
                ]} />
            </Col>

            <Col span={24} md={24}>
              <CustomInput label={'State'} name={'state'} placeholder={'Enter State'}
                rules={[
                  {
                    required: true,
                    message: 'Please Enter State!',
                  }
                ]} />
            </Col>

            <Col span={24} md={24}>
              <CustomTextArea label={'Business Description'} name={'business_description'} placeholder={'Enter Business Description'}
                rules={[
                  {
                    required: true,
                    message: 'Please enter Business Description',
                  },
                ]} />
            </Col>
          </Col>

          <Col span={24} md={12}>
            <Col span={24} md={24}>
              <Label>signature</Label><br /><br />
              <SignImageHolder>
                <ViewImg>
                  <img src={Profiledata.signature} alt="signature" />
                </ViewImg>
              </SignImageHolder>
            </Col>
          </Col>

        </CustomRow>
      </Form>
    </Fragment>

  )
}

export default ViewProfile