import { Col, Form } from 'antd'
import React, { useEffect } from 'react'
import { CustomRow } from '../../../components/CustomRow';
import { CustomInput } from '../../../components/Form/CustomInput';
import { CustomTextArea } from '../../../components/Form/CustomTextArea';
import Flex from '../../../components/Flex';
import Button from '../../../components/Form/CustomButton';
import { toast } from 'react-toastify';
import request from '../../../utils/request';
import { useDispatch } from 'react-redux';
import { getSubCategory } from '../../Product/ProductSlice';

export const EditSubCategory = ({ subcategoryhandleOk, subcategoryrecord ,subCategorytrigger}) => {

    const [form] = Form.useForm()

    const EDIT__SUBCATEGORY_URL = 'product/edit_subcategory'

    const dispatch = useDispatch()

    useEffect(() => {
        form.resetFields();
    }, [])

    useEffect(() => {
        form.setFieldsValue(subcategoryrecord)
        form.setFieldsValue({main_category:subcategoryrecord?.main_category})
    }, [subcategoryrecord,subCategorytrigger])

    const onClose = () => {
        subcategoryhandleOk();
    }

    const UpdateCategory = (values) => {
        request.patch(`${EDIT__SUBCATEGORY_URL}/${subcategoryrecord?.id}/`,values)
            .then(function (response) {
                toast.info("Sub Category Updated Successfully")
                dispatch(getSubCategory())
                subcategoryhandleOk()
            })
            .catch(function (error) {
                if (error.response.status === 406) {
                    toast.warn("Please Add business Profile!")
                } 
                else if(error.response.status === 302){
                    toast.warn("Limit Exceeded!")
                }
                else{
                    toast.error('Failed')
                }
            });
    }

    const onFinish = (values) => {

        UpdateCategory(values)
    };

    const onFinishFailed = (errorInfo) => {
        toast.warn("Please fill the details!")
    };

    return (
        <Form
            name='AddUnit'
            form={form}
            labelCol={{
                span: 24,
            }}
            wrapperCol={{
                span: 24,
            }}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            autoComplete="off">
            <CustomRow space={[24, 24]}>
                <Col span={24} md={24}>
                    <CustomInput label={'Sub Category'} placeholder={'Enter Sub Category'} name={'subcategory'}
                        rules={[
                            {
                                required: true,
                                message: 'Please Enter Sub Category!',
                            }
                        ]}
                    />
                    <CustomInput name={'main_category'} display={'none'}/>
                </Col>

                <Col span={24} md={24}>
                    <CustomInput label={'Sub Category Code'} placeholder={'Code'} name={'subcategory_code'}
                    />
                </Col>

                <Col span={24} md={24}>
                    <CustomTextArea label={'Description'} name={'subcategory_description'}
                        placeholder={'Description'} />
                </Col>
            </CustomRow>
            <Flex center gap={'20px'} style={{ margin: '30px' }}>
                <Button.Primary text={'UPDATE'} htmlType={'submit'} />
                <Button.Danger text={'CANCEL'} onClick={onClose} />
            </Flex>
        </Form>
    )
}
