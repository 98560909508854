import React, { useState } from 'react'
import { CustomPageTitle } from '../../components/CustomPageTitle'
import { CustomCardView } from '../../components/CustomCardView'
import {  PurchaseReturnEntryPage } from './Partials/ReturnEntryPage'

const PurchaseReturn = () => {

    return (
        <div>
            <CustomPageTitle Heading={'Purchase Return'} />
            <CustomCardView>
                <PurchaseReturnEntryPage />
            </CustomCardView>

        </div>
    )
}

export default PurchaseReturn