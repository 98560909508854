import React, { useState } from 'react';
import { BodyContent, HeaderNav, MainContent, MainLayout, SideMenuLayout, TopHeader, } from './Partials/Style'
import { SideMenu } from './Partials/SideMenu';
import { NavHeader } from './Partials/NavHeader';
import { Layout, Menu, theme, Button, Drawer } from 'antd';
import {
    MenuFoldOutlined,
    MenuUnfoldOutlined,
    UploadOutlined,
    UserOutlined,
    VideoCameraOutlined,
} from '@ant-design/icons';
import Logo from '../../src/Images/logo -Hi Biller.png'
import styled from 'styled-components';

const LogoDrawer = styled.div`
  display: flex;
  align-items: center;

  cursor: pointer;
  & img{
    padding: 20px;
    
    width: 160px;
  }

`


const LogoPlace = styled.div`
  display: flex;
  align-items: center;

  cursor: pointer;
  & img{
    width: 120px;
  }



`


export const DashLayout = ({ children }) => {

    const [collapse, setCollapse] = useState(false)

    const updateCollapse = () => {
        setCollapse(!collapse)
    }

    const [collapsed, setCollapsed] = useState(false);
    const {
        token: { colorBgContainer },
    } = theme.useToken();

    const [open, setOpen] = useState(false);
    const [placement, setPlacement] = useState('left');
    const showDrawer = () => {
        setOpen(true);
    };
    const onClose = () => {
        setOpen(false);
    };
    const onChange = (e) => {
        setPlacement(e.target.value);
    };

    return (
        <MainLayout >

            <Layout>
                <SideMenuLayout width={'280'} trigger={null} collapsible collapsed={collapsed}>
                    <div style={{ boxShadow: '0 0 4px 4px rgba(0, 0, 0, 0.08)', height: '73px', padding: '14px 0' }}>
                        <HeaderNav>
                            <Button
                                type="text"
                                icon={collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
                                onClick={() => setCollapsed(!collapsed)}
                            />
                            <div>
                                {
                                    collapsed ? null :
                                        // <h3 style={{ fontSize: '20px', marginLeft: '10px' }}>Hi&nbsp;-&nbsp;Biller</h3>
                                        <LogoPlace>
                                            &nbsp;&nbsp;&nbsp;&nbsp;<img src={Logo} alt="Logo" />
                                        </LogoPlace>

                                    // <p style={{ transition: '0.5s', overflow: 'hidden' }}>
                                    // <img src={Logo} />
                                    // </p>
                                }
                            </div>

                        </HeaderNav>
                    </div>
                    <SideMenu collapsed={collapsed} />
                </SideMenuLayout>

                <Drawer
                    placement={placement}
                    closable={false}
                    onClose={onClose}
                    open={open}
                    key={placement}
                    width={250}
                >
                    <LogoDrawer>
                    <img src={Logo} alt="Logo" />
                    </LogoDrawer>
                
                    <SideMenu collapsed={collapsed} />
                </Drawer>
                <Layout>
                    <TopHeader
                        style={{
                            padding: ' 0 15px',
                            background: colorBgContainer,
                        }}
                    >
                        <NavHeader updateCollapse={updateCollapse} showDrawer={showDrawer} />
                    </TopHeader>
                    <BodyContent
                        style={{
                            margin: '1px 1px',
                            padding: 24,
                            // minHeight: 280,
                            // background: colorBgContainer,
                        }}
                    >
                        {children}
                    </BodyContent>
                </Layout>
            </Layout>
        </MainLayout>
    )
};