import React, { useEffect } from 'react'
import styled from 'styled-components'
import Flex from '../../../components/Flex'
import SignInForm from './SignInForm'
import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { setCredentials, selectCurrentUser } from '../authSlice'
import request, { baseRequest } from '../../../utils/request'
import { toast } from 'react-toastify'
import { THEME } from '../../../theme'
import { useState } from 'react'


export const Wrapper = styled(Flex)`
  height: 100vh;
  width: 100%;
  background: whitesmoke;
`
const SignInCard = styled.div`
  background-color:${THEME.primary_color_dark};
  backdrop-filter:blur(1px);
  padding: 40px 32px;
  border-radius:0px 40px 0px 40px;
  max-width: 450px;
  width: 100%;
  margin: auto;
  height: 50%;
  border: 2px solid #949292;
`
const UserSignin = () => {

  const [isLoading, setIsLoading] = useState(false)
  const navigate = useNavigate()
  const dispatch = useDispatch();
  const handleSignIn = async (data) => {
    setIsLoading(true)
    try {
      const authData = await baseRequest.post(`user/login`, {
        ...data,
      })
      console.log(authData, 'authData');
      // Mock API, add the origin API and payload data
      if (authData?.data !== '') {
        toast.success(`Login Successful, Welcome ${authData?.data?.username}`)

        localStorage.setItem('persist', JSON.stringify(authData?.data))
        dispatch(setCredentials(authData?.data))
        navigate('/')
      }
      else {
        toast.error('UserName or Password is incorrect ')
      }
    } catch (error) {
      toast.error('Getting error while login, Please Login Again')
      console.error('Getting error while login', error)
      setIsLoading(false)
    }finally{
      setIsLoading(false)
    }
  }
  const token = useSelector(selectCurrentUser);

  useEffect(() => {
    if (token) {
      // if()
      navigate('/signin')
    }
  }, [token])
  return (
    <Wrapper column>
      <SignInForm handleSignIn={handleSignIn} isLoading={isLoading}/>
    </Wrapper>
  )
}
export default UserSignin