import { Divider, Dropdown, Menu } from "antd";
import { useLocation, useNavigate, useRoutes } from 'react-router-dom';
import { AiOutlineBell, AiOutlineDashboard, AiOutlineFundView, AiOutlineStock, AiOutlineTags } from "react-icons/ai";
import { FaUserTie } from 'react-icons/fa'
import { useEffect, useState } from "react";
import { FaRegUserCircle } from "react-icons/fa";
import { BsEnvelopePaper } from "react-icons/bs";
import { FiLogOut } from "react-icons/fi";
import styled from "styled-components";
import profile from "../../Images/logshow.png"

import { useDispatch, useSelector } from "react-redux";
import {
    VideoCameraOutlined,
} from '@ant-design/icons';

import { LiaCalendarDaySolid } from 'react-icons/lia'

import Flex from "../../components/Flex";
import { BiLogoUnity, BiMessageAltAdd, BiSearchAlt, BiUserCircle } from 'react-icons/bi'
import { BsBoxSeam, BsChatSquareText } from 'react-icons/bs'
import { MdKeyboardArrowDown, MdOutlineEditAttributes, MdOutlineRequestQuote, MdProductionQuantityLimits, MdWorkOutline } from 'react-icons/md'
import { HiOutlineNewspaper } from 'react-icons/hi'
import { GiExpense } from 'react-icons/gi'
import { RiBillLine, RiContactsBookUploadLine } from 'react-icons/ri'
import { TbBrandAdobe, TbCategory, TbMessageReport, TbReportSearch } from "react-icons/tb";
import { PiShoppingCartSimpleBold, PiUsersThreeBold } from 'react-icons/pi'
import { AntdStyledMenu, BtnProfile, Userchoice } from '../Partials/Style'

import Button from "../../components/Form/CustomButton";
import { CustomModal } from "../../components/CustomModal";
// import { logOut } from "../../modules/Auth/authSlice";
import { Notifications } from "../../modules/Notifications/Partials/Notifications";
import { logOut, selectCurrentUser } from "../../modules/Auth/authSlice";
import { selectAllProfile } from "../../modules/BusinessProfile/BusinessSlice";



function getItem(label, key, icon, children, type) {
    return {
        key,
        icon,
        children,
        label,
        type,
    };
}

const Profile = styled.div`
display: flex;
align-items: center;
margin: 20px 0 10px 0px;
gap: 10px;
padding: 5px 20px;


`


const ImageProfile = styled.div`
/* width:100%; */
    & div{
        width: 50px; 
        height: 50px;
        border-radius: 50%; 
        overflow: hidden; 
    }
    & img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border: 1px solid #dededec7;
    }
    }
`

const MenuText = styled.div`
   font-size: 16px;
   color: #545454;
   padding-left: 7px;
`

export const SideMenu = ({ collapsed }) => {

    const [profileImg, setProfileImg] = useState({})

    // ======  Modal Open ========
    const [isModalOpen, setIsModalOpen] = useState(false);

    // ======  Modal Title and Content ========
    const [modalTitle, setModalTitle] = useState("");
    const [modalContent, setModalContent] = useState(null);

    const [openKeys, setOpenKeys] = useState([]);
    const [activeTab, setActiveTab] = useState('')

    const route = useLocation()
    useEffect(() => {
        const pathname = route.pathname;
        const parts = pathname.split('/');
        const lastPart = parts[1];
        setActiveTab(lastPart)
        const storedOpenKeys = JSON.parse(localStorage.getItem('openKeys'));
        if (storedOpenKeys) {
            setOpenKeys(storedOpenKeys);
        }
    }, [route])

    const dispatch = useDispatch()

    const showModal = () => {
        setIsModalOpen(true);
    };

    const handleCancel = () => {
        setIsModalOpen(false);
    };


    const rootSubinmenuKeys = ['sub1', 'sub2', 'sub3', 'sub4'];

    // ===========  Set Profile Img =======

    const ProfileImgSet = useSelector(selectAllProfile)

    useEffect(() => {
        setProfileImg(ProfileImgSet?.company_logo)
    }, [ProfileImgSet])


    const onOpenChange = (keys) => {
        // Store the open keys in local storage
        const latestOpenKey = keys.find((key) => openKeys.indexOf(key) === -1);
        if (rootSubinmenuKeys.indexOf(latestOpenKey) === -1) {
            localStorage.setItem('openKeys', JSON.stringify(keys));
            setOpenKeys(keys);
        } else {
            localStorage.setItem('openKeys', JSON.stringify(latestOpenKey ? [latestOpenKey] : []));
            setOpenKeys(latestOpenKey ? [latestOpenKey] : []);
        }
    };


    const ProfileName = useSelector(selectCurrentUser)

    const items = [
        getItem('Dashboard', '', <AiOutlineDashboard />),

        getItem(<MenuText>{collapsed ? null : 'Main'}</MenuText>, 'mainMenu', null,
            [
                // getItem('Business Profile', 'addprofile', <BiUserCircle />),

                getItem('Service', 'viewservice', <MdWorkOutline />),


                getItem('Product', 'sub1', <BsBoxSeam />, [
                    // getItem('Sale', 'addsale', <BiMessageAltAdd />),
                    getItem('Product', 'product', <BsBoxSeam />),
                    getItem('Stock', 'stock', <AiOutlineStock />),
                    // getItem('Sale Return', 'addsalereturn', <BiMessageAltAdd />),
                    getItem('Units', 'ViewUnit', <BiLogoUnity />),
                    getItem('Brands', 'ViewBrand', <TbBrandAdobe />),
                    getItem('Category', 'ViewCategory', <TbCategory />),
                    getItem('Variations', 'ViewVariations', <MdOutlineEditAttributes />)

                ]),

                getItem('Party', 'party', <PiUsersThreeBold />),

                getItem('Expense', 'sub2', <GiExpense />, [
                    getItem('Expense', 'expense', <GiExpense />),
                    getItem('Expense Category', 'expense_category', <TbCategory />),
                ]
                )

            ], 'group'),

        getItem(<MenuText>{collapsed ? null : 'Billing'}</MenuText>, 'billingMenu', null,
            [

                // getItem('Sale', 'sub3', <AiOutlineTags />, [
                // getItem('Sale', 'addsale', <BiMessageAltAdd />),
                getItem('Sale', 'viewsale', <AiOutlineTags />),
                // getItem('Sale Return', 'addsalereturn', <BiMessageAltAdd />),
                // getItem('Sale Return', 'viewsalereturn', <AiOutlineFundView />),
                // ]),

                // getItem('Purchase', 'sub4', <PiShoppingCartSimpleBold />, [
                // getItem('Purchase', 'addpurchase', <BiMessageAltAdd />),
                getItem('Purchase', 'viewpurchase', <MdProductionQuantityLimits />),
                // getItem('Purchase Return', 'addpurchasereturn', <BiMessageAltAdd />),
                // getItem('Purchase Return', 'viewpurchasereturn', <AiOutlineFundView />),
                // ]),


                getItem('Quotation', 'viewquotation', <RiBillLine />),

                // getItem('Quotation', 'sub3', <HiOutlineNewspaper />, [
                //     getItem('Quotation', 'viewquotation', <BiMessageAltAdd />),
                //     getItem('View Quotation', 'viewquotation', <RiContactsBookUploadLine />),
                // ])
            ], 'group'),

        getItem(<MenuText>{collapsed ? null : 'Details'}</MenuText>, 'detailsMenu', null,
            [
                getItem('Payment In', 'unpaidtab', <MdOutlineRequestQuote />),

                getItem('DayBook', 'daybook', <LiaCalendarDaySolid />),

                getItem('Report', 'reporttab', <TbReportSearch />),
            ], 'group'),
    ];

    const navigate = useNavigate();

    const onClick = ({ key }) => {

        if (key === null) {
        }
        else {
            navigate(`${key}/`)
        }
    }

    const handleOk = () => {
        setIsModalOpen(false);
    };


    const SearchDatas = styled.div`
    display: flex;
    align-items: center;
    gap: 35px;
    margin-left: 30px;
    color: #038fe0;
    `

    const Signout = () => {
        dispatch(logOut());

    }


    const LogOutModal = () => (
        <div>
            <h1 style={{ fontSize: '1.2rem' }}>Are you Sure You Want to Logout ?</h1>
            <br />
            <Flex gap={'20px'} W_100 center verticallyCenter>
                <Button.Success text={'Logout'} onClick={Signout} />
                <Button.Danger text={'Cancel'} onClick={handleOk} />
            </Flex>
        </div>
    )

    const handleClick = () => {
        navigate('/addprofile');
    };


    const AdminLogOut = () => {
        setModalContent(<LogOutModal />);
        setModalTitle("Log Out");
        showModal();
    }

    return (
        <>
            <Profile>
                {/* <Dropdown overlay={userMenu} trigger={['click']} placement="bottomLeft" arrow={{ pointAtCenter: true }}> */}

                {/* <a onClick={(e) => e.preventDefault()}> */}
                {/* {!collapsed && ( */}
                <Flex alignCenter>
                    <ImageProfile>
                        {ProfileImgSet?.length === 0 || ProfileImgSet?.company_logo === null ?

                           <div>
                             <img src={profile} alt="Profile" />
                           </div>
                            :
                           <div>
                             <img src={profileImg} alt="Profile"/>
                           </div>

                        }
                    </ImageProfile>
                    {!collapsed && (
                        <>
                            <p
                                style={{
                                    transition: '0.5s',
                                    overflow: 'hidden',
                                    textOverflow: 'ellipsis',
                                    lineHeight: '1.3',
                                    textTransform: 'capitalize',
                                }}
                            >
                                &nbsp;&nbsp;&nbsp;&nbsp;{ProfileName}
                            </p>
                            &nbsp;
                            {/* <MdKeyboardArrowDown /> */}
                        </>
                    )}

                </Flex>
                {/* )} */}
                {/* </a> */}
                {/* </Dropdown> */}
            </Profile>
            {/* {
                collapsed ? null :
                    <SearchDatas>
                        <BiSearchAlt style={{ fontSize: '23px' }} />
                        <AiOutlineBell style={{ fontSize: '23px' }} onClick={ViewNotification} />
                        <BsChatSquareText style={{ fontSize: '23px' }} />
                    </SearchDatas>
            }

            <div style={{ margin: 'auto', width: '82%' }}>
                <Divider style={{ background: 'rgb(232 232 232 / 11%)' }} />
            </div> */}
            <div style={{ minHeight: 'calc(100vh - 165px)', overflowY: 'auto', position: 'relative' }}>

                <Menu style={{ color: '#545454', position: 'absolute', width: '100%' }}
                    onClick={onClick}
                    openKeys={openKeys}
                    onOpenChange={onOpenChange}
                    // defaultSelectedKeys={['1']}
                    selectedKeys={[activeTab]}
                    mode="inline"
                    items={items}
                />
            </div>
            <CustomModal isVisible={isModalOpen} handleOk={handleOk} handleCancel={handleCancel}
                width={400}
                modalTitle={modalTitle} modalContent={modalContent} />
        </>
    )
}

