import { Checkbox, Col, Divider, Form } from 'antd'
import React, { Fragment, useEffect, useState } from 'react'
import Flex from '../../../../components/Flex'
import Button from '../../../../components/Form/CustomButton'
import dayjs from 'dayjs'
import { CustomDatePicker } from '../../../../components/Form/CustomDatePicker'
import { CustomInput } from '../../../../components/Form/CustomInput'
import { CustomRow } from '../../../../components/CustomRow'
import request from '../../../../utils/request'
import { toast } from 'react-toastify'
import { CustomInputNumber } from '../../../../components/Form/CustomInputNumber'

const SaleReturnPay = ({ SaleReturnRecord, FormUpdate, trigger }) => {

    const [form] = Form.useForm()

    const SALE_UNPAID_URL = 'sale_pay/add_salereturn_unpaid/'

    const [selectedDates, setSelectedDates] = useState(dayjs().format('YYYY-MM-DD'));
    const [formRecall, setFormRecall] = useState(0)

    const [amountData, setAmountData] = useState(false)

    const CheckboxGroup = Checkbox.Group;
    const plainOptions = ['View Details', 'Pay Bill'];
    // const defaultCheckedList = ['Pay Bill'];

    // const [checkedList, setCheckedList] = useState(defaultCheckedList);
    const [checkedList, setCheckedList] = useState([]);
    const checkAll = plainOptions.length === checkedList.length;
    const indeterminate = checkedList.length > 0 && checkedList.length < plainOptions.length;
    const onChange = (list) => {
        setCheckedList(list);
    };
    const onCheckAllChange = (e) => {
        setCheckedList(e.target.checked ? plainOptions : []);
    };

    useEffect(() => {
        form.resetFields()
    }, [trigger])

    const onFinish = (values) => {

        const NewValue = {
            ...values,
            payment_date: values?.payment_date === null ? '' : dayjs(selectedDates).format('YYYY-MM-DD') ? dayjs(values?.payment_date).format('YYYY-MM-DD') : dayjs(values?.payment_date).format('YYYY-MM-DD'),
            return_date: values?.return_date === null ? '' : dayjs(selectedDates).format('YYYY-MM-DD') ? dayjs(values?.return_date).format('YYYY-MM-DD') : dayjs(values?.return_date).format('YYYY-MM-DD'),
        }
        let NewValues = {
            sale_return: NewValue.sale_return,
            party_name: NewValue.party_name,
            return_no: NewValue.return_no,
            bill_date: NewValue.return_date,
            bill_amt: NewValue.bill_amt,
            payment_date: NewValue.payment_date,
            paid_amt: NewValue.paid_amt,
            amount: NewValue.amount,
            balance_amt: NewValue.balance_amt,
            party: NewValue.party,
        }
        PostUnpaid(NewValues)
    }

    useEffect(() => {
        form.setFieldsValue({ payment_date: dayjs() })
    }, [formRecall])

    useEffect(() => {
        const fromdatee = new Date(SaleReturnRecord?.invoice_date)
        const dateFormat = 'YYYY-MM-DD';
        const FrmDateee = dayjs(fromdatee).format(dateFormat);
        form.setFieldsValue({
            payment_date: dayjs(),
            invoice_date: dayjs(FrmDateee, dateFormat),
            sale: SaleReturnRecord?.id,
            party: SaleReturnRecord?.party,
            balance_amt: SaleReturnRecord?.balance,
            bill_amt: SaleReturnRecord?.grand_total,
            sale_return: SaleReturnRecord?.id,
            party: SaleReturnRecord?.party,

        })
        form.setFieldsValue(SaleReturnRecord)
    }, [formRecall, SaleReturnRecord, trigger])


    const onFinishFailed = (errorInfo) => {
        toast.warn("Please fill the details!")
    };

    const PostUnpaid = (values) => {
        request.post(`${SALE_UNPAID_URL}`, values)
            .then(function (response) {
                toast.success("Successfully Paid!")
                form.resetFields();
                FormUpdate()
            })
            .catch(function (error) {
                if (error.response.status === 302) {
                    toast.warn("The free plan is over, so please subscribe to a plan.!")
                }
                else if (error.response.status === 410) {
                    toast.warn("Your plan has to be valid. Please subscribe to your plan!")
                }
                else {
                    toast.error("Something Went Wrong")

                }
            }
            )
    }

    const handleAmountChange = () => {
        const Amount = parseFloat(form.getFieldValue('amount'))
        const Balance = parseFloat(form.getFieldValue('balance_amt'))
        if (Amount > Balance) {
            toast.warn('Amount Not greater than Balance!')
            setAmountData(true);
            setFormRecall(formRecall + 1)
        }
        else {
            setAmountData(false);
        }
    }

    const onReset = () => {
        const requiredFields = ['amount', 'payment_date'];
        form.resetFields(requiredFields);
        setFormRecall(formRecall + 1)
    }

    const Datechange = (date) => {
        setSelectedDates(date)
    }
    return (
        <Fragment>
            <Form
                form={form}
                labelCol={{
                    span: 24,
                }}
                wrapperCol={{
                    span: 24,
                }}
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                autoComplete="off">
                <CustomRow space={[24, 24]}>
                    <Col span={24} md={12}>
                        <CustomInput label={'Party Name'} name={'party_name'} placeholder={'Enter Party Name'}
                            disabled={true}
                            rules={[
                                {
                                    required: true,
                                    message: 'Please Enter Party Name!',
                                }
                            ]} />
                        <CustomInput name={'party'} display={'none'} />
                    </Col>
                    <Col span={24} md={12}>
                        <CustomInput label={'Balance Amount'} name={'balance_amt'} placeholder={'Enter Balance Amount'}
                            disabled={true}
                            rules={[
                                {
                                    required: true,
                                    message: 'Please Enter Balance Amount!',
                                }
                            ]} />
                    </Col>
                    <Col span={24} md={12}>
                        <Checkbox indeterminate={indeterminate} onChange={onCheckAllChange} checked={checkAll}>
                            Check all
                        </Checkbox>
                        <Divider />
                        <CheckboxGroup options={plainOptions} value={checkedList} onChange={onChange} />
                    </Col>

                    {checkedList.includes('View Details') && (
                        <>

                            <Col span={24} md={12}>
                                <CustomInput label={'Return Number'} name={'return_no'} placeholder={'Enter Return Number'}
                                    disabled={true}
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please Enter Return Number!',
                                        }
                                    ]} />
                                <CustomInput name={'sale_return'} display={'none'} />

                                <CustomInput label={'Return Date'} name={'return_date'} placeholder={'Enter Return Date'}
                                    disabled={true}
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please Enter Return Date!',
                                        }
                                    ]} />

                                <CustomInput label={'Bill Amount'} name={'bill_amt'} placeholder={'Enter Bill Amount'}
                                    disabled={true}
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please Enter Bill Amount!',
                                        }
                                    ]} />

                                <CustomInput label={'Paid Amount'} name={'paid_amt'} placeholder={'Enter Paid Amount'}
                                    disabled={true}
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please Enter Paid Amount!',
                                        }
                                    ]}
                                />
                            </Col>
                        </>
                    )}

                    {checkedList.includes('Pay Bill') && (
                        <>
                            <Col span={24} md={12}>
                                <CustomDatePicker label={'Payment Date'} name={'payment_date'} placeholder={'Enter Payment Date'}
                                    onChange={Datechange}
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please Enter Payment Date!',
                                        }
                                    ]} />

                                <CustomInputNumber label={'Amount'} name={'amount'} placeholder={'Enter Amount'}
                                    onChange={handleAmountChange}
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please Enter Amount!',
                                        }
                                    ]}

                                    onKeyPress={(e) => {
                                        if (!/^\d$/.test(e.key) && e.key !== 'Backspace') {
                                            e.preventDefault();
                                        }
                                    }} />
                            </Col>


                            <CustomInput name={'sale'} display={'none'} />
                        </>
                    )}

                </CustomRow>

                <Flex center={'true'} gap={'20px'} margin={'20px 0px'}>
                    <Button.Primary text={'SAVE'} htmlType={'submit'} disabled={amountData} />
                    <Button.Danger text={'RESET'} onClick={() => onReset()} />
                </Flex>
            </Form>
        </Fragment>
    )
}

export default SaleReturnPay