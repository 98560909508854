import React, { useState } from 'react';
import { Col, Form, Spin, Typography } from 'antd';
import { CustomRow } from '../../../components/CustomRow';
import { CustomInput } from '../../../components/Form/CustomInput';
import Flex from '../../../components/Flex';
import ButtonStandard from '../../../components/Form/CustomStandardButton';
import { CustomInputPassword } from '../../../components/Form/CustomInputPassword';
import { toast } from 'react-toastify';
import { baseRequest } from '../../../utils/request';
import { useNavigate } from 'react-router-dom';
import { CustomPageTitle } from '../../../components/CustomPageTitle';
import { CustomOtp } from '../../../components/Form/CustomOtp';
import styled from 'styled-components';
import LoginImage from '../../../Images/login-img.png'
import { LoadingOutlined } from '@ant-design/icons';

const LeftSide = styled.div`
 display: flex;
  justify-content: center;
  height: 450px;


 & img{
    /* position: absolute;
    left:311px;
    top:190px; */
    margin-top: 100px;
    /* display: flex;
    justify-content: center; */
} 

@media (max-width: 768px) {    
      display: none;   
  }
`

export const RegisterMenu = () => {

    const [form] = Form.useForm();

    const navigate = useNavigate();

    const URLS = 'user/register';

    //Mobile

    const get_number = 'user/send_otp_for_verify_phone_number';
    const verify_number = 'user/verify_phone_number';
    const regenerate_otp = 'user/regenerate_otp_phone_number';

    const [isMobileNumberVerified, setIsMobileNumberVerified] = useState(false);
    const [isMobileNumberVerifieds, setIsMobileNumberVerifieds] = useState(false);
    const [showOtpField, setShowOtpField] = useState(false);
    const [otpButtonLabel, setOtpButtonLabel] = useState('Generate OTP');
    const [hideMobile, setHideMobile] = useState(false)

    //Email

    const get_email = 'user/send_otp_for_verify_email';
    const verify_email = 'user/verify_email';
    const regenerate_email_otp = 'user/regenerate_otp_email';

    const [emailTickVerified, setEmailTickVerified] = useState(false);
    const [emailVerified, setEmailVerified] = useState(false);
    const [emailOtpField, SetEmailOtpField] = useState(false);
    const [otpEmailLabel, setOtpEmailLabel] = useState('Generate OTP');
    const [hideEmail, setHideEmail] = useState(false)
    const [emailOtpLoading, setEmailOtpLoading] = useState(false)
    const [numberOtpLoading, setNumberOtpLoading] = useState(false)
    const [signInLoading, setSignInLoading] = useState(false)


    const onFinish = (values) => {

        const registerValues = {
            name: values?.name,
            email: values?.email,
            mobile_number: values?.mobile_number,
            password: values?.password,
            referal_code: values?.referal_code || null,
            signup_type: "Normal",
        }

        HandlePostPerson(registerValues);
    };


    const getNumber = async (values) => {
        const phoneNumber = values.mobile_number;
        if (!phoneNumber) {
            toast.error('Mobile number cannot be empty.');
            return;
        } else {
            setNumberOtpLoading(true)
        }

        try {
            let response;
            if (otpButtonLabel === 'Generate OTP') {
                response = await baseRequest.post(`${get_number}/${phoneNumber}/`, values);

                if (response.status === 201) {
                    toast.success('Number Verified Successfully');
                }
                if (response.status === 410) {
                    toast.success('Please Verify Mobile Number');
                }
                if (response.status === 400 && response.data.error === 'Mobile number already added') {
                    throw new Error('Mobile number already added');
                }

            } else {
                response = await baseRequest.patch(`${regenerate_otp}/${phoneNumber}/`, values);

                if (response.status === 201) {
                    form.resetFields(['otpmobile']);
                    toast.success('OTP Sent Successfully');
                    setShowOtpField(true);
                }
                if (response.status === 412) {
                    toast.warn('Enter a valid phone number');
                }
                if (
                    response.status === 400 &&
                    response.data.error === 'Maximum OTP generation attempts exceeded. Please try again later.'
                ) {
                    throw new Error('Maximum OTP generation attempts exceeded. Please try again later.');
                }
            }

            setShowOtpField(true);
            setOtpButtonLabel('Regenerate OTP');
            form.resetFields(['otpmobile']);
            console.log(response.data, 'kk');
            // toast.success('OTP Sent Successfully');
        } catch (error) {
            if (error.response.status === 412) {
                toast.warn('Enter a valid phone number');
            } else {
                toast.error('Something Went Wrong');
            }
            setNumberOtpLoading(false)
        } finally {
            setNumberOtpLoading(false)
        }
    };

    const handleFinish = (values) => {
        verifyNumber(values);
    };

    const verifyNumber = async (values) => {
        const phoneNumber = values.mobile_number;
        const Otp = values.otpmobile;

        try {
            const response = await baseRequest.post(`${verify_number}/${phoneNumber}/`, {
                otp: Otp,
            }
            );

            if (response.status === 410) {
                toast.success('Please Verify Mobile Number');
            }

            if (response.status === 200) {
                setIsMobileNumberVerified(true); // OTP verified successfully
                setHideMobile(true)
                toast.success("Number Verified Successfully");
                setShowOtpField(false);
                setIsMobileNumberVerifieds(true)
            } else {
                setIsMobileNumberVerified(false); // OTP verification failed
                toast.error("Wrong OTP");
            }

        } catch (error) {
            setIsMobileNumberVerified(false); // OTP verification failed
            toast.error("Re-Enter Correct OTP");
        }
    };


    const getEmail = async (values) => {
        const Email = values.email;

        if (!Email) {
            toast.error('Email cannot be empty.');
            return;
        } else {
            setEmailOtpLoading(true)
        }

        try {
            let response;
            if (otpEmailLabel === 'Generate OTP') {
                response = await baseRequest.post(`${get_email}/${Email}/`, values);

                if (response.status === 201) {
                    toast.success('Email Verified Successfully');
                }

                if (response.status === 302) {
                    toast.success('Email Id Already Exists');
                }

                if (response.status === 303) {
                    toast.success('Please Verify Email');
                }


                if (response.status === 400 && response.data.error === 'Email already added') {
                    throw new Error('Email already added');
                }
            } else {
                response = await baseRequest.patch(`${regenerate_email_otp}/${Email}/`, values);

                if (response.status === 201) {
                    form.resetFields(['otp']);
                    toast.success('OTP Sent Successfully');
                    SetEmailOtpField(true);
                }

                if (
                    response.status === 400 &&
                    response.data.error === 'Maximum OTP generation attempts exceeded. Please try again later.'
                ) {
                    throw new Error('Maximum OTP generation attempts exceeded. Please try again later.');
                }
            }

            SetEmailOtpField(true);
            setOtpEmailLabel('Regenerate OTP');
            form.resetFields(['otp']);
            console.log(response.data, 'kk');
            // toast.success('OTP Sent Successfully');
        } catch (error) {
            // console.log(error,'kkkkkkkkkkkkk');
            toast.error('Email Id Already exists!');
            setEmailOtpLoading(false)
        } finally {
            setEmailOtpLoading(false)
        }
    };

    const handleEmailFinish = (values) => {
        verifyEmail(values);
    };

    const verifyEmail = async (values) => {
        const Email = values.email;
        const Otp = values.otp;

        try {
            const response = await baseRequest.post(`${verify_email}/${Email}/`, {
                otp: Otp,
            }
            );

            if (response.status === 303) {
                toast.success('Please Verify Email');
            }

            if (response.status === 200) {
                setEmailTickVerified(true); // OTP verified successfully
                toast.success("Email Verified Successfully");
                SetEmailOtpField(false);
                setEmailVerified(true)
            } else {
                setEmailTickVerified(false); // OTP verification failed
                toast.error("Wrong OTP");
            }

        } catch (error) {
            setEmailTickVerified(false); // OTP verification failed
            toast.error("Re-Enter Correct OTP");
        }
    };

    const HandlePostPerson = (values) => {
        setSignInLoading(true)
        baseRequest
            .post(`${URLS}`, values)
            .then((response) => {
                if (response.status === 201) {
                    form.resetFields();
                    toast.success('Your Profile Added Successfully');
                    navigate('/signin')

                } else {
                    form.resetFields();
                    toast.warning('Email Id Already exists!');
                }
            })
            .catch((error) => {
                if (error.response) {
                    if (error.response.status === 400) {
                        if (error.response.data) {
                            toast.warn(error.response.data?.email[0]);
                        }
                    } else {
                        toast.error('Email Id Already exists!');
                    }
                } else if (error.response.status === 412) {
                    toast.error('Enter a valid phone number');
                } else if (error.request) {
                    toast.error('baseRequest Error');
                } else {
                    toast.error('Connection Error');
                }
                setSignInLoading(false)
            })
            .finally(() => {
                setSignInLoading(false)
            })

    };

    const onFinishFailed = () => { };

    return (
        <Form
            form={form}
            labelCol={{
                span: 24,
            }}
            wrapperCol={{
                span: 24,
            }}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            autoComplete="off"
        >
            <br></br>
            <Flex center>
                <CustomPageTitle Heading={'ENTER YOUR DETAILS'} />
            </Flex>
            <CustomRow space={[24, 24]} style={{ marginTop: '40px' }}>
                <Col span={24} md={12}>

                    <LeftSide>
                        <img src={LoginImage} alt="Login" />
                    </LeftSide>

                </Col>
                <Col span={24} md={12}>
                    <CustomRow space={[24, 24]} >
                        <Col span={24} md={2}></Col>
                        <Col span={24} md={16}>
                            <CustomRow space={[24, 24]} >

                                <Col span={24} md={24}>
                                    <CustomInput
                                        label={'Name'}
                                        placeholder={'Name'}
                                        name={'name'}
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Please Enter Name !',
                                            },
                                        ]}
                                    />
                                    <CustomInput
                                        name={'signup_type'} display={'none'} />
                                </Col>

                                <Col span={24} md={24}>
                                    {emailOtpField ?
                                        (<CustomInput
                                            label={'Email'}
                                            name={'email'}
                                            placeholder={'Enter Email'}
                                            disabled={true}
                                        />) :
                                        (
                                            <CustomInput
                                                label={'Email'}
                                                name={'email'}
                                                type={'email'}
                                                placeholder={'Enter Email'}
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: 'Please Enter Email !',
                                                    },
                                                ]}
                                            />
                                        )
                                    }

                                    {emailTickVerified ? (
                                        <Typography.Text style={{ color: 'green', marginLeft: '10px' }}>
                                            &#10004; Verified
                                        </Typography.Text>
                                    ) : null}
                                </Col>
                                {emailOtpField && (
                                    <Col span={24} md={12}>
                                        <CustomOtp label={'Fill your OTP here 👇'} name={'otp'} maxLength={6}
                                            onKeyPress={(event) => {
                                                if (!/[0-9]/.test(event.key)) {
                                                    event.preventDefault();
                                                }
                                            }}
                                            rules={[
                                                {
                                                    required: true,
                                                    message: 'Required *!',
                                                },
                                            ]}
                                        />
                                    </Col>
                                )}
                                <Col span={24} md={24}>
                                    <Flex>
                                        {emailVerified ?
                                            null
                                            :
                                            <>
                                                {
                                                    emailOtpLoading ?
                                                        <Spin
                                                            indicator={
                                                                <LoadingOutlined
                                                                    style={{
                                                                        fontSize: 20,
                                                                    }}
                                                                    spin
                                                                />
                                                            }
                                                        />
                                                        :
                                                        <ButtonStandard.Primary
                                                            text={otpEmailLabel}
                                                            onClick={() => getEmail(form.getFieldsValue())}
                                                        />
                                                }
                                            </>
                                        }

                                        {emailOtpField && (
                                            <ButtonStandard.Primary
                                                text={'Verify OTP'}
                                                onClick={(value) => handleEmailFinish(form.getFieldsValue())}
                                            />
                                        )}
                                    </Flex>
                                </Col>

                                <Col span={24} md={24}>
                                    {showOtpField ?
                                        (
                                            <CustomInput
                                                label={'Mobile No'}
                                                name={'mobile_number'}
                                                placeholder={'Enter Phone No'}
                                                maxLength={10}
                                                disabled={true}
                                            />
                                        )
                                        :
                                        (
                                            <CustomInput
                                                label={'Mobile No'}
                                                name={'mobile_number'}
                                                placeholder={'Enter Phone No'}
                                                maxLength={10}
                                                onKeyPress={(event) => {
                                                    if (!/[0-9]/.test(event.key)) {
                                                        event.preventDefault();
                                                    }
                                                }}
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: 'Please Enter Phone Number!',
                                                    },
                                                    {
                                                        min: 10,
                                                        message: 'Phone Number must be at least 10 characters!'
                                                    }
                                                ]}
                                            />
                                        )
                                    }

                                    {isMobileNumberVerified ? (
                                        <Typography.Text style={{ color: 'green', marginLeft: '10px' }}>
                                            &#10004; Verified
                                        </Typography.Text>
                                    ) : null}


                                </Col>
                                {showOtpField && (
                                    <Col span={24} md={12}>
                                        <CustomOtp label={'Fill your OTP here 👇'} name={'otpmobile'} maxLength={6}
                                            onKeyPress={(event) => {
                                                if (!/[0-9]/.test(event.key)) {
                                                    event.preventDefault();
                                                }
                                            }}
                                            rules={[
                                                {
                                                    required: true,
                                                    message: 'Required *!',
                                                },
                                            ]}
                                        />
                                    </Col>
                                )}
                                <Col span={24} md={24}>
                                    <Flex>
                                        {isMobileNumberVerifieds ?
                                            null
                                            :
                                            <>
                                                {
                                                    numberOtpLoading ?
                                                        <Spin
                                                            indicator={
                                                                <LoadingOutlined
                                                                    style={{
                                                                        fontSize: 20,
                                                                    }}
                                                                    spin
                                                                />
                                                            }
                                                        />
                                                        :
                                                        <ButtonStandard.Primary
                                                            text={otpButtonLabel}
                                                            onClick={() => getNumber(form.getFieldsValue())}
                                                        // disabled={emailTickVerifieds}
                                                        />
                                                }


                                            </>

                                        }

                                        {showOtpField && (
                                            <ButtonStandard.Primary
                                                text={'Verify OTP'}
                                                onClick={(value) => handleFinish(form.getFieldsValue())}
                                            />
                                        )}
                                    </Flex>
                                </Col>


                                <Col span={24} md={24}>
                                    <CustomInputPassword
                                        label={'Password'}
                                        placeholder={'Password'}
                                        type={'password'}
                                        name={'password'}
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Please Enter Password!',
                                            },
                                            // {
                                            //   min: 8, // Minimum password length
                                            //   message: 'Password must be at least 8 characters long',
                                            // },
                                            // {
                                            //   pattern: /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[@#$%^&+=!]).*$/,
                                            //   message: 'Password must include at least one uppercase letter, one lowercase letter, one number, and one special character (@#$%^&+=!)',
                                            // },
                                        ]}
                                    />
                                </Col>
                                {/* <Col span={24} md={24}>
                                    <CustomInput placeholder={'Referal Code'} name={'referal_code'}
                                    />

                                </Col> */}
                            </CustomRow>
                            <br></br>
                            <Flex center>
                                {
                                    signInLoading ?
                                        <Spin
                                            indicator={
                                                <LoadingOutlined
                                                    style={{
                                                        fontSize: 20,
                                                    }}
                                                    spin
                                                />
                                            }
                                        />
                                        :
                                        <ButtonStandard.Primary text={'Sign Up'} htmlType={'submit'} />
                                }
                            </Flex>
                        </Col>

                    </CustomRow>

                </Col>
            </CustomRow>
        </Form>
    );
};







// import React, { useState } from 'react'
// import { StyledLoginLeft, StyledRegisterMenu } from './style'
// import { StyledLogin } from './style'
// import { CustomCardView } from '../../../components/CustomCardView'
// import { Col, Form } from 'antd'
// import { CustomRow } from '../../../components/CustomRow'
// import { CustomInput } from '../../../components/Form/CustomInput'
// import Flex from '../../../components/Flex'
// import ButtonStandard from '../../../components/Form/CustomStandardButton'
// import { CustomInputPassword } from '../../../components/Form/CustomInputPassword'
// import { toast } from 'react-toastify'
// import baseRequest, { basebaseRequest } from '../../../utils/baseRequest'
// import { useNavigate } from 'react-router-dom'
// import Button from '../../../components/Form/CustomButton'

// const RegisterMenu = () => {

//     const [form] = Form.useForm()

//     const [check, setCheck] = useState(false)

//     const navigate = useNavigate()

//     const Navigatee = () => {
//         navigate('/signin')
//     }

//     const onChange = () => {
//         setCheck(!check)
//     }

//     const URLS = 'user/register'
//     const onFinish = values => {

//         const registerValues = {
//             name: values?.name,
//             email: values?.email,
//             password: values?.password,
//             referal_code: values?.referal_code || null
//         }
//         HandlePostPerson(registerValues)

//     }
//     const HandlePostPerson = (values) => {
//         basebaseRequest.post(`${URLS}`, values).then((response) => {
//             console.log(response.data);
//             if (response.status === 201) {
//                 form.resetFields();
//                 toast.success('Your Profile Added Successfully')
//                 navigate('/signin')
//             }
//             else if (response.status === 226) {
//                 toast.warning('Referral code not valid please check it!')
//             }
//             else {
//                 toast.warn('Email Id Already Exits')
//             }
//         }).catch(error => {
//             console.log(error);
//             if (error.response) {
//                 if (error.response.status === 400) {
//                     if (error.response.data) {
//                         toast.warn(error.response.data?.email[0]);
//                     }
//                 }
//                else if (error.response.status === 303) {

//                         toast.warn(error.response.data?.message);

//                 }
//                  else {
//                     toast.error('Something Went Wrong');
//                 }
//             }
//             else if (error.baseRequest) {
//                 console.log(error.baseRequest);
//                 toast.error('baseRequest Error');
//             } else {
//                 console.log('Error', error.message);
//                 toast.error('Connection Error');
//             }
//         });
//     }

//     const onFinishFailed = () => {

//     }

//     return (
//         <StyledRegisterMenu>
//             <CustomCardView>
//                 <Form
//                     form={form}
//                     labelCol={{
//                         span: 24,
//                     }}
//                     wrapperCol={{
//                         span: 24,
//                     }}
//                     onFinish={onFinish}
//                     onFinishFailed={onFinishFailed}
//                     autoComplete="off">
//                     <CustomRow>
//                         <Col span={24} md={10} style={{ background: '#28aaeb', display: "flex", justifyContent: "center", borderRadius: '10px 0px 0px 10px' }}>
//                             {/* <img src={log} alt='log' /> */}
//                             <StyledLoginLeft>
//                                 Hi Biller !!!
//                             </StyledLoginLeft>
//                         </Col>
//                         <Col span={24} md={14}>
//                             <StyledLogin>
//                                 <CustomRow space={[12, 12]}>
//                                     <Col span={24} md={24}>
//                                         <CustomInput placeholder={'Name'} name={'name'}
//                                             rules={[
//                                                 {
//                                                     required: true,
//                                                     message: 'Please Enter Name !',
//                                                 }
//                                             ]}
//                                         />
//                                     </Col>

//                                     <Col span={24} md={24}>
//                                         <CustomInput placeholder={'Email ID'} type={'email'} name={'email'}
//                                             rules={[
//                                                 {
//                                                     required: true,
//                                                     message: 'Please Enter Email ID !',
//                                                 }
//                                             ]}
//                                         />
//                                     </Col>

//                                     <Col span={24} md={24}>
//                                         <CustomInputPassword placeholder={'Password'} type={'password'} name={'password'}
//                                             rules={[
//                                                 {
//                                                     required: true,
//                                                     message: 'Please Enter Password!',
//                                                 }
//                                             ]} />

//                                     </Col>
//                                     <Col span={24} md={24}>
//                                         <CustomInput placeholder={'Referal Code'} name={'referal_code'}
//                                         // rules={[
//                                         //     {
//                                         //         required: true,
//                                         //         message: 'This is required Field',
//                                         //     }
//                                         // ]}
//                                         />

//                                     </Col>
//                                     {/* <Col span={24} md={24}><br/>
//                                         <Flex style={{ marginTop: "10px" }}>
//                                             <CustomCheckBox label={'by signing up , I confirm to proceed'} onChange={onChange} checked={check} />
//                                         </Flex>
//                                     </Col>
//                                     */}
//                                     <Flex style={{ marginTop: '10px' }}>
//                                         <ButtonStandard.Primary className={'ant-btn'} text={'Sign Up'} htmlType={'submit'} />
//                                         <div style={{ marginTop: "10px" }} >or</div> &nbsp; &nbsp;
//                                         <Button.Primary text={'Sign In'} onClick={Navigatee} style={{ height: '33px' }} />
//                                     </Flex>

//                                 </CustomRow>
//                             </StyledLogin>
//                         </Col>
//                     </CustomRow>
//                 </Form>
//             </CustomCardView>
//         </StyledRegisterMenu>
//     )
// }

// export default RegisterMenu