import React, { Fragment } from 'react'
import Flex from '../../../../components/Flex'
import { CustomPageTitle } from '../../../../components/CustomPageTitle'
import { CategoryNameModal } from './CategoryNameModal'
import { CustomCardView } from '../../../../components/CustomCardView'





export const CatdWithCard = () => {
    return (
        <Fragment>
            <Flex alignCenter>
                <CustomPageTitle Heading={'Expense'} />
            </Flex>
            <CustomCardView width={'400px'}>
                <CategoryNameModal />
            </CustomCardView>
        </Fragment>
    )
}
