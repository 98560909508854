import React from 'react'
import { CustomCardView } from '../../../components/CustomCardView'
import { CustomPageTitle } from '../../../components/CustomPageTitle'
import Flex from '../../../components/Flex'
import { Variation } from './Variation'

export const AddVariationwithCard = () => {
    return (
        <div>
            <CustomPageTitle Heading={'Add Variation'} />
            <Flex center={'true'}>
                <CustomCardView width={'600px'} >
                    <Variation />
                </CustomCardView>
            </Flex>
        </div>
    )
}
