import React from 'react'
import ReportQuotation from './Partials/ReportQuotation'

export const QuotationReportMain = () => {
    return (
        <div>
            <ReportQuotation />
        </div>
    )
}
