import styled from 'styled-components'


export const RemoveBtn = styled.div`
display: flex;
text-align:center;
justify-content:center;
cursor:pointer;
background: none;

&:hover {
    
    transform: scale(1.1); /* Zoom in effect */
  
}

`;

export const VarRemoveBtn = styled.div`
cursor:pointer;
background: none;
margin-top:30px;
margin-left:20px;

&:hover {
    
    transform: scale(1.1); /* Zoom in effect */
  
}

`;
