import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { Form, Spin } from 'antd'
import Flex from '../../../components/Flex'
import { CustomCardView } from '../../../components/CustomCardView'
import { Col } from 'antd'
import { CustomRow } from '../../../components/CustomRow'
import { CustomInput } from '../../../components/Form/CustomInput'
import ButtonStandard from '../../../components/Form/CustomStandardButton'
import { CustomInputPassword } from '../../../components/Form/CustomInputPassword'
import Button from '../../../components/Form/CustomButton'
import { Checkinpu, StyledLogin, StyledLoginMenu } from '../../LoginPage/Partials/style'
import { useLocation, useNavigate } from 'react-router-dom'
import { GoogleLogin } from '@react-oauth/google'
import jwt_decode from 'jwt-decode'
import { useDispatch } from 'react-redux'
import { LoadingOutlined } from '@ant-design/icons';
import Logo from '../../../Images/logshow.png'
import Leftsideimg from '../../../Images/Loginimg.png'


const LogImg = styled.div`
  & img {
    width: 40px !important;
    margin: 20px 30px;
  }
`

const SignInForm = ({ handleSignIn, isLoading }) => {


  const [check, setCheck] = useState(false)

  const [userEmail, setUserEmail] = useState('');

  const dispatch = useDispatch();


  const location = useLocation();
  const { email, Password } = location.state || {};

  useEffect(() => {
    form.setFieldsValue({ email: email?.email }) || form.setFieldsValue({ email: email })
    form.setFieldsValue({ password: Password });
  }, []);




  const googleConfig = {
    clientId: '176349257307-8277n57npt2tie23pssv9t5g4snbnrdb.apps.googleusercontent.com',
    redirectUri: 'https://dev.hibiller.com/auth/callback/google/',
  };

  const navigate = useNavigate();


  const onChange = () => {
    setCheck(!check)
  }


  const [form] = Form.useForm();

  const Navigatee = () => {
    navigate('/register')
  }

  const ForgotPassword = () => {
    navigate('/forgotPassword')
  }

  const handleGoogleLoginSuccess = (credentialResponse) => {
    const details = jwt_decode(credentialResponse.credential);
    const email = details.email;
    const givenname = details.given_name;


    navigate('/password', {
      state: { email, givenname },
    });
  };

  const onFinish = (values) => {
    handleSignIn(values);
  };


  const onFinishFailed = () => {

  }

  return (

    <StyledLoginMenu>
      <Form
        form={form}
        labelCol={{
          span: 24,
        }}
        wrapperCol={{
          span: 24,
        }}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        autoComplete="off">
        <CustomRow>
          <Col span={24} md={12} className='Imageleft'>
            <LogImg>
              <img src={Logo} alt='log' />
            </LogImg>
            <img src={Leftsideimg} alt='Img' className='Imagelffeft'/>
          </Col>
          <Col span={24} md={12} >
            <StyledLogin>
              <h1>SIGN IN</h1>
              <CustomRow space={[12, 12]}>
                <Col span={24} md={24}>
                  <h2>Email Id</h2>
                  <CustomInput placeholder={'Email ID'} type={'email'} name={'email'}
                    rules={[
                      {
                        required: true,
                        message: 'Please Enter Email ID !',
                      }
                    ]}
                  />
                </Col>

                <Col span={24} md={24}>
                  <h2>Password</h2>
                  <Checkinpu>
                  <CustomInputPassword placeholder={'Password'} type={'password'} name={'password'}
                    rules={[
                      {
                        required: true,
                        message: 'Please Enter Password !',
                      }
                    ]} />
                    </Checkinpu>
                </Col>
                <Col span={24} md={24} style={{ margin: '30px 0' }}>
                  {
                    isLoading ?
                      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '30px', width: '60px', marginTop: '3px' }}>
                        <Spin
                          indicator={
                            <LoadingOutlined
                              style={{
                                fontSize: 20,
                              }}
                              spin
                            />
                          }
                        />
                      </div>
                      :
                      <ButtonStandard.Primary style={{ width: '100%', padding: '20px 0', border: '0' }} text={'Sign In'} className='GGG' htmlType={'submit'} />
                  }
                </Col>
                <Button.Primary text={'Sign Up'} onClick={Navigatee} />

                {/* <Col span={24} md={12} style={{ marginTop: '20px' }}>               // Future update
                  <h5 onClick={ForgotPassword}>Forgot password</h5>
                </Col>
                <Col span={24} md={12} style={{ marginTop: '20px' }}>
                  <div>or connect with</div>
                </Col> */}
                {/* <GoogleLogin                                                        // Future update
                  config={googleConfig}
                  onSuccess={handleGoogleLoginSuccess}
                  onError={(error) => {
                  }}
                /> */}

              </CustomRow>

            </StyledLogin>
          </Col>
        </CustomRow>
      </Form>
      {/* </CustomCardView> */}
    </StyledLoginMenu>
  )
}

export default SignInForm
