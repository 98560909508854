import { Col, Form } from 'antd';
import React, { Fragment, useEffect, useState } from 'react';
import request from '../../../utils/request';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { CustomRow } from '../../../components/CustomRow';
import { CustomTextArea } from '../../../components/Form/CustomTextArea';
import { CustomInput } from '../../../components/Form/CustomInput';
import { CustomModal } from '../../../components/CustomModal';
import { CustomSelect } from '../../../components/Form/CustomSelect';
import { CustomDatePicker } from '../../../components/Form/CustomDatePicker';
import { CustomAddSelect } from '../../../components/Form/CustomAddSelect';
import { AddPartyForm } from '../../AddParty/Party/Partials/AddPartyForm';


export const SalesFormHeader = ({ setSelectedDate, setSelectedSale, trigger, gstChecked, SaleRecord }) => {

    const [dataSource, setDataSource] = useState([]);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [modalTitle, setModalTitle] = useState("");
    const [modalContent, setModalContent] = useState(null);
    const [width, setWidth] = useState(0)
    const [triggers, setTriggers] = useState(0);
    const [payterms, setPayterms] = useState(0)
    //=========Find Select part Fields ============//
    const [findParty, setFindParty] = useState(false)
    //=======
    const HEADER_PARTY_URL = 'party/add_party/'

    const dispatch = useDispatch()

    const onViewRow = () => {
        setTriggers(triggers + 1)
        setWidth(800)
        setModalTitle("Add Party");
        setModalContent(<AddPartyForm UpdateSalesParty={handleSalesParty} SaleHeaderTrigger={triggers} />);
        showModal();
    };

    const showModal = () => {
        setIsModalOpen(true);
    };

    const handleOk = () => {
        setIsModalOpen(false);
    };

    const handleCancel = () => {
        setIsModalOpen(false);
    };

    const handleSalesParty = () => {
        handleCancel();
        GetSaleParty();
    };

    useEffect(() => {
        if (trigger) {
            GetSaleParty();
        }
    }, [trigger]);

    useEffect(() => {
        GetSaleParty();
    }, []);

    const GetSaleParty = () => {
        request.get(`${HEADER_PARTY_URL}`)
            .then(function (response) {
                setDataSource(response.data);
            })
            .catch(function (error) {
            });
    };

    //=====================Customer name ===================================

    const SaleCompanies = dataSource?.map((com) => ({ label: com.party_name, value: com.id }));

    const handleSelectCompany = (value) => {
        const SelectedCompanyDetails = dataSource?.find((mem) => mem.id === value);
        setSelectedSale(SelectedCompanyDetails);
        if(SaleRecord){
        setFindParty(false)

        }
        else{
            setFindParty(value)

        }
    };

    //===================Date onChange fn======================//

    const handleOnChange = (date) => {
        setSelectedDate(date);
    };

    return (
        <Fragment>
            <CustomRow space={[24, 24]}>
                <Col span={24} md={16}>
                    <CustomRow space={[24, 24]}>
                        {SaleRecord ? 
                        <Col span={24} md={12}>
                            <CustomSelect options={SaleCompanies} onButtonClick={onViewRow}
                                showSearch={true} buttonLabel="Add Party"
                                onChange={handleSelectCompany} label={'Party Name'} name={'party_name'}
                                placeholder={'Party Name'} rules={[
                                    {
                                        required: true,
                                        message: 'Please Fill Details!',
                                    },
                                ]} />
                            <CustomInput name={'party'} display={'none'}  />
                        </Col> :
                            <Col span={24} md={12}>
                                <CustomAddSelect options={SaleCompanies} onButtonClick={onViewRow}
                                    showSearch={true} buttonLabel="Add Party"
                                    onChange={handleSelectCompany} label={'Party Name'} name={'party_name'}
                                    placeholder={'Party Name'} rules={[
                                        {
                                            required: true,
                                            message: 'Please Fill Details!',
                                        },
                                    ]} />
                                <CustomInput name={'party'} display={'none'} />
                            </Col>
                            }
                        {findParty && <>
                            <Col span={24} md={12}>
                                <CustomInput label="Mobile Number" InputLabelProps={{ shrink: true }}
                                    maxLength={10}
                                    minLength={10}
                                    onKeyPress={(event) => {
                                        if (!/[0-9]/.test(event.key)) {
                                            event.preventDefault();
                                        }
                                    }}
                                    name="mobile_number" rules={[
                                        {
                                            required: true,
                                            message: 'Please Fill Details!'
                                        },
                                    ]} />
                            </Col>

                            <Col span={24} md={12}>
                                <CustomTextArea label='Billing Address'
                                    name="billing_address" placeholder="Billing Address" />
                            </Col>

                            <Col span={24} md={12}>
                                <CustomTextArea label={'Shipping Address'} placeholder={'Shipping Address'}
                                    name={'shipping_address'} />
                            </Col>
                        </>
                        }
                              {SaleRecord && <>
                            <Col span={24} md={12}>
                                <CustomInput label="Mobile Number" InputLabelProps={{ shrink: true }}
                                    maxLength={10}
                                    minLength={10}
                                    onKeyPress={(event) => {
                                        if (!/[0-9]/.test(event.key)) {
                                            event.preventDefault();
                                        }
                                    }}
                                    name="mobile_number" rules={[
                                        {
                                            required: true,
                                            message: 'Please Fill Details!'
                                        },
                                    ]} />
                            </Col>

                            <Col span={24} md={12}>
                                <CustomTextArea label='Billing Address'
                                    name="billing_address" placeholder="Billing Address" />
                            </Col>

                            <Col span={24} md={12}>
                                <CustomTextArea label={'Shipping Address'} placeholder={'Shipping Address'}
                                    name={'shipping_address'} />
                            </Col>
                        </>
                        }

                        {gstChecked === false &&
                            <Col span={24} md={12}>
                                <CustomInput label={'GST IN'} name={'GSTIN'}
                                    InputLabelProps={{ shrink: true }} InputProps={{ readOnly: true, }} rules={[
                                        {
                                            required: true,
                                            message: 'Please Fill Details!',
                                        },
                                    ]}
                                    variant="filled"
                                />
                            </Col>
                        }

                    </CustomRow>
                </Col>

                <Col span={24} md={8}>
                    <CustomRow space={[24, 24]}>
                        <Col span={24} md={24}>
                            <CustomInput label={'Invoice No'} name={'invoice_no'} disabled={true}  rules={[
                                {
                                    required: true,
                                    message: 'Please Fill Details!',
                                },
                            ]}
                                
                            />
                        </Col>
                        <Col span={24} md={24}>
                            <CustomDatePicker label={'Invoice Date'} onChange={handleOnChange} name={'invoice_date'} rules={[
                                {
                                    required: true,
                                    message: 'Please Fill Details!',
                                },
                            ]} />
                        </Col>
                        {findParty &&
                            <Col span={24} md={24}>
                                <CustomInput label={'State'} InputLabelProps={{ shrink: true }} placeholder={'State'} name={'state_of_supply'}  />
                            </Col>
                        }
                           {SaleRecord &&
                            <Col span={24} md={24}>
                                <CustomInput label={'State'} InputLabelProps={{ shrink: true }} placeholder={'State'} name={'state_of_supply'}  />
                            </Col>
                        }
                    </CustomRow>
                </Col>

            </CustomRow >
            <CustomModal isVisible={isModalOpen} handleOk={handleOk} handleCancel={handleCancel} width={width} modalTitle={modalTitle} modalContent={modalContent} />
        </Fragment >
    );
};
