import React, { Fragment } from 'react'
import { CustomPageTitle } from '../../components/CustomPageTitle'
import { ViewDayBook } from './Partials/ViewDaybook'




export const DayBook = () => {
    return (
        <Fragment>
            <CustomPageTitle Heading={'Day Book'} />  
                <ViewDayBook />
        </Fragment>
    )
}