import React from 'react'
import Salereturn from './Partials/Salereturn'

export const SaleReturnReport = () => {
    return (
        <div>
            <Salereturn />
        </div>
    )
}
