import React from 'react'
import AddCategory from './AddCategory'
import { CustomCardView } from '../../../components/CustomCardView'
import { CustomPageTitle } from '../../../components/CustomPageTitle'
import Flex from '../../../components/Flex'


export const AddCategorywithCard = () => {
    return (
        <div>
            <CustomPageTitle Heading={'Add Category'} />
            <Flex center={'true'}>
                <CustomCardView width={'800px'}>
                    <AddCategory />
                </CustomCardView>
            </Flex>



        </div>
    )
}
