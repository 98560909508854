import { Col, Form } from "antd";
import { CustomRow } from "../../../../components/CustomRow";
import Flex from "../../../../components/Flex";
import { Fragment, useEffect, useState } from "react";
import request from "../../../../utils/request";
import { toast } from "react-toastify";
import Button from "../../../../components/Form/CustomButton";
import { CustomInput } from "../../../../components/Form/CustomInput";
import { CustomUpload } from "../../../../components/Form/CustomUpload";
import { CustomTextArea } from "../../../../components/Form/CustomTextArea";
import { CustomPageFormSubTitle } from "../../../../components/CustomPageTitle";
import { CustomModal } from "../../../../components/CustomModal";

import { FormLoadingIndicator } from "../../../../components/CommonLoading";
import { SetBusiness, getProfile } from "../../BusinessSlice";

import { useDispatch } from "react-redux";


const AddProfile = ({ Trigger, handleOks, profiletFormReset, ProfileFormExternalClose, FormUpdate }) => {
  const [form] = Form.useForm()

  const dispatch = useDispatch();
  const EDIT_PROFILE_URL = 'business_owner/business_detail_edit'
  const GETFIRST = 'business_owner/business_detail/'
  const ADD_PROFILE_URL = 'business_owner/business_detail/'
  const [ImageInitialValue, setImageInitialValue] = useState([]);
  const [imageSignatureValue, setImageSignatureValue] = useState([]);
  const [formLoading, setFormLoading] = useState(false)
  const [updateFormData, setUpdateFormData] = useState({})
  const [editFormDatas, setEditFormDatas] = useState({})
  const [button, setButton] = useState('Submit');
  const [defval, setDefVal] = useState([])
  // ======  Modal Open ========
  const [isModalOpen, setIsModalOpen] = useState(false);
  // ======  Modal Title and Content ========
  const [modalTitle, setModalTitle] = useState("");
  const [modalContent, setModalContent] = useState(null);
  // ----------  Form Reset UseState ---------
  const [modelwith, setModelwith] = useState(0);
  // ===== Modal Functions Start =====
  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const onFinish = (values) => {
    if (button === 'Update') {
      const formData = new FormData();
      formData.append('business_address', values.business_address);
      formData.append(
        'business_description',
        values.business_description,
      );
      formData.append('business_name', values.business_name);
      formData.append('email', values.email);
      formData.append('gst_no', values.gst_no === null || values.gst_no === undefined ? '' : values.gst_no);
      formData.append('phone_no', values.phone_no);
      formData.append('pincode', values.pincode);
      formData.append('state', values.state);

      if (values?.company_logo.length === 0) {
        formData.append('company_logo', '')
      } else if (!values?.company_logo[0]?.url) {
        formData.append('company_logo', values?.company_logo[0].originFileObj)
      }

      if (values?.signature.length === 0) {
        formData.append('signature', '')
      } else if (!values?.signature[0]?.url) {
        formData.append('signature', values?.signature[0].originFileObj)
      }
      UpdateProfile(formData, editFormDatas?.id)

    }
    else {
      const formData = new FormData();
      formData.append('business_name', values?.business_name);
      formData.append('phone_no', values?.phone_no);
      formData.append('email', values?.email);

      if (values?.gst_no && values.gst_no.length > 0) {
        formData.append(`gst_no`, values?.gst_no === null || values.gst_no === undefined ? '' : values.gst_no);
      } else {
        console.error('gst not selected');
      }
      if (values?.business_address && values.business_address.length > 0) {
        formData.append(`business_address`, values?.business_address);
      } else {
        console.error('business address not selected');
      }
      if (values?.state && values.state.length > 0) {
        formData.append(`state`, values?.state);
      } else {
        console.error('state not selected');
      }
      if (values?.business_description && values.business_description.length > 0) {
        formData.append(`business_description`, values?.business_description);
      } else {
        console.error('business description not selected');
      }
      if (values?.pincode && values.pincode.length > 0) {
        formData.append(`pincode`, values?.pincode);
      } else {
        console.error('pincode not selected');
      }
      if (values?.company_logo && values.company_logo.length > 0) {
        values.company_logo.forEach((file) => {
          formData.append(`company_logo`, file.originFileObj);
        });
      } else {
        console.error('No company logo selected');
      }
      if (values?.signature && values.signature.length > 0) {
        values.signature.forEach((file) => {
          formData.append(`signature`, file.originFileObj);
        });
      } else {
        console.error('No images selected');
      }
      AddProfile(formData)

    }
  }
  const onFinishFailed = (errorInfo) => {
    toast.error("Please Fill the Details")
  };
  const onReset = () => {
    form.resetFields();
  }
  //================ Profile - post =============================//
  const config = {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  };

  useEffect(() => {
    GetProfile()
  }, [])

  const AddProfile = (values) => {
    request.post(`${ADD_PROFILE_URL}`, values)
      .then(function (response) {
        toast.success("Profile Added Successfully")
        form.resetFields();
        GetProfile()
        dispatch(SetBusiness(response.data))

      })
      .catch(function (error) {
        if (error.response && error.response.status === 400) {
          if (error.response.data) {
            if (error.response.data.phone_no) {
              toast.warn(error.response.data.phone_no[0]);
            } else {
              toast.error('Invalid input.');

            }
          } else {
            toast.error('Invalid input.');

          }
        }
      });
  }
  const UpdateProfile = (values, id) => {
    request.put(`${EDIT_PROFILE_URL}/${id}/`, values, config)
      .then(function (response) {
        toast.info("Profile Updated Successfully")
        dispatch(getProfile())
      })
      .catch(function (error) {
        if (error.response && error.response.status === 400) {
          if (error.response.data) {
            if (error.response.data.phone_no) {
              toast.warn(error.response.data.phone_no[0]);
            } else {
              toast.error('Invalid input.');
            }
          } else {
            toast.error('Invalid input.');
          }
        }
      });
  }

  const GetProfile = () => {
    request.get(`${GETFIRST}`)
      .then(function (response) {
        setDefVal(response?.data)
        if (response?.status === 226) {
          setButton('Update')
          setEditFormDatas(response.data);
        }
        else {
          setButton('Submit')
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  const Editbusnes = () => {
    GetProfile()
  }

  useEffect(() => {
    if (button === 'Update') {
      if (editFormDatas?.company_logo?.length > 0) {
        setImageInitialValue([
          {
            uid: '1',
            name: 'example.jpg',
            status: 'done',
            url: editFormDatas.company_logo,
          },
        ])
      }
      else {
        setImageInitialValue([]);
      }
      if (editFormDatas?.signature?.length > 0) {
        setImageSignatureValue([
          {
            uid: '1',
            name: 'signatureexample.jpg',
            status: 'done',
            url: editFormDatas.signature,
          },
        ])
      }
      else {
        setImageSignatureValue([]);
      }
      setUpdateFormData(editFormDatas)
    }
  }, [editFormDatas, Trigger])

  useEffect(() => {
    form.setFieldsValue(updateFormData)
    form.setFieldsValue({ 'company_logo': ImageInitialValue })
    form.setFieldsValue({ 'signature': imageSignatureValue })
  }, [updateFormData, profiletFormReset, Trigger])

  return (
    <Fragment>
      <FormLoadingIndicator active={formLoading} />
      <Form
        form={form}
        labelCol={{
          span: 24,
        }}
        wrapperCol={{
          span: 24,
        }}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        autoComplete="off">
        <CustomRow space={[24, 24]}>
          <Col span={24} md={12}>
            <CustomInput label={'Business Name'} name={'business_name'} placeholder={'Enter Business Name'}
              rules={[
                {
                  required: true,
                  message: 'Please Enter Business Name',
                }
              ]}
            />
          </Col>
          <Col span={24} md={12}>
            <CustomInput label={'GST IN'} name={'gst_no'} placeholder={'Enter GST IN'}
            />
          </Col>
          <Col span={24} md={12}>
            <CustomInput label={'Phone No'} name={'phone_no'} placeholder={'Enter Phone No'}
              maxLength={10}
              onKeyPress={(event) => {
                if (!/[0-9]/.test(event.key)) {
                  event.preventDefault();
                }
              }}
              rules={[
                {
                  required: true,
                  message: 'This is Required Field!',
                },
                {
                  min: 10,
                  required: true,
                  message: 'Please Enter 10 Digit Mobile Number !',
                }
              ]}
            />
          </Col>
          <Col span={24} md={12}>
            <CustomInput label={'Email Id'} name={'email'} type={'email'} placeholder={'Enter Email Id'}
              rules={[
                {
                  required: true,
                  message: 'Please Enter Email Id',
                }
              ]}
            />
          </Col>
          <Col span={24} md={12}>
            <CustomUpload form={form} initialValue={ImageInitialValue} label={'Add Logo'}
              name={'company_logo'} listType='picture-card' maxCount={1} accept='.png,.jpeg,.jpg'
            />
          </Col>
        </CustomRow><br />
        <CustomPageFormSubTitle Heading={'Business Details'} /><br />
        <CustomRow space={[24, 24]}>
          <Col span={24} md={12}>
            <CustomTextArea label={'Business Address'} name={'business_address'} placeholder={'Enter Business Address'}
            />
          </Col>
          <Col span={24} md={12}>
            <CustomTextArea label={'Business Description'} name={'business_description'} placeholder={'Enter Business Description'}
            />
          </Col>
          <Col span={24} md={12}>
            <CustomInput label={'Pincode'} name={'pincode'} placeholder={'Enter Pincode'} />
          </Col>
          <Col span={24} md={12}>
            <CustomInput label={'State'} name={'state'} placeholder={'Enter State'} />
          </Col>
          <Col span={24} md={12}>
            <CustomUpload form={form} initialValue={imageSignatureValue} label={'Add Signature'}
              name={'signature'} listType='picture-card' maxCount={1} accept='.png,.jpeg,.jpg' />
          </Col>
        </CustomRow>
        <Flex center={'true'} gap={'20px'}>
          {
            button === 'Submit' && (<Button.Primary text={'Submit'} htmlType={'submit'} />)
          }
          {
            button === 'Submit' && (<Button.Danger text={'Cancel'} onClick={() => onReset()} />)
          }
          {
            button === 'Update' && (<Button.Primary text={'Update'} htmlType={'submit'} />)
          }
        </Flex>
      </Form>
      <CustomModal isVisible={isModalOpen} handleOk={handleOk} handleCancel={handleCancel} width={modelwith} modalTitle={modalTitle} modalContent={modalContent} />
    </Fragment>
  )
}
export default AddProfile