import { Col, Form } from 'antd';
import React from 'react'
import { CustomRow } from '../../../components/CustomRow';
import { CustomInputPassword } from '../../../components/Form/CustomInputPassword';
import Flex from '../../../components/Flex';
import Button from '../../../components/Form/CustomButton';
import request from '../../../utils/request';
import { toast } from 'react-toastify';

const PasswordRest = () => {

    const [form] = Form.useForm();

    const URL = 'user/change_password/'

    const onFinish = (values) => {
        RestPost(values)
    };

    const onFinishFailed = (error) => {
        console.log(error);
    }

    const onReset = () => {
        form.resetFields();
    }

    const RestPost = (values) => {
        request.post(`${URL}`, values)
            .then(function (response) {
                if (response.status === 201) {
                    toast.success("Changed Successfully")
                    form.resetFields();
                } else {
                    toast.error('Something Went Wrong')
                }
            }).catch(function (error) {
                if (error.response.status === 400) {
                    toast.warn(error.response.data.msg)
                } else {
                    toast.error('Faild')
                }
            })

    }

    return (
        <Form
            form={form}
            labelCol={{
                span: 24,
            }}
            wrapperCol={{
                span: 24,
            }}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            autoComplete="off">
            <CustomRow space={[24, 24]}>
                <Col span={24} md={12}>
                    <CustomInputPassword label={'Current Password'} placeholder={'Password'} type={'password'} name={'current_password'}
                        rules={[
                            {
                                required: true,
                                message: 'Please Enter Password !',
                            }
                        ]} />
                </Col>
                <Col span={24} md={12}>
                    <CustomInputPassword label={'New Password'} placeholder={'Password'} type={'password'} name={'new_password'}
                        rules={[
                            {
                                required: true,
                                message: 'Please Enter Password !',
                            }
                        ]} />
                </Col>
                <Col span={24} md={12}>
                    <CustomInputPassword label={'Confirm Password'} placeholder={'Confirm Password'} type={'password'} name={'confirm_password'}
                        rules={[
                            {
                                required: true,
                                message: 'Please Enter Password !',
                            }
                        ]} />
                </Col>
                <Col span={24} md={24} lg={12}>
                    <Flex center={'true'} gap={'10px'} style={{ marginTop: '20px' }} alignCenter={true}>
                        <Button.Primary text={'Submit'} htmlType={'submit'} />
                        <Button.Danger text={'Cancel'} onClick={() => onReset()} />
                    </Flex>
                </Col>
            </CustomRow>
        </Form>
    )
}

export default PasswordRest