import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import request from "../../utils/request";

const initialState = {
    purchaseList: [],
    status: 'idle',  // 'idle' | 'loading' | 'succeeded' | 'failed'  
    error: null
}

export const getpurchase = createAsyncThunk(
    "Purchase/Get",
    async () => {
        try {
            const response = await request.get('purchase/purchasedetails_search/');
            return response.data;
        }
        catch (error) {       
            throw error;
        }
    }
);

const purchaseSlice = createSlice({
    name: 'sales',
    initialState,
    reducers: {
    },
    extraReducers: (builder) => {
        builder
            .addCase(getpurchase.pending, (state, action) => {
                state.status = 'loading'
            })
            .addCase(getpurchase.fulfilled, (state, action) => {
                state.status = 'succeeded'
                state.purchaseList = action.payload;
            })
            .addCase(getpurchase.rejected, (state, action) => {
                state.status = 'failed'
                state.error = action.error.message
            })
          
    }
})


export const selectAllPurchase = (state) => state.purchase.purchaseList
export const getPurchaseStatus = (state) => state.purchase.status
export const getPurchaseError = (state) => state.purchase.error

export const { reducer } = purchaseSlice;

export default purchaseSlice.reducer


