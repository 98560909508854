import React, { Fragment } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import NetWorkImg from '../Images/Networkissue.png'
import styled from 'styled-components'
import Flex from './Flex'
import ButtonStandard from './Form/CustomStandardButton'

const NetworkError = styled.div`
    margin: auto;
    background-color: #fff;
    padding-top: 80px;
    height: 80vh;
    text-align:center;
& img {
    width: 10%;
}
& h6 {
    font-size: 25px;
    margin: 20px;
    color: #FE6700;
}
@media screen and (max-width: 500px) {
    & img {
        width: 15%;
    }
}
`

export const NetWorkError = () => {

    const navigate = useNavigate();
    const location = useLocation();

    const goBack = () => {
        navigate(location.state.previousUrl)
    };

    return (
        <NetworkError>
            {/* <div>NetWorkError</div> */}
            <Flex center={true}>
                <img src={NetWorkImg} />
            </Flex>
            <h6>No Internet Connection</h6>
            <p>Plese check your connection and try again.</p>
            <Flex center={true} style={{ margin: '30px 0' }}>
                <ButtonStandard.Primary text={'ok'} onClick={() => goBack()} />
            </Flex>
        </NetworkError>
    )
}
