import React, { useEffect, useState } from 'react'
import { CommonLoading } from '../../../../components/CommonLoading';
import { CustomTable } from '../../../../components/Form/CustomTable';
import { useDispatch, useSelector } from 'react-redux';
import { AllStocks, getStock, getStockError, getStockStatus } from '../../ProductSlice';
import { CustomPageTitle } from '../../../../components/CustomPageTitle';
import Label from '../../../../components/Form/Label';
import { CustomInput } from '../../../../components/Form/CustomInput';
import { CustomTandardTable } from '../../../../components/Form/CustomStandardTable';

const ProductStock = () => {

    const [dataSource, setDataSource] = useState([])

    const [searchTexts, setSearchTexts] = useState([]);   //---------Seach Bar --------

    const dispatch = useDispatch()

    const AllStocksDetails = useSelector(AllStocks)
    const GetStockStatus = useSelector(getStockStatus)
    const GetStockError = useSelector(getStockError)

    useEffect(() => {
        setDataSource(AllStocksDetails)
    }, [AllStocksDetails])

    const handleSearchs = (value) => {
        setSearchTexts(value);
    };

    useEffect(() => {
        dispatch(getStock())
    }, [])

    const columns = [
        {
            title: 'SI No',
            render: (value, item, index) => index + 1,
        },
        {
            title: 'Product Name',
            dataIndex: 'item_name',
            filteredValue: searchTexts ? [searchTexts] : null,
            onFilter: (value, record) => {
                return String(record.item_name).toLowerCase().includes(value.toLowerCase()) ||
                    String(record.item_name).includes(value.toUpperCase());
            },
        },
        {
            title: 'HSN Code',
            dataIndex: 'item_hsn'
        },
        {
            title: 'Available QTY',
            dataIndex: 'available_qty'
        },
        {
            title: 'Unit',
            dataIndex: 'item_unit'
        },
        {
            title: 'Stock IN',
            dataIndex: 'stock_in_qty'
        },
        {
            title: 'Stock Out',
            dataIndex: 'stock_out_qty'
        },
        {
            title: 'Direct Stock QTY',
            dataIndex: 'direct_stock_qnty'
        },
    ]

    let content;

    if (GetStockStatus === 'loading') {
        content = <CommonLoading />
    } else if (GetStockStatus === 'succeeded') {
        const rowKey = (dataSource) => dataSource.id;
        content = <CustomTandardTable columns={columns} data={dataSource} rowKey={rowKey} />
    }
    else if (GetStockStatus === 'failed') {
        const rowKey = (dataSource) => dataSource.id;
        content = <CustomTable columns={columns} data={dataSource} rowKey={rowKey} />
    }
    return (
        <div>
            <CustomPageTitle Heading={'Stock'} />
            <div style={{ display: 'flex', alignItems: 'center', marginTop: '50px' }}>
                <Label style={{ marginRight: '20px' }}>Search by Product Name :</Label>
                <CustomInput
                    value={searchTexts}
                    placeholder="Search"
                    onSearch={handleSearchs}
                    onChange={(e) => handleSearchs(e.target.value)}
                />
            </div>
            {content}
        </div>
    )
}

export default ProductStock