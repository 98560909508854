import React, { Fragment, useState } from 'react'
import { CustomRow } from '../../../components/CustomRow';
import { Col, Form } from 'antd';
import dayjs from 'dayjs'
import Flex from '../../../components/Flex';
import { CustomModal } from '../../../components/CustomModal'
import { CustomTable } from '../../../components/Form/CustomTable';
import { DayBookData, data } from './DayBookData';
import { Cards } from './Style';
import { CustomDatePicker } from '../../../components/Form/CustomDatePicker';
import Button from '../../../components/Form/CustomButton';
import { useEffect } from 'react';
import request from '../../../utils/request';
import { toast } from 'react-toastify';



export const ViewDayBook = ({ pageSize }) => {

    const [selectedDate, setSelectedDate] = useState(dayjs().format('YYYY-MM-DD'));
    const [dataSource, setDataSource] = useState([])
    const [uppercards, setUpperCards] = useState([])
    const [dashData, setDashData] = useState(DayBookData);

    // ======  Modal Open ========
    const [isModalOpen, setIsModalOpen] = useState(false);
   
    const ADD_DAYBOOK_URL = 'report/daybook/'
    // ======  Modal Title and Content ========
    const [modalTitle, setModalTitle] = useState("");
    const [modalContent, setModalContent] = useState(null);

    // ----- Define Form

    // -------  Form Reset Funtion

    const FormExternalClose = () => {
        handleOk();
    }

    // ===== Modal Functions Start =====

    const showModal = () => {
        setIsModalOpen(true);
    };

    const handleOk = () => {
        setIsModalOpen(false);
    };

    const handleCancel = () => {
        setIsModalOpen(false);
    };

    // ===== Modal Functions End =====

    const [form] = Form.useForm();

    const DaybookView = (values) => {
        request.post(`${ADD_DAYBOOK_URL}`, values)
            .then(function (response) {
                if (response.status === 200) {
                    toast.success("Fetching Details . . .")
                }
                setDataSource(response.data?.day_book)
                setUpperCards(response.data?.transactions)

                if (response.status === 204) {
                    toast.info("No details to fetch")
                } else if (response.status == 226) {
                    toast.warn('Add the Business Profile');
                } else {
                    toast.error("Something Went Wrong")
                }

            })
            .catch(function (error) {
                toast.error("Something Went Wrong")
            });
    }

    const onFinish = (values) => {
        const newvalue = { ...values, start_date: selectedDate }
        DaybookView(newvalue)
    }

    const onFinishFailed = (errorInfo) => {
        toast.warn("Please fill the details!")
    };

    useEffect(() => {

        setDashData((prevData) =>
            prevData.map((item) => {
                switch (item.h1) {
                    case 'Sale':
                        return {
                            ...item,
                            Amount: uppercards?.sales_amount || 0,
                            
                        };
                    case 'Sale Return':
                        return {
                            ...item,
                            Amount: uppercards?.sales_return_amount || 0,
                          
                        };
                    case 'Purchase':
                        return {
                            ...item,
                            Amount: uppercards?.purchase_amount || 0,
                        };
                    case 'Purchase Return':
                        return {
                            ...item,
                            Amount: uppercards?.purchase_return_amount || 0,
                        };
                    case 'Expense':
                        return {
                            ...item,
                            Amount: uppercards?.expense_amount || 0,
                        };
                    default:
                        return item;
                }
            })
        );

    }, [uppercards])


    const handleDateChange = (e) => {
        setSelectedDate(e);
    }


    const TableColumn = [
        {
            title: 'SI No',
            render: (value, item, index) => index + 1,
        },
        {
            title: 'Date',
            dataIndex: 'Date',
        },
        {
            title: 'Invoice Number',
            dataIndex: 'Invoice_No',
        },
        {
            title: 'Party Name',
            dataIndex: 'Party_Name',
        },
        {
            title: 'Transactions',
            dataIndex: 'Transactions',
        },
        {
            title: 'Bill Amount',
            dataIndex: 'Amount',
        },
        {
            title: 'Balance',
            dataIndex: 'Balance_Due',
        }
    ]


    useEffect(() => {
        GetDaybookDetailss()
    }, [])

    const GetDaybookDetailss = () => {
        request.get('report/daybook/')
            .then(function (response) {
                setDataSource(response.data.day_book)
                setUpperCards(response.data.transactions)
            })
            .catch(function (error) {
                console.log(error);
            });
    }
    return (
        <Fragment>
            <Form form={form} initialValues={{ start_date: dayjs() }} onFinish={onFinish}
                onFinishFailed={onFinishFailed}>

                <CustomRow space={[12, 12]}>

                    <Col span={24} md={6}>
                        <CustomDatePicker
                            onChange={handleDateChange}
                            name={'start_date'}
                            value={selectedDate}
                            rules={[{ required: true, message: 'Please Select the Date' }]}
                        />
                    </Col>

                    <Col span={24} md={6}>
                        <Flex>
                            <Button.Primary text={'Submit'} htmlType='submit' />
                        </Flex>
                    </Col>

                    <Col span={24} md={24}>
                        <div>
                            <CustomTable columns={TableColumn} data={dataSource} />
                          
                        </div>
                    </Col>
                    {dashData.map((val, i) => {
                        return (
                            <Col span={24} xs={24} sm={12} md={12} lg={6} key={i} >
                                <div>
                                    <Cards>
                                        <Flex center>
                                            <h1 style={{ color: "#545454" }}>{val.h1}</h1>
                                        </Flex>
                                        <Flex center>
                                            <h4 style={{ color: "#0469B9" }}>{val.Amount}</h4>
                                        </Flex>
                                    </Cards>
                                </div>
                            </Col>
                        )
                    })}
                </CustomRow>
            </Form>
            <CustomModal isVisible={isModalOpen} handleOk={handleOk} handleCancel={handleCancel} width={800} modalTitle={modalTitle} modalContent={modalContent} />
        </Fragment>
    )
}
