import React, { useEffect, useState } from 'react'
import profile from "../../Images/logshow.png"
import styled from 'styled-components'
import profiles from '../../Images/profile.png'
import logout from '../../Images/logout.png'
import Resetpass from '../../Images/resetpassword.png'
import Flex from '../../components/Flex'
import { logOut, selectCurrentEmail, selectCurrentUser } from '../../modules/Auth/authSlice'
import { useSelector, useDispatch } from 'react-redux'
import { CustomModal } from '../../components/CustomModal'
import Button from '../../components/Form/CustomButton'
import { useNavigate } from 'react-router-dom'
import { ImSad2 } from 'react-icons/im'
import { BsFillEmojiSmileFill } from 'react-icons/bs'
import { toast } from 'react-toastify'
import { selectAllProfile } from '../../modules/BusinessProfile/BusinessSlice'


export const SubMenuWrap = styled.div`
    position: absolute;
    top: 80%;
    right: 10%;
    width: 270px;
    z-index: 1;
    max-height: 0px;
    border-radius: 10px;
    color: var(--light-color);
 
`;

export const SubMenu = styled.div`
background-color: white;
border: 1px solid rgb(219, 205, 205);
box-shadow: rgb(0 0 0 / 6%) 0px 8px 10px;
padding: 20px;
margin: 10px;
border-radius: 0 10%;

& hr {
    border: 0;
    height: 1px;
    width: 100%;
    background-color: #ccc;
    margin: 15px 0 10px;
}
`

const UserInfo = styled.div`
display: flex;
flex-direction: column;
align-items: center;
justify-content: center;

& h2{
    font-weight: 900;
    margin: 5px 0;
    font-size: 20px;
}
& div{
    width: 70px; 
    height: 70px;
    border-radius: 50%; 
    overflow: hidden; 
}
& img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border: 1px solid #dededec7;
}
& h5 {
    font-size: 15px;
    // margin: 5px 0;
}
`

const Submenulink = styled.div`
display: flex;
align-items: center;
text-decoration: none;
color: #525252;
margin: 12px 0;

& p {
    width: 100%;
}

& p:hover {
    font-size: 15px;
    cursor: pointer;
  }


& img {
    width: 40px;
    background-color: #e5e5e5;
    border-radius: 50%;
    padding: 8px;
    margin-right: 15px;
    cursor: pointer;

}

& span {
    font-size: 22px;
}
`

const ProfileStyle = ({ setIsProfileOpen }) => {

    // const ProfileName = useSelector(selectCurrentUser)

    const [profileImg, setProfileImg] = useState({})

    // ======  Modal Open ========
    const [isModalOpen, setIsModalOpen] = useState(false);
    // ======  Modal Title and Content ========
    const [modalTitle, setModalTitle] = useState("");
    const [modalContent, setModalContent] = useState(null);

    const dispatch = useDispatch();

    const showModal = () => {
        setIsModalOpen(true);
    };
    const handleCancel = () => {
        setIsModalOpen(false);
    };

    const AdminLogOut = () => {
        setModalContent(<LogOutModal />);
        setModalTitle("");
        showModal();
    }

    const ResetPassword = () => {
        navigate('/resetpassword');
        setIsProfileOpen(false)
    }

    const ProfileName = useSelector(selectCurrentUser)
    const ProfileEmail = useSelector(selectCurrentEmail)
    // console.log(ProfileEmail,'ProfileEmail');

    // ===========  Set Profile Img =======

    const ProfileImgSet = useSelector(selectAllProfile)

    useEffect(() => {
        setProfileImg(ProfileImgSet?.company_logo)
    }, [ProfileImgSet])

    const LogOutModal = () => (
        <div>
            <h1 style={{ fontSize: '1.2rem', textAlign: 'center' }}>Are you Sure You Want to Logout ?</h1>
            <br />
            <Flex gap={'20px'} W_100 center verticallyCenter>
                <Button.Success text={'Logout'} icon={<ImSad2 style={{ marginRight: '10px' }} />} onClick={Signout} />
                <Button.Danger text={'Cancel'} icon={<BsFillEmojiSmileFill style={{ marginRight: '10px' }} />} onClick={handleOk} />
            </Flex>
        </div>
    )

    const Signout = () => {
        dispatch(logOut());
        localStorage.removeItem('persist')
        localStorage.removeItem('openKeys')
        toast('Thank you for spending your valuable time with me !.')
    }
    const handleOk = () => {
        setIsModalOpen(false);
    };

    const navigate = useNavigate()

    const handleClick = () => {
        navigate('/addprofile');
        setIsProfileOpen(false)
    };

    return (
        <SubMenuWrap>
            <SubMenu>
                <UserInfo>
                    {ProfileImgSet.length === 0 || ProfileImgSet?.company_logo === null ?
                        <div>
                            <img src={profile} alt="Profile" />
                        </div>
                        :
                        <div>
                            <img src={profileImg} alt="Profile" />
                        </div>

                    }

                    <h2>{ProfileName}</h2>
                </UserInfo>
                <hr></hr>

                <Submenulink href='#' >
                    <img src={profiles} onClick={handleClick} />
                    <p onClick={handleClick}>Business Profile</p>
                </Submenulink>

                <Submenulink href='#' className='submenulink'>
                    <img src={Resetpass} onClick={ResetPassword} />
                    <p onClick={ResetPassword}>Reset Password</p>
                </Submenulink>

                <Submenulink href='#' className='submenulink'>
                    <img src={logout} onClick={AdminLogOut} />
                    <p onClick={AdminLogOut}>Logout</p>
                </Submenulink>

            </SubMenu>
            <CustomModal isVisible={isModalOpen} handleOk={handleOk} handleCancel={handleCancel}
                width={400}
                modalTitle={modalTitle} modalContent={modalContent} />
        </SubMenuWrap>
    )
}

export default ProfileStyle
