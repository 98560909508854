import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import request from "../../utils/request";
import axios from "axios";

const initialState = {
    profile: [],
    status: 'idle',  // 'idle' | 'loading' | 'succeeded' | 'failed'  
    error: null,
    business: []
}


export const getProfile = createAsyncThunk(
    "Profile/Get",
    async (_, { rejectWithValue }) => {
        try {
            const response = await request.get('business_owner/business_detail/');
            return response.data;
        }
        catch (error) {
            // Handle specific errors if needed, or just pass along the error
            if (axios.isAxiosError(error)) {
                console.error('Axios error:', error.response || error.message);
                return rejectWithValue(error.response || error.message);
            } else {
                console.error('Error:', error);
                return rejectWithValue(error);
            }
        }
    }
);

const profileSlice = createSlice({
    name: 'businessprofile',
    initialState,
    reducers: {
        SetBusiness: (state, action) => {
            state.business = action.payload

        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(getProfile.pending, (state, action) => {
                state.status = 'loading'
            })
            .addCase(getProfile.fulfilled, (state, action) => {
                state.status = 'succeeded'
                state.profile = action.payload;
            })
            .addCase(getProfile.rejected, (state, action) => {
                state.status = 'failed'
                state.error = action.error.message
            })
    }
})

export const { SetBusiness } = profileSlice.actions
export const storeBusiness = (state) => state.profiles.business
export const selectAllProfile = (state) => state.profiles.profile
export const getProfileStatus = (state) => state.profiles.status
export const getProfileError = (state) => state.profiles.error

export const { reducer } = profileSlice;

export default profileSlice.reducer


