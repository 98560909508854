import { Card, Col } from 'antd'
import React, { Fragment } from 'react'
import { CustomRow } from '../../../components/CustomRow'
import Flex from '../../../components/Flex'
import { CustomInputNumber } from '../../../components/Form/CustomInputNumber'


export const PurchaseFormFooter = ({BalanceOnChange, setRoundDecimalValue, RoundOffChecked, tableSecondaryData }) => {
    

    const HandleOnchange = (val) => {
        BalanceOnChange(val)
    }

    return (
        <Fragment>
            <CustomRow gutter={[24, 24]} >
                <Col lg={8} md={8} span={24}>
                </Col>
                <Col lg={0} md={0} span={0}></Col>
                <Col lg={16} md={16} span={24}>
                    <Card>
                        <CustomRow space={[24, 24]}>
                            <Col span={24} md={8}>
                                <Flex alignCenter={true}>
                                    {/* <Label style={{ paddingTop: '30px' }}>Total&nbsp;:</Label>&nbsp;&nbsp;&nbsp; */}
                                    <CustomInputNumber label={'Total Quantity'} disabled name={'qnty_total'} />
                                </Flex>
                            </Col>
                            <Col span={24} md={8}>
                                <CustomInputNumber label={'Total Discount Amt'} disabled name={'dis_total'} />
                            </Col>
                            <Col span={24} md={8}>
                                <CustomInputNumber label={'Total Tax Amt'} disabled name={'tax_total'} />
                            </Col>
                            <Col span={24} md={24}>
                                <Flex end>
                                    <CustomInputNumber label={'Sub Total'} disabled name={'sub_total'} />
                                </Flex>

                            </Col>
                        </CustomRow>
                    </Card>
                </Col>

                <Col lg={10} md={10} span={24}>
               
                </Col>

                <Col lg={14} md={20} span={24}>
                    <CustomRow gutter={[12, 12]} style={{ marginTop: '20px', gap: '10px' }}>
                        <Col span={24}>
                            <CustomRow gutter={[12, 12]}>
                                <Col sm={12} span={24} style={{
                                    display: 'flex',
                                    alignItems: 'end',
                                }}>
                                    <CustomRow gutter={[12, 12]}>
                                    </CustomRow>
                                </Col>

                                <Col sm={12} span={24}>
                                    <CustomInputNumber precision={2} name={'grand_total'} label={'Grand Total'} placed={'end'} disabled />
                                </Col>
                            </CustomRow>
                        </Col>
                        <Col span={24}>
                            <CustomRow gutter={[12, 12]}>
                                <Col sm={6} span={0}></Col>

                                <Col sm={6} span={6} style={{ display: 'flex', width: '100%', alignItems: 'center', }}>
                                    <CustomRow gutter={[12, 12]}>
                                        <Col span={12}>
                                        </Col>
                                    </CustomRow>
                                </Col>
                                <Col md={12} span={18}>
                                    <CustomInputNumber
                                        precision={2}
                                        name={'paid_amt'}
                                        label={'Paid Amount'}
                                        placed={'end'}
                                        onChange={(val) => HandleOnchange(val)}
                                    />
                                </Col>
                            </CustomRow>
                        </Col>
                        <Col span={24}>
                            <CustomRow gutter={[12, 12]}>
                                <Col sm={6} span={0}></Col>

                                <Col sm={6} span={6} style={{ display: 'flex', width: '100%', alignItems: 'center', }}>
                                    <CustomRow gutter={[12, 12]}>
                                        <Col span={12}>
                                        </Col>
                                        <Col span={12}>
                                        </Col>
                                    </CustomRow>
                                </Col>
                                <Col md={12} span={18}>
                                    <CustomInputNumber
                                        precision={2}
                                        name={'balance'}
                                        label={'Balance'}
                                        placed={'end'}
                                        disabled

                                    />
                                </Col>
                            </CustomRow>

                        </Col>

                    </CustomRow>

                </Col >
            </CustomRow >
            <Flex flexEnd gap={'10px'}>


            </Flex>
        </Fragment>
    )
}