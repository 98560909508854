import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import request from "../../utils/request";


const initialState = {
    salesunpaid: [],
    status: 'idle',  // 'idle' | 'loading' | 'succeeded' | 'failed'  
    error: null
}

export const getSalesUnpaid = createAsyncThunk(
    "SalesUnpaid/Get",
    async () => {
        try {
            const response = await request.get('sale_pay/get_sale_unpaid_bills/');
            return [...response.data];
        }
        catch (error) {
            throw error;
        }
    }
);

const unpaidSlice = createSlice({
    name: 'unpaidamounts',
    initialState,
    reducers: {
    },
    extraReducers: (builder) => {
        builder
            .addCase(getSalesUnpaid.pending, (state, action) => {
                state.status = 'loading'
            })
            .addCase(getSalesUnpaid.fulfilled, (state, action) => {
                state.status = 'succeeded'
                state.salesunpaid = action.payload;
            })
            .addCase(getSalesUnpaid.rejected, (state, action) => {
                state.status = 'failed'
                state.error = action.error.message
            })
    }
})

export const selectAllSalesUnpaid = (state) => state.unpaid.salesunpaid
export const getSalesUnpaidStatus = (state) => state.unpaid.status
export const getSalesUnpaidError = (state) => state.unpaid.error

export const { reducer } = unpaidSlice;

export default unpaidSlice.reducer


