import { Col } from 'antd'
import React, { useEffect, useState } from 'react'
import { FiEdit, FiPlus } from 'react-icons/fi'
import { MdDelete } from 'react-icons/md'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import { CommonLoading } from '../../../../components/CommonLoading'
import { TableIconHolder } from '../../../../components/CommonStyled'
import { CustomModal } from '../../../../components/CustomModal'
import { CustomPageTitle } from '../../../../components/CustomPageTitle'
import { CustomPopconfirm } from '../../../../components/CustomPopConfirm'
import { CustomRow } from '../../../../components/CustomRow'
import Flex from '../../../../components/Flex'
import ButtonStandard from '../../../../components/Form/CustomStandardButton'
import { CustomTable } from '../../../../components/Form/CustomTable'
import { THEME } from '../../../../theme'
import request from '../../../../utils/request'
import { getCategory, getSubCategory, getUnit, selectAllCategory, selectAllCategoryError, selectAllCategoryStatus, selectAllSubCategory, selectAllSubCategoryError, selectAllSubCategoryStatus } from '../../ProductSlice'
import { EditCategory } from '../../../AddSubModule/Category/EditCategory'
import { EditSubCategory } from '../../../AddSubModule/Category/EditSubCategory'
import Label from '../../../../components/Form/Label'
import { CustomInput } from '../../../../components/Form/CustomInput'
import { CustomTandardTable } from '../../../../components/Form/CustomStandardTable'

export const ViewCategory = () => {

  const DEL_CAT_URL = 'product/edit_category'
  const DEL_SUBCAT_URL = 'product/edit_subcategory'
  const navigate = useNavigate();

  const [dataSource, setDataSource] = useState([])
  const [dataSourcee, setDataSourcee] = useState([])
  const [searchTexts, setSearchTexts] = useState([]);   //---------Seach Bar --------
  const [searchTexts2, setSearchTexts2] = useState([]);   //---------Seach Bar 2 --------

  // ======  Modal Open ========
  const [isModalOpen, setIsModalOpen] = useState(false);


  // ======  Modal Title and Content ========
  const [modalTitle, setModalTitle] = useState("");
  const [modalContent, setModalContent] = useState(null);

  // ----------  Form Reset UseState ---------
  const [formReset, setFormReset] = useState(0);
  const [modelwith, setModelwith] = useState(0);
  const [updateservice, setUpdateservice] = useState(0);
  const [profileFormUpdate, setProfileFormUpdate] = useState(0)
  const [trigger, setTrigger] = useState(0)


  const dispatch = useDispatch()

  const ViewCategoryTableData = useSelector(selectAllCategory)
  const GetCategoryStatus = useSelector(selectAllCategoryStatus)
  const GetCategoryError = useSelector(selectAllCategoryError)

  const ViewSubCategoryTableData = useSelector(selectAllSubCategory)
  const GetSubCategoryStatus = useSelector(selectAllSubCategoryStatus)
  const GetSubCategoryError = useSelector(selectAllSubCategoryError)

  // ===== Modal Functions Start =====

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = () => {
    setIsModalOpen(false);
    FormRest()
  };

  const handleSearchs = (value) => {
    setSearchTexts(value);
  };

  const handleSearchtwo = (value) => {
    setSearchTexts2(value);
  };

  const handleCancel = () => {
    setIsModalOpen(false);

  };
  const FormUpdate = () => {
    setProfileFormUpdate(profileFormUpdate + 1)
  }

  const handleGetUnit = () => {
    dispatch(getUnit())

  }

  const FormRest = () => {
    setFormReset(formReset + 1)
  }

  // ===== Modal Functions End =====

  const UpdateCategory = (record) => {
    setTrigger(trigger + 1)
    setModelwith(500)
    setModalTitle("Update Category");
    setModalContent(<EditCategory categoryrecord={record} categoryTrigger={trigger} UnitFormUpdate={FormUpdate} categoryhandleOk={handleOk} />);
    showModal();
  };

  const UpdateSubCategory = (record) => {
    setTrigger(trigger + 1)
    setModelwith(500)
    setModalTitle("Update Sub Category");
    setModalContent(<EditSubCategory subcategoryrecord={record} subCategorytrigger={trigger} UnitFormUpdate={FormUpdate} subcategoryhandleOk={handleOk} />);
    showModal();
  };

  // ========== Delete post ==========

  const handleConfirm = (record) => {
    FormUpdate()
    DeleteCategory(record)
  }
  const DeleteCategory = (record) => {
    request.delete(`${DEL_CAT_URL}/${record?.id}/`)
      .then((response) => {
        dispatch(getCategory())
        toast.info("Deleted Successfully")
      }).catch(error => {
        if (error.response.status === 406) {
          toast.warn("This Category Already Used!")
        } else if (error.response.status === 302) {
          toast.warn(error.response.data.message)
        }
      });
  };

  const handleConfirmSub = (record) => {
    FormUpdate()
    DeleteSubCategory(record)
  }
  const DeleteSubCategory = (record) => {
    request.delete(`${DEL_SUBCAT_URL}/${record?.id}/`)
      .then((response) => {
        dispatch(getSubCategory())
        toast.info("Deleted Successfully")
      }).catch(error => {
        if (error.response.status === 406) {
          toast.warn("This Category Already Used!")
        } else if (error.response.status === 302) {
          toast.warn(error.response.data.message)
        }
      });
  };

  const columns = [
    {
      title: 'SI No',
      render: (value, item, index) => index + 1,
    },
    {
      title: 'Category Code',
      dataIndex: 'category_code'
    },
    {
      title: 'Category',
      dataIndex: 'category',
      filteredValue: searchTexts ? [searchTexts] : null,
      onFilter: (value, record) => {
        return String(record.category).toLowerCase().includes(value.toLowerCase()) ||
          String(record.category).includes(value.toUpperCase());
      },
    },
    {
      title: 'Action',
      render: (record, i) => {
        return (
          <Flex center={"true"} gap={'10px'}>

            <TableIconHolder color={THEME.blue} size={'22px'} onClick={() => { UpdateCategory(record) }}>
              <FiEdit />
            </TableIconHolder>

            <CustomPopconfirm
              record={record}
              confirm={handleConfirm}
              title={"Delete the Category"}
              description={"Are you sure to delete this category ?"}>
              <TableIconHolder color={THEME.red} size={'22px'}>
                <MdDelete />
              </TableIconHolder>
            </CustomPopconfirm>
          </Flex>
        );
      },
    }
  ]

  const subcolumns = [
    {
      title: 'SI No',
      render: (value, item, index) => index + 1,
    },
    {
      title: 'Sub Category Code',
      dataIndex: 'subcategory_code'
    },
    {
      title: 'Sub Category',
      dataIndex: 'subcategory',
      filteredValue: searchTexts2 ? [searchTexts2] : null,
      onFilter: (value, record) => {
        return String(record.subcategory).toLowerCase().includes(value.toLowerCase()) ||
          String(record.subcategory).includes(value.toUpperCase());
      },
    },
    {
      title: 'Action',
      render: (record, i) => {
        return (
          <Flex center={"true"} gap={'10px'}>

            <TableIconHolder color={THEME.blue} size={'22px'} onClick={() => { UpdateSubCategory(record) }}>
              <FiEdit />
            </TableIconHolder>

            <CustomPopconfirm
              record={record}
              confirm={handleConfirmSub}
              title={"Delete the party List"}
              description={"Are you sure to delete this subcategory ?"}>
              <TableIconHolder color={THEME.red} size={'22px'}>
                <MdDelete />
              </TableIconHolder>
            </CustomPopconfirm>
          </Flex>
        );
      },
    }
  ]

  useEffect(() => {
    setDataSource(ViewCategoryTableData)
  }, [ViewCategoryTableData])

  useEffect(() => {
    setDataSourcee(ViewSubCategoryTableData)
  }, [ViewSubCategoryTableData])

  useEffect(() => {
    dispatch(getCategory())
  }, [profileFormUpdate])

  useEffect(() => {
    dispatch(getSubCategory())
  }, [profileFormUpdate])

  let content1;

  if (GetCategoryStatus === 'loading') {
    content1 = <CommonLoading />
  } else if (GetCategoryStatus === 'succeeded') {
    const rowKey = (dataSource) => dataSource.id;
    content1 = <CustomTandardTable columns={columns} data={dataSource} rowKey={rowKey} />
  }
  else if (GetCategoryStatus === 'failed') {
    const rowKey = (dataSource) => dataSource.id;
    content1 = <CustomTable columns={columns} data={dataSource} rowKey={rowKey} />
  }


  let content2;
  console.log(dataSourcee, 'subbbb');
  if (GetSubCategoryStatus === 'loading') {
    content2 = <CommonLoading />
  } else if (GetSubCategoryStatus === 'succeeded') {
    const rowKey = (dataSource) => dataSource.id;
    content2 = <CustomTandardTable columns={subcolumns} data={dataSourcee} rowKey={rowKey} />
  }
  else if (GetSubCategoryStatus === 'failed') {
    const rowKey = (dataSource) => dataSource.id;
    content2 = <CustomTable columns={subcolumns} data={dataSourcee} rowKey={rowKey} />
  }

  return (
    <div>
      <CustomRow space={[24, 24]}>
        <Col span={24} md={15}>
          <CustomPageTitle Heading={'View Category & Sub Category'} />
        </Col>
        <Col span={24} md={9}>
          <CustomRow space={[24, 24]}>
            <Col span={24} md={16}>
            </Col>
            <Col span={24} md={8} style={{ float: 'right' }}>
              <ButtonStandard.Primary icon={<FiPlus style={{ marginRight: '5px', fontSize: '20px' }} />} text={'Add'}
                onClick={() => navigate('/AddCategory')} />
            </Col>
          </CustomRow>
        </Col>
      </CustomRow>
      <CustomRow space={[12, 12]}>
        <Col span={24} md={12}>
          <div style={{ display: 'flex', alignItems: 'center', marginTop: '50px' }}>
            <Label style={{ marginRight: '20px' }}>Search by Category Name :</Label>
            <CustomInput
              value={searchTexts}
              placeholder="Search"
              onSearch={handleSearchs}
              onChange={(e) => handleSearchs(e.target.value)}
            />
          </div>
          {content1}
        </Col>

        <Col span={24} md={12}>
          <div style={{ display: 'flex', alignItems: 'center', marginTop: '50px' }}>
            <Label style={{ marginRight: '20px' }}>Search by Sub Category Name :</Label>
            <CustomInput
              value={searchTexts2}
              placeholder="Search"
              onSearch={handleSearchtwo}
              onChange={(e) => handleSearchtwo(e.target.value)}
            />
          </div>
          {content2}
        </Col>

      </CustomRow>
      <CustomModal isVisible={isModalOpen} handleOk={handleOk} handleCancel={handleCancel} width={modelwith} modalTitle={modalTitle} modalContent={modalContent} />

    </div>
  )
}

