import React from 'react'
import { CustomPageTitle } from '../../../components/CustomPageTitle'
import SaleReturnUnpaid from './Partials/SaleReturnUnpaid'

export const SaleReturnUnpaileBill = () => {
    return (
        <div>
            <CustomPageTitle Heading={'Sale Return Bill'} />
            <SaleReturnUnpaid />
        </div>
    )
}
