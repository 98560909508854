import React, { useState } from 'react'
import { Col, Form } from 'antd';
import dayjs from 'dayjs';
import { TbArrowsExchange } from 'react-icons/tb';
import { CustomPageTitle } from '../../../../components/CustomPageTitle';
import { CustomCardView } from '../../../../components/CustomCardView';
import { CustomModal } from '../../../../components/CustomModal';
import Button from '../../../../components/Form/CustomButton';
import Flex from '../../../../components/Flex';
import { CustomSelect } from '../../../../components/Form/CustomSelect';
import { CustomDateRangePicker } from '../../../../components/Form/CustomDateRangePicker';
import { CustomRow } from '../../../../components/CustomRow';
import { CustomTable } from '../../../../components/Form/CustomTable';
import { useNavigate } from 'react-router-dom';
import request from '../../../../utils/request';
import ButtonStandard from '../../../../components/Form/CustomStandardButton';
import { toast } from 'react-toastify';

export const ViewSaleReturn = () => {
    const [form] = Form.useForm();

    const SEARCH_URL = 'sales/saledetails_search/'

    const [dataSource, setDataSource] = useState([])

    const [choiceFull, setChoiceFull] = useState('')
    const [dateRange, setDateRange] = useState([]);

    const [show, setShow] = useState(false)
    const [modalContent, setModalContent] = useState(null);
    const [modalTitle, setModalTitle] = useState("");
    const [isModalOpen, setIsModalOpen] = useState(false);

    

    const nevigate = useNavigate();

    const showModal = () => {
        setIsModalOpen(true);
    };

    const handleOk = () => {
        setIsModalOpen(false);
    };

    const handleCancel = () => {
        setIsModalOpen(false);
    };


    const handleDateRangeChange = (values) => {
        setDateRange(values);
    };
    const handleMonthSelect = (value) => {

        if (value === 'custom') {
            setShow(true);
            setChoiceFull(value)
        } else {
            setShow(false);
        }

        const choice = {
            choice: value
        }

        DateSearch(choice)

    };

    const DateSearch = (values) => {
        request.post(`${SEARCH_URL}`, values)
            .then(function (response) {
                setDataSource(response?.data?.sale)
            })
            .catch(function (error) {
                console.log(error);
            });
    }

    const month = [
        { label: 'This Month', value: 'This_month' },
        { label: 'Year', value: 'This_year' },
        { label: 'Quater', value: 'This_quorter' },
        { label: 'Custom', value: 'custom' },
    ]

    const onFinish = (values) => {

        const record = { ...values, range: dateRange, choice: choiceFull }

        DateSearch(record)

    }

    const onFinishFailed = (errorInfo) => {
       toast.warn("Please fill the details!")
    };

    const columns = [
        {
            title: 'Sl No',
            render: (item, value, index) => index + 1,
        },
        {
            title: 'Date',
            dataIndex: 'date',
        },
        {
            title: 'Party Name',
            dataIndex: 'party_name',
        },
        {
            title: 'Total Amount',
            dataIndex: 'total_amount',
        },
        {
            title: 'Paid Amount',
            dataIndex: 'paid_amount',
        },

    ];

    const data = [
        {
            date: '2023-09-05',
            party_name: 'Party A',
            receipt_no: 'R12345',
            total_amount: 100.50,
            paid_amount: 50
        },
    ];


    return (
        <>
            <CustomPageTitle Heading={'View Sale Return'} />
            <CustomCardView>
                <Form
                    form={form}
                    labelCol={{
                        span: 24,
                    }}
                    wrapperCol={{
                        span: 24,
                    }}
                    initialValues={
                        {
                            from_date: dayjs(),
                            to_date: dayjs(),
                            payment_type: 'Cash'
                        }
                    }
                    onFinish={onFinish}
                    onFinishFailed={onFinishFailed}
                    autoComplete="off">
                    <CustomRow space={[24, 24]} style={{ padding: '10px 0' }}>
                        <Col span={24} md={3} style={{ marginTop: '10px' }}>
                            <b>Choose</b>&nbsp;&nbsp;
                        </Col>
                        <Col span={24} md={12}>
                            <CustomSelect
                                options={month}
                                name={'year'}
                                value={dateRange}
                                placeholder={'This year'}
                                onChange={handleMonthSelect}
                                rules={[{ required: true, message: 'Please Select the year' }]}
                            />
                        </Col>
                        <Col span={24} md={9} style={{ float: 'inline-end' }}>
                            <Flex end={true} >
                                <ButtonStandard.Primary text={'Add Sale Return'} style={{ marginRight: '10px' }} onClick={() => nevigate(`/addsalereturn`)} />
                            </Flex>
                        </Col>
                    </CustomRow>

                    <CustomRow space={[24, 24]} style={{ padding: '10px 0' }}>
                        {show ?
                            <>
                                <Col span={24} md={3} style={{ marginTop: '10px' }}>
                                    <b>Between</b>&nbsp;&nbsp; <TbArrowsExchange />
                                </Col>

                                <Col span={24} md={12}>
                                    <CustomDateRangePicker
                                        onChange={handleDateRangeChange}
                                        name={'range'}
                                        rules={[{ required: true, message: 'Please Select the Date' }]}
                                    />
                                </Col>

                                <Col span={24} md={6}>
                                    <Flex>
                                        <Button.Primary text={'Submit'} htmlType='submit' />
                                    </Flex>
                                </Col>
                            </>
                            : null}

                    </CustomRow>
                </Form>
                <br /><br />
                {/* <Flex end >
                    <Button.Primary text={'Add Sale Return'} style={{ marginRight: '10px' }} onClick={() => nevigate(`/addsalereturn`)} />
                </Flex> */}

                <CustomRow space={[12, 12]}>
                    <Col span={24} md={24}>
                        <CustomTable columns={columns} data={dataSource} />
                    </Col>
                    <CustomModal isVisible={isModalOpen} handleOk={handleOk} handleCancel={handleCancel} width={1000} modalTitle={modalTitle} modalContent={modalContent} />
                </CustomRow>

            </CustomCardView >

        </>
    )
}

