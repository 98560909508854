import { Checkbox, Col, Divider, Form } from 'antd'
import React, { Fragment, useEffect, useState } from 'react'
import Flex from '../../../../components/Flex'
import Button from '../../../../components/Form/CustomButton'
import dayjs from 'dayjs'
import { CustomDatePicker } from '../../../../components/Form/CustomDatePicker'
import { CustomInput } from '../../../../components/Form/CustomInput'
import { CustomRow } from '../../../../components/CustomRow'
import { CustomInputUnpaid } from '../../../../components/Form/CustomInputUnpaid'
import request from '../../../../utils/request'
import { toast } from 'react-toastify'
import { getSalesUnpaid } from '../../UnpaidSlice'
import { useDispatch } from 'react-redux'
import { CustomInputNumber } from '../../../../components/Form/CustomInputNumber'

const SalePay = ({ salesunpaidrecord, handleOk, trigger }) => {
    // sale_pay/add_sale_unpaid/    ===>post salesunpaid

    const [form] = Form.useForm()
    const SALE_UNPAID_URL = 'sale_pay/add_sale_unpaid/'
    const [selectedDates, setSelectedDates] = useState(dayjs().format('YYYY-MM-DD'));
    const [formRecall, setFormRecall] = useState(0)
    const dispatch = useDispatch()
    const [amountData, setAmountData] = useState(false)

    const CheckboxGroup = Checkbox.Group;
    const plainOptions = ['View Details', 'Pay Bill'];
    // const defaultCheckedList = ['Pay Bill'];

    // const [checkedList, setCheckedList] = useState(defaultCheckedList);
    const [checkedList, setCheckedList] = useState([]);
    const checkAll = plainOptions.length === checkedList.length;
    const indeterminate = checkedList.length > 0 && checkedList.length < plainOptions.length;
    const onChange = (list) => {
        setCheckedList(list);
    };
    const onCheckAllChange = (e) => {
        setCheckedList(e.target.checked ? plainOptions : []);
    };

    useEffect(() => {
        form.resetFields()
    }, [trigger])

    const PostUnpaid = (values) => {
        // setFormLoading(true)
        request.post(`${SALE_UNPAID_URL}`, values)
            .then(function (response) {
                toast.success("Successfully Paid!")
                form.resetFields();
                handleOk()
                dispatch(getSalesUnpaid())

                // setFormLoading(false)
            })
            .catch(function (error) {
                if (error.response.status === 302) {
                    toast.warn("The free plan is over, so please subscribe to a plan.!")
                }
                else if (error.response.status === 410) {
                    toast.warn("Your plan has to be valid. Please subscribe to your plan!")
                }
                else {
                    toast.error("Something Went Wrong")

                }
            }
            )
    }
    const handleAmountChange = () => {
        const Amount = parseFloat(form.getFieldValue('amount'))
        const Balance = parseFloat(form.getFieldValue('balance'))
        if (Amount > Balance) {
            toast.warn('Amount Not greater than Balance!')
            setAmountData(true);
            setFormRecall(formRecall + 1)
        }
        else {
            setAmountData(false);
        }
    }

    const onFinish = (values) => {

        const NewValue = {
            ...values,
            payment_date: values?.payment_date === null ? '' : dayjs(selectedDates).format('YYYY-MM-DD') ? dayjs(values?.payment_date).format('YYYY-MM-DD') : dayjs(values?.payment_date).format('YYYY-MM-DD'),
            invoice_date: selectedDates
        }
        PostUnpaid(NewValue)

    }

    useEffect(() => {
        const fromdatee = new Date(salesunpaidrecord?.invoice_date)
        const dateFormat = 'YYYY-MM-DD';
        const FrmDateee = dayjs(fromdatee).format(dateFormat);
        form.setFieldsValue({
            payment_date: dayjs(),
            invoice_date: dayjs(FrmDateee, dateFormat),
            sale: salesunpaidrecord?.id,
            party: salesunpaidrecord?.party,
        })
        form.setFieldsValue(salesunpaidrecord)
    }, [formRecall, salesunpaidrecord, trigger])

    const onFinishFailed = (errorInfo) => {
        toast.warn("Please fill the details!")
    };

    const onReset = () => {
        const requiredFields = ['amount', 'payment_date'];
        form.resetFields(requiredFields);
        setFormRecall(formRecall + 1)
    }

    const Datechange = (date) => {
        setSelectedDates(date)
    }
    return (
        <Fragment>
            <Form
                form={form}
                labelCol={{
                    span: 24,
                }}
                wrapperCol={{
                    span: 24,
                }}
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                autoComplete="off">
                <CustomRow space={[24, 24]}>
                    <Col span={24} md={12}>
                        <CustomInputUnpaid label={'Party Name'} name={'party_name'} disabled={true} />
                    </Col>

                    <Col span={24} md={12}>
                        <CustomInput label={'Balance Amount'} name={'balance'} disabled={true} />
                    </Col>

                    <Col span={24} md={12}>
                        <Checkbox indeterminate={indeterminate} onChange={onCheckAllChange} checked={checkAll}>
                            Check all
                        </Checkbox>
                        <Divider />
                        <CheckboxGroup options={plainOptions} value={checkedList} onChange={onChange} />
                    </Col>

                    {checkedList.includes('View Details') && (
                        <>
                            <Col span={24} md={12}>
                                <CustomInput label={'Invoice Number'} name={'invoice_no'} placeholder={'Enter Invoice Number'} disabled={true} />
                                <CustomInput label={'Invoice Date'} name={'invoice_date'} placeholder={'Enter Invoice Date'}
                                    disabled={true} />
                                <CustomInput label={'Bill Amount'} name={'grand_total'} placeholder={'Enter Bill Amount'}
                                    disabled={true} />
                                <CustomInput label={'Received Amount'} name={'received_amt'} placeholder={'Enter Received Amount'}
                                    disabled={true} />
                            </Col>
                        </>
                    )}

                    {checkedList.includes('Pay Bill') && (
                        <>
                            <Col span={24} md={12}>
                                <CustomDatePicker label={'Payment Date'} name={'payment_date'} placeholder={'Enter Payment Date'}
                                    onChange={Datechange}
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please Enter Payment Date!',
                                        }
                                    ]} />

                                <CustomInputNumber label={'Amount'} name={'amount'} placeholder={'Enter Received Amount'}
                                    onChange={handleAmountChange}
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please Enter Received Amount!',
                                        }
                                    ]}
                                    onKeyPress={(e) => {
                                        if (!/^\d$/.test(e.key) && e.key !== 'Backspace') {
                                            e.preventDefault();
                                        }
                                    }} />
                            </Col>
                            <CustomInput name={'sale'} display={'none'} />
                            <CustomInput name={'party'} display={'none'} />
                        </>
                    )}
                </CustomRow>

                <Flex center={'true'} gap={'20px'} margin={'20px 0px'}>
                    <Button.Primary text={'SAVE'} htmlType={'submit'} disabled={amountData} />
                    <Button.Danger text={'RESET'} onClick={() => onReset()} />
                </Flex>
            </Form>
        </Fragment>
    )
}

export default SalePay