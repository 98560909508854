import React from "react";
import GraphFromDash from "../Dashboard/Partials/GraphFromDash";
import { BsCartPlusFill, BsCartXFill, BsDatabaseDown, BsFillDatabaseFill } from "react-icons/bs";


export const SalesDashData = [
    {
        key:'1',
        rate:'',
        percentage:'38%',
        icon:<BsFillDatabaseFill/>,
        p:'This Month Sale',
        graph:<GraphFromDash/>
    },
    {
        key:'2',
        rate:'',
        percentage:'07%',
        icon:<BsCartPlusFill/>,
        p:'This Month Purchase',
        graph:<GraphFromDash/>
    },
    {
        key:'3',
        rate:'',
        percentage:'08%',
        icon:<BsDatabaseDown/>,
        p:'This Month Sale Return',
        graph:<GraphFromDash/>
    },
    {
        key:'4',
        rate:'',
        percentage:'47%',
        icon:<BsCartXFill/>,
        p:'This Month Purchase Return',
        graph:<GraphFromDash/>
    },
]