import React, { useEffect } from 'react'
import { Route, Routes, useNavigate } from 'react-router-dom'
import { adminAuthenticated } from '../config/user'
import Flex from '../../components/Flex'
import styled from 'styled-components'
import { DashLayout } from '../../layout/DashLayout'
import { useDispatch, useSelector } from 'react-redux'
import { SetBusiness, getProfile, storeBusiness } from '../../modules/BusinessProfile/BusinessSlice'
import { toast } from 'react-toastify'
import request from '../../utils/request'

const PageFlex = styled(Flex)`
  overflow: hidden;
`
const AuthPage = ({ isAuthenticated }) => {

    const dispatch = useDispatch()

    const BusinessProfile = useSelector(storeBusiness);

    const navigate = useNavigate()

    useEffect(() => {
        dispatch(getProfile())
    }, [])

    useEffect(() => {
        if (!isAuthenticated) {
            navigate('/signin')
        

        const profileLength = Object.keys(BusinessProfile).length;
        if (profileLength < 1) {
            toast.warn('Business profile is empty!');
            // setAuthenticatedRoutes(authenticatedRoutes)
          }
          else{
            toast.warn('iiiiiiiiiiiiii!'); 
          }
        }
    }, [isAuthenticated,BusinessProfile])

    return (
        <PageFlex>
            {isAuthenticated && (
                <DashLayout>
                    <Routes>
                        {adminAuthenticated.map(({ routePath, Component }) => {
                            return (
                                <Route
                                    key={routePath}
                                    path={routePath}
                                    element={<Component />}
                                ></Route>
                            )
                        })}
                    </Routes>
                </DashLayout>
            )}
        </PageFlex>
    )
}

export default AuthPage
