import React, { Fragment } from 'react'
import dayjs from 'dayjs'
import { Document, Page, View, Text, Image,StyleSheet } from '@react-pdf/renderer';
import { useSelector } from 'react-redux';
import { storeBusiness } from '../../BusinessProfile/BusinessSlice';
import logo from '../../../Images/pxfuel.jpg'
import { useEffect } from 'react';
import { useState } from 'react';
import { ToWords } from 'to-words';

const PurchasePdfView = ({ record, activePro }) => {

    const styles = StyleSheet.create({
        page: {
          fontFamily: "Helvetica",
          fontSize: 11,
          lineHeight: 1.5,
          padding:'11px 10px',
          flexDirection: "column"
        },})

    const toWords = new ToWords({
        localeCode: 'en-IN',
        converterOptions: {
            currency: true,
            ignoreDecimal: false,
            ignoreZeroCurrency: false,
            doNotAddOnly: false,
            currencyOptions: { 
                name: 'Rupee',
                plural: 'Rupees',
                symbol: '₹',
                fractionalUnit: {
                    name: 'Paisa',
                    plural: 'Paise',
                    symbol: '',
                },
            }
        }
    });

    const PrintedDate = dayjs().format('DD-MMM-YY [at] HH:mm');
    const formattedDate = dayjs(record?.bill_date).format('DD-MMM-YY');
    const formattedAmount = toWords.convert(record?.grand_total, { currency: true });

    const Header = () => {
        return (
            <>
                <View style={{ flexDirection: 'row' }}>
                    <View style={{ flex: 0.5, alignItems: 'center' }}>
                        <Text style={{ fontSize: '10px', fontWeight: 'bold' }}>INVOICE</Text>
                    </View>
                    <View style={{ flex: 0.5, alignItems: 'flex-end', paddingRight: '10px', gap: '3px' }}>
                        <Text style={{ fontSize: '10px', fontWeight: 'bold', }}>Printed on {PrintedDate}</Text>
                        <Text style={{ fontSize: '11px', fontWeight: 'bold', }}>(ORIGINAL FOR RECEIPT)</Text>
                    </View>
                </View>

                <View style={{ flexDirection: 'row', border: '1px solid black', marginTop: '5px' }}>
                    <View style={{ flex: 0.5, gap: '10px' }}>
                        <View style={{ gap: '5px', padding: '5px' }}>
                            <Text style={{ fontSize: '11px', fontWeight: 'bold', }}>{activePro?.business_name.toUpperCase()}</Text>
                            <Text style={{ fontSize: '10px', fontWeight: 'bold', }}>{activePro?.business_address}, {activePro?.state}-{activePro?.pincode} .</Text>
                            <Text style={{ fontSize: '10px', fontWeight: 'bold', }}>GSTIN / UIN&nbsp;:&nbsp;{activePro?.gst_no}.</Text>
                            <Text style={{ fontSize: '10px', fontWeight: 'bold', }}>State&nbsp;Name&nbsp;:&nbsp;{activePro?.state}.</Text>
                            <Text style={{ fontSize: '10px', fontWeight: 'bold', }}>E-Mail&nbsp;:&nbsp;{activePro?.email}</Text>
                        </View>
                        <View style={{ gap: '4px', borderTop: '1px solid black', padding: '5px' }}>
                            <Text style={{ fontSize: '11px', fontWeight: 'bold', textDecoration: 'underline' }}>Buyer (Billing Address)</Text>
                            <Text style={{ fontSize: '10px', fontWeight: 'bold', }}>{record?.party_name}</Text>
                            <Text style={{ fontSize: '10px', fontWeight: 'bold', }}>{record?.billing_address}</Text>
                            <Text style={{ fontSize: '10px', fontWeight: 'bold', }}>GSTIN / UIN&nbsp;:&nbsp;{record?.gstin}</Text>
                            <Text style={{ fontSize: '10px', fontWeight: 'bold', }}>State of Supply&nbsp;:&nbsp;{record?.state_of_supply}</Text>
                            <Text style={{ fontSize: '10px', fontWeight: 'bold', }}>Contact&nbsp;:&nbsp;{record?.mobile_no}</Text>
                        </View>
                    </View>
                    <View style={{ flex: 0.5, borderLeft: '1px solid black' }}>
                        <View style={{ flexDirection: 'row', borderBottom: '1px solid black' }}>
                            <View style={{ flex: 0.5, gap: '2px', padding: '3px' }}>
                                <Text style={{ fontSize: '10px', fontWeight: 'bold', }}>Return No.</Text>
                                <Text style={{ fontSize: '11px', fontWeight: 'bold', }}>{record?.bill_date}</Text>
                            </View>
                            <View style={{ flex: 0.5, gap: '2px', borderLeft: '1px solid black', padding: '3px' }}>
                                <Text style={{ fontSize: '10px', fontWeight: 'bold', }}>Dated</Text>
                                <Text style={{ fontSize: '11px', fontWeight: 'bold', }}>{formattedDate}</Text>
                            </View>
                        </View>
                        <View >
                            <View style={{ gap: '4px', borderBottom: '1px solid black', padding: '5px 10px', }}>
                                <Text style={{ fontSize: '11px', fontWeight: 'bold', textDecoration: 'underline' }}>Shipping Address</Text>
                                <Text style={{ fontSize: '10px', fontWeight: 'bold', }}>{record?.party_name}</Text>
                                <Text style={{ fontSize: '10px', fontWeight: 'bold', }}>{record?.shipping_address}</Text>
                                <Text style={{ fontSize: '10px', fontWeight: 'bold', }}>GSTIN / UIN&nbsp;:&nbsp;{record?.gstin}</Text>
                                <Text style={{ fontSize: '10px', fontWeight: 'bold', }}>State of Supply&nbsp;:&nbsp;{record?.state_of_supply}</Text>
                                <Text style={{ fontSize: '10px', fontWeight: 'bold', }}>Contact&nbsp;:&nbsp;{record?.mobile_number}</Text>
                            </View>
                            <View style={{ padding: '5px' }}>
                                <Text style={{ fontSize: '11px', fontWeight: 'bold' }}>Terms of Delivery</Text>
                            </View>
                        </View>
                    </View>
                </View>
            </>

        )
    }

    const TableHeadings = () => {
        return (
            <View style={{ display: 'flex', flexDirection: 'row', border: '1px solid black', marginTop: '10px', }}>
                <View style={{ flex: '0.5', borderRight: '1px solid black', padding: '10px 0' }}>
                    <Text style={{ fontSize: '10px', fontWeight: 'bold', textAlign: 'center' }}>Si.No</Text>
                </View>
                <View style={{ flex: '1.5', borderRight: '1px solid black', padding: '10px 0' }}>
                    <Text style={{ fontSize: '10px', fontWeight: 'bold', textAlign: 'center' }}>Product</Text>
                </View>

                <View style={{ flex: '1', borderRight: '1px solid black', padding: '10px 0' }}>
                    <Text style={{ fontSize: '10px', fontWeight: 'bold', textAlign: 'center' }}>Item Code</Text>
                </View>
                <View style={{ flex: '1', borderRight: '1px solid black', padding: '10px 0' }}>
                    <Text style={{ fontSize: '10px', fontWeight: 'bold', textAlign: 'center' }}>Quantity</Text>
                </View>
                <View style={{ flex: '1', borderRight: '1px solid black', padding: '10px 0' }}>
                    <Text style={{ fontSize: '10px', fontWeight: 'bold', textAlign: 'center' }}>Price</Text>
                </View>
                <View style={{ flex: '1', padding: '10px 0', borderRight: '1px solid black' }}>
                    <Text style={{ fontSize: '10px', fontWeight: 'bold', textAlign: 'center' }}>Discount</Text>
                </View>
                <View style={{ flex: '1', padding: '10px 0', borderRight: '1px solid black' }}>
                    <Text style={{ fontSize: '10px', fontWeight: 'bold', textAlign: 'center' }}>GST</Text>
                </View>
                <View style={{ flex: '1', padding: '10px 0' }}>
                    <Text style={{ fontSize: '10px', fontWeight: 'bold', textAlign: 'center' }}>Sub Total</Text>
                </View>
                <View style={{ flex: '1', padding: '10px 0' }}>
                    <Text style={{ fontSize: '10px', fontWeight: 'bold', textAlign: 'center' }}>Total</Text>
                </View>
            </View>
        )
    }

    const Footer = () => {
        return (
            <View style={{ position: 'absolute', bottom: '0', left: '0', width: '100vw', padding: '10px 30px' }}>
                <View style={{ marginTop: '50px' }}>
                    <View style={{ display: 'flex', flexDirection: 'row' }}>
                        <View style={{ flex: '0.5' }}>
                            <Text style={{ fontSize: '10px', fontWeight: '500', marginBottom: '5px' }}>Amount Chargeable (in words)</Text>
                            <Text style={{ fontSize: '10px', fontWeight: '500', marginBottom: '10px' }}>INR {formattedAmount}</Text>
                        </View>
                    </View>

                    <View style={{ display: 'flex', flexDirection: 'row', }}>
                        <View style={{ flex: '0.5' }}>

                        </View>
                        <View style={{ flex: '0.5', display: 'flex', flexDirection: 'row' }}>

                            <Text style={{ fontSize: '11px', fontWeight: 'bold', flex: '0.5', textAlign: 'right', marginTop: '5px' }}>Total Quantity :</Text>
                            <Text style={{ fontSize: '11px', fontWeight: 'bold', flex: '0.5', textAlign: 'right', marginTop: '5px' }}>&nbsp;{record?.qty_total}</Text>
                        </View>
                    </View>
                    
                    <View style={{ display: 'flex', flexDirection: 'row', }}>
                        <View style={{ flex: '0.5' }}>

                        </View>
                        <View style={{ flex: '0.5', display: 'flex', flexDirection: 'row' }}>

                            <Text style={{ fontSize: '11px', fontWeight: 'bold', flex: '0.5', textAlign: 'right', marginTop: '5px' }}>Total Amount :</Text>
                            <Text style={{ fontSize: '11px', fontWeight: 'bold', flex: '0.5', textAlign: 'right', marginTop: '5px' }}>Rs.&nbsp;{record?.grand_total}</Text>
                        </View>
                    </View>
                </View>

                
                <View style={{ display: 'flex', flexDirection: 'row', marginTop: '20px', }}>
                    <View style={{ flex: '0.5' }}>
                        <Text style={{ fontSize: '11px', fontWeight: 'bold', marginBottom: '10px' }}>Delcaration</Text>
                        <Text style={{ fontSize: '10px', fontWeight: '500', paddingRight: '20px' }}>We declare that this invoice shows the actual price of the goods described and that all particulars are true and correct</Text>
                    </View>
            
                </View>
                <View style={{ border: '1px solid black', padding: '4px', marginTop: '10px', display: 'flex', flexDirection: 'column' }}>
                    <View style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                        <Text style={{ fontSize: '11px', fontWeight: 'bold', textAlign: 'left' }}>Customer's Seal and Signature</Text>
                        <Text style={{ fontSize: '11px', fontWeight: 'bold', textAlign: 'right' }}>For : {activePro?.business_name}</Text>
                    </View>
                    <View style={{ marginTop: '30px' }}>
                        <Text style={{ fontSize: '11px', fontWeight: 'bold', textAlign: 'right' }}>Authorised Signatory</Text>
            
                    </View>

                </View>
                <Text style={{ fontSize: '10px', fontWeight: '500', textAlign: 'center', marginTop: '10px' }}>This is a Computer generated Invoice</Text>
            </View>
        )
    }


    const objectsPerPage = 10;
    const totalPages = Math.ceil((record?.purchase_return?.length) / objectsPerPage);
    return (
        <Document>
            {Array.from({ length: totalPages }, (_, pageIndex) => (
                <Page style={styles.Page}>
                    <Fragment>
                        {pageIndex === 0 && (
                            <Header />
                        )}

                        <TableHeadings />
                        {record?.purchase_return
                            ?.slice(pageIndex * objectsPerPage, (pageIndex + 1) * objectsPerPage)
                            ?.map((value, index) => {
                                return (
                                    <View style={{ display: 'flex', flexDirection: 'row', }}>

                                        <View style={{ flex: '0.5', padding: '5px 0', borderBottom: '1px solid black', borderRight: '1px solid black', borderLeft: '1px solid black' }}>
                                            <Text style={{ fontSize: '10px', textAlign: 'center' }}>{index + 1}</Text>
                                        </View>
                                        <View style={{ flex: '1.5', padding: '5px 0', borderBottom: '1px solid black', borderRight: '1px solid black' }}>
                                            <Text style={{ fontSize: '10px', textAlign: 'center' }}>{value.item_name}</Text>
                                        </View>
                                        <View style={{ flex: '1', padding: '5px 0', borderBottom: '1px solid black', borderRight: '1px solid black' }}>
                                            <Text style={{ fontSize: '10px', textAlign: 'center' }}> {value.item_code}</Text>
                                        </View>
                                        <View style={{ flex: '1', padding: '5px 0', borderBottom: '1px solid black', borderRight: '1px solid black' }}>
                                            <Text style={{ fontSize: '10px', textAlign: 'center' }}> {value.item_qty}</Text>
                                        </View>
                                        <View style={{ flex: '1', padding: '5px 0', borderBottom: '1px solid black', borderRight: '1px solid black' }}>
                                            <Text style={{ fontSize: '10px', textAlign: 'center' }}>{value.price_per_unit}</Text>
                                        </View>


                                        <View style={{ flex: '1', padding: '5px 0', borderBottom: '1px solid black', borderRight: '1px solid black' }}>
                                            <Text style={{ fontSize: '10px', textAlign: 'center' }}>{value.dis_amt}</Text>
                                        </View>
                                        <View style={{ flex: '1', padding: '5px 0', borderBottom: '1px solid black', borderRight: '1px solid black' }}>
                                            <Text style={{ fontSize: '10px', textAlign: 'center' }}>{value.tax_percentage_name}</Text>
                                        
                                        </View>
                                        <View style={{ flex: '1', padding: '5px 0', borderBottom: '1px solid black', borderRight: '1px solid black' }}>
                                            <Text style={{ fontSize: '10px', textAlign: 'center' }}>{value.sub_total}</Text>
                                            
                                        </View>
                                        <View style={{ flex: '1', padding: '5px 0', borderBottom: '1px solid black', borderRight: '1px solid black' }}>
                                            <Text style={{ fontSize: '10px', textAlign: 'center' }}>{value.qty_total}</Text>
                                        </View>

                                    </View>
                                )
                            })}
                        {pageIndex === totalPages - 1 && (
                            <Footer />
                        )}
                    </Fragment>

                </Page>
            ))}
        </Document>
    )
}

export default PurchasePdfView