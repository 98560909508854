import { Col } from 'antd'
import React, { useEffect, useState } from 'react'
import Flex from '../../../../components/Flex';
import { CustomPopconfirm } from '../../../../components/CustomPopConfirm';
import { THEME } from '../../../../theme';
import { CustomModal } from '../../../../components/CustomModal';
import { MdDelete } from "react-icons/md";
import { FiEdit, FiPlus } from "react-icons/fi";
import AddService from '../../AddService/Partials/AddService';
import { useDispatch, useSelector } from 'react-redux';
import { getService, getServiceError, getServiceStatus, selectAllService } from '../../ServiceSlice';
import { CommonLoading } from '../../../../components/CommonLoading';
import { toast } from 'react-toastify';
import request from '../../../../utils/request';
import { CustomRow } from '../../../../components/CustomRow';
import { CustomPageTitle } from '../../../../components/CustomPageTitle';
import { useNavigate } from 'react-router-dom';
import { TableIconHolder } from '../../../../components/CommonStyled';
import { CustomTable } from '../../../../components/Form/CustomTable';
import Label from '../../../../components/Form/Label';
import { CustomInput } from '../../../../components/Form/CustomInput';
import ButtonStandard from '../../../../components/Form/CustomStandardButton';
import { CustomTandardTable } from '../../../../components/Form/CustomStandardTable';


const ViewTable = () => {

    const navigate = useNavigate();

    const DEL_SERVICE_URL = 'service/edit_service'

    const [searchText, setSearchText] = useState([])

    const [dataSource, setDataSource] = useState([])
    // ======  Modal Open ========
    const [isModalOpen, setIsModalOpen] = useState(false);

    // ======  Modal Title and Content ========
    const [modalTitle, setModalTitle] = useState("");
    const [modalContent, setModalContent] = useState(null);

    // ----------  Form Reset UseState ---------
    const [formReset, setFormReset] = useState(0);
    const [modelwith, setModelwith] = useState(0);
    const [updateservice, setUpdateservice] = useState(0);
    const [trigger, setTrigger] = useState(0)

    // ===== Modal Functions Start =====

    const showModal = () => {
        setIsModalOpen(true);
        RestForm()
    };

    const handleOk = () => {
        setIsModalOpen(false);
        RestForm()
    };

    const handleSearchs = (value) => {
        setSearchText(value);
    };

    const handleCancel = () => {
        setIsModalOpen(false);
    };

    const onEditService = (record) => {
        setTrigger(trigger + 1)
        setModelwith(800)
        setModalTitle("Update Service");
        setModalContent(<AddService servicedata={record} FormUpdate={FormUpdate} Trigger={trigger} Formcancel={handleOk} />);
        showModal();
    };

    // ========== Get Service ============ 

    const dispatch = useDispatch()

    const AllService = useSelector(selectAllService)
    const ServiceStatus = useSelector(getServiceStatus)
    const ServiceError = useSelector(getServiceError)

    useEffect(() => {
        dispatch(getService())
    }, [updateservice])

    useEffect(() => {
        setDataSource(AllService)
    }, [AllService])

    const FormUpdate = () => {
        setUpdateservice(updateservice + 1)
    }

    const RestForm = () => {
        setFormReset(formReset + 1)
    }

    const handleConfirm = (record) => {
        DeleteService(record)
    }

    const DeleteService = (record) => {
        request.delete(`${DEL_SERVICE_URL}/${record?.id}/`)
            .then((response) => {
                if (response.status === 204) {
                    dispatch(getService())
                    toast.info("Deleted Successfully")
                }
                if (response.status === 226) {
                    toast.warn("Service used for billing, so can't deleted")
                }
            }).catch(error => {
                if (error.response.status === 400) {
                    toast.error("Something Went Wrong")
                } else if (error.response.status === 302) {
                    toast.error("Please Subscribe your plan!")
                } else {
                    toast.error("Failed")
                }
            });
    };


    const columns = [
        {
            title: 'Sl No',
            render: (item, value, index) => index + 1,
        },
        {
            title: 'Service Name',
            dataIndex: 'service_name',
            filteredValue: searchText ? [searchText] : null,
            onFilter: (value, record) => {
                return String(record.service_name).toLowerCase().includes(value.toLowerCase()) ||
                    String(record.service_name).includes(value.toUpperCase());
            }
        },
        {
            title: 'Service Code',
            dataIndex: 'service_code',
        },
        {
            title: 'Description',
            dataIndex: 'description',
        },
        {
            title: 'Rate',
            dataIndex: 'rate',
        },
        {
            title: 'Action',
            render: (record) => {
                return (
                    <Flex center={"true"} gap={'10px'}>

                        <TableIconHolder color={THEME.blue} size={'22px'} onClick={() => onEditService(record)}>
                            <FiEdit />
                        </TableIconHolder>

                        <CustomPopconfirm
                            record={record}
                            confirm={handleConfirm}
                            // cancel={handlePopConfrmCancel}
                            title={"Delete the service List"}
                            description={"Are you sure to delete this service ?"}>
                            <TableIconHolder color={THEME.red} size={'22px'}>
                                <MdDelete />
                            </TableIconHolder>
                        </CustomPopconfirm>
                    </Flex>
                )
            }
        },
    ];


    let content;

    if (ServiceStatus === 'loading') {
        content = <CommonLoading />
    } else if (ServiceStatus === 'succeeded') {
        const rowKey = (dataSource) => dataSource.id;
        // content = <CustomTable columns={columns} data={dataSource} rowKey={rowKey} />
        content = <CustomTandardTable columns={columns} data={dataSource} rowKey={rowKey} />
    }
    else if (ServiceStatus === 'failed') {
        const rowKey = (dataSource) => dataSource.id;
        content = <CustomTable columns={columns} data={dataSource} rowKey={rowKey} />
    }

    return (

        <div>
            <CustomRow space={[24, 24]}>
                <Col span={24} md={15}>
                    <CustomPageTitle Heading={'View Service'} />
                </Col>
                <Col span={24} md={9}>
                    <CustomRow space={[24, 24]}>
                        <Col span={24} md={16}>
                            {/* <CustomInputSearch prefix={<BiSearchAlt2 />} placeholder={'Search service'} /> */}
                        </Col>
                        <Col span={24} md={8} style={{ float: 'right' }}>
                            <ButtonStandard.Primary icon={<FiPlus style={{ marginRight: '5px', fontSize: '20px' }} />} text={'Add'}
                                onClick={() => navigate('/service')} />
                        </Col>
                    </CustomRow>
                </Col>
            </CustomRow>
            <div style={{ display: 'flex', alignItems: 'center', marginTop: '50px' }}>
                <Label style={{ marginRight: '20px' }}>Search by Service Name :</Label>
                <CustomInput
                    value={searchText}
                    placeholder="Search"
                    onSearch={handleSearchs}
                    onChange={(e) => handleSearchs(e.target.value)}
                />
            </div>
            {content}
            <CustomModal isVisible={isModalOpen} handleOk={handleOk} handleCancel={handleCancel} width={modelwith} modalTitle={modalTitle} modalContent={modalContent} />
        </div>
    )
}

export default ViewTable