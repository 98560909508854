import React from 'react'
import Details from './Partials/Detail'


export const Tab = () => {
  return (
    <div><Details /></div>
  )
}

