import { Col, Form, Select } from 'antd';
import React, { Fragment, useEffect, useState } from 'react';
import request from '../../../utils/request';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { CustomRow } from '../../../components/CustomRow';
import { CustomTextArea } from '../../../components/Form/CustomTextArea';
import { CustomInput } from '../../../components/Form/CustomInput';
import { CustomModal } from '../../../components/CustomModal';
import { CustomSelect } from '../../../components/Form/CustomSelect';
import { CustomDatePicker } from '../../../components/Form/CustomDatePicker';
import { CustomAddSelect } from '../../../components/Form/CustomAddSelect';
import { AddPartyForm } from '../../AddParty/Party/Partials/AddPartyForm';
import { CustomInputNumber } from '../../../components/Form/CustomInputNumber';
import CustomInputs from '../../Services/AddService';
import { useParams } from 'react-router-dom';


export const PurchaseReturnFormHeader = ({ dataSource, setSelectedDate, setSelectedDates, setSelectedrevisedDates, setSelectedSale, trigger, setShipState, setInvoiceNumber }) => {


    const [isModalOpen, setIsModalOpen] = useState(false);
    const [modalTitle, setModalTitle] = useState("");
    const [modalContent, setModalContent] = useState(null);
    const [width, setWidth] = useState(0)
    const [triggers, setTriggers] = useState(0);
    const [payterms, setPayterms] = useState(0)
    const [purchase, setPurchase] = useState(0)
    //=========Find Select party Fields ============//
    const [findParty, setFindParty] = useState(false)
    const [vartrigger, setVarTrigger] = useState(0);

    //=======

    const [form] = Form.useForm();


    const dispatch = useDispatch()

    const onViewRow = () => {
        setPurchase(purchase + 1)
        setWidth(800)
        setModalTitle("Add Party");
        setModalContent(<AddPartyForm handleOk={handleOk} purchaseTrigger={purchase} />);
        showModal();
    };
    const id = useParams()
    const newid = id.id

    const showModal = () => {
        setIsModalOpen(true);
    };

    const handleOk = () => {
        setIsModalOpen(false);
    };

    const handleCancel = () => {
        setIsModalOpen(false);
    };



    //=====================Party  name ===================================

    const PurchaseProductOptions = [];

    if (dataSource) {
        PurchaseProductOptions.push({
            label: dataSource.party_name,            // <---- Static
            value: dataSource.id
        });
    }



    const handleSelectCompany = (value) => {

        setFindParty(value)
        handlevarRefresh()
    };

    const handlevarRefresh = (value) => {
        form.setFieldsValue({ party_name: setVarTrigger(vartrigger + 1) })

        if (value) {
            form.setFieldsValue({ party_name: '' })
        }
    }

    //===================Date onChange fn======================//

    const handleOnChange = (date) => {
        setSelectedDate(date);

    };


    return (
        <Fragment>
            <CustomRow space={[24, 24]} >
                <Col span={24} md={16}>
                    <CustomRow space={[24, 24]}>
                        <Col span={24} md={12}>
                            <CustomInput
                                disabled={true}
                                label={'Party Name'} name={'party_name'}
                                placeholder={'Party Name'} rules={[
                                    {
                                        required: true,
                                        message: 'Please Fill Details!',
                                    },
                                ]} />
                            <CustomInputNumber name={'party'} display={'none'} />
                            <CustomInputNumber name={'email'} display={'none'} />
                        </Col>

                        <Col span={24} md={12}>
                            <CustomInput label="Mobile Number"
                                name="mobile_no" placeholder="Mobile Number" rules={[
                                    {
                                        required: true,
                                        message: 'Please Fill Details!',
                                    },
                                ]}
                                InputLabelProps={{ shrink: true }}
                                maxLength={10} disabled={true}
                                minLength={10}
                                onKeyPress={(event) => {
                                    if (!/[0-9]/.test(event.key)) {
                                        event.preventDefault();
                                    }
                                }}
                            />
                        </Col>
                        {dataSource.gstin === null ? null :
                            <Col span={24} md={12}>
                                <CustomInput label={'GST IN'} name={'gstin'}
                                    InputLabelProps={{ shrink: true }} InputProps={{ readOnly: true, }} disabled={true}
                                    variant="filled"
                                />
                            </Col>
                        }

                        <Col span={24} md={12}>
                            <CustomTextArea label={'Address'} placeholder={'Address'} name={'address'} disabled={true}
                            />
                        </Col>
                    </CustomRow>
                </Col>

                <Col span={24} md={8}>
                    <CustomRow space={[24, 24]}>
                        <Col span={24} md={24}>
                            <CustomInput label={'Return No'} name={'return_no'} disabled={true} rules={[
                                {
                                    required: true,
                                    message: 'Please Fill Details!',
                                },
                            ]}
                            />
                            <CustomInput name={'bill_no'} display={'none'} />
                            <CustomInput name={'purchase_order'} display={'none'} />
                            <CustomInput name={'bill_date'} display={'none'} />
                        </Col>
                        <Col span={24} md={24}>
                            <CustomDatePicker label={'Return Date'} onChange={handleOnChange} name={'return_date'} rules={[
                                {
                                    required: true,
                                    message: 'Please Fill Details!',
                                },
                            ]} />
                        </Col>
                        <Col span={24} md={24}>
                            <CustomInput label={'State of Supply'}
                                placeholder={'State of Supply'}
                                disabled={true}
                                name={'state_of_supply'} rules={[
                                    {
                                        required: true,
                                        message: 'Please Fill Details!',
                                    },
                                ]}
                            />
                        </Col>

                    </CustomRow>
                </Col>

            </CustomRow>
            <CustomModal isVisible={isModalOpen} handleOk={handleOk} handleCancel={handleCancel} width={width} modalTitle={modalTitle} modalContent={modalContent} />
        </Fragment>
    );
};
