import React, { useEffect, useRef, useState } from 'react'
import { Col } from 'antd'
import { useDispatch, useSelector } from 'react-redux'
import { AiFillFilePdf, AiFillPrinter } from 'react-icons/ai'
import { useReactToPrint } from 'react-to-print'
import { useNavigate } from 'react-router-dom'
import { PrintHolder, PrintTableFooterHolders, PrintViewTitle, PrintViewValue, PrintWrapper, SignImageHolder } from '../../../../components/Form/Styled'
import { PrintSubTitle, PrintTitle } from '../../../BillPage/style'
import Flex from '../../../../components/Flex'
import Button from '../../../../components/Form/CustomButton'
import { CustomRow } from '../../../../components/CustomRow'
import { getProfile, selectAllProfile, storeBusiness } from '../../../BusinessProfile/BusinessSlice'
import { BillTable, Cardsin, Maindesign } from '../../../Purchase/Partials/PurchaseTableStyle'
import { CustomCardView } from '../../../../components/CustomCardView'
import { InvoiceTitle } from '../../../../components/Form/FormTitle'
import request from '../../../../utils/request'
import SaleReturnPdf from './SaleReturnPdf'
import { PDFDownloadLink } from '@react-pdf/renderer'
// import { PDFDownloadLink } from '@react-pdf/renderer'


export const SaleRetunBill = ({ record }) => {

    const [viewData, setViewData] = useState([])


    const componentRef = useRef();


    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getProfile())

    }, [])

    const activePro = useSelector(selectAllProfile);




    useEffect(() => {
        setViewData(record)
    }, [record])

    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
    })

    const navigate = useNavigate()


    const HeaderTable = () => {
        return (
            <Cardsin>
                <CustomRow>
                    <Col span={24} md={12} style={{ borderRight: '1px solid', borderBottom: '1px solid', padding: '2px 10px' }}>

                        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
                            <PrintTitle Size={'16px'} UPPER Weight={'600'}> {activePro?.business_name}</PrintTitle>
                            {/* <PrintTitle Size={'14px'} UPPER Weight={'600'} TextAlign={'left'}>{activePro.address}, {activePro.state}, {activePro.district} DISTRICT.</PrintTitle> */}
                            <PrintTitle Size={'14px'} UPPER Weight={'600'}>GSTIN&nbsp;:&nbsp;{activePro?.gst_no}</PrintTitle>
                            <PrintTitle Size={'14px'} Weight={'600'}>State&nbsp;Name&nbsp;:&nbsp;{activePro?.state}</PrintTitle>
                            <PrintTitle Size={'14px'} Weight={'600'}>Pincode&nbsp;&nbsp;:&nbsp;{activePro?.pincode}</PrintTitle>
                            <PrintTitle Size={'14px'} Weight={'600'}>E-Mail&nbsp;:&nbsp;{activePro?.email}</PrintTitle>
                        </div>
                    </Col>
                    <Col span={24} md={12} >
                        <CustomRow>
                            <Col span={24} md={12} style={{ textAlign: 'center' }} >
                                <div style={{ borderRight: '1px solid', height: '100%' }}>
                                    <p style={{ fontSize: '14px', fontWeight: '600', textTransform: 'uppercase' }}>Invoice No.</p>
                                    <br></br>
                                    <b style={{ fontSize: '14px', fontWeight: '600', textTransform: 'uppercase' }}>{viewData?.sale_retun?.invoice_no}</b>
                                </div>
                            </Col>
                            <Col span={24} md={12} style={{ textAlign: 'center' }}>
                                <div style={{ height: '100%' }}>
                                    <p style={{ fontSize: '14px', fontWeight: '600', textTransform: 'uppercase' }}>Dated</p>
                                    <br></br>
                                    <b style={{ fontSize: '14px', fontWeight: '600', textTransform: 'uppercase' }}>{(viewData?.sale_retun?.invoice_date)}</b>
                                </div>
                            </Col>
                            <Col span={24} md={24} style={{ textAlign: 'center' }} >
                                <div style={{ borderTop: '1px solid', height: '100%' }}>
                                    <p style={{ fontSize: '14px', fontWeight: '600', textTransform: 'uppercase' }}>Shipping Address</p>
                                    <br></br>
                                    <div style={{ textAlign: 'start', padding: '5px 10px' }}>
                                        <PrintTitle Size={'12px'}>Buyer (Bill To)</PrintTitle>
                                        <PrintTitle Size={'16px'} UPPER Weight={'600'}> {record?.sale_retun?.party_name}</PrintTitle>
                                        <PrintTitle Size={'14px'} UPPER Weight={'600'} TextAlign={'left'}>{record?.address}</PrintTitle>
                                        <PrintTitle Size={'14px'} UPPER Weight={'600'}>GSTIN&nbsp;:&nbsp;{activePro?.gst_no}</PrintTitle>
                                        <PrintTitle Size={'14px'} Weight={'600'}>State&nbsp;Name&nbsp;:&nbsp;{activePro?.state}&nbsp;,&nbsp;Code&nbsp;:&nbsp;{activePro?.pincode}</PrintTitle>
                                        <PrintTitle Size={'14px'} Weight={'600'}>Contact&nbsp;:&nbsp;{activePro?.phone_no}</PrintTitle>
                                    </div>
                                </div>
                            </Col>
                        </CustomRow>
                    </Col>
                </CustomRow>
                <CustomRow>
                    <Col span={24} md={12} style={{ borderRight: '1px solid', padding: '2px 10px' }}>

                        <div style={{ textAlign: 'start', padding: '5px 10px' }}>
                            <PrintTitle Size={'12px'}>Buyer (Billing Address)</PrintTitle>
                            <PrintTitle Size={'16px'} UPPER Weight={'600'}> {record?.name}</PrintTitle>
                            <PrintTitle Size={'14px'} UPPER Weight={'600'} TextAlign={'left'}>{record?.address}</PrintTitle>
                            <PrintTitle Size={'14px'} UPPER Weight={'600'}>GSTIN&nbsp;:&nbsp;{activePro?.gst_no}</PrintTitle>
                            <PrintTitle Size={'14px'} Weight={'600'}>State&nbsp;Name&nbsp;:&nbsp;{activePro?.state}&nbsp;,&nbsp;Code&nbsp;:&nbsp;{activePro?.pincode}</PrintTitle>
                            <PrintTitle Size={'14px'} Weight={'600'}>Contact&nbsp;:&nbsp;{activePro?.phone_no}</PrintTitle>
                        </div>
                    </Col>
                    <Col span={24} md={12} >
                        <CustomRow>
                            <Col span={24} md={24} style={{ textAlign: 'center' }} >
                                <div style={{ borderTop: '1px solid', height: '100%' }}>
                                    <p style={{ fontSize: '14px', fontWeight: '600', textTransform: 'uppercase' }}>Terms of Delivery</p>

                                </div>
                                <br></br>

                            </Col>
                        </CustomRow>
                    </Col>
                </CustomRow>
            </Cardsin>
        )
    }

    return (
        <>

            <Flex margin={"20px"} gap={"10px"}>
                <Button.Primary text={<AiFillPrinter style={{ fontSize: '30px' }} />} onClick={handlePrint} />
                <PDFDownloadLink fileName='Invoice' document={<SaleReturnPdf record={record} activePro={activePro} />}>
                    <Button.Danger text={<AiFillFilePdf style={{ fontSize: '30px' }} />} />
                </PDFDownloadLink>

            </Flex>

            <PrintWrapper>
                <PrintHolder ref={componentRef}>

                    <Maindesign>
                        <CustomRow gutter={[12, 12]}>
                            <Col span={14}>
                                <div style={{ textAlign: 'center' }}>
                                    <InvoiceTitle Title={'INVOICE BILL'} />
                                </div>
                            </Col>
                            <Col span={10}>
                                <div style={{ textAlign: 'end', marginRight: '40px' }}>
                                    <p style={{ fontSize: '12px', fontWeight: '500', textTransform: 'uppercase' }}>(Original)</p>
                                </div>
                            </Col>
                        </CustomRow>
                        <HeaderTable />

                    </Maindesign>
                    {/* <br></br> */}
                    <BillTable >
                        <table>
                            <thead>
                                <tr>
                                    <th>Si.No</th>
                                    <th>Product</th>
                                    <th>ItemCode</th>
                                    <th>Quantity</th>
                                    <th>Price </th>
                                    <th>Discount</th>
                                    <th>GST</th>
                                    <th>SubTotal</th>
                                    <th>Total</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    viewData?.sale_retun?.sales?.map((item, index) => (
                                        <tr key={item.id}>
                                            <td>{index + 1}</td>
                                            <td>{item?.item_name}</td>
                                            <td>{item?.item_code}</td>
                                            <td>{item?.quantity}</td>
                                            <td>{item?.sale_price}</td>
                                            <td>{item?.sale_discount}</td>
                                            <td>{item?.tax_cal_amt}</td>
                                            <td>{item?.sub_total}</td>
                                            <td>{item?.item_cal_amt}</td>
                                        </tr>
                                    ))
                                }

                            </tbody>
                        </table>
                    </BillTable>
                    <PrintTableFooterHolders>
                        <Flex gap={'5px'} alignEnd={'true'}>
                            <PrintViewTitle style={{ width: '200px' }} >
                                TOTAL AMOUNT IN WORDS&nbsp;&nbsp;
                            </PrintViewTitle >
                            <h1>:</h1>
                            <PrintViewValue>
                                <h4 style={{ textTransform: 'uppercase', fontSize: '12px' }}>{viewData?.sale_retun?.rupees_words}</h4>

                            </PrintViewValue>
                        </Flex>
                        <CustomRow space={[12, 12]}>
                            <Col span={18} md={12}>
                            </Col>
                            <Col span={18} md={12}>

                                <Flex flexEnd={'true'}>
                                    <CustomRow space={[2, 2]}>
                                        <Col span={24} sm={12} md={12}>
                                            <Flex gap={'5px'} spaceBetween={'true'} alignEnd={'true'}>
                                                <PrintViewTitle>
                                                    TOTAL AMOUNT
                                                </PrintViewTitle>
                                                <h1>:</h1>
                                            </Flex>
                                        </Col>
                                        <Col span={24} sm={12} md={12}>
                                            <Flex alignEnd={'true'} H_100>
                                                <PrintViewValue>
                                                    {viewData?.sale_retun?.grand_total}
                                                </PrintViewValue>
                                            </Flex>
                                        </Col>
                                        <Col span={24} sm={12} md={12}>
                                            <Flex gap={'5px'} spaceBetween={'true'} alignEnd={'true'}>
                                                <PrintViewTitle>
                                                    CGST
                                                </PrintViewTitle>
                                                <h1>:</h1>
                                            </Flex>
                                        </Col>
                                        <Col span={24} sm={12} md={12}>
                                            <Flex alignEnd={'true'} H_100>
                                                <PrintViewValue>
                                                    {viewData?.sale_retun?.total_cgst_amt}
                                                </PrintViewValue>
                                            </Flex>
                                        </Col>
                                        <Col span={24} sm={12} md={12}>
                                            <Flex gap={'5px'} spaceBetween={'true'} alignEnd={'true'}>
                                                <PrintViewTitle>
                                                    SGST
                                                </PrintViewTitle>
                                                <h1>:</h1>
                                            </Flex>
                                        </Col>
                                        <Col span={24} sm={12} md={12}>
                                            <Flex alignEnd={'true'} H_100>
                                                <PrintViewValue>
                                                    {viewData?.sale_retun?.total_sgst_amt}
                                                </PrintViewValue>
                                            </Flex>
                                        </Col>
                                        <Col span={24} sm={12} md={12}>
                                            <Flex gap={'5px'} spaceBetween={'true'} alignEnd={'true'}>
                                                <PrintViewTitle>
                                                    IGST
                                                </PrintViewTitle>
                                                <h1>:</h1>
                                            </Flex>
                                        </Col>
                                        <Col span={24} sm={12} md={12}>
                                            <Flex alignEnd={'true'} H_100>
                                                <PrintViewValue>
                                                    {viewData?.sale_retun?.total_igst_amt}
                                                </PrintViewValue>
                                            </Flex>
                                        </Col>
                                    </CustomRow>
                                </Flex>
                            </Col>
                        </CustomRow>
                    </PrintTableFooterHolders>

                    <div style={{ pageBreakInside: 'avoid' }}>
                        <CustomRow gutter={[12, 12]}>
                            <Col span={24} md={10}><PrintSubTitle Under Size={'12px'} Weight={'600'}>Declaration</PrintSubTitle>
                                <PrintTitle Size={'10px'} >We declare that this invoice shows the actual price of the <br />
                                    goods described and that all particulars are true and correct</PrintTitle>
                            </Col>
                        </CustomRow>
                        <div style={{ border: '1px solid', padding: '2px 10px', marginTop: '5px' }}>
                            <CustomRow>
                                <Col span={12}><PrintSubTitle Size={'12px'} Weight={'600'}>Customer's Seal and Signature</PrintSubTitle></Col>
                                <Col span={12} style={{ textAlign: 'end' }}><b>for</b><PrintSubTitle Size={'14px'} Weight={'600'} UPPER> {activePro.business_name}</PrintSubTitle></Col>
                            </CustomRow>
                            <PrintTitle Size={'14px'} TextAlign={'end'} MT={'30px'}>Authorised Signatory  </PrintTitle>
                            <Flex flexEnd={'true'}>
                                <SignImageHolder >
                                    {activePro.signature ? (
                                        <img src={activePro.signature} alt="signature" />
                                    ) : null}
                                </SignImageHolder>
                            </Flex>
                        </div>
                        <PrintTitle Size={'14px'} TextAlign={'center'}>This is a Computer Generated Invoice</PrintTitle>
                    </div>
                </PrintHolder>
            </PrintWrapper>
        </>
    )
}

