import { Card, Col, Form } from "antd"
import React, { Fragment, useEffect, useState } from "react"
import { DeleteOutlined } from "@ant-design/icons";
import dayjs from 'dayjs'
import request from "../../../../utils/request";
import { toast } from "react-toastify";
import Flex from "../../../../components/Flex";
import { CustomRow } from "../../../../components/CustomRow";
import Button from "../../../../components/Form/CustomButton";
import { CustomInputNumber } from "../../../../components/Form/CustomInputNumber";
import { CustomSelect } from "../../../../components/Form/CustomSelect";
import { CustomSwitch } from "../../../../components/Form/CustomSwitch";
import { CustomTandardTable } from "../../../../components/Form/CustomStandardTable";
import { CustomInput } from "../../../../components/Form/CustomInput";
import { Navigate, useNavigate, useParams } from "react-router-dom";
import { ReturnFormHeader } from './ReturnFormHeader'
import { ReturnFormFooter } from './ReturnFormFooter'
import { ViewSaleBill } from "../../Partials/ViewSaleBill";
import { CustomModal } from "../../../../components/CustomModal";
import { SaleRetunBill } from "./SaleReturnBill";
import { useSelector } from "react-redux";
import { selectAllProfile } from "../../../BusinessProfile/BusinessSlice";



export const ReturnEntryPage = ({ setSale, SaleTrigger }) => {

  const { id } = useParams();

  const nevigate = useNavigate()

  const [variationadd, setVariationadd] = useState([])

  const [proCount, setProCount] = useState(1);  //  -->  Product Count
  const [serviceCount, setServiceCount] = useState(1);  //  -->  Service Count

  const [invoiceNumber, setInvoiceNumber] = useState({})  //  -->  Invoice Number
  const [returnNumber, setReturnNumber] = useState({})  //  -->  Return Number

  const [totalProductData, setTotalProductData] = useState([])  //  -->  Total Get TableDatsa

  const [selectedDate, setSelectedDate] = useState(dayjs().format('YYYY-MM-DD'));  //  -->  Invoice Date

  const [gstChecked, setGstChecked] = useState(false)  //  -->  GST Checked
  const [serviceClick, setServiceClick] = useState(false)  // --> Service Checked

  const [getdata, setGetdata] = useState([]) // --> Product Data

  const [selectedSale, setSelectedSale] = useState({})  // --> User Selected Data

  const [trigger, setTrigger] = useState(0);

  const [form] = Form.useForm();  //  --> Form Ref

  // -----------------  Balance Checking  ------------
  const [balanceAmount, setBalanceAmount] = useState(0);  // --> Product 
  const [balanceChangeAmount, setBalanceChangeAmount] = useState(0);  // --> Product 
  const [balanceChange, setBalanceChange] = useState(false);  // --> Product 

  // -----------------  Balance Checking  ------------
  const [balanceServiceAmount, setBalanceServiceAmount] = useState(0);  // --> Service 
  const [balanceChangeServiceAmount, setBalanceChangeServiceAmount] = useState(0);  // --> Service 
  const [balanceServiceChange, setBalanceServiceChange] = useState(false);  // --> Service 

  // Modal

  // ======  Modal Open ========
  const [isModalOpen, setIsModalOpen] = useState(false);
  // ======  Modal Title and Content ========
  const [modalTitle, setModalTitle] = useState("");
  const [modalContent, setModalContent] = useState(null);

  const [modalWidth, setModalWidth] = useState(null);

  const profileget = useSelector(selectAllProfile)

  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };

  useEffect(() => {
    SaleReturnGet()
    GetReturnNumber()
  }, [])

  // =================== Edit Reset Fields ==============

  useEffect(() => {
    if (totalProductData) {
      form.resetFields();
    }
  }, [SaleTrigger, totalProductData])

  // ======  Selected Party Details ====
  useEffect(() => {

    // ======  Party Details ====
    form.setFieldsValue({ party_name: totalProductData.party_name })
    form.setFieldsValue({ mobile_number: totalProductData.mobile_number })
    form.setFieldsValue({ billing_address: totalProductData.billing_address })
    form.setFieldsValue({ state_of_supply: totalProductData.state_of_supply })
    form.setFieldsValue({ shipping_address: totalProductData.shipping_address })
    form.setFieldsValue({ partyid: totalProductData.party })
    form.setFieldsValue({ GSTIN: totalProductData.GSTIN })
    if (!totalProductData.received_amt) {
      form.setFieldsValue({ return_date: totalProductData.return_date })
    }

    // ======  Invoice ====
    form.setFieldsValue({ invoice_no: totalProductData.invoice_no })
    form.setFieldsValue({ invoice_date: totalProductData.invoice_date })
    form.setFieldsValue({ sale_id: totalProductData.id })
   
    if (totalProductData.covert_salereturn === false) {
      form.setFieldsValue({ tax_total: null })
      form.setFieldsValue({ table_total: null })
      form.setFieldsValue({ discount_total: null })
      form.setFieldsValue({ qty_total: null })
      form.setFieldsValue({ grand_total: null })
      form.setFieldsValue({ paid_amt: null })

    } else {
      form.setFieldsValue({ tax_total: totalProductData?.tax_total })
      form.setFieldsValue({ table_total: totalProductData.table_total })
      form.setFieldsValue({ discount_total: totalProductData.discount_total })
      form.setFieldsValue({ qty_total: totalProductData.qty_total })
      form.setFieldsValue({ grand_total: totalProductData.grand_total })
      form.setFieldsValue({ paid_amt: totalProductData.paid_amt })
    }
  }, [totalProductData])

  // -------------------------------------------------------------  Variant Array Start
  const variationsArray = [];
  getdata?.item?.forEach(item => {
    const productName = item.item_name;
    const variationListId = item.variation_list.id;
    const ItemCode = item.variation_list.item_code;
    const AvailableQty = item.variation_list.available_qty;
    const SaleRate = item.variation_list.sale_rate;
    const Unit = item.unit;
    const StockMaintain = item.variation_list.variant_stock_maintain;

    let Variant = [];
    item.variation_list.varity.forEach(variation => {
      const variationName = variation.variation_name;
      const variationValue = variation.variation_value;
      Variant.push({ variationName, variationValue })
    });

    variationsArray.push({
      productName,
      Variant,
      ItemCode,
      AvailableQty,
      variationListId,
      SaleRate,
      Unit,
      StockMaintain,
    })
  });



  const setValue = totalProductData?.sales?.map(Val => ({
    label: Val.item_name,
    value: Val.item
  }))

  const setItemCode = totalProductData?.sales?.map(Val => ({
    label: Val.item_code,
    value: Val.item
  }))

  
  // ======  Product State Start =====

  const ProductInitialData = [
    {
      key: 0,
      description: '',
      item: '',
      item_name: '',
      item_code: '',
      quantity: '',
      unit: '',
      sale_price: '',
      discount_percentage: '',
      sale_discount: '',
      tax_percentage: '',
      tax_cal_amt: '',
      sub_total: '',
      item_cal_total_amt: '',
      available_qty: '',
      stock_maintain: '',
    }
  ]

  const ProSecondaryData = [
    {
      qty_total: '',
      discount_total: '',
      tax_total: '',
      table_total: '',
    },
  ];

  const ProFooterCalData = [
    {
      grand_total: '',
      received_amt: '',
      balance: '',
    },
  ];


  const [proTableData, setProTableData] = useState(ProductInitialData);
  const [proTabSecondaryData, setProTabSecondaryData] = useState(ProSecondaryData);
  const [ProTabFooterData, setProTabFooterData] = useState(ProFooterCalData);
  // ======  Product State End =====
  useEffect(() => {
    if (getdata.item_choice === 'Product') {
      proTableData.forEach(record => {
        form.setFieldsValue({ [`description${record.key}`]: record.description });
        form.setFieldsValue({ [`item${record.key}`]: record.item });
        form.setFieldsValue({ [`item_name${record.key}`]: record.item_name });
        form.setFieldsValue({ [`item_code${record.key}`]: record.item_code });
        form.setFieldsValue({ [`quantity${record.key}`]: record.quantity });
        form.setFieldsValue({ [`unit${record.key}`]: record.unit });
        form.setFieldsValue({ [`sale_price${record.key}`]: record.sale_price });
        form.setFieldsValue({ [`discount_percentage${record.key}`]: record.discount_percentage });
        form.setFieldsValue({ [`sale_discount${record.key}`]: record.sale_discount });
        form.setFieldsValue({ [`tax_percentage${record.key}`]: record.tax_percentage });
        form.setFieldsValue({ [`tax_cal_amt${record.key}`]: record.tax_cal_amt });
        form.setFieldsValue({ [`sub_total${record.key}`]: record.sub_total });
        form.setFieldsValue({ [`item_cal_total_amt${record.key}`]: record.item_cal_total_amt });
        form.setFieldsValue({ [`available_qty${record.key}`]: record.available_qty });
        form.setFieldsValue({ [`stock_maintain${record.key}`]: record.stock_maintain });
      });
      if (totalProductData?.covert_salereturn === false) {
        form.setFieldsValue({ [`qty_total`]: proTabSecondaryData[0].qty_total });
        form.setFieldsValue({ [`discount_total`]: proTabSecondaryData[0].discount_total });
        form.setFieldsValue({ [`tax_total`]: proTabSecondaryData[0].tax_total });
        form.setFieldsValue({ [`table_total`]: proTabSecondaryData[0].table_total });
        form.setFieldsValue({ grand_total: proTabSecondaryData[0].table_total });
        form.setFieldsValue({ received_amt: totalProductData.received_amt })
        form.setFieldsValue({ paid_amt: proTabSecondaryData[0].table_total });
      }

    }

  }, [proTableData])


  useEffect(() => {
    form.setFieldsValue({ return_no: returnNumber?.return_no })
  }, [returnNumber, SaleTrigger])

  useEffect(() => {
    form.setFieldsValue({ received_amt: ProTabFooterData?.received_amt })
  }, [ProTabFooterData])

  // ====================== 

  const BalanceOnChange = (value) => {
    setBalanceChangeAmount(value)
  }

  // ======  Product  End =====




  // ======== Product Received Balance ============


  useEffect(() => {
    HandleTableCal();
  }, [gstChecked])


  useEffect(() => {

    form.setFieldsValue({ 'balance': balanceAmount })

  }, [balanceAmount])


  // ===============  Table Data Start ==================

  const handleGSTSwitch = (e) => {   //----------------------------  Switch For Product or Service
    setGstChecked(e)
  }


  // ============  Product Table Columns

  const ProductColumns = [
    {
      title: '#',
      render: (text, record, index) => {
        return (
          (
            <Flex alignCenter gap={'20px'} style={{ alignItems: 'center' }}>
              <h4>{index + 1}</h4>
              <Button
                style={{
                  display: 'flex',
                  padding: '10px',
                  height: 'auto',
                  fontSize: '16px',
                  // display: 'none'
                }}
                htmlType="button"
                danger
                onClick={() => onProductTabRowDelete(record.key)}
              >
                <DeleteOutlined />
              </Button>
            </Flex>
          )
        );
      },
    },
    {
      title: (
        <p>Product&nbsp;Name</p>
      ),
      dataIndex: 'item_name',
      key: 'item_name',
      render: (text, record, index) => {
        return (
          <>
            <CustomSelect
              rules={[
                {
                  required: true,
                  message: 'This is a required field',
                },
              ]}
              minWidth={'230px'}
              showSearch={true}
              name={`item_name${record.key}`}
              options={setValue || []}
              disabled={true}
              onChange={(value) => handleOnChangeProduct(value, record)}
            />
            <CustomInputNumber
              minWidth={'150px'}
              name={`item${record.key}`}
              disabled={true}
              display={'none'}
            />
            <CustomInputNumber
              minWidth={'150px'}
              name={`stock_maintain${record.key}`}
              disabled={true}
              display={'none'}
            />
            {/* <CustomSelect
              rules={[
                {
                  required: true,
                  message: 'This is a required field',
                },
              ]}
              minWidth={'230px'}
              showSearch={true}
              name={`item${record.key}`}
              options={setValue}
              onChange={(value) => handleOnChangeProduct(value, record)}
            />
            <CustomInput
              minWidth={'150px'}
              name={`item_name${record.key}`}
              disabled
              display={'none'}
            /> */}
            <CustomInput
              minWidth={'150px'}
              name={`stock_maintain${record.key}`}
              display={'none'}
              disabled
            />
          </>
        );
      },
    },
    {
      title: (
        <p>Item&nbsp;Code</p>
      ),
      dataIndex: 'item_code',
      render: (text, record) => (
        <CustomInput
          rules={[
            {
              required: true,
              message: 'This is a required field',
            },
          ]}
          minWidth={'130px'}
          showSearch={true}
          name={`item_code${record.key}`}
          // options={setItemCode || []}
          disabled={true}
          onChange={(value) => handleOnChangeItemCodeProduct(value, record)} />
      )
    },
    {
      title: 'Unit',
      dataIndex: 'unit',
      render: (text, record) => (
        <>
          <CustomInput
            minWidth={'80px'}
            placed={'end'}
            disabled={true}
            name={`unit${record.key}`} />
          <div style={{ display: 'none' }}>
            <CustomInput
              minWidth={'80px'}
              style={{ textAlign: 'center' }}
              name={`available_qty${record.key}`}
              disabled={true}
            />
          </div>
        </>
      )
    },
    totalProductData?.covert_salereturn === true
      ? {
        title: 'Quantity',
        dataIndex: 'quantity',
        render: (text, record) => (
          <CustomInputNumber
            minWidth={'80px'}
            precision={2}
            placed={'end'}
            disabled={true}
            name={`quantity${record.key}`}
            onChange={(value) => handleOnChangeQuantity(value, record)}
          />
        ),
      }
      : {
        title: 'Quantity',
        dataIndex: 'quantity',
        render: (text, record) => (
          <CustomInputNumber
            minWidth={'80px'}
            precision={2}
            placed={'end'}
            // disabled={true}
            name={`quantity${record.key}`}
          />
        ),
      },
    // {
    //   title: 'Quantity',
    //   dataIndex: 'quantity',
    //   render: (text, record) => (
    //     <CustomInputNumber
    //       minWidth={'80px'}
    //       precision={2}
    //       placed={'end'}
    //       name={`quantity${record.key}`}
    //       onChange={(value) => handleOnChangeQuantity(value, record)}
    //       // {totalProductData ?}
    //     />
    //   )
    // },
    {
      title: 'Price',
      dataIndex: 'sale_price',
      key: 'sale_price',
      render: (text, record) => (
        <CustomInputNumber
          minWidth={'80px'}
          style={{ textAlign: 'center' }}
          name={`sale_price${record.key}`}
          precision={2}
          disabled={true}
          rules={[
            {
              required: true,
              message: 'This is a required field',
            },
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (parseFloat(value) <= 0) {
                  return Promise.reject('Price must be greater than 1');
                }
                return Promise.resolve();
              },
            }),
          ]}
          onChange={(value) => handleOnChangePrice(value, record)}
        />
      )
    },
    {
      title: 'Discount',
      children: [
        {
          title: '%',
          dataIndex: 'discount_percentage',
          key: 'discount_percentage',
          render: (text, record) => (
            <CustomInputNumber
              precision={2}
              minWidth={'80px'}
              placed={'end'}
              name={`discount_percentage${record.key}`}
              min={0.00}
              max={100.00}
              disabled={true}
              onChange={(value) => handleOnChangeDiscount(value, record)}
            />
          )
        },
        {
          title: 'Amount',
          dataIndex: 'sale_discount',
          key: 'sale_discount',
          render: (text, record) => (
            <CustomInputNumber
              precision={2}
              minWidth={'80px'}
              placed={'end'}
              disabled={true}
              name={`sale_discount${record.key}`}
            />
          )
        },
      ],
    },
    {
      title: 'Tax',
      children: [
        {
          title: '%',
          dataIndex: 'tax_percentage',
          key: 'tax_percentage',
          render: (text, record) => (
            <CustomInputNumber
              minWidth={'80px'}
              placed={'end'}
              precision={2}
              rules={[
                {
                  required: true,
                  message: 'This is a required field',
                },
              ]}
              name={`tax_percentage${record.key}`}
              min={0.00}
              max={100.00}
              disabled={true}
              onChange={(value) => handleOnChangeTax(value, record)}
            />
          )
        },
        {
          title: 'Amount',
          dataIndex: 'tax_cal_amt',
          key: 'tax_cal_amt',
          render: (text, record) => (
            <CustomInputNumber
              minWidth={'80px'}
              precision={2}
              disabled={true}
              placed={'end'}
              name={`tax_cal_amt${record.key}`}
            />
          )
        },
      ],
    },
    {
      title: (
        <p>Sub&nbsp;Total</p>
      ),
      dataIndex: 'sub_total',
      key: 'sub_total',
      render: (text, record) => (
        <CustomInputNumber precision={2}
          disabled
          minWidth={'80px'}
          placed={'end'}
          name={`sub_total${record.key}`}
        />
      )
    },
    {
      title: (
        <p>Total&nbsp;Amount</p>
      ),
      dataIndex: 'item_cal_total_amt',
      key: 'item_cal_total_amt',
      render: (text, record) => (
        <CustomInputNumber precision={2}
          disabled
          minWidth={'80px'}
          placed={'end'}
          name={`item_cal_total_amt${record.key}`}
        />
      )
    }
  ]

  // ===============  Table Data End ==================


  // ================== Product Table Functions Start ==================

  // ========= Product Delete  & AddRow ========

  const AddRow = () => { // ----------------- Add Row Function
    const newData = {
      key: proCount,
      description: '',
      item: '',
      item_name: '',
      item_code: '',
      quantity: '',
      unit: '',
      sale_price: '',
      discount_percentage: '',
      sale_discount: '',
      tax_percentage: '',
      tax_cal_amt: '',
      sub_total: '',
      item_cal_total_amt: '',
      available_qty: '',
      stock_maintain: '',
    };
    setProTableData(pre => {
      return [...pre, newData]
    })
    setProCount(proCount + 1);
  }

  const onProductTabRowDelete = (key) => {  // -----------------------  Delete Row Function
    if (proTableData.length > 1) {
      setProTableData(prevState => {
        const newData = prevState.filter(item => item.key !== key);

        // ------ Variables 
        let totalQuantity = 0;
        let totalDiscount = 0;
        let totalTax = 0;
        let totalAmount = 0;

        newData.forEach(item => {
          if (item.quantity !== '' || item.sale_discount !== '' || item.tax_cal_amt !== '' || item.item_cal_total_amt !== '') {
            totalQuantity += parseFloat(item.quantity);
            totalDiscount += parseFloat(item.sale_discount);
            totalTax += parseFloat(item.tax_cal_amt);
            totalAmount += parseFloat(item.item_cal_total_amt);
          }
        });

        // update the grand_total value in the tableSecondaryData array
        setProTabSecondaryData([{
          qty_total: totalQuantity.toFixed(2),
          discount_total: totalDiscount.toFixed(2),
          tax_total: totalTax.toFixed(2),
          table_total: totalAmount.toFixed(2),
        }]);

        return newData;
      });
    } else {
      console.log(`only ${proTableData.length} is available`)
    }
  };

  const CalculateTotal = (record) => {  // ----------------- 1. Calculate TotalAmount 

    setProTableData(prevState => {
      const newData = [...prevState];
      const index = newData.findIndex(item => record.key === item.key);
      const item = newData[index];

      // ------ Variables 
      let totalQuantity = 0;
      let totalDiscount = 0;
      let totalTax = 0;
      let totalAmount = 0;

      newData.forEach(item => {
        if (item.quantity !== '' || item.sale_discount !== '' || item.tax_cal_amt !== '' || item.item_cal_total_amt !== '') {
          totalQuantity += parseFloat(item.quantity);
          totalDiscount += parseFloat(item.sale_discount);
          totalTax += parseFloat(item.tax_cal_amt);
          totalAmount += parseFloat(item.item_cal_total_amt);
        }
      });

      // update the grand_total value in the tableSecondaryData array
      setProTabSecondaryData([{
        qty_total: totalQuantity.toFixed(2),
        discount_total: totalDiscount.toFixed(2),
        tax_total: totalTax.toFixed(2),
        table_total: totalAmount.toFixed(2),
      }]);

      return newData;
    });

  }


  // ================== Product Table Functions End ==================

  // ============  Products OnChange Functions  ==============

  const HandleQty = (value, record) => {  //  OnCHANGE QTY
  
    setProTableData(prevState => {
      const newData = [...prevState];
      const index = newData.findIndex(item => record.key === item.key);
      const item = newData[index];

      const valueObject = getdata.sales.find(obj => obj.item_name === record.item_name);
     

      let AvailableQty = valueObject.quantity;
      let QTY = 0;

      if (AvailableQty < value) {
        QTY = AvailableQty;
        toast.warn('Cannot Return More Than you Received')
      }
      else if (value <= 0) {
        QTY = 1;
      }
      else if (AvailableQty == 0) {
        QTY = 0;
      }
      else {
        QTY = value;

      }

      item.quantity = QTY;

      CalculateTotal({
        ...item,
        quantity: QTY,
      });

      HandleRowCal({
        ...item,
        quantity: QTY,
      })
      // form.setFieldsValue({ paid_amt: totalProductData.received_amt - totalProductData.grand_total })
      // setBalanceChangeAmount(totalProductData.received_amt)

      return newData;
    });
  };
  // ============  Products OnChange Functions end ==============

  const HandlePrice = (value, record) => {  //  OnCHANGE PRICE
    setProTableData(prevState => {
      const newData = [...prevState];
      const index = newData.findIndex(item => record.key === item.key);
      const item = newData[index];

      item.sale_price = value || 0;

      CalculateTotal({
        ...item,
        sale_price: item.sale_price,
      })

      HandleRowCal({
        ...item,
        sale_price: item.sale_price,
      })

      return newData;
    });
  }

  const HandleDiscount = (value, record) => {  //  ONCHANGE DISCOUNT
    setProTableData(prevState => {
      const newData = [...prevState];
      const index = newData.findIndex(item => record.key === item.key);
      const item = newData[index];

      let Dis_per = 0; // Dis - Percentage

      if (value <= 100)  // Less Than 100
      {
        Dis_per = value;
      }
      else {  // Greater Than 100
        Dis_per = 100;
      }

      CalculateTotal({
        ...item,
        discount_percentage: Dis_per,
      })

      HandleRowCal({
        ...item,
        discount_percentage: Dis_per,
      })

      item.discount_percentage = Dis_per;

      return newData;
    })
  }

  const HandleTax = (value, record) => {  // ONCHANGE TAX
    setProTableData(prevState => {
      const newData = [...prevState];
      const index = newData.findIndex(item => record.key === item.key);
      const item = newData[index];

      let Tax_per = 0; // Tax - Percentage

      if (value <= 100)  // Less Than 100
      {
        Tax_per = value;
      }
      else {  // Greater Than 100
        Tax_per = 100;
      }

      CalculateTotal({
        ...item,
        tax_percentage: Tax_per,
      })

      HandleRowCal({
        ...item,
        tax_percentage: Tax_per,
      })

      item.tax_percentage = Tax_per;

      return newData;
    })
  }
  // ========== product =============

  const HandleProduct = (value, record) => {   // ONCHANGE PRODUCT 
    setProTableData(prevState => {
      const newData = [...prevState];
      const index = newData.findIndex(item => record.key === item.key);
      const item = newData[index];

      const setSelectedSale = totalProductData?.sales?.find(item => item.item === value);
    
      if (setSelectedSale) {
        item.item_name = setSelectedSale.item_name;
        item.item = setSelectedSale.item; //setSelectedSale.item_name to set product name
        item.item_code = setSelectedSale.item_code;
        item.unit = setSelectedSale.unit;
        item.available_qty = setSelectedSale.available_qty;
        item.quantity = setSelectedSale.quantity;
        item.sale_price = setSelectedSale.sale_price;
        item.discount_percentage = setSelectedSale.discount_percentage;
        item.tax_percentage = setSelectedSale.tax_percentage;
        item.tax_cal_amt = setSelectedSale.tax_cal_amt;
        item.sale_discount = setSelectedSale.sale_discount;
        item.sub_total = setSelectedSale.sub_total;
        item.item_cal_total_amt = setSelectedSale.item_cal_total_amt;
        item.stock_maintain = selectedSale.stock_maintain ? 'true' : 'false'
      }

      const isItemAlreadyAdded = newData.some(
        (item, i) => i !== index && item.item === value
      );

      if (isItemAlreadyAdded) {

        item.item_name = '';


        toast.warn("Product already added in the table.");
        // return newData;
      }

      else {
        CalculateTotal({
          ...item,
          sale_price: setSelectedSale.sale_price,
          quantity: setSelectedSale.quantity,
        })

        HandleRowCal({
          ...item,
          sale_price: setSelectedSale.sale_price,
          quantity: setSelectedSale.quantity,
        })

      }
      return newData;
    });
  };

  const HandleItemCodeProduct = (value, record) => {   // ONCHANGE ITEM CODE

    setProTableData(prevState => {
      const newData = [...prevState];
      const index = newData.findIndex(item => record.key === item.key);
      const item = newData[index];

      const setSelectedSale = totalProductData?.sales?.find(item => item.item === value);

      if (setSelectedSale) {
        item.item_code = setSelectedSale.item_code;
      }

      const isItemAlreadyAdded = newData.some(
        (item, i) => i !== index && item.item === value
      );

      if (isItemAlreadyAdded) {
        // Product = '';
        // ProductName = '';
        // ItemCode = setSelectedSale.ItemCode;
        // AvailableQty = '';
        // Unit = '';
        // SalePrice = 0;
        // Quantity = 0;

        toast.warn("Product already added in the table.");
        // return newData;
      }


      // CalculateTotal({
      //   ...item,
      //   sale_price: SalePrice,
      //   quantity: Quantity,
      // })

      // HandleRowCal({
      //   ...item,
      //   sale_price: SalePrice,
      //   quantity: Quantity,
      // })

      return newData;
    });
  };


  // ===================  Whole Tax Row Calculation ============

  const HandleRowCal = (record) => {
    setProTableData(prevState => {
      const newData = [...prevState];
      const index = newData.findIndex(item => record.key === item.key);
      const item = newData[index];

      let sub_total = 0; // Sub - Total
      let grand_total = 0; // Grand - Total
      let tax_amount = 0; // Tax - Amount
      let dis_amount = 0; // Discount - Amount


      const price = record.sale_price || 0;
      const quantity = record.quantity || 0;
      const Tax_per = record.tax_percentage || 0;
      const Dis_per = record.discount_percentage || 0;

      const OriginalAmount = calculateProductTableAmount(item); // Qty x Price

      if (gstChecked)  //  GST CHECKED
      {
        // --------------  TAX PERCENTAGE NOT EQUAL TO ZERO ---
        if (Tax_per != 0) {
          // --------------  DisCount NOT EQUAL TO ZERO ---
          if (Dis_per != 0) {

            // --- Tax Calculation
            let TaxPlusHun = (Tax_per + 100);
            let ExcludingAmt = OriginalAmount * (100 / TaxPlusHun);

            // --- Discount Calculation
            let DisWithOutGST = (OriginalAmount * Dis_per) / 100;
            let TaxIncludePrice = OriginalAmount - DisWithOutGST;
            let DisAmt = (ExcludingAmt * Dis_per) / 100;

            // TaxAmount Calculation
            let SecExcludingAmt = TaxIncludePrice * (100 / TaxPlusHun);

            const TaxAmt = TaxIncludePrice - SecExcludingAmt;

            dis_amount = DisAmt;
            tax_amount = TaxAmt;
            sub_total = ExcludingAmt;
            grand_total = TaxIncludePrice;
          }
          else // --------------  DisCount EQUAL TO ZERO ---
          {
            // --- Tax Calculation

            let TaxPlusHun = (Tax_per + 100);
            let ExcludingAmt = OriginalAmount * (100 / TaxPlusHun);

            let TaxAmt = OriginalAmount - ExcludingAmt;
            let TaxQtyAmt = TaxAmt * quantity

            dis_amount = 0;
            tax_amount = TaxQtyAmt;
            sub_total = ExcludingAmt;
            grand_total = OriginalAmount;
          }
        }
        else // --------------  TAX PERCENTAGE EQUAL TO ZERO ---
        {
          // --------------  DisCount NOT EQUAL TO ZERO ---
          if (Dis_per != 0) {

            // --- Discount Calculation
            const DisMinus = (OriginalAmount * Dis_per) / 100;
            const ApplyDiscount = OriginalAmount - DisMinus;

            dis_amount = DisMinus;
            tax_amount = 0;
            sub_total = OriginalAmount;
            grand_total = ApplyDiscount;
          }
          else // --------------  DisCount EQUAL TO ZERO ---
          {
            dis_amount = 0;
            tax_amount = 0;
            sub_total = OriginalAmount;
            grand_total = OriginalAmount;
          }
        }
      }
      else // --------------  TAX PERCENTAGE NOT EQUAL TO ZERO ---
      {
        // --------------  TAX PERCENTAGE NOT EQUAL TO ZERO ---
        if (Tax_per != 0) {
          // --------------  DisCount NOT EQUAL TO ZERO ---
          if (Dis_per != 0) {

            // --- Discount Calculation
            const DisMinus = (OriginalAmount * Dis_per) / 100;
            const ApplyDiscount = OriginalAmount - DisMinus;

            // --- Tax Calculation
            const taxAmt = (ApplyDiscount * Tax_per) / 100;
            const ApplyTax = ApplyDiscount + taxAmt;

            dis_amount = DisMinus;
            tax_amount = taxAmt;
            sub_total = OriginalAmount;
            grand_total = ApplyTax;
          }
          else // --------------  DisCount EQUAL TO ZERO ---
          {
            // --- Tax Calculation
            const taxAmt = (OriginalAmount * Tax_per) / 100;
            const ApplyTax = OriginalAmount + taxAmt;

            dis_amount = 0;
            tax_amount = taxAmt;
            sub_total = OriginalAmount;
            grand_total = ApplyTax;
          }
        }
        else  // --------------  TAX PERCENTAGE EQUAL TO ZERO ---
        {
          // --------------  DisCount NOT EQUAL TO ZERO ---
          if (Dis_per != 0) {

            // --- Discount Calculation
            const DisMinus = (OriginalAmount * Dis_per) / 100;
            const ApplyDiscount = OriginalAmount - DisMinus;

            dis_amount = DisMinus;
            tax_amount = 0;
            sub_total = OriginalAmount;
            grand_total = ApplyDiscount;
          }
          else // --------------  DisCount EQUAL TO ZERO ---
          {
            dis_amount = 0;
            tax_amount = 0;
            sub_total = OriginalAmount;
            grand_total = OriginalAmount;
          }
        }
      }
      item.quantity = quantity;
      item.sale_price = price;
      item.discount_percentage = Dis_per;
      item.sale_discount = dis_amount;
      item.tax_percentage = Tax_per;
      item.tax_cal_amt = tax_amount;
      item.sub_total = sub_total;
      item.item_cal_total_amt = grand_total;

      CalculateTotal({
        ...item,
        quantity: quantity,
        sale_discount: dis_amount,
        tax_cal_amt: tax_amount,
        item_cal_total_amt: sub_total,
      })

      return newData;
    })
  }


  // ===================  Whole Tax Table Calculation ============

  const HandleTableCal = () => {

    setProTableData(prevState => {
      const newData = prevState.map(item => {
        let sub_total = 0;
        let grand_total = 0;
        let tax_amount = 0;
        let dis_amount = 0;

        const price = item.sale_price || 0;
        const quantity = item.quantity || 0;
        const Tax_per = item.tax_percentage || 0;
        const Dis_per = item.discount_percentage || 0;

        const OriginalAmount = calculateProductTableAmount(item);

        if (gstChecked) {
          if (Tax_per !== 0) {
            if (Dis_per !== 0) {
              let TaxPlusHun = Tax_per + 100;
              let ExcludingAmt = OriginalAmount * (100 / TaxPlusHun);

              let DisWithOutGST = (OriginalAmount * Dis_per) / 100;
              let TaxIncludePrice = OriginalAmount - DisWithOutGST;
              let DisAmt = (ExcludingAmt * Dis_per) / 100;

              let SecExcludingAmt = TaxIncludePrice * (100 / TaxPlusHun);

              const TaxAmt = TaxIncludePrice - SecExcludingAmt;

              dis_amount = DisAmt;
              tax_amount = TaxAmt;
              sub_total = ExcludingAmt;
              grand_total = TaxIncludePrice;
            } else {
              let TaxPlusHun = Tax_per + 100;
              let ExcludingAmt = OriginalAmount * (100 / TaxPlusHun);

              let TaxAmt = OriginalAmount - ExcludingAmt;
              let TaxQtyAmt = TaxAmt * quantity;

              dis_amount = 0;
              tax_amount = TaxQtyAmt;
              sub_total = ExcludingAmt;
              grand_total = OriginalAmount;
            }
          } else {
            if (Dis_per !== 0) {
              let DisMinus = (OriginalAmount * Dis_per) / 100;
              let ApplyDiscount = OriginalAmount - DisMinus;

              dis_amount = DisMinus;
              tax_amount = 0;
              sub_total = OriginalAmount;
              grand_total = ApplyDiscount;
            } else {
              dis_amount = 0;
              tax_amount = 0;
              sub_total = OriginalAmount;
              grand_total = OriginalAmount;
            }
          }
        } else {
          if (Tax_per !== 0) {
            if (Dis_per !== 0) {
              let DisMinus = (OriginalAmount * Dis_per) / 100;
              let ApplyDiscount = OriginalAmount - DisMinus;

              let taxAmt = (ApplyDiscount * Tax_per) / 100;
              let ApplyTax = ApplyDiscount + taxAmt;

              dis_amount = DisMinus;
              tax_amount = taxAmt;
              sub_total = OriginalAmount;
              grand_total = ApplyTax;
            } else {
              let taxAmt = (OriginalAmount * Tax_per) / 100;
              let ApplyTax = OriginalAmount + taxAmt;

              dis_amount = 0;
              tax_amount = taxAmt;
              sub_total = OriginalAmount;
              grand_total = ApplyTax;
            }
          } else {
            if (Dis_per !== 0) {
              let DisMinus = (OriginalAmount * Dis_per) / 100;
              let ApplyDiscount = OriginalAmount - DisMinus;

              dis_amount = DisMinus;
              tax_amount = 0;
              sub_total = OriginalAmount;
              grand_total = ApplyDiscount;
            } else {
              dis_amount = 0;
              tax_amount = 0;
              sub_total = OriginalAmount;
              grand_total = OriginalAmount;
            }
          }
        }

        item.quantity = quantity;
        item.sale_price = price;
        item.discount_percentage = Dis_per;
        item.sale_discount = dis_amount;
        item.tax_percentage = Tax_per;
        item.tax_cal_amt = tax_amount;
        item.sub_total = sub_total;
        item.item_cal_total_amt = grand_total;

        CalculateTotal({
          ...item,
          quantity: quantity,
          sale_discount: dis_amount,
          tax_cal_amt: tax_amount,
          item_cal_total_amt: sub_total,
        });

        return item;
      });

      return newData;
    });


  };

  // ---------------- 1.TotalQuantity ONCHANGE Function

  const handleOnChangeQuantity = (value, record) => {  //  ----> QUANTITY ONCHANGE (PRODUCT TABLE)
    HandleQty(value, record);  //  1227
  };

  const handleOnChangePrice = (value, record) => {   //  ----> PRICE ONCHANGE (PRODUCT TABLE)
    HandlePrice(value, record) //  1258
  }

  const handleOnChangeDiscount = (value, record) => {  //  ----> DISCOUNT ONCHANGE (PRODUCT TABLE)
    HandleDiscount(value, record)  //  1317
  }

  const handleOnChangeTax = (value, record) => {  //  -----> TAX ONCHANGE (PRODUCT TABLE)
    HandleTax(value, record)  // 1413
  }

  const handleOnChangeProduct = (value, record) => {  //  -----> PRODUCT ONCHANGE (PRODUCT TABLE)
    HandleProduct(value, record)
  }

  const handleOnChangeItemCodeProduct = (value, record) => {  //  -----> PRODUCT ONCHANGE (PRODUCT TABLE)
    HandleItemCodeProduct(value, record)
  }

  // -------------- Handle Total Row Amount  --------------
  const calculateProductTableAmount = (record) => {
    const quantity = parseFloat(record.quantity) || 0;
    const sale_amount = parseFloat(record.sale_price) || 0;
    return quantity * sale_amount
  }

  // ================== Product Table Functions End ==================


  // ====================  On Finish Function ============
  
  const onFinish = (values) => {
    const record = {
      ...values,
      return_date: selectedDate,
      item_choice: serviceClick ? 'Service' : 'Product',
      tax_choice: gstChecked ? 'GST' : 'NOGST',
    };
    let result = {
      party_name: record.party_name,
      party: record.partyid,
      mobile_number: record.mobile_number,
      invoice_date: record.invoice_date,
      return_date: record.return_date,
      stock_maintain_return: record.stock_maintain_return,
      invoice_no: record.invoice_no,
      return_no: record.return_no,
      state_of_supply: record.state_of_supply,
      billing_address: record.billing_address,
      shipping_address: record.shipping_address,
      item_choice: record.item_choice,
      tax_choice: record.tax_choice,
      qty_total: record.qty_total || 0,
      discount_total: record.discount_total,
      tax_total: record.tax_total,
      table_total: record.table_total,
      grand_total: record.grand_total,
      sale_id: record.sale_id,
      received_amt: record.received_amt ? parseFloat(record.received_amt).toFixed(2) : parseFloat(0).toFixed(2),
      balance: record.balance,
      GSTIN: record.GSTIN,
      paid_amt: record.paid_amt,

      sales: serviceClick
        ? Object.entries(record)
          .filter(([key]) => key.startsWith('item_code'))
          .map(([key, item_code]) => {                          // ----- Service ---------
            const index = key.match(/\d+/)[0];
            const itemNamekey = `item_name${index}`;
            const itemkey = `service${index}`;
            // const itemcode = `item_code${index}`;
            const unitKey = `unit${index}`;
            const salePriceKey = `sale_price${index}`;
            const discountpercnt = `discount_percentage${index}`;
            const saledisct = `sale_discount${index}`;
            const taxtpercnt = `tax_percentage${index}`;
            const taxt_total_amt = `tax_cal_amt${index}`;
            const item_Total_amt = `item_cal_total_amt${index}`;
            const subTotal = `sub_total${index}`;
            const stockMaintainReturn = `stock_maintain${index}`

            return {
              item_code,
              item_name: record[itemNamekey],
              service: record[itemkey],
              // item_code: record[itemcode],
              unit: record[unitKey],
              sale_price: record[salePriceKey] || 0,
              stock_maintain_return: record[stockMaintainReturn],
              discount_percentage: !isNaN(parseFloat(record[discountpercnt])) ? parseFloat(record[discountpercnt]).toFixed(2) : 0,
              sale_discount: !isNaN(parseFloat(record[saledisct])) ? parseFloat(record[saledisct]).toFixed(2) : 0,
              tax_percentage: !isNaN(parseFloat(record[taxtpercnt])) ? parseFloat(record[taxtpercnt]).toFixed(2) : 0,
              sub_total: !isNaN(parseFloat(record[subTotal])) ? parseFloat(record[subTotal]).toFixed(2) : 0,
              tax_cal_amt: !isNaN(parseFloat(record[taxt_total_amt])) ? parseFloat(record[taxt_total_amt]).toFixed(2) : 0,
              item_cal_amt: !isNaN(parseFloat(record[item_Total_amt])) ? parseFloat(record[item_Total_amt]).toFixed(2) : 0,
            };
          })
        :
        Object.entries(record)                             // ----- product ---------
          .filter(([key]) => key.startsWith('item_code'))
          .map(([key, item_code]) => {
            const index = key.match(/\d+/)[0];
            const itemNamekey = `item_name${index}`;
            const itemkey = `item${index}`;
            const itemdescription = `description${index}`;
            const itemAvailableQty = `available_qty${index}`;
            const availableQtyKey = `quantity${index}`;
            const unitKey = `unit${index}`;
            const salePriceKey = `sale_price${index}`;
            const discountpercnt = `discount_percentage${index}`;
            const saledisct = `sale_discount${index}`;
            const taxtpercnt = `tax_percentage${index}`;
            const taxt_total_amt = `tax_cal_amt${index}`;
            const item_Total_amt = `item_cal_total_amt${index}`;
            const subTotal = `sub_total${index}`;
            const stockMaintainReturn = `stock_maintain${index}`
            return {
              item_code,
              item_name: record[itemNamekey],
              item: record[itemkey],
              available_qty: record[itemAvailableQty],
              quantity: record[availableQtyKey],
              unit: record[unitKey],
              sale_price: record[salePriceKey] || 0,
              stock_maintain_return: record[stockMaintainReturn],
              discount_percentage: !isNaN(parseFloat(record[discountpercnt])) ? parseFloat(record[discountpercnt]).toFixed(2) : 0,
              sale_discount: !isNaN(parseFloat(record[saledisct])) ? parseFloat(record[saledisct]).toFixed(2) : 0,
              tax_percentage: !isNaN(parseFloat(record[taxtpercnt])) ? parseFloat(record[taxtpercnt]).toFixed(2) : 0,
              sub_total: !isNaN(parseFloat(record[subTotal])) ? parseFloat(record[subTotal]).toFixed(2) : 0,
              tax_cal_amt: !isNaN(parseFloat(record[taxt_total_amt])) ? parseFloat(record[taxt_total_amt]).toFixed(2) : 0,
              item_cal_amt: !isNaN(parseFloat(record[item_Total_amt])) ? parseFloat(record[item_Total_amt]).toFixed(2) : 0,
            };
          })
    };
    SalesReturnPost(result)

  };

  const SalesReturnPost = (values) => {
    request.post('sales/add_sale_return_detail/', values)
      .then(function (response) {
        if (response.status == 201) {
          toast.success('Successfully Return')
          form.resetFields();
          setTrigger((trigger) => trigger + 1);
          SaleReturnGet()
          GetReturnNumber()
          handleClick(response.data.id)
          setProTableData(ProductInitialData);
          setProTabSecondaryData(ProSecondaryData);
          setBalanceChangeAmount(0)
          // nevigate(-1)
        }
        else if (response.status == 226) {
          if (response.data) {
            if (response.data.message) {
              toast.warn(response.data.message[0]);
            }
          }
        }
      })
      .catch(function (error) {
        if (error.response && error.response.status === 400) {
          if (error.response.data) {
            if (error.response.data.GSTIN) {
              toast.warn(error.response.data.GSTIN[0]);
            } else if (error.response.data.mobile_number) {
              toast.warn(error.response.data.mobile_number[0]);
            } else {
              toast.error('Failed.');
            }
          }
        }
        else if (error.response.status === 406) {
          toast.warn("Please Add Business Profile!")
        } else if (error.response.status === 302) {
          toast.warn("Sale Order Return limit exceeded!, so cant be Submited, Please subscribe your plan!")
        }
        else if (error.response.status === 410) {
          toast.warn("You plan has been blocked, So Please subscribe your plan!")
        } else {
          toast.error("Something Went Wrong")
        }
      });
  }

  const SaleReturnGet = () => {
    // ------ sale table details
    request.get(`sales/sale_return_pro_detail/${id}/`)
      .then(function (response) {
        setTotalProductData(response.data?.sale)
        setGetdata(response.data?.sale)
        setVariationadd(response.data?.item_ser)
        if (!response.data.sale.received_amt) {

          const tableData = response.data?.sale.sales.map((value, index) => ({
            ...value,
            key: index
          }));
          setProTableData(tableData)
        } else {
          setProTableData(ProductInitialData)
        }

      })
      .catch(function (error) {
        console.log(error);
      });
  }

  const GetReturnNumber = () => {                         // ------ sale return num details
    request.get('sales/get_detail_salereturn/')
      .then(function (response) {
        setReturnNumber(response.data)
      })
      .catch(function (error) {
        console.log();
      });
  }




  const onFinishFailed = (errorInfo) => {
    toast.warn('Please Fill the Details!')
  };

  // ==============  Add Row Component  ================

  const FooterComponent = () => {
    return (
      <div style={{ background: 'var(--light-color)', padding: '20px' }}>
        <CustomRow>
          <Col lg={4} sm={12} span={24}>
            <Button type="primary" style={{
              fontSize: '1rem',
              height: 'auto',
              fontFamily: 'Poppins',
              fontWeight: 500,
              letterSpacing: '1px',
            }}
              htmlType="button"
              onClick={AddRow}>
              Add Row
            </Button>
          </Col>
        </CustomRow>
      </div >
    )
  }


  // ==================  Table  ==================

  const onRest = () => {
    form.resetFields();
    form.setFieldsValue({ invoice_no: totalProductData?.invoice_no })
    form.setFieldsValue({ return_no: returnNumber?.return_no })
    setSelectedSale(!selectedSale)
    setTrigger((trigger) => trigger + 1);
    setProTableData(ProductInitialData);
    setProTabSecondaryData(ProSecondaryData);


  }


  const handleServiceSwitch = () => {
    if (serviceClick !== true) {
      form.resetFields();
      form.setFieldsValue({ invoice_no: invoiceNumber?.invoice_no });
      setServiceClick(true);
      setSelectedSale(true);

    } else {
      form.resetFields();
      form.setFieldsValue({ invoice_no: invoiceNumber?.invoice_no });
      setSelectedSale(true);
      setServiceClick(false);

      setProTableData(ProductInitialData)
      setProTabSecondaryData(ProSecondaryData)
    }
  };
  const isDisabled = serviceClick ? balanceServiceChange : balanceChange;



  const handleClick = (record) => {
    setModalWidth(400)
    setModalTitle("Sale Return Print");
    setModalContent(<LogOutModal record={record} />);
    showModal();
  }

  const printOk = async (record) => {
    try {
      const response = await request.get(`sales/get_print_sale_return/${record.record}/`);
      setModalWidth(1200)
      setModalTitle("Sale Return Details");
      setModalContent(<SaleRetunBill record={response.data} />);
      showModal();
      return response.data;
    }
    catch (error) {
  
    }
  };


  const LogOutModal = (record) => (
    <div>
      <h1 style={{ fontSize: '1.2rem' }}>Are you Sure You Want to Print ?</h1>
      <br />
      <Flex gap={'20px'} W_100 center verticallyCenter>
        <Button.Success text={'Print'} onClick={() => printOk(record)} />
        <Button.Danger text={'Cancel'} onClick={handleOk} />
      </Flex>
    </div>
  )

  return (
    <Fragment>

      <Form name="sales"
        labelCol={{
          span: 24,
        }}
        wrapperCol={{
          span: 24,
        }}
        form={form}
        initialValues={
          {
            return_date: dayjs(),
          }
        }
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}>
        <Flex alignCenter centervertically>
          <div style={{ display: 'none' }}>
            <CustomSwitch onChange={handleServiceSwitch} leftLabel={'Product'} />
          </div>
          <h2 style={{ fontWeight: 'bold', fontSize: '20px' }}>Product</h2>
        </Flex>
        <br />
        <ReturnFormHeader gstChecked={gstChecked} trigger={trigger} setSale={setSale} setSelectedDate={setSelectedDate}
          setGetdata={setGetdata} selectedSale={selectedSale} setSelectedSale={setSelectedSale} totalProductData={totalProductData} />

        <Flex end={true} style={{ margin: '20px 0px' }}>
          {/* <CustomSwitch leftLabel={'WithOut GST'} rightLabel={'With GST'} onChange={handleGSTSwitch} /> */}
        </Flex>

        <div style={{ margin: '20px 0' }}>

          <CustomTandardTable columns={ProductColumns.filter(Boolean)} data={proTableData} pagination={false} />

          <FooterComponent />

        </div>

        <div style={{ margin: '20px 0' }}>
          <ReturnFormFooter BalanceOnChange={BalanceOnChange} totalProductData={totalProductData} serviceClick={serviceClick} />
        </div>

        <Card>
          {totalProductData?.covert_salereturn === true ? null :
            <Flex center gap={'10px'}>
              <Button.Primary text={'Submit'} htmlType="submit" disabled={isDisabled} />
              <Button.Danger text={'Back'} onClick={() => nevigate(-1)} />
            </Flex>
          }

          <CustomModal isVisible={isModalOpen} handleOk={handleOk} handleCancel={handleCancel}
            width={modalWidth}
            modalTitle={modalTitle} modalContent={modalContent} />
        </Card>
      </Form>

    </Fragment>
  )
}