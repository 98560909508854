import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import request from "../../utils/request";

const initialState = {
    service: [],
    status: 'idle',  // 'idle' | 'loading' | 'succeeded' | 'failed'  
    error: null
}

export const getService = createAsyncThunk(
    "Service/Get",
    async () => {
        try {
            const response = await request.get('service/add_service/');
            return [...response.data];
        }
        catch (error) {
            throw error;
        }
    }
);

const serviceSlice = createSlice({
    name: 'service',
    initialState,
    reducers: {
    },
    extraReducers: (builder) => {
        builder
            .addCase(getService.pending, (state, action) => {
                state.status = 'loading'
            })
            .addCase(getService.fulfilled, (state, action) => {
                state.status = 'succeeded'
                state.service = action.payload;
            })
            .addCase(getService.rejected, (state, action) => {
                state.status = 'failed'
                state.error = action.error.message
            })
    }
})

export const selectAllService = (state) => state.services.service
export const getServiceStatus = (state) => state.services.status
export const getServiceError = (state) => state.services.error

export const { reducer } = serviceSlice;

export default serviceSlice.reducer


