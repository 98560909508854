import React, { Fragment } from 'react'
import { AddExpenseForm } from './Partials/AddExpenseForm'
import { CustomPageTitle } from '../../../components/CustomPageTitle'
import { CustomCardView } from '../../../components/CustomCardView'
import Flex from '../../../components/Flex'



export const AddExpense = () => {
    return (
        <Fragment>
            <Flex alignCenter>
                <CustomPageTitle Heading={'Expense'} />
            </Flex>
            <CustomCardView>
                <AddExpenseForm />
            </CustomCardView>
        </Fragment>
    )
}
