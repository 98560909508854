import React, { useState, useEffect } from 'react';
import { Layout, Tabs } from 'antd';
import styled from 'styled-components';
import SaleUnpaid from '../../Sale/Partials/SaleUnpaid';
import SaleReturnUnpaid from '../../SaleReturn/Partials/SaleReturnUnpaid';
import PurchaseUnpaid from '../../Purchase/Partials/PurchaseUnpaid';
import PurchaseRetunUnpaid from '../../PurchaseReturn/Partials/PurchaseRetunUnpaid';
import { CustomCardView } from '../../../../components/CustomCardView';


const { Content } = Layout;
const { TabPane } = Tabs;

const UnpaidBills = () => {
  const [tabPosition, setTabPosition] = useState('top'); 
  const [activeTab, setActiveTab] = useState('');

  useEffect(() => {
    const storedTab = localStorage.getItem('activeTab');
    if (storedTab) {
      setActiveTab(storedTab);
    }
  }, []);

  useEffect(() => {
    if (activeTab) {
      localStorage.setItem('activeTab', activeTab);
    }
  }, [activeTab]);

  useEffect(() => {
    
    const handleResize = () => {
      if (window.innerWidth < 768) {
        setTabPosition('top');
      } else {
        setTabPosition('left');
      }
    };

    // Initial check when the component mounts
    handleResize();

    // Listen for window resize events
    window.addEventListener('resize', handleResize);

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const handleTabChange = (key) => {
    setActiveTab(key);
  };

  const TabHeader = styled(Tabs)`
  :where(.css-dev-only-do-not-override-190m0jy).ant-tabs >.ant-tabs-nav .ant-tabs-nav-list, :where(.css-dev-only-do-not-override-190m0jy).ant-tabs >div>.ant-tabs-nav .ant-tabs-nav-list {
    position: relative;
    display: flex;
    transition: opacity 0.3s;
    margin-top: 56px;

}
  `;

  return (
    <Layout>
      <Content style={{ margin: '24px 16px', padding: 0 }}>
    <CustomCardView>
      <TabHeader tabPosition={tabPosition} activeKey={activeTab} onChange={handleTabChange}>
        <TabPane tab="Sale " key="1">
          <SaleUnpaid />
        </TabPane>
        <TabPane tab="Sale Return" key="2">
          <SaleReturnUnpaid />
        </TabPane>
        <TabPane tab="Purchase" key="4">
          <PurchaseUnpaid />
        </TabPane>
        <TabPane tab="Purchase Return" key="5">
          <PurchaseRetunUnpaid />
        </TabPane>
      </TabHeader>
    </CustomCardView>
     </Content>
  </Layout>
  );
};

export default UnpaidBills;
