import React from 'react'
import SaleUnpaid from './Partials/SaleUnpaid'
import { CustomPageTitle } from '../../../components/CustomPageTitle'

export const SaleUnpaileBill = () => {
    return (
        <div>
            <CustomPageTitle Heading={'Sale Bill'} />
            <SaleUnpaid />
        </div>
    )
}
