import React, { Fragment } from 'react'
import dayjs from 'dayjs'
import { Document, Page, View, Text, Image,StyleSheet } from '@react-pdf/renderer';
import { ToWords } from 'to-words';

const QuotationPdfView = ({ record, activePro }) => {

    const styles = StyleSheet.create({
        page: {
          fontFamily: "Helvetica",
          fontSize: 11,
          lineHeight: 1.5,
          padding:'11px 10px',
          flexDirection: "column"
        },})

    const toWords = new ToWords({
        localeCode: 'en-IN',
        converterOptions: {
            currency: true,
            ignoreDecimal: false,
            ignoreZeroCurrency: false,
            doNotAddOnly: false,
            currencyOptions: { // can be used to override defaults for the selected locale
                name: 'Rupee',
                plural: 'Rupees',
                symbol: '₹',
                fractionalUnit: {
                    name: 'Paisa',
                    plural: 'Paise',
                    symbol: '',
                },
            }
        }
    });

    const PrintedDate = dayjs().format('DD-MMM-YY [at] HH:mm');
    const formattedDate = dayjs(record?.quotation.invoice_date).format('DD-MMM-YY');
    const formattedAmount = toWords.convert(record?.quotation?.grand_total, { currency: true });

    const Header = () => {
        return (
            <>
                <View style={{ flexDirection: 'row' }}>
                    <View style={{ flex: 0.5, alignItems: 'center' }}>
                        <Text style={{ fontSize: '10px', fontWeight: 'bold' }}>Quotation</Text>
                    </View>
                    <View style={{ flex: 0.5, alignItems: 'flex-end', paddingRight: '10px', gap: '3px' }}>
                        <Text style={{ fontSize: '10px', fontWeight: 'bold', }}>Printed on {PrintedDate}</Text>
                        <Text style={{ fontSize: '11px', fontWeight: 'bold', }}>(ORIGINAL FOR RECEIPT)</Text>
                    </View>
                </View>

                <View style={{ flexDirection: 'row', border: '1px solid black', marginTop: '5px' }}>
                    <View style={{ flex: 0.5, gap: '10px' }}>
                        <View style={{ gap: '5px', padding: '5px' }}>
                            <Text style={{ fontSize: '11px', fontWeight: 'bold', }}>{activePro?.business_name.toUpperCase()}</Text>
                            <Text style={{ fontSize: '10px', fontWeight: 'bold', }}>{activePro?.business_address}, {activePro?.state}-{activePro?.pincode} .</Text>
                            <Text style={{ fontSize: '10px', fontWeight: 'bold', }}>GSTIN / UIN&nbsp;:&nbsp;{activePro?.gst_no}.</Text>
                            <Text style={{ fontSize: '10px', fontWeight: 'bold', }}>State&nbsp;Name&nbsp;:&nbsp;{activePro?.state}.</Text>
                            <Text style={{ fontSize: '10px', fontWeight: 'bold', }}>E-Mail&nbsp;:&nbsp;{activePro?.email}</Text>
                        </View>
                        <View style={{ gap: '4px', borderTop: '1px solid black', padding: '5px' }}>
                            <Text style={{ fontSize: '11px', fontWeight: 'bold', textDecoration: 'underline' }}>Buyer (Billing Address)</Text>
                            <Text style={{ fontSize: '10px', fontWeight: 'bold', }}>{record?.quotation.party_name}</Text>
                            <Text style={{ fontSize: '10px', fontWeight: 'bold', }}>{record?.quotation.billing_address}</Text>
                            <Text style={{ fontSize: '10px', fontWeight: 'bold', }}>GSTIN / UIN&nbsp;:&nbsp;{record?.quotation?.GSTIN}</Text>
                            <Text style={{ fontSize: '10px', fontWeight: 'bold', }}>State of Supply&nbsp;:&nbsp;{record?.quotation.state_of_supply}</Text>
                            <Text style={{ fontSize: '10px', fontWeight: 'bold', }}>Contact&nbsp;:&nbsp;{record?.quotation?.mobile_number}</Text>
                        </View>
                    </View>
                    <View style={{ flex: 0.5, borderLeft: '1px solid black' }}>
                        <View style={{ flexDirection: 'row', borderBottom: '1px solid black' }}>
                            <View style={{ flex: 0.5, gap: '2px', padding: '3px' }}>
                                <Text style={{ fontSize: '10px', fontWeight: 'bold', }}>Invoice No.</Text>
                                <Text style={{ fontSize: '11px', fontWeight: 'bold', }}>{record.quotation?.ref_no}</Text>
                            </View>
                            <View style={{ flex: 0.5, gap: '2px', borderLeft: '1px solid black', padding: '3px' }}>
                                <Text style={{ fontSize: '10px', fontWeight: 'bold', }}>Dated</Text>
                                <Text style={{ fontSize: '11px', fontWeight: 'bold', }}>{formattedDate}</Text>
                            </View>
                        </View>
                        <View >
                            <View style={{ gap: '4px', borderBottom: '1px solid black', padding: '5px 10px', }}>
                                <Text style={{ fontSize: '11px', fontWeight: 'bold', textDecoration: 'underline' }}>Shipping Address</Text>
                                <Text style={{ fontSize: '10px', fontWeight: 'bold', }}>{record?.quotation.party_name}</Text>
                                <Text style={{ fontSize: '10px', fontWeight: 'bold', }}>{record?.quotation.shipping_address}</Text>
                                <Text style={{ fontSize: '10px', fontWeight: 'bold', }}>GSTIN / UIN&nbsp;:&nbsp;{record?.quotation?.GSTIN}</Text>
                                <Text style={{ fontSize: '10px', fontWeight: 'bold', }}>State of Supply&nbsp;:&nbsp;{record?.quotation.state_of_supply}</Text>
                                <Text style={{ fontSize: '10px', fontWeight: 'bold', }}>Contact&nbsp;:&nbsp;{record?.quotation?.mobile_number}</Text>
                            </View>
                            <View style={{ padding: '5px' }}>
                                <Text style={{ fontSize: '11px', fontWeight: 'bold' }}>Terms of Delivery</Text>
                            </View>
                        </View>
                    </View>
                </View>
            </>

        )
    }

    const TableHeadings = () => {
        return (
            <View style={{ display: 'flex', flexDirection: 'row', border: '1px solid black', marginTop: '10px', borderTop: '1px solid black' }}>
                <View style={{ flex: '0.5', borderRight: '1px solid black', padding: '10px 0'}}>
                    <Text style={{ fontSize: '10px', fontWeight: 'bold', textAlign: 'center' }}>Si.No</Text>
                </View>
                <View style={{ flex: '1.5', borderRight: '1px solid black', padding: '10px 0' }}>
                    <Text style={{ fontSize: '10px', fontWeight: 'bold', textAlign: 'center' }}>Item Name</Text>
                </View>
                {record?.quotation?.item_choice === 'Product' &&
                    <>
                        <View style={{ flex: '1', borderRight: '1px solid black', padding: '10px 0' }}>
                            <Text style={{ fontSize: '10px', fontWeight: 'bold', textAlign: 'center' }}>Quantity</Text>
                        </View>
                        <View style={{ flex: '1', borderRight: '1px solid black', padding: '10px 0' }}>
                            <Text style={{ fontSize: '10px', fontWeight: 'bold', textAlign: 'center' }}>Unit</Text>
                        </View>
                    </>
                }
                <View style={{ flex: '1', borderRight: '1px solid black', padding: '10px 0' }}>
                    <Text style={{ fontSize: '10px', fontWeight: 'bold', textAlign: 'center' }}>{record?.quotation?.item_choice === 'Product' ? 'Price/unit' : 'Price'}</Text>
                </View>
                <View style={{ flex: '1', padding: '10px 0', borderRight: '1px solid black' }}>
                    <Text style={{ fontSize: '10px', fontWeight: 'bold', textAlign: 'center' }}>Discount</Text>
                </View>
                <View style={{ flex: '1', padding: '10px 0', borderRight: '1px solid black' }}>
                    <Text style={{ fontSize: '10px', fontWeight: 'bold', textAlign: 'center' }}>GST</Text>
                </View>
                <View style={{ flex: '1', padding: '10px 0' }}>
                    <Text style={{ fontSize: '10px', fontWeight: 'bold', textAlign: 'center' }}>Total</Text>
                </View>
            </View>
        )
    }

    const Footer = () => {
        return (
            <View style={{ position: 'relative', width: '100vw', padding: '10px 30px',pageBreakInside:'avoid' }}>
                <View style={{ marginTop: '50px' }}>
                    <View style={{ display: 'flex', flexDirection: 'row' }}>
                        <View style={{ flex: '0.5' }}>
                            <Text style={{ fontSize: '10px', fontWeight: '500', marginBottom: '5px' }}>Amount Chargeable (in words)</Text>
                            <Text style={{ fontSize: '10px', fontWeight: '500', marginBottom: '10px' }}>INR {formattedAmount}</Text>
                        </View>
                        <View style={{ flex: '0.5', display: 'flex', alignItems: "flex-end", flexDirection: 'row', justifyContent: 'flex-end', borderBottom: '1px solid black' }}>
                            <View style={{ flex: '0.5', alignItems: "flex-end" }}>
                                {record?.quotation?.item_choice === 'Product' &&
                                    <Text style={{ fontSize: '11px', fontWeight: '500', marginBottom: '10px' }}>Total Quantity :</Text>
                                }
                                <Text style={{ fontSize: '11px', fontWeight: '500', marginBottom: '10px' }}>Total Discount :</Text>
                                <Text style={{ fontSize: '11px', fontWeight: '500', marginBottom: '10px' }}>Total CGST :</Text>
                                <Text style={{ fontSize: '11px', fontWeight: '500', marginBottom: '10px' }}>Total SGST :</Text>
                            </View>
                            <View style={{ flex: '0.5', alignItems: "flex-end" }}>
                                {record?.quotation?.item_choice === 'Product' &&
                                    <Text style={{ fontSize: '11px', fontWeight: '500', marginBottom: '10px' }}>{record?.quotation?.qty_total}</Text>
                                }
                                <Text style={{ fontSize: '11px', fontWeight: '500', marginBottom: '10px' }}>Rs.&nbsp;{record?.quotation?.discount_total}</Text>
                                <Text style={{ fontSize: '11px', fontWeight: '500', marginBottom: '10px' }}>Rs.&nbsp;{record?.quotation?.total_cgst_amt}</Text>
                                <Text style={{ fontSize: '11px', fontWeight: '500', marginBottom: '10px' }}>Rs.&nbsp;{record?.quotation?.total_sgst_amt}</Text>
                            </View>
                        </View>
                    </View>
                    <View style={{ display: 'flex', flexDirection: 'row', }}>
                        <View style={{ flex: '0.5' }}>

                        </View>
                        <View style={{ flex: '0.5', display: 'flex', flexDirection: 'row' }}>
                            <Text style={{ fontSize: '11px', fontWeight: 'bold', flex: '0.5', textAlign: 'right', marginTop: '5px' }}>Total Amount :</Text>
                            <Text style={{ fontSize: '11px', fontWeight: 'bold', flex: '0.5', textAlign: 'right', marginTop: '5px' }}>Rs.&nbsp;{record?.quotation?.grand_total}</Text>
                        </View>
                    </View>
                </View>
                <View style={{ display: 'flex', flexDirection: 'row', marginTop: '20px', }}>
                    <View style={{ flex: '0.5' }}>
                        <Text style={{ fontSize: '11px', fontWeight: 'bold', marginBottom: '10px' }}>Delcaration</Text>
                        <Text style={{ fontSize: '10px', fontWeight: '500', paddingRight: '20px' }}>We declare that this invoice shows the actual price of the goods described and that all particulars are true and correct</Text>
                    </View>
                </View>
                <View style={{ border: '1px solid black', padding: '4px', marginTop: '10px', display: 'flex', flexDirection: 'column' }}>
                    <View style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                        <Text style={{ fontSize: '11px', fontWeight: 'bold', textAlign: 'left' }}>Customer's Seal and Signature</Text>
                        <Text style={{ fontSize: '11px', fontWeight: 'bold', textAlign: 'right' }}>For : {activePro?.business_name}</Text>
                    </View>
                    <View style={{ marginTop: '30px' }}>
                        <Text style={{ fontSize: '11px', fontWeight: 'bold', textAlign: 'right' }}>Authorised Signatory</Text>
                       
                    </View>

                </View>
                <Text style={{ fontSize: '10px', fontWeight: '500', textAlign: 'center', marginTop: '10px' }}>This is a Computer generated Invoice</Text>
            </View>
        )
    }


    const objectsPerPage = 100;
    const totalPages = Math.ceil((record?.quotation?.sales?.length) / objectsPerPage)


    
    return (
        <Document >
            {Array.from({ length: totalPages }, (_, pageIndex) => (
                <Page   style={styles.page}>
                    <Fragment>
                        {pageIndex === 0 && (
                            <Header />
                        )}

                        <TableHeadings />
                        {record?.quotation?.sales
                            ?.slice(pageIndex * objectsPerPage, (pageIndex + 1) * objectsPerPage)
                            ?.map((value, index) => {
                                return (
                                    <View style={{ display: 'flex', flexDirection: 'row', }}>

                                        <View style={{ flex: '0.5', padding: '5px 0', borderBottom: '1px solid black', borderRight: '1px solid black', borderLeft: '1px solid black' }}>
                                            <Text style={{ fontSize: '10px', textAlign: 'center' }}>{index + 1}</Text>
                                        </View>
                                        <View style={{ flex: '1.5', padding: '5px 0', borderBottom: '1px solid black', borderRight: '1px solid black' }}>
                                            <Text style={{ fontSize: '10px', textAlign: 'center' }}>{value.item_name}</Text>
                                        </View>
                                        {record?.quotation?.item_choice === 'Product' &&
                                            <>
                                                <View style={{ flex: '1', padding: '5px 0', borderBottom: '1px solid black', borderRight: '1px solid black' }}>
                                                    <Text style={{ fontSize: '10px', textAlign: 'center' }}> {value.quantity}</Text>
                                                </View>
                                                <View style={{ flex: '1', padding: '5px 0', borderBottom: '1px solid black', borderRight: '1px solid black' }}>
                                                    <Text style={{ fontSize: '10px', textAlign: 'center' }}>{value.unit}</Text>
                                                </View>
                                            </>
                                        }
                                        <View style={{ flex: '1', padding: '5px 0', borderBottom: '1px solid black', borderRight: '1px solid black' }}>
                                            <Text style={{ fontSize: '10px', textAlign: 'center' }}>{value.sale_price}</Text>
                                        </View>
                                        <View style={{ flex: '1', padding: '5px 0', borderBottom: '1px solid black', borderRight: '1px solid black' }}>
                                            <Text style={{ fontSize: '10px', textAlign: 'center' }}>{value.sale_discount}</Text>
                                        </View>
                                        <View style={{ flex: '1', padding: '5px 0', borderBottom: '1px solid black', borderRight: '1px solid black' }}>
                                            <Text style={{ fontSize: '10px', textAlign: 'center' }}>{value.tax_cal_amt}</Text>
                                            <Text style={{ fontSize: '10px', textAlign: 'center' }}>({value.tax_percentage}%)</Text>
                                        </View>
                                        <View style={{ flex: '1', padding: '5px 0', borderBottom: '1px solid black', borderRight: '1px solid black' }}>
                                            <Text style={{ fontSize: '10px', textAlign: 'center' }}>{value.item_cal_total_amt}</Text>
                                        </View>

                                    </View>
                                )
                            })}
                        {pageIndex === totalPages - 1 && (
                            <Footer />
                        )}
                    </Fragment>

                </Page>
            ))}
        </Document>
    )
}

export default QuotationPdfView