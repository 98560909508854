import React, { useEffect, useState } from 'react'
import { CustomModal } from '../../../../components/CustomModal'
import Button from '../../../../components/Form/CustomButton';
import Flex from '../../../../components/Flex';
import SalePay from './SalePay';
import { CustomTable } from '../../../../components/Form/CustomTable';
import { CustomPageTitle } from '../../../../components/CustomPageTitle';
import { CommonLoading } from '../../../../components/CommonLoading';
import { useDispatch, useSelector } from 'react-redux';
import { getSalesUnpaid, getSalesUnpaidError, getSalesUnpaidStatus, selectAllSalesUnpaid } from '../../UnpaidSlice';
import { CustomTandardTable } from '../../../../components/Form/CustomStandardTable';


const SaleUnpaid = () => {

    // ======  Modal Open ========
    const [isModalOpen, setIsModalOpen] = useState(false);

    // ======  Modal Title and Content ========
    const [modalTitle, setModalTitle] = useState("");
    const [modalContent, setModalContent] = useState(null);
    const [dataSource, setDataSource] = useState([])
    const [trigger, setTrigger] = useState(0)

    // ----------  Form Reset UseState ---------
    const [formReset, setFormReset] = useState(0);
    const [modelwith, setModelwith] = useState(0);

    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(getSalesUnpaid())
    }, [])

    const AllUnpaidSales = useSelector(selectAllSalesUnpaid)
    const AllUnpaidSalesStatus = useSelector(getSalesUnpaidStatus)
    const AllUnpaidError = useSelector(getSalesUnpaidError)

    useEffect(() => {
        setDataSource(AllUnpaidSales)
    }, [AllUnpaidSales])


    // ===== Modal Functions Start =====

    const showModal = () => {
        setIsModalOpen(true);
    };

    const handleOk = () => {
        setIsModalOpen(false);
    };

    const handleCancel = () => {
        setIsModalOpen(false);
    };


    const onPayMethod = (record) => {
        setModelwith(800)
        setTrigger(trigger + 1)
        setModalTitle("Pay Bill");
        setModalContent(<SalePay salesunpaidrecord={record} handleOk={handleOk} trigger={trigger} />);
        showModal();
    };

    const columns = [
        {
            title: 'Sl No',
            render: (item, value, index) => index + 1,
        },
        {
            title: 'Invoice Number',
            dataIndex: 'invoice_no',
        },
        {
            title: 'Date',
            dataIndex: 'invoice_date',
        },
        {
            title: 'Party Name',
            dataIndex: 'party_name',
        },
        {
            title: 'Mobile Number',
            dataIndex: 'mobile_number',
        },
        {
            title: 'Total Bill Amount',
            dataIndex: 'grand_total',
        },
        {
            title: 'Received Amount',
            dataIndex: 'received_amt',
        },
        {
            title: 'Balance Amount',
            dataIndex: 'balance',
        },
        {
            title: 'Action',
            render: (record) => {
                return (
                    <Flex center={"true"} gap={'10px'}>

                        <Button.Danger text={'Pay'} onClick={() => onPayMethod(record)} />

                    </Flex>
                )
            }
        },
    ];

    let content;
    if (AllUnpaidSalesStatus === 'loading') {
        content = <CommonLoading />
    } else if (AllUnpaidSalesStatus === 'succeeded') {
        const rowKey = (dataSource) => dataSource.id
        content = <CustomTable columns={columns} data={dataSource} rowKey={rowKey} />
        // content = <CustomTandardTable columns={columns} data={dataSource} rowKey={rowKey} />
    } else if (AllUnpaidSalesStatus === 'failed') {
        content = <h2>{AllUnpaidError}</h2>
    }

    return (
        <div>
            <CustomPageTitle Heading={'Sale '} />
            {content}
            {/* <CustomTable columns={columns} data={dataSource} /> */}
            <CustomModal isVisible={isModalOpen} handleOk={handleOk} handleCancel={handleCancel} width={modelwith} modalTitle={modalTitle} modalContent={modalContent} />
        </div>
    )
}

export default SaleUnpaid