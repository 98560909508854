import React, { useEffect, useState } from 'react'
import { CustomModal } from '../../../../components/CustomModal'
import Flex from '../../../../components/Flex';
import Button from '../../../../components/Form/CustomButton';
import PurchaseReturnPay from './PurchaseReturnPay';
import { CustomTable } from '../../../../components/Form/CustomTable';
import { CustomPageTitle } from '../../../../components/CustomPageTitle';
import request from '../../../../utils/request';

const PurchaseRetunUnpaid = () => {

    // ======  Modal Open ========
    const [isModalOpen, setIsModalOpen] = useState(false);

    // ======  Modal Title and Content ========
    const [modalTitle, setModalTitle] = useState("");
    const [modalContent, setModalContent] = useState(null);

    // ----------  Form Reset UseState ---------
    const [formReset, setFormReset] = useState(0);
    const [modelwith, setModelwith] = useState(0);
    const [dataSource, setDataSource] = useState([])
    const [trigger, setTrigger] = useState(0)

    useEffect(() => {
        GetUnpaid()
    }, [])

    const GetUnpaid = () => {
        request.get(`purchase/purchase_return_unpaid_bills/`)
            .then(function (response) {
                setDataSource(response.data)
            })
            .catch(function (error) {

            }
            )
    }
    // ===== Modal Functions Start =====

    const showModal = () => {
        setIsModalOpen(true);
    };

    const handleOk = () => {
        setIsModalOpen(false);
    };

    const handleCancel = () => {
        setIsModalOpen(false);
    };


    const onPayMethod = (record) => {
        setModelwith(800)
        setTrigger(trigger + 1)
        setModalTitle("Pay Bill");
        setModalContent(<PurchaseReturnPay unpaidpurchaserecord={record} GetUnpaid={GetUnpaid} trigger={trigger} handleOk={handleOk} />);
        showModal();
    };

    const columns = [
        {
            title: 'Sl No',
            render: (item, value, index) => index + 1,
        },
        {
            title: 'Return Number',
            dataIndex: 'return_no',
        },
        {
            title: 'Date',
            dataIndex: 'bill_date',
        },
        {
            title: 'Party Name',
            dataIndex: 'party_name',
        },
        {
            title: 'Mobile Number',
            dataIndex: 'mobile_no',
        },
        {
            title: 'Total Amount',
            dataIndex: 'amt_total',
        },
        {
            title: 'Received Amount',
            dataIndex: 'received_amt',
        },
        {
            title: 'Balance Amount',
            dataIndex: 'balance',
        },
        {
            title: 'Action',
            render: (record) => {
                return (
                    <Flex center={"true"} gap={'10px'}>
                        <Button.Danger text={'Pay'} onClick={() => onPayMethod(record)} />
                    </Flex>
                )
            }
        },
    ];

    return (
        <div>
            <CustomPageTitle Heading={'Purchase Return'} />
            <CustomTable columns={columns} data={dataSource} />
            <CustomModal isVisible={isModalOpen} handleOk={handleOk} handleCancel={handleCancel} width={modelwith} modalTitle={modalTitle} modalContent={modalContent} />
        </div>
    )
}

export default PurchaseRetunUnpaid