import { Col } from 'antd'
import React from 'react'
import { CustomCardView } from '../../../components/CustomCardView'
import { CustomPageTitle } from '../../../components/CustomPageTitle'
import Flex from '../../../components/Flex'
import ProductBrand from './ProductBrand'


const AddBrand = () => {
    return (
        <div>
            <CustomPageTitle Heading={'Add Brand'} />
            <Flex center={'true'}>
                <CustomCardView width={'500px'}>

                    <ProductBrand />
                </CustomCardView>
            </Flex>



        </div>
    )
}

export default AddBrand