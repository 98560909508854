import { Card, Col, Form } from "antd"
import React, { Fragment, useEffect, useState } from "react"
import { DeleteOutlined } from "@ant-design/icons";
import { SalesFormHeader } from "./SalesFormHeader";
import { SalesFormFooter } from "./SalesFormFooter";
import dayjs from 'dayjs'
import request from "../../../utils/request";
import { toast } from "react-toastify";
import Flex from "../../../components/Flex";
import { CustomModal } from "../../../components/CustomModal";
import { CustomRow } from "../../../components/CustomRow";
import Button from "../../../components/Form/CustomButton";
import { CustomInputNumber } from "../../../components/Form/CustomInputNumber";
import { CustomSelect } from "../../../components/Form/CustomSelect";
import { CustomSwitch } from "../../../components/Form/CustomSwitch";
import { CustomTandardTable } from "../../../components/Form/CustomStandardTable";
import { CustomInput } from "../../../components/Form/CustomInput";
import { ViewSaleBill } from "./ViewSaleBill";



export const SalesEntryPage = ({ setSale, SaleRecord, SaleTrigger, SaleFormUpdate }) => {

  const [proCount, setProCount] = useState(1);  //  -->  Product Count
  const [serviceCount, setServiceCount] = useState(1);  //  -->  Service Count

  const [invoiceNumber, setInvoiceNumber] = useState({})  //  -->  Invoice Number
  const [selectedDate, setSelectedDate] = useState(dayjs().format('YYYY-MM-DD'));  //  -->  Invoice Date

  const [gstChecked, setGstChecked] = useState(false)  //  -->  GST Checked
  const [serviceClick, setServiceClick] = useState(false)  // --> Service Checked

  const [getdata, setGetdata] = useState([]) // --> Product Data

  const [getdataservice, setGetdataservice] = useState([])  // --> Service Data

  const [selectedSale, setSelectedSale] = useState({})  // --> User Selected Data

  const [trigger, setTrigger] = useState(0);

  const [isDisable, setIsDisable] = useState(true)

  // ======  Modal Open ========
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalWidth, setModalWidth] = useState(0)  // --> Modal Width

  // ======  Modal Title and Content ========
  const [modalTitle, setModalTitle] = useState("");
  const [modalContent, setModalContent] = useState(null);

  // =====  Modal Functions Start ===

  const showModal = () => {
    setIsModalOpen(true);
  };


  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  // =====  Modal Functions End ===

  const [form] = Form.useForm();  //  --> Form Ref

  // -----------------  Balance Checking  ------------
  const [balanceAmount, setBalanceAmount] = useState(0);  // --> Product 
  const [balanceChangeAmount, setBalanceChangeAmount] = useState(0);  // --> Product 
  const [balanceChange, setBalanceChange] = useState(false);  // --> Product 

  // -----------------  Balance Checking  ------------
  const [balanceServiceAmount, setBalanceServiceAmount] = useState(0);  // --> Service 
  const [balanceChangeServiceAmount, setBalanceChangeServiceAmount] = useState(0);  // --> Service 
  const [balanceServiceChange, setBalanceServiceChange] = useState(false);  // --> Service 

  // ======  Selected Party Details ====
  useEffect(() => {
    form.setFieldsValue({ party_name: selectedSale.party_name })
    form.setFieldsValue({ mobile_number: selectedSale.mobile_number })
    form.setFieldsValue({ billing_address: selectedSale.billing_address })
    form.setFieldsValue({ state_of_supply: selectedSale.state_of_party })
    form.setFieldsValue({ shipping_address: selectedSale.shipping_address })
    form.setFieldsValue({ party: selectedSale.id })
  }, [selectedSale])


  useEffect(() => {
    GetSaleProduct()
    GetSaleService()
  }, [])

  const GetSaleProduct = () => {     // ------------------------------------- GET PRODUCT DATA
    request.get('sales/get_detail_sale/')
      .then(function (response) {
        setGetdata(response.data)
        setInvoiceNumber(response.data)
      })
      .catch(function (error) {
        console.log(error, 'error')
      });
  }

  //===================Edit record placed on product & Service =======================

  useEffect(() => {
    if (SaleRecord?.item_choice === 'Product') {
      form.setFieldsValue(SaleRecord)

      setBalanceChangeAmount(SaleRecord?.received_amt)
      const fromdatee = new Date(SaleRecord?.invoice_date)
      const dateFormat = 'YYYY-MM-DD';
      const FrmDateee = dayjs(fromdatee).format(dateFormat);

      form.setFieldsValue({
        invoice_date: dayjs(FrmDateee),

      })
      form.setFieldsValue({
        received_amt: SaleRecord?.received_amt,

      })

    }
    if (SaleRecord?.item_choice === 'Service') {
      form.setFieldsValue(SaleRecord)

      setBalanceChangeServiceAmount(SaleRecord?.received_amt)

      const fromdatee = new Date(SaleRecord?.invoice_date)
      const dateFormat = 'YYYY-MM-DD';
      const FrmDateee = dayjs(fromdatee).format(dateFormat);

      form.setFieldsValue({
        invoice_date: dayjs(FrmDateee),

      })
      form.setFieldsValue({
        received_amt: SaleRecord?.received_amt,

      })
    }

  }, [SaleRecord, SaleTrigger, SaleFormUpdate])

  //=====
  // -------------------------------------------------------------  Variant Array Start
  const variationsArray = [];
  getdata?.item?.forEach(item => {
    const productName = item.product_name;
    const variationListId = item.variation_list.id;
    const ItemCode = item.variation_list.item_code;
    const AvailableQty = item.variation_list.available_qty;
    const SaleRate = item.variation_list.sale_rate;
    const Unit = item.unit;
    const DisPercentage = item.variation_list.dis_percentage;
    const GSTPercentage = item.variation_list.gst_percentage;
    const StockMaintain = item.variation_list.variant_stock_maintain;

    let Variant = [];
    item.variation_list.varity.forEach(variation => {
      const variationName = variation.variation_name;
      const variationValue = variation.variation_value;
      Variant.push({ variationName, variationValue })
    });

    variationsArray.push({
      productName,
      Variant,
      ItemCode,
      AvailableQty,
      variationListId,
      SaleRate,
      Unit,
      GSTPercentage,
      DisPercentage,
      StockMaintain,
    })
  });

  // ==================  Product Options

  const setValue = variationsArray.map(Val => ({
    label: `${Val.productName}/ ${Val.Variant.map((v) => `${v.variationName}:"${v.variationValue}"`).join('/')}`,
    value: Val.variationListId
  }))

  const setItemCode = variationsArray.map(Val => ({
    label: Val.ItemCode,
    value: Val.variationListId
  }))

  // ==================  Service Options

  const ServiceItem = getdataservice.map(val => ({ label: val.service_name, value: val.service_name }))

  // const ServiceCode = getdataservice?.map(com => ({
  //   label: com.service_code,
  //   value: com.service_code
  // }))


  // -------------------------------------------------------------  Variant Array End 


  const GetSaleService = () => {  // ------------------------------------- GET SERVICE DATA
    request.get('sales/get_detail_sale_service/')
      .then(function (response) {
        setGetdataservice(response.data?.service)
      })
      .catch(function (error) {
        // toast.error('Something went Wrong')
      });
  }

  // =========================  Other Functions End  =========================

  // ======  Product State Start =====

  const ProductInitialData = [
    {
      key: 0,
      description: '',
      item: '',
      item_name: '',
      item_code: '',
      quantity: '',
      unit: '',
      sale_price: '',
      discount_percentage: '',
      sale_discount: '',
      tax_percentage: '',
      tax_cal_amt: '',
      sub_total: '',
      item_cal_total_amt: '',
      available_qty: '',
      stock_maintain: '',
    }
  ]

  const ProSecondaryData = [
    {
      qty_total: '',
      discount_total: '',
      tax_total: '',
      table_total: '',
    },
  ];

  const ProFooterCalData = [
    {
      grand_total: '',
      received_amt: '',
      balance: '',
    },
  ];


  const [proTableData, setProTableData] = useState(ProductInitialData);
  const [proTabSecondaryData, setProTabSecondaryData] = useState(ProSecondaryData);
  const [ProTabFooterData, setProTabFooterData] = useState(ProFooterCalData);

  // ======  Product State End =====

  useEffect(() => {
    proTableData.forEach(record => {
      form.setFieldsValue({ [`description${record.key}`]: record.description });
      form.setFieldsValue({ [`item${record.key}`]: record.item });
      form.setFieldsValue({ [`item_name${record.key}`]: record.item_name });
      form.setFieldsValue({ [`item_code${record.key}`]: record.item_code });
      form.setFieldsValue({ [`quantity${record.key}`]: record.quantity });
      form.setFieldsValue({ [`unit${record.key}`]: record.unit });
      form.setFieldsValue({ [`sale_price${record.key}`]: record.sale_price });
      form.setFieldsValue({ [`discount_percentage${record.key}`]: record.discount_percentage });
      form.setFieldsValue({ [`sale_discount${record.key}`]: record.sale_discount });
      form.setFieldsValue({ [`tax_percentage${record.key}`]: record.tax_percentage });
      form.setFieldsValue({ [`tax_cal_amt${record.key}`]: record.tax_cal_amt });
      form.setFieldsValue({ [`sub_total${record.key}`]: record.sub_total });
      form.setFieldsValue({ [`item_cal_total_amt${record.key}`]: record.item_cal_total_amt });
      form.setFieldsValue({ [`available_qty${record.key}`]: record.available_qty });
      form.setFieldsValue({ [`stock_maintain${record.key}`]: record.stock_maintain });
    });

    form.setFieldsValue({ [`qty_total`]: proTabSecondaryData[0].qty_total });
    form.setFieldsValue({ [`discount_total`]: proTabSecondaryData[0].discount_total });
    form.setFieldsValue({ [`tax_total`]: proTabSecondaryData[0].tax_total });
    form.setFieldsValue({ [`table_total`]: proTabSecondaryData[0].table_total });
    form.setFieldsValue({ "grand_total": proTabSecondaryData[0].table_total });

  }, [proTableData])


  useEffect(() => {

    if (SaleRecord) {
      form.setFieldsValue({ invoice_no: SaleRecord.invoice_no })
    }
    else {
      form.setFieldsValue({ invoice_no: invoiceNumber?.invoice_no })

    }
  }, [invoiceNumber, SaleTrigger, SaleFormUpdate, SaleRecord])


  useEffect(() => {
    form.setFieldsValue({ received_amt: ProTabFooterData?.received_amt })
  }, [ProTabFooterData])

  useEffect(() => {
    if (SaleRecord?.item_choice === 'Service') {
      setServiceClick(true)
    }
    else {
      setServiceClick(false)
    }
  }, [SaleRecord, SaleTrigger])

  // ====================== 

  const BalanceOnChange = (value) => {
    const gandTotal = parseFloat(form.getFieldValue('grand_total'))
    if (value > gandTotal) {
      toast.warn('Paid amount cannot be greater than the grand total!')

    }

    setBalanceChangeAmount(value)

  }

  // ======  Product  End =====


  // =============== Service State Start ============

  const ServiceInitialData = [
    {
      key: 0,
      description: '',
      service: '',
      item_name: '',
      item_code: '',
      rete: '',
      serviceid: '',
      discount_percentage: '',
      sale_discount: '',
      tax_percentage: '',
      tax_cal_amt: '',
      sub_total: '',
      item_cal_total_amt: '',
    }
  ]

  const ServiceSecondaryData = [
    {
      discount_total: '',
      tax_total: '',
      table_total: '',
      grand_total: '',
      received_amt: '',
      balance: '',
    },
  ];

  const ServiceFooterCalData = [
    {
      grand_total: '',
      received_amt: '',
      balance: '',
    },
  ];

  const [serviceTableData, setServiceTableData] = useState(ServiceInitialData);
  const [serviceTabSecondaryData, setServiceTabSecondaryData] = useState(ServiceSecondaryData);
  const [ServiceTabFooterData, setServiceTabFooterData] = useState(ServiceFooterCalData);


  // ====== Service UseEffect =====

  useEffect(() => {
    serviceTableData.forEach(record => {
      form.setFieldsValue({ [`item_name${record.key}`]: record.item_name });
      form.setFieldsValue({ [`description${record.key}`]: record.description });
      form.setFieldsValue({ [`item_code${record.key}`]: record.item_code });
      form.setFieldsValue({ [`service${record.key}`]: record.service });
      form.setFieldsValue({ [`discount_percentage${record.key}`]: record.discount_percentage });
      form.setFieldsValue({ [`sale_discount${record.key}`]: record.sale_discount });
      form.setFieldsValue({ [`tax_percentage${record.key}`]: record.tax_percentage });
      form.setFieldsValue({ [`tax_cal_amt${record.key}`]: record.tax_cal_amt });
      form.setFieldsValue({ [`sub_total${record.key}`]: record.sub_total });
      form.setFieldsValue({ [`item_cal_total_amt${record.key}`]: record.item_cal_total_amt });
      form.setFieldsValue({ [`sale_price${record.key}`]: record.sale_price });
    });
    if (SaleRecord?.item_choice === 'Service') {
      form.setFieldsValue({ [`discount_total`]: serviceTabSecondaryData[0].discount_total });
      form.setFieldsValue({ [`tax_total`]: serviceTabSecondaryData[0].tax_total });
      form.setFieldsValue({ [`table_total`]: serviceTabSecondaryData[0].table_total });
      form.setFieldsValue({ [`grand_total`]: serviceTabSecondaryData[0].table_total });

      // form.setFieldsValue({ [`received_amt`]: serviceTabSecondaryData[0].received_amt });
    }
    // else{
    //   form.setFieldsValue({ [`discount_total`]: serviceTabSecondaryData[0].discount_total });
    //   form.setFieldsValue({ [`tax_total`]: serviceTabSecondaryData[0].tax_total });
    //   form.setFieldsValue({ [`table_total`]: serviceTabSecondaryData[0].table_total });
    //   form.setFieldsValue({ [`grand_total`]: serviceTabSecondaryData[0].table_total });

    //   form.setFieldsValue({ [`received_amt`]: serviceTabSecondaryData[0].received_amt });
    // }

  }, [serviceTableData, serviceTabSecondaryData])


  useEffect(() => {
    if (serviceTableData) {
      form.setFieldsValue({ [`discount_total`]: serviceTabSecondaryData[0].discount_total });
      form.setFieldsValue({ [`tax_total`]: serviceTabSecondaryData[0].tax_total });
      form.setFieldsValue({ [`table_total`]: serviceTabSecondaryData[0].table_total });
      form.setFieldsValue({ [`grand_total`]: serviceTabSecondaryData[0].table_total });

    }

    // form.setFieldsValue({ [`received_amt`]: serviceTabSecondaryData[0].received_amt });

  }, [serviceTabSecondaryData])


  //=================================================

  useEffect(() => {
    form.setFieldsValue({ received_amt: ServiceTabFooterData?.received_amt })
  }, [ServiceTabFooterData])

  useEffect(() => {
    if (SaleRecord?.item_choice === 'Product') {
      // form.setFieldsValue({ invoice_no: SaleRecord?.invoice_no })
      if (SaleRecord?.tax_choice === 'GST') {
        setGstChecked(true)
        form.setFieldsValue({ GSTIN: SaleRecord?.GSTIN })
      } else {
        setGstChecked(false)
      }
      setProTabSecondaryData([{
        qty_total: SaleRecord?.qty_total,
        discount_total: SaleRecord?.discount_total,
        tax_total: SaleRecord?.tax_total,
        table_total: SaleRecord?.table_total,
      }])
      form.setFieldsValue({ received_amt: SaleRecord?.received_amt })
    }
    if (SaleRecord?.item_choice === 'Service') {
      // form.setFieldsValue({ invoice_no: SaleRecord?.invoice_no })
      if (SaleRecord?.tax_choice === 'GST') {
        setGstChecked(true)
        form.setFieldsValue({ GSTIN: SaleRecord?.GSTIN })
      } else {
        setGstChecked(false)
      }
      setServiceTabSecondaryData([{
        discount_total: SaleRecord?.discount_total,
        tax_total: SaleRecord?.tax_total,
        table_total: SaleRecord?.table_total,
      }])
      form.setFieldsValue({ received_amt: SaleRecord?.received_amt })

    }

  }, [SaleRecord, SaleTrigger])

  // ====================== 

  const BalanceOnChangeService = (value) => {
    const gandTotal = parseFloat(form.getFieldValue('grand_total'))
    if (value > gandTotal) {
      toast.warn('Paid amount cannot be greater than the grand total!')

    }
    setBalanceChangeServiceAmount(value)
  }

  // ======== Product Received Balance ============

  useEffect(() => {
    if (proTabSecondaryData) {
      let GrandTotal = proTabSecondaryData[0].table_total;
      let ReceivedAmt = balanceChangeAmount;
      let Balance = 0;

      if (ReceivedAmt == 0) {
        Balance = GrandTotal;
      }
      else {
        Balance = GrandTotal - ReceivedAmt;
      }

      if (Balance < 0) {
        setBalanceChange(true);
      } else if (Balance > GrandTotal) {

        setBalanceChange(true);
      }
      else {
        setBalanceChange(false);
      }

      setBalanceAmount(Balance)
    }
  }, [proTableData, balanceChangeAmount])

  // ======== Service Received Balance ============

  useEffect(() => {
    if (serviceTabSecondaryData) {
      let GrandTotals = serviceTabSecondaryData[0].table_total;
      let ReceivedAmt = balanceChangeServiceAmount;
      let Balance = 0;

      if (ReceivedAmt == 0) {
        Balance = GrandTotals;
      }
      else {
        Balance = GrandTotals - ReceivedAmt;
      }

      if (Balance < 0) {
        setBalanceServiceChange(true);
        // toast.warn('Paid amount cannot be greater than the grand total!')
      } else if (Balance > GrandTotals) {
        setBalanceServiceChange(true);
      }
      else {
        setBalanceServiceChange(false);
      }

      setBalanceServiceAmount(Balance)
    }
    if (SaleRecord) {
      let GrandTotals = serviceTabSecondaryData[0]?.table_total;
      let ReceivedAmt = balanceChangeServiceAmount;
      let Balance = 0;

      if (ReceivedAmt == 0) {
        Balance = GrandTotals;
      }
      else {
        Balance = GrandTotals - ReceivedAmt;
      }

      if (Balance < 0) {
        setBalanceServiceChange(true);
        // toast.warn('Paid amount cannot be greater than the grand total!')  
      } else if (Balance > GrandTotals) {
        setBalanceServiceChange(true);
      }
      else {
        setBalanceServiceChange(false);
      }

      setBalanceServiceAmount(Balance)
    }

  }, [serviceTableData, balanceChangeServiceAmount, SaleTrigger])

  // useEffect(() => {
  //   if(serviceClick){
  //     setProTableData
  //   }
  // }, [serviceClick])


  useEffect(() => {
    if (serviceClick) {
      HandleTableServiceCal();
    } else {
      HandleTableCal();
    }
  }, [gstChecked])



  useEffect(() => {
    if (serviceClick) {
      form.setFieldsValue({ 'balance': balanceServiceAmount })


    }
    else {
      form.setFieldsValue({ 'balance': balanceAmount })


    }
  }, [balanceServiceAmount, balanceAmount])

  // useEffect(() => {
  //   if()
  //   form.setFieldsValue({ balance: '' })
  // }, [])

  // ===============  Table Data Start ==================

  const handleGSTSwitch = (e) => {   //----------------------------  Switch For Product or Service
    setGstChecked(e)
  }

  // ============  Product Table Columns

  const ProductColumns = [
    {
      title: '#',
      render: (text, record, index) => {
        return (
          (
            <Flex alignCenter gap={'20px'} style={{ alignItems: 'center' }}>
              <h4>{index + 1}</h4>
              <Button
                style={{
                  display: 'flex',
                  padding: '10px',
                  height: 'auto',
                  fontSize: '16px',
                }}
                htmlType="button"
                danger
                onClick={() => onProductTabRowDelete(record.key)}
              >
                <DeleteOutlined />
              </Button>
            </Flex>
          )
        );
      },
    },
    {
      title: (
        <p>Product&nbsp;Name</p>
      ),
      dataIndex: 'item_name',
      key: 'item_name',
      render: (text, record, index) => {
        return (
          <>
            <CustomSelect
              rules={[
                {
                  required: true,
                  message: 'This is a required field',
                },
              ]}
              minWidth={'230px'}
              showSearch={true}
              name={`item${record.key}`}
              options={setValue}
              onChange={(value) => handleOnChangeProduct(value, record)}
            />
            <CustomInput
              minWidth={'150px'}
              name={`item_name${record.key}`}
              disabled
              display={'none'}
            />
            <CustomInput
              minWidth={'150px'}
              name={`stock_maintain${record.key}`}
              display={'none'}
              disabled
            />
          </>
        );
      },
    },
    {
      title: (
        <p>Item&nbsp;Code</p>
      ),
      dataIndex: 'item_code',
      render: (text, record) => (
        <CustomSelect
          rules={[
            {
              required: true,
              message: 'This is a required field',
            },
          ]}
          minWidth={'130px'}
          showSearch={true}
          name={`item_code${record.key}`}
          options={setItemCode}
          onChange={(value) => handleOnChangeItemCodeProduct(value, record)} />
      )
    },
    {
      title: 'Unit',
      dataIndex: 'unit',
      render: (text, record) => (
        <CustomInput
          minWidth={'80px'}
          placed={'end'}
          name={`unit${record.key}`} />
      )
    },
    // SaleRecord ? null :
    {
      title: (
        <p>Avaliable&nbsp;Qty</p>
      ),
      dataIndex: 'available_qty',
      key: 'available_qty',
      render: (text, record) => (
        <CustomInput
          minWidth={'80px'}
          style={{ textAlign: 'center' }}
          name={`available_qty${record.key}`}
          disabled={true}
        />
      )
    },
    {
      title: 'Quantity',
      dataIndex: 'quantity',
      render: (text, record) => (
        <CustomInputNumber
          minWidth={'80px'}
          precision={2}
          placed={'end'}
          name={`quantity${record.key}`}
          onChange={(value) => handleOnChangeQuantity(value, record)}
        />
      )
    },
    {
      title: 'Price',
      dataIndex: 'sale_price',
      key: 'sale_price',
      render: (text, record) => (
        <CustomInputNumber
          minWidth={'80px'}
          style={{ textAlign: 'center' }}
          name={`sale_price${record.key}`}
          precision={2}
          rules={[
            {
              required: true,
              message: 'This is a required field',
            },
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (parseFloat(value) <= 0) {
                  return Promise.reject('Price must be greater than 1');
                }
                return Promise.resolve();
              },
            }),
          ]}
          onChange={(value) => handleOnChangePrice(value, record)}
        />
      )
    },
    {
      title: 'Discount',
      children: [
        {
          title: '%',
          dataIndex: 'discount_percentage',
          key: 'discount_percentage',
          render: (text, record) => (
            <CustomInputNumber
              precision={2}
              minWidth={'80px'}
              placed={'end'}
              name={`discount_percentage${record.key}`}
              min={0.00}
              max={100.00}
              onChange={(value) => handleOnChangeDiscount(value, record)}
            />
          )
        },
        {
          title: 'Amount',
          dataIndex: 'sale_discount',
          key: 'sale_discount',
          render: (text, record) => (
            <CustomInputNumber
              precision={2}
              minWidth={'80px'}
              placed={'end'}
              name={`sale_discount${record.key}`}
              disabled
            />
          )
        },
      ],
    },
    {
      title: 'Tax',
      children: [
        {
          title: '%',
          dataIndex: 'tax_percentage',
          key: 'tax_percentage',
          render: (text, record) => (
            <CustomInputNumber
              minWidth={'80px'}
              placed={'end'}
              precision={2}
              rules={[
                {
                  required: true,
                  message: 'This is a required field',
                },
              ]}
              name={`tax_percentage${record.key}`}
              min={0.00}
              max={100.00}
              onChange={(value) => handleOnChangeTax(value, record)}
            />
          )
        },
        {
          title: 'Amount',
          dataIndex: 'tax_cal_amt',
          key: 'tax_cal_amt',
          render: (text, record) => (
            <CustomInputNumber
              minWidth={'80px'}
              precision={2}
              disabled
              placed={'end'}
              name={`tax_cal_amt${record.key}`}
            />
          )
        },
      ],
    },
    {
      title: (
        <p>Sub&nbsp;Total</p>
      ),
      dataIndex: 'sub_total',
      key: 'sub_total',
      render: (text, record) => (
        <CustomInputNumber precision={2}
          disabled
          minWidth={'80px'}
          placed={'end'}
          name={`sub_total${record.key}`}
        />
      )
    },
    {
      title: (
        <p>Total&nbsp;Amount</p>
      ),
      dataIndex: 'item_cal_total_amt',
      key: 'item_cal_total_amt',
      render: (text, record) => (
        <CustomInputNumber precision={2}
          disabled
          minWidth={'80px'}
          placed={'end'}
          name={`item_cal_total_amt${record.key}`}
        />
      )
    }
  ]

  // ============  Service Table Columns

  const ServiceColumns = [
    {
      title: '#',
      render: (text, record, index) => {
        return (
          (
            <Flex alignCenter gap={'20px'} style={{ alignItems: 'center' }}>
              <h4>{index + 1}</h4>
              <Button
                style={{
                  display: 'flex',
                  padding: '10px',
                  height: 'auto',
                  fontSize: '16px',
                }}
                htmlType="button"
                danger
                onClick={() => onServiceTabRowDelete(record.key)}
              >
                <DeleteOutlined />
              </Button>
            </Flex>
          )
        );
      },
    },
    {
      title: (
        <p>Service&nbsp;Name</p>
      ),
      dataIndex: 'item_name',
      key: 'item_name',
      render: (text, record, index) => {
        return (
          <>
            <CustomSelect
              rules={[
                {
                  required: true,
                  message: 'This is a required field',
                },
              ]}
              minWidth={'230px'}
              showSearch={true}
              name={`item_name${record.key}`}
              options={ServiceItem}
              onChange={(value) => handleOnChangeService(value, record)}
            />
            <CustomInputNumber
              minWidth={'150px'}
              name={`service${record.key}`}
              disabled
              display={'none'}
            />
            <CustomInputNumber
              minWidth={'150px'}
              name={`description${record.key}`}
              disabled
              display={'none'}
            />
          </>
        );
      },
    },
    {
      title: (
        <p>Service&nbsp;Code</p>
      ),
      dataIndex: 'item_code',
      render: (text, record) => (
        <CustomInput
          rules={[
            {
              required: true,
              message: 'This is a required field',
            },
          ]}
          minWidth={'130px'}
          showSearch={true}
          name={`item_code${record.key}`}
          disabled={'true'}
        // options={ServiceCode}
        // onChange={(value) => handleOnChangeItemCodeService(value, record)}
        />
      )
    },
    {
      title: 'Price',
      dataIndex: 'sale_price',
      key: 'sale_price',
      render: (text, record) => (
        <CustomInputNumber
          minWidth={'80px'}
          style={{ textAlign: 'center' }}
          name={`sale_price${record.key}`}
          precision={2}
          rules={[
            {
              required: true,
              message: 'This is a required field',
            },
            // ({ getFieldValue }) => ({
            //   validator(_, value) {
            //     if (parseFloat(value) <= 0) {
            //       return Promise.reject('Price must be greater than 1');
            //     }
            //     return Promise.resolve();
            //   },
            // }),
          ]}
          onChange={(value) => handleOnChangeServicePrice(value, record)}
        />
      )
    },
    {
      title: 'Discount',
      children: [
        {
          title: '%',
          dataIndex: 'discount_percentage',
          key: 'discount_percentage',
          render: (text, record) => (
            <CustomInputNumber
              precision={2}
              minWidth={'80px'}
              placed={'end'}
              name={`discount_percentage${record.key}`}
              min={0.00}
              max={100.00}
              onChange={(value) => handleonChangeServiceDiscount(value, record)}
            />
          )
        },
        {
          title: 'Amount',
          dataIndex: 'sale_discount',
          key: 'sale_discount',
          render: (text, record) => (
            <CustomInputNumber
              precision={2}
              minWidth={'80px'}
              placed={'end'}
              defaultValue={0}
              name={`sale_discount${record.key}`}
              disabled
            />
          )
        },
      ],
    },
    {
      title: 'Tax',
      children: [
        {
          title: '%',
          dataIndex: 'tax_percentage',
          key: 'tax_percentage',
          render: (text, record) => (
            <CustomInputNumber
              minWidth={'80px'}
              placed={'end'}
              precision={2}
              rules={[
                {
                  required: true,
                  message: 'This is a required field',
                },
              ]}
              name={`tax_percentage${record.key}`}
              min={0.00}
              max={100.00}
              onChange={(value) => handleOnChangeServiceTax(value, record)}
            />
          )
        },
        {
          title: 'Amount',
          dataIndex: 'tax_cal_amt',
          key: 'tax_cal_amt',
          render: (text, record) => (
            <CustomInputNumber
              minWidth={'80px'}
              precision={2}
              disabled
              placed={'end'}
              defaultValue={0}
              name={`tax_cal_amt${record.key}`}
            />
          )
        },
      ],
    },
    {
      title: (
        <p>Sub&nbsp;Total</p>
      ),
      dataIndex: 'sub_total',
      key: 'sub_total',
      render: (text, record) => (
        <CustomInputNumber precision={2}
          disabled
          minWidth={'80px'}
          placed={'end'}
          name={`sub_total${record.key}`}
        />
      )
    },
    {
      title: (
        <p>Total&nbsp;Amount</p>
      ),
      dataIndex: 'item_cal_total_amt',
      key: 'item_cal_total_amt',
      render: (text, record) => (
        <CustomInputNumber precision={2}
          disabled
          minWidth={'80px'}
          placed={'end'}
          name={`item_cal_total_amt${record.key}`}
        />
      )
    }
  ]

  // ===============  Table Data End ==================


  // ================== Product Table Functions Start ==================

  // ========= Product Delete  & AddRow ========

  const AddRow = () => { // ----------------- Add Row Function
    const newData = {
      key: proCount,
      description: '',
      product: '',
      item_name: '',
      item_code: '',
      quantity: '',
      unit: '',
      sale_price: '',
      discount_percentage: '',
      sale_discount: '',
      tax_percentage: '',
      tax_cal_amt: '',
      sub_total: '',
      item_cal_total_amt: '',
      available_qty: '',
      stock_maintain: '',
    };
    setProTableData(pre => {
      return [...pre, newData]
    })
    setProCount(proCount + 1);
  }

  const onProductTabRowDelete = (key) => {  // -----------------------  Delete Row Function
    if (proTableData.length > 1) {
      setProTableData(prevState => {
        const newData = prevState.filter(item => item.key !== key);

        // ------ Variables 
        let totalQuantity = 0;
        let totalDiscount = 0;
        let totalTax = 0;
        let totalAmount = 0;

        newData.forEach(item => {
          if (item.quantity !== '' || item.sale_discount !== '' || item.tax_cal_amt !== '' || item.item_cal_total_amt !== '') {
            totalQuantity += parseFloat(item.quantity);
            totalDiscount += parseFloat(item.sale_discount);
            totalTax += parseFloat(item.tax_cal_amt);
            totalAmount += parseFloat(item.item_cal_total_amt);
          }
        });

        // update the grand_total value in the tableSecondaryData array
        setProTabSecondaryData([{
          qty_total: totalQuantity.toFixed(2),
          discount_total: totalDiscount.toFixed(2),
          tax_total: totalTax.toFixed(2),
          table_total: totalAmount.toFixed(2),
        }]);

        return newData;
      });
    } else {
      console.log(`only ${proTableData.length} is available`)
    }
  };

  const CalculateTotal = (record) => {  // ----------------- 1. Calculate TotalAmount 

    setProTableData(prevState => {
      const newData = [...prevState];
      const index = newData.findIndex(item => record.key === item.key);
      const item = newData[index];

      // ------ Variables 
      let totalQuantity = 0;
      let totalDiscount = 0;
      let totalTax = 0;
      let totalAmount = 0;

      newData.forEach(item => {
        if (item.quantity !== '' || item.sale_discount !== '' || item.tax_cal_amt !== '' || item.item_cal_total_amt !== '') {
          totalQuantity += parseFloat(item.quantity);
          totalDiscount += parseFloat(item.sale_discount);
          totalTax += parseFloat(item.tax_cal_amt);
          totalAmount += parseFloat(item.item_cal_total_amt);
        }
      });

      // update the grand_total value in the tableSecondaryData array
      setProTabSecondaryData([{
        qty_total: totalQuantity.toFixed(2),
        discount_total: totalDiscount.toFixed(2),
        tax_total: totalTax.toFixed(2),
        table_total: totalAmount.toFixed(2),
      }]);

      return newData;
    });

  }


  // ================== Product Table Functions End ==================

  // ============  Products OnChange Functions  ==============

  const HandleQty = (value, record) => {  //  OnCHANGE QTY
    setProTableData(prevState => {
      const newData = [...prevState];
      const index = newData.findIndex(item => record.key === item.key);
      const item = newData[index];

      let AvailableQty = record.available_qty;
      let QTY = 0;

      if (AvailableQty < value) {
        QTY = AvailableQty;
        toast.warn('Quantity Value Not greater than Available Qty!')
      } else if (value <= 0) {
        QTY = 1;
      } else if (AvailableQty == 0) {
        QTY = 0;
      } else {
        QTY = value;
      }

      item.quantity = QTY;

      CalculateTotal({
        ...item,
        quantity: QTY,
      });

      HandleRowCal({
        ...item,
        quantity: QTY,
      })

      return newData;
    });
  };
  // ============  Products OnChange Functions end ==============

  const HandlePrice = (value, record) => {  //  OnCHANGE PRICE
    setProTableData(prevState => {
      const newData = [...prevState];
      const index = newData.findIndex(item => record.key === item.key);
      const item = newData[index];

      item.sale_price = value || 0;

      CalculateTotal({
        ...item,
        sale_price: item.sale_price,
      })

      HandleRowCal({
        ...item,
        sale_price: item.sale_price,
      })

      return newData;
    });
  }

  const HandleDiscount = (value, record) => {  //  ONCHANGE DISCOUNT
    setProTableData(prevState => {
      const newData = [...prevState];
      const index = newData.findIndex(item => record.key === item.key);
      const item = newData[index];

      let Dis_per = 0; // Dis - Percentage

      if (value <= 100)  // Less Than 100
      {
        Dis_per = value;
      }
      else {  // Greater Than 100
        Dis_per = 100;
      }

      CalculateTotal({
        ...item,
        discount_percentage: Dis_per,
      })

      HandleRowCal({
        ...item,
        discount_percentage: Dis_per,
      })

      item.discount_percentage = Dis_per;

      return newData;
    })
  }

  const HandleTax = (value, record) => {  // ONCHANGE TAX
    setProTableData(prevState => {
      const newData = [...prevState];
      const index = newData.findIndex(item => record.key === item.key);
      const item = newData[index];

      let Tax_per = 0; // Tax - Percentage

      if (value <= 100)  // Less Than 100
      {
        Tax_per = value;
      }
      else {  // Greater Than 100
        Tax_per = 100;
      }

      CalculateTotal({
        ...item,
        tax_percentage: Tax_per,
      })

      HandleRowCal({
        ...item,
        tax_percentage: Tax_per,
      })

      item.tax_percentage = Tax_per;

      return newData;
    })
  }
  // ========== product =============

  const HandleProduct = (value, record) => {   // ONCHANGE PRODUCT 
    setProTableData(prevState => {
      const newData = [...prevState];
      const index = newData.findIndex(item => record.key === item.key);
      const item = newData[index];

      const setSelectedSale = variationsArray?.find(item => item.variationListId === value);

      let Product = '';
      let ProductName = '';
      let Quantity = '';
      let ItemCode = '';
      let AvailableQty = '';
      let Unit = '';
      let SalePrice = '';
      let Stock = '';
      let GSTPercentage = '';
      let DisPercentage = '';

      if (setSelectedSale) {
        Product = setSelectedSale.variationListId;
        ProductName = setSelectedSale.productName;
        ItemCode = setSelectedSale.ItemCode;
        AvailableQty = parseFloat(setSelectedSale.AvailableQty);
        Unit = setSelectedSale.Unit;
        SalePrice = parseFloat(setSelectedSale.SaleRate);
        Quantity = 1;
        GSTPercentage = parseFloat(setSelectedSale.GSTPercentage);
        DisPercentage = parseFloat(setSelectedSale.DisPercentage);
        Stock = setSelectedSale.StockMaintain;
      }

      const isItemAlreadyAdded = newData.some(
        (item, i) => i !== index && item.item === value
      );

      if (isItemAlreadyAdded) {
        Product = '';
        ProductName = '';
        ItemCode = '';
        AvailableQty = '';
        Unit = '';
        SalePrice = 0;
        Quantity = 0;
        Stock = '';
        GSTPercentage = 0;
        DisPercentage = 0;

        toast.warn("Product already added in the table.");
        // return newData;
      }

      item.item_name = ProductName;
      item.item = Product;
      item.item_code = ItemCode;
      item.unit = Unit;
      item.available_qty = AvailableQty;
      item.sale_price = SalePrice;
      item.quantity = Quantity;
      item.discount_percentage = DisPercentage;
      item.tax_percentage = GSTPercentage;
      item.stock_maintain = Stock;

      CalculateTotal({
        ...item,
        sale_price: SalePrice,
        quantity: Quantity,
      })

      HandleRowCal({
        ...item,
        sale_price: SalePrice,
        quantity: Quantity,
      })

      return newData;
    });
  };

  const HandleItemCodeProduct = (value, record) => {   // ONCHANGE ITEM CODE
  


    setProTableData(prevState => {
      const newData = [...prevState];
      const index = newData.findIndex(item => record.key === item.key);
      const item = newData[index];

      const setSelectedSales = variationsArray?.find(item => item.variationListId === value);

      let Product = '';
      let ProductName = '';
      let Quantity = '';
      let ItemCode = '';
      let AvailableQty = '';
      let Unit = '';
      let SalePrice = '';
      let Stock = '';
      let MRP = '';
      let DisCount = '';
      let TaxPer = '';

      if (setSelectedSales) {
        Product = setSelectedSales.variationListId;
        ProductName = setSelectedSales.productName;
        ItemCode = setSelectedSales.ItemCode;
        AvailableQty = parseFloat(setSelectedSales.AvailableQty);
        Stock = setSelectedSales.StockMaintain;
        Unit = setSelectedSales.Unit;
        SalePrice = setSelectedSales.SaleRate;
        Quantity = 1;
        MRP = setSelectedSales.MRP;
        DisCount = parseFloat(setSelectedSales.DistCountPer);
        TaxPer = parseFloat(setSelectedSales.TaxtPer)
      }

      const isItemAlreadyAdded = newData.some(
        (item, i) => i !== index && item.item === value
      );

      if (isItemAlreadyAdded) {
        Product = '';
        ProductName = '';
        ItemCode = '';
        AvailableQty = '';
        Unit = '';
        SalePrice = 0;
        Quantity = 0;
        Stock = '';
        MRP = 0;
        DisCount = 0;
        TaxPer = 0;
        toast.warn("Product already added in the table.");
        // return newData;
      }

      item.item_name = ProductName;
      item.item = Product;
      item.item_code = ItemCode;
      item.unit = Unit;
      item.available_qty = AvailableQty;
      item.price_per_unit = SalePrice;
      item.item_qty = Quantity;
      item.stock_maintain = Stock;
      item.mrp = MRP;
      item.dis_percentage = DisCount;
      item.tax_percentage_name = TaxPer;

      CalculateTotal({
        ...item,
        price_per_unit: SalePrice,
        item_qty: Quantity,
      })

      HandleRowCal({
        ...item,
        price_per_unit: SalePrice,
        item_qty: Quantity,
      })
      item.item_name = value

      return newData;
    });
  };


  // ===================  Whole Tax Row Calculation ============

  const HandleRowCal = (record) => {
    setProTableData(prevState => {
      const newData = [...prevState];
      const index = newData.findIndex(item => record.key === item.key);
      const item = newData[index];

      let sub_total = 0; // Sub - Total
      let grand_total = 0; // Grand - Total
      let tax_amount = 0; // Tax - Amount
      let dis_amount = 0; // Discount - Amount


      const price = record.sale_price || 0;
      const quantity = record.quantity || 0;
      const Tax_per = record.tax_percentage || 0;
      const Dis_per = record.discount_percentage || 0;

      const OriginalAmount = calculateProductTableAmount(item); // Qty x Price

      if (gstChecked)  //  GST CHECKED
      {
        // --------------  TAX PERCENTAGE NOT EQUAL TO ZERO ---
        if (Tax_per != 0) {
          // --------------  DisCount NOT EQUAL TO ZERO ---
          if (Dis_per != 0) {

            // --- Discount Calculation
            let DisAmt = (OriginalAmount * Dis_per) / 100;  // --> Discount Amt

            // --- Tax Calculation
            let TaxPlusHun = (Tax_per + 100);  // --> Tax + 100

            let TaxIncludePrice = OriginalAmount - DisAmt;

            let ExcludingAmt = TaxIncludePrice * (100 / TaxPlusHun); // -- > Excluding Amount

            const TaxAmt = TaxIncludePrice - ExcludingAmt;

            dis_amount = DisAmt;
            tax_amount = TaxAmt;
            sub_total = ExcludingAmt;
            grand_total = TaxIncludePrice;
          }
          else // --------------  DisCount EQUAL TO ZERO ---
          {
            // --- Tax Calculation

            let TaxPlusHun = (Tax_per + 100); // --> Tax + 100

            let ExcludingAmt = OriginalAmount * (100 / TaxPlusHun); // -- > Excluding Amount

            let TaxAmt = OriginalAmount - ExcludingAmt;
            let TaxQtyAmt = TaxAmt * quantity

            dis_amount = 0;
            tax_amount = TaxQtyAmt;
            sub_total = ExcludingAmt;
            grand_total = OriginalAmount;
          }
        }
        else // --------------  TAX PERCENTAGE EQUAL TO ZERO ---
        {
          // --------------  DisCount NOT EQUAL TO ZERO ---
          if (Dis_per != 0) {

            // --- Discount Calculation
            const DisMinus = (OriginalAmount * Dis_per) / 100;
            const ApplyDiscount = OriginalAmount - DisMinus;

            dis_amount = DisMinus;
            tax_amount = 0;
            sub_total = OriginalAmount;
            grand_total = ApplyDiscount;
          }
          else // --------------  DisCount EQUAL TO ZERO ---
          {
            dis_amount = 0;
            tax_amount = 0;
            sub_total = OriginalAmount;
            grand_total = OriginalAmount;
          }
        }
      }
      else // --------------  TAX PERCENTAGE NOT EQUAL TO ZERO ---
      {
        // --------------  TAX PERCENTAGE NOT EQUAL TO ZERO ---
        if (Tax_per != 0) {
          // --------------  DisCount NOT EQUAL TO ZERO ---
          if (Dis_per != 0) {

            // --- Discount Calculation
            const DisMinus = (OriginalAmount * Dis_per) / 100;
            const ApplyDiscount = OriginalAmount - DisMinus;

            // --- Tax Calculation
            const taxAmt = (ApplyDiscount * Tax_per) / 100;
            const ApplyTax = ApplyDiscount + taxAmt;

            dis_amount = DisMinus;
            tax_amount = taxAmt;
            sub_total = OriginalAmount;
            grand_total = ApplyTax;
          }
          else // --------------  DisCount EQUAL TO ZERO ---
          {
            // --- Tax Calculation
            const taxAmt = (OriginalAmount * Tax_per) / 100;
            const ApplyTax = OriginalAmount + taxAmt;

            dis_amount = 0;
            tax_amount = taxAmt;
            sub_total = OriginalAmount;
            grand_total = ApplyTax;
          }
        }
        else  // --------------  TAX PERCENTAGE EQUAL TO ZERO ---
        {
          // --------------  DisCount NOT EQUAL TO ZERO ---
          if (Dis_per != 0) {

            // --- Discount Calculation
            const DisMinus = (OriginalAmount * Dis_per) / 100;
            const ApplyDiscount = OriginalAmount - DisMinus;

            dis_amount = DisMinus;
            tax_amount = 0;
            sub_total = OriginalAmount;
            grand_total = ApplyDiscount;
          }
          else // --------------  DisCount EQUAL TO ZERO ---
          {
            dis_amount = 0;
            tax_amount = 0;
            sub_total = OriginalAmount;
            grand_total = OriginalAmount;
          }
        }
      }
      item.quantity = quantity;
      item.sale_price = price;
      item.discount_percentage = Dis_per;
      item.sale_discount = dis_amount;
      item.tax_percentage = Tax_per;
      item.tax_cal_amt = tax_amount;
      item.sub_total = sub_total;
      item.item_cal_total_amt = grand_total;

      CalculateTotal({
        ...item,
        quantity: quantity,
        sale_discount: dis_amount,
        tax_cal_amt: tax_amount,
        item_cal_total_amt: sub_total,
      })

      return newData;
    })
  }

  // =============== Service Table Functions ===============

  const serviceHandleRowCal = (record) => {
    setServiceTableData(prevState => {
      const newData = [...prevState];
      const index = newData.findIndex(item => record.key === item.key);
      const item = newData[index];

      let sub_total = 0; // Sub - Total
      let grand_total = 0; // Grand - Total
      let tax_amount = 0; // Tax - Amount
      let dis_amount = 0; // Discount - Amount


      const price = record.sale_price || 0;
      // const quantity = record.quantity || 0;
      const Tax_per = record.tax_percentage || 0;
      const Dis_per = record.discount_percentage || 0;


      if (gstChecked)  //  GST CHECKED
      {
        // --------------  TAX PERCENTAGE NOT EQUAL TO ZERO ---
        if (Tax_per != 0) {
          // --------------  DisCount NOT EQUAL TO ZERO ---
          if (Dis_per != 0) {

            // --- Tax Calculation
            let TaxPlusHun = (Tax_per + 100);
            let ExcludingAmt = price * (100 / TaxPlusHun);

            // --- Discount Calculation
            let DisWithOutGST = (price * Dis_per) / 100;
            let TaxIncludePrice = price - DisWithOutGST;
            let DisAmt = (ExcludingAmt * Dis_per) / 100;

            // TaxAmount Calculation
            let SecExcludingAmt = TaxIncludePrice * (100 / TaxPlusHun);

            const TaxAmt = TaxIncludePrice - SecExcludingAmt;

            dis_amount = DisAmt;
            tax_amount = TaxAmt;
            sub_total = ExcludingAmt;
            grand_total = TaxIncludePrice;
          }
          else // --------------  DisCount EQUAL TO ZERO ---
          {
            // --- Tax Calculation

            let TaxPlusHun = (Tax_per + 100);
            let ExcludingAmt = price * (100 / TaxPlusHun);

            let TaxAmt = price - ExcludingAmt;
            // let TaxQtyAmt = TaxAmt * quantity
            let TaxQtyAmt = TaxAmt

            dis_amount = 0;
            tax_amount = TaxQtyAmt;
            sub_total = ExcludingAmt;
            grand_total = price;
          }
        }
        else // --------------  TAX PERCENTAGE EQUAL TO ZERO ---
        {
          // --------------  DisCount NOT EQUAL TO ZERO ---
          if (Dis_per != 0) {

            // --- Discount Calculation
            const DisMinus = (price * Dis_per) / 100;
            const ApplyDiscount = price - DisMinus;

            dis_amount = DisMinus;
            tax_amount = 0;
            sub_total = price;
            grand_total = ApplyDiscount;
          }
          else // --------------  DisCount EQUAL TO ZERO ---
          {
            dis_amount = 0;
            tax_amount = 0;
            sub_total = price;
            grand_total = price;
          }
        }
      }
      else // --------------  TAX PERCENTAGE NOT EQUAL TO ZERO ---
      {
        // --------------  TAX PERCENTAGE NOT EQUAL TO ZERO ---
        if (Tax_per != 0) {
          // --------------  DisCount NOT EQUAL TO ZERO ---
          if (Dis_per != 0) {

            // --- Discount Calculation
            const DisMinus = (price * Dis_per) / 100;
            const ApplyDiscount = price - DisMinus;

            // --- Tax Calculation
            const taxAmt = (ApplyDiscount * Tax_per) / 100;
            const ApplyTax = ApplyDiscount + taxAmt;

            dis_amount = DisMinus;
            tax_amount = taxAmt;
            sub_total = price;
            grand_total = ApplyTax;
          }
          else // --------------  DisCount EQUAL TO ZERO ---
          {
            // --- Tax Calculation
            const taxAmt = (price * Tax_per) / 100;
            const ApplyTax = price + taxAmt;

            dis_amount = 0;
            tax_amount = taxAmt;
            sub_total = price;
            grand_total = ApplyTax;
          }
        }
        else  // --------------  TAX PERCENTAGE EQUAL TO ZERO ---
        {
          // --------------  DisCount NOT EQUAL TO ZERO ---
          if (Dis_per != 0) {

            // --- Discount Calculation
            const DisMinus = (price * Dis_per) / 100;
            const ApplyDiscount = price - DisMinus;

            dis_amount = DisMinus;
            tax_amount = 0;
            sub_total = price;
            grand_total = ApplyDiscount;
          }
          else // --------------  DisCount EQUAL TO ZERO ---
          {
            dis_amount = 0;
            tax_amount = 0;
            sub_total = price;
            grand_total = price;
          }
        }
      }
      // item.quantity = quantity;
      item.sale_price = price;
      item.discount_percentage = Dis_per;
      item.sale_discount = dis_amount;
      item.tax_percentage = Tax_per;
      item.tax_cal_amt = tax_amount;
      item.sub_total = sub_total;
      item.item_cal_total_amt = grand_total;

      CalculateServiceTotal({
        ...item,
        // quantity: quantity,
        sale_discount: dis_amount,
        tax_cal_amt: tax_amount,
        item_cal_total_amt: sub_total,
      })

      return newData;
    })
  }

  // ===================  Whole Tax Table Calculation ============

  const HandleTableCal = () => {

    setProTableData(prevState => {
      const newData = prevState.map(item => {
        let sub_total = 0;
        let grand_total = 0;
        let tax_amount = 0;
        let dis_amount = 0;

        const price = item.sale_price || 0;
        const quantity = item.quantity || 0;
        const Tax_per = item.tax_percentage || 0;
        const Dis_per = item.discount_percentage || 0;

        const OriginalAmount = calculateProductTableAmount(item);


        if (gstChecked) {
          if (Tax_per !== 0) {
            if (Dis_per != 0) {

              // --- Discount Calculation
              let DisAmt = (OriginalAmount * Dis_per) / 100;  // --> Discount Amt
  
              // --- Tax Calculation
              let TaxPlusHun = (Tax_per + 100);  // --> Tax + 100
  
              let TaxIncludePrice = OriginalAmount - DisAmt;
  
              let ExcludingAmt = TaxIncludePrice * (100 / TaxPlusHun); // -- > Excluding Amount
  
              const TaxAmt = TaxIncludePrice - ExcludingAmt;
  
              dis_amount = DisAmt;
              tax_amount = TaxAmt;
              sub_total = ExcludingAmt;
              grand_total = TaxIncludePrice;
            }
            else // --------------  DisCount EQUAL TO ZERO ---
            {
              // --- Tax Calculation
  
              let TaxPlusHun = (Tax_per + 100); // --> Tax + 100
  
              let ExcludingAmt = OriginalAmount * (100 / TaxPlusHun); // -- > Excluding Amount
  
              let TaxAmt = OriginalAmount - ExcludingAmt;
              let TaxQtyAmt = TaxAmt * quantity
  
              dis_amount = 0;
              tax_amount = TaxQtyAmt;
              sub_total = ExcludingAmt;
              grand_total = OriginalAmount;
            }
          } else {
            if (Dis_per !== 0) {
              let DisMinus = (OriginalAmount * Dis_per) / 100;
              let ApplyDiscount = OriginalAmount - DisMinus;

              dis_amount = DisMinus;
              tax_amount = 0;
              sub_total = OriginalAmount;
              grand_total = ApplyDiscount;
            } else {
              dis_amount = 0;
              tax_amount = 0;
              sub_total = OriginalAmount;
              grand_total = OriginalAmount;
            }
          }
        } else {
          if (Tax_per !== 0) {
            if (Dis_per !== 0) {
              let DisMinus = (OriginalAmount * Dis_per) / 100;
              let ApplyDiscount = OriginalAmount - DisMinus;

              let taxAmt = (ApplyDiscount * Tax_per) / 100;
              let ApplyTax = ApplyDiscount + taxAmt;

              dis_amount = DisMinus;
              tax_amount = taxAmt;
              sub_total = OriginalAmount;
              grand_total = ApplyTax;
            } else {
              let taxAmt = (OriginalAmount * Tax_per) / 100;
              let ApplyTax = OriginalAmount + taxAmt;

              dis_amount = 0;
              tax_amount = taxAmt;
              sub_total = OriginalAmount;
              grand_total = ApplyTax;
            }
          } else {
            if (Dis_per !== 0) {
              let DisMinus = (OriginalAmount * Dis_per) / 100;
              let ApplyDiscount = OriginalAmount - DisMinus;

              dis_amount = DisMinus;
              tax_amount = 0;
              sub_total = OriginalAmount;
              grand_total = ApplyDiscount;
            } else {
              dis_amount = 0;
              tax_amount = 0;
              sub_total = OriginalAmount;
              grand_total = OriginalAmount;
            }
          }
        }

        item.quantity = quantity;
        item.sale_price = price;
        item.discount_percentage = Dis_per;
        item.sale_discount = dis_amount;
        item.tax_percentage = Tax_per;
        item.tax_cal_amt = tax_amount;
        item.sub_total = sub_total;
        item.item_cal_total_amt = grand_total;

        CalculateTotal({
          ...item,
          quantity: quantity,
          sale_discount: dis_amount,
          tax_cal_amt: tax_amount,
          item_cal_total_amt: sub_total,
        });

        return item;
      });

      return newData;
    });


  };

  // ---------------- 1.TotalQuantity ONCHANGE Function

  const handleOnChangeQuantity = (value, record) => {  //  ----> QUANTITY ONCHANGE (PRODUCT TABLE)
    HandleQty(value, record);  //  1227
  };

  const handleOnChangePrice = (value, record) => {   //  ----> PRICE ONCHANGE (PRODUCT TABLE)
    HandlePrice(value, record) //  1258
  }

  const handleOnChangeDiscount = (value, record) => {  //  ----> DISCOUNT ONCHANGE (PRODUCT TABLE)
    HandleDiscount(value, record)  //  1317
  }

  const handleOnChangeTax = (value, record) => {  //  -----> TAX ONCHANGE (PRODUCT TABLE)
    HandleTax(value, record)  // 1413
  }

  const handleOnChangeProduct = (value, record) => {  //  -----> PRODUCT ONCHANGE (PRODUCT TABLE)
    HandleProduct(value, record)
  }

  const handleOnChangeItemCodeProduct = (value, record) => {  //  -----> PRODUCT ONCHANGE (PRODUCT TABLE)
    HandleItemCodeProduct(value, record)
  }

  // -------------- Handle Total Row Amount  --------------
  const calculateProductTableAmount = (record) => {
    const quantity = parseFloat(record.quantity) || 0;
    const sale_amount = parseFloat(record.sale_price) || 0;
    return quantity * sale_amount
  }

  // ================== Product Table Functions End ==================



  // ================== Service Table Functions start ==================


  // ===================  Whole Tax Table Calculation ============

  const HandleTableServiceCal = () => {

    setServiceTableData(prevState => {
      const newData = prevState.map(item => {
        let sub_total = 0;
        let grand_total = 0;
        let tax_amount = 0;
        let dis_amount = 0;

        const price = item.sale_price || 0;
        const Tax_per = item.tax_percentage || 0;
        const Dis_per = item.discount_percentage || 0;

        if (gstChecked) {
          if (Tax_per !== 0) {
            if (Dis_per !== 0) {
              let TaxPlusHun = Tax_per + 100;
              let ExcludingAmt = price * (100 / TaxPlusHun);

              let DisWithOutGST = (price * Dis_per) / 100;
              let TaxIncludePrice = price - DisWithOutGST;
              let DisAmt = (ExcludingAmt * Dis_per) / 100;

              let SecExcludingAmt = TaxIncludePrice * (100 / TaxPlusHun);

              const TaxAmt = TaxIncludePrice - SecExcludingAmt;

              dis_amount = DisAmt;
              tax_amount = TaxAmt;
              sub_total = ExcludingAmt;
              grand_total = TaxIncludePrice;
            } else {
              let TaxPlusHun = Tax_per + 100;
              let ExcludingAmt = price * (100 / TaxPlusHun);

              let TaxAmt = price - ExcludingAmt;
              let TaxQtyAmt = TaxAmt;

              dis_amount = 0;
              tax_amount = TaxQtyAmt;
              sub_total = ExcludingAmt;
              grand_total = price;
            }
          } else {

            if (Dis_per !== 0) {

              let DisMinus = (price * Dis_per) / 100;
              let ApplyDiscount = price - DisMinus;

              dis_amount = DisMinus;
              tax_amount = 0;
              sub_total = price;
              grand_total = ApplyDiscount;
            } else {
              dis_amount = 0;
              tax_amount = 0;
              sub_total = price;
              grand_total = price;
            }
          }
        } else {
          if (Tax_per !== 0) {
            if (Dis_per !== 0) {
              let DisMinus = (price * Dis_per) / 100;
              let ApplyDiscount = price - DisMinus;

              let taxAmt = (ApplyDiscount * Tax_per) / 100;
              let ApplyTax = ApplyDiscount + taxAmt;

              dis_amount = DisMinus;
              tax_amount = taxAmt;
              sub_total = price;
              grand_total = ApplyTax;
            } else {
              let taxAmt = (price * Tax_per) / 100;
              let ApplyTax = price + taxAmt;

              dis_amount = 0;
              tax_amount = taxAmt;
              sub_total = price;
              grand_total = ApplyTax;
            }
          } else {
            if (Dis_per !== 0) {
              let DisMinus = (price * Dis_per) / 100;
              let ApplyDiscount = price - DisMinus;

              dis_amount = DisMinus;
              tax_amount = 0;
              sub_total = price;
              grand_total = ApplyDiscount;
            } else {
              dis_amount = 0;
              tax_amount = 0;
              sub_total = price;
              grand_total = price;
            }
          }
        }

        // item.quantity = quantity;
        item.sale_price = price;
        item.discount_percentage = Dis_per;
        item.sale_discount = dis_amount;
        item.tax_percentage = Tax_per;
        item.tax_cal_amt = tax_amount;
        item.sub_total = sub_total;
        item.item_cal_total_amt = grand_total;

        CalculateServiceTotal({
          ...item,
          // quantity: quantity,
          sale_discount: dis_amount,
          tax_cal_amt: tax_amount,
          item_cal_total_amt: sub_total,
        });

        return item;
      });

      return newData;
    });

  };

  // ========= Service Delete & AddRow ========

  const AddServiceRow = () => { // ----------------- Add Row Function
    const newData = {
      key: serviceCount,
      description: '',
      service: '',
      item_name: '',
      item_code: '',
      sale_price: '',
      service: '',
      discount_percentage: '',
      service_tax_percentage: '',
      tax_cal_amt: '',
      sub_total: '',
      item_cal_total_amt: '',
    };
    setServiceTableData(pre => {
      return [...pre, newData]
    })
    setServiceCount(serviceCount + 1);

  }

  const onServiceTabRowDelete = (key) => {     // -----------------------  Delete Row Function

    if (serviceTableData.length > 1) {
      setServiceTableData(prevState => {
        const newData = prevState.filter(item => item.key !== key);

        // ------ Variables 
        let totalDiscount = 0;
        let totalTax = 0;
        let totalAmount = 0;

        newData.forEach(item => {
          if (item.sale_discount !== '' || item.tax_cal_amt !== '' || item.item_cal_total_amt !== '') {

            totalDiscount += parseFloat(item.sale_discount);
            totalTax += parseFloat(item.tax_cal_amt);
            totalAmount += parseFloat(item.item_cal_total_amt);
          }
        });

        // update the grand_total value in the tableSecondaryData array
        setServiceTabSecondaryData([{

          discount_total: totalDiscount.toFixed(2),
          tax_total: totalTax.toFixed(2),
          table_total: totalAmount.toFixed(2),
        }]);

        return newData;
      });
    }
  }

  const CalculateServiceTotal = (record) => {  // ----------------- 1. Calculate TotalAmount Service

    setServiceTableData(prevState => {
      const newData = [...prevState];
      const index = newData.findIndex(item => record.key === item.key);
      const item = newData[index];

      // ------ Variables -----
      let totalDiscount = 0;
      let totalTax = 0;
      let totalAmount = 0;

      newData.forEach(item => {
        if (item.sale_discount !== '' || item.tax_cal_amt !== '' || item.item_cal_total_amt !== '') {
          totalDiscount += parseFloat(item.sale_discount);
          totalTax += parseFloat(item.tax_cal_amt);
          totalAmount += parseFloat(item.item_cal_total_amt);
        }
      });

      // update the grand_total value in the tableSecondaryData array

      setServiceTabSecondaryData([{
        discount_total: totalDiscount.toFixed(2),
        tax_total: totalTax.toFixed(2),
        table_total: totalAmount.toFixed(2),
      }]);

      return newData;
    });

  }

  // ================== Service Table Functions start ==================

  const ServiceChanges = (value, record) => {
    setServiceTableData(prevState => {
      const newData = [...prevState];
      const index = newData.findIndex(item => record.key === item.key);
      const item = newData[index];

      const setSelectedSale = getdataservice.find(item => item.service_name === value);

      let Service = '';
      let ItemName = '';
      let ItemCode = '';
      let SalePrice = '';

      if (setSelectedSale) {
        Service = setSelectedSale.id;
        ItemName = setSelectedSale.service_name;
        ItemCode = setSelectedSale.service_code;
        SalePrice = parseFloat(setSelectedSale.rate);
      }

      const isItemAlreadyAdded = newData.some(
        (item, i) => i !== index && item.item_name === value
      );

      if (isItemAlreadyAdded) {
        Service = '';
        ItemName = '';
        ItemCode = '';
        SalePrice = 0;

        toast.warn("Service already added in the table.");
      }

      item.service = Service;
      item.item_name = ItemName;
      item.item_code = ItemCode;
      item.sale_price = SalePrice;
      item.discount_percentage = 0;
      item.tax_percentage = 0;

      CalculateServiceTotal({
        ...item,
        sale_price: item.sale_price,

      })

      serviceHandleRowCal({
        ...item,
        discount_percentage: item.discount_percentage,
      })

      return newData;
    });
  };

  const ServiceDiscountChanges = (value, record) => {
    setServiceTableData(prevState => {
      const newData = [...prevState];
      const index = newData.findIndex(item => record.key === item.key);
      const item = newData[index];

      let Dis_per = 0; // Dis - Percentage

      if (value <= 100)  // Less Than 100
      {
        Dis_per = value;
      }
      else {  // Greater Than 100
        Dis_per = 100;
      }

      CalculateServiceTotal({
        ...item,
        discount_percentage: Dis_per,
      })

      serviceHandleRowCal({
        ...item,
        discount_percentage: Dis_per,
      })

      item.discount_percentage = Dis_per;

      return newData;
    })
  }

  const ServiceTaxChanges = (value, record) => {
    setServiceTableData(prevState => {
      const newData = [...prevState];
      const index = newData.findIndex(item => record.key === item.key);
      const item = newData[index];

      let Tax_per = 0; // Tax - Percentage

      if (value <= 100)  // Less Than 100
      {
        Tax_per = value;
      }
      else {  // Greater Than 100
        Tax_per = 100;
      }

      CalculateServiceTotal({
        ...item,
        tax_percentage: Tax_per,
      })

      serviceHandleRowCal({
        ...item,
        tax_percentage: Tax_per,
      })

      item.tax_percentage = Tax_per;

      return newData;
    })
  }

  const ServicePriceChanges = (value, record) => {
    setServiceTableData(prevState => {
      const newData = [...prevState];
      const index = newData.findIndex(item => record.key === item.key);
      const item = newData[index];

      item.sale_price = value || 0;

      CalculateServiceTotal({
        ...item,
        sale_price: item.sale_price,

      })

      serviceHandleRowCal({
        ...item,
        sale_price: item.sale_price,
      })

      return newData;
    });
  }

  const handleOnChangeService = (value, record) => {    //  -----> SERVICE ONCHANGE (SERVICE TABLE)
    ServiceChanges(value, record)
  }

  const handleonChangeServiceDiscount = (value, record) => {  // -----> SERVICE DISCOUNT PERCENTAGE ONCHANGE (SERVICE TABLE)
    ServiceDiscountChanges(value, record)
  }

  const handleOnChangeServiceTax = (value, record) => {  // -----> SERVICE TAX ONCHANGE (SERVICE TABLE)
    ServiceTaxChanges(value, record)
  }

  const handleOnChangeServicePrice = (value, record) => {  // -----> SERVICE PRICE ONCHANGE (SERVICE TABLE)
    ServicePriceChanges(value, record)
  }

  // ====================  On Finish Function ============

  const onFinish = (values) => {
    const record = {
      ...values,
      // invoice_date: selectedDate,
      item_choice: serviceClick ? 'Service' : 'Product',
      tax_choice: gstChecked ? 'GST' : 'NOGST',
      stock_maintain: getdata?.item?.variation_list?.variant_stock_maintain === false ? "true" : "false",
      invoice_date: values?.invoice_date === null ? '' : dayjs(selectedDate).format('YYYY-MM-DD') ? dayjs(values?.invoice_date).format('YYYY-MM-DD') : dayjs(values?.invoice_date).format('YYYY-MM-DD'),

    };

    let result = {
      party_name: record.party_name,
      party: record.party,
      stock_maintain: record.stock_maintain,
      mobile_number: record.mobile_number,
      invoice_date: record.invoice_date,
      invoice_no: record.invoice_no,
      state_of_supply: record.state_of_supply,
      billing_address: record.billing_address,
      shipping_address: record.shipping_address,
      item_choice: record.item_choice,
      tax_choice: record.tax_choice,
      qty_total: record.qty_total || 0,
      discount_total: record.discount_total,
      tax_total: record.tax_total,
      table_total: record.table_total,
      grand_total: record.grand_total,
      sub_total: record.table_total,
      received_amt: record.received_amt ? parseFloat(record.received_amt).toFixed(2) : parseFloat(0).toFixed(2),
      balance: parseFloat(record.balance).toFixed(2),
      GSTIN: record.GSTIN,

      sales: serviceClick
        ? Object.entries(record)
          .filter(([key]) => key.startsWith('item_code'))
          .map(([key, item_code]) => {                          // ----- Service ---------
            const index = key.match(/\d+/)[0];
            const itemNamekey = `item_name${index}`;
            const itemkey = `service${index}`;
            // const itemcode = `item_code${index}`;
            const unitKey = `unit${index}`;
            const salePriceKey = `sale_price${index}`;
            const discountpercnt = `discount_percentage${index}`;
            const saledisct = `sale_discount${index}`;
            const taxtpercnt = `tax_percentage${index}`;
            const taxt_total_amt = `tax_cal_amt${index}`;
            const item_Total_amt = `item_cal_total_amt${index}`;
            const subTotal = `sub_total${index}`;

            return {
              item_code,
              item_name: record[itemNamekey],
              service: record[itemkey],
              // item_code: record[itemcode],
              unit: record[unitKey],
              sale_price: !isNaN(parseFloat(record[salePriceKey])) ? parseFloat(record[salePriceKey]).toFixed(2) : 0,
              discount_percentage: !isNaN(parseFloat(record[discountpercnt])) ? parseFloat(record[discountpercnt]).toFixed(2) : 0,
              sale_discount: !isNaN(parseFloat(record[saledisct])) ? parseFloat(record[saledisct]).toFixed(2) : 0,
              tax_percentage: !isNaN(parseFloat(record[taxtpercnt])) ? parseFloat(record[taxtpercnt]).toFixed(2) : 0,
              sub_total: !isNaN(parseFloat(record[subTotal])) ? parseFloat(record[subTotal]).toFixed(2) : 0,
              tax_cal_amt: !isNaN(parseFloat(record[taxt_total_amt])) ? parseFloat(record[taxt_total_amt]).toFixed(2) : 0,
              item_cal_total_amt: !isNaN(parseFloat(record[item_Total_amt])) ? parseFloat(record[item_Total_amt]).toFixed(2) : 0,
            };
          })
        :
        Object.entries(record)                             // ----- product ---------
          .filter(([key]) => key.startsWith('item_code'))
          .map(([key, item_code]) => {
            const index = key.match(/\d+/)[0];
            const itemNamekey = `item_name${index}`;
            const itemkey = `item${index}`;
            const itemAvailableQty = `available_qty${index}`;
            const availableQtyKey = `quantity${index}`;
            const unitKey = `unit${index}`;
            const salePriceKey = `sale_price${index}`;
            const discountpercnt = `discount_percentage${index}`;
            const saledisct = `sale_discount${index}`;
            const taxtpercnt = `tax_percentage${index}`;
            const taxt_total_amt = `tax_cal_amt${index}`;
            const item_Total_amt = `item_cal_total_amt${index}`;
            const subTotal = `sub_total${index}`;

            return {
              item_code,
              item_name: record[itemNamekey],
              item: record[itemkey],
              // description: record[itemdescription],
              available_qty: record[itemAvailableQty],
              quantity: record[availableQtyKey],
              unit: record[unitKey],
              sale_price: !isNaN(parseFloat(record[salePriceKey])) ? parseFloat(record[salePriceKey]).toFixed(2) : 0,
              discount_percentage: !isNaN(parseFloat(record[discountpercnt])) ? parseFloat(record[discountpercnt]).toFixed(2) : 0,
              sale_discount: !isNaN(parseFloat(record[saledisct])) ? parseFloat(record[saledisct]).toFixed(2) : 0,
              tax_percentage: !isNaN(parseFloat(record[taxtpercnt])) ? parseFloat(record[taxtpercnt]).toFixed(2) : 0,
              sub_total: !isNaN(parseFloat(record[subTotal])) ? parseFloat(record[subTotal]).toFixed(2) : 0,
              tax_cal_amt: !isNaN(parseFloat(record[taxt_total_amt])) ? parseFloat(record[taxt_total_amt]).toFixed(2) : 0,
              item_cal_total_amt: parseFloat(record[item_Total_amt]).toFixed(2),
            };
          })
    };
    if (SaleRecord) {
      GetSaleEditDetails(result)
    }
    else {
      SalesPost(result);

    }
  };

  const SalesPost = (values) => {

    request.post('sales/add_sale/', values)
      .then(function (response) {
        if (response.status === 201) {
          toast.success('Successfully Billed Sales Entry')
          form.resetFields();
          setTrigger((trigger) => trigger + 1);
          handleClick(response.data.id)
          GetSaleProduct()
          GetSaleService()
          setProTableData(ProductInitialData);
          setProTabSecondaryData(ProSecondaryData);
          setServiceTableData(ServiceInitialData);
          setServiceTabSecondaryData(ServiceSecondaryData);
          setBalanceChangeAmount(0)
          setBalanceChangeServiceAmount(0)
        }
        else if (response.status == 226) {
          if (response.data) {
            if (response.data.message) {
              toast.warn(response.data.message[0]);
            }
          }
        }
        else {
          toast.success('Successfully Billed Sales Entry ')
        }
      })
      .catch(function (error) {
        if (error.response && error.response.status === 400) {
          if (error.response.data) {
            if (error.response.data.GSTIN) {
              toast.warn(error.response.data.GSTIN[0]);
            } else if (error.response.data.mobile_number) {
              toast.warn(error.response.data.mobile_number[0]);
            } else {
              toast.error('Failed.');
            }
          }
        }
        else if (error.response.status === 302) {
          toast.warn("Sale Order limit exceeded!,so cant be Submited, Please subscribe your plan!")
        }
        else if (error.response.status === 401) {
          toast.warn("You plan has been blocked, So Please subscribe your plan!")
        } else {
          toast.error("Something Went Wrong")
        }

      });
  }


  const handleClick = (record) => {

    setModalWidth(400)
    setModalTitle("Sale Print");
    setModalContent(<LogOutModal record={record} />);

    showModal();
  }

  const LogOutModal = (record) => (
    <div>
      <h1 style={{ fontSize: '1.2rem' }}>Are you Sure You Want to Print ?</h1>
      <br />
      <Flex gap={'20px'} W_100 center verticallyCenter>
        <Button.Success text={'Print'} onClick={() => printOk(record)} />
        <Button.Danger text={'Cancel'} onClick={handleOk} />
      </Flex>
    </div>
  )

  const printOk = async (record) => {
    try {
      const response = await request.get(`sales/sale_return_pro_detail/${record?.record}/`);
      setModalWidth(1200)
      setModalTitle("Sale Details ");
      setModalContent(<ViewSaleBill record={response.data} />);
      showModal();
      return response.data;
    }
    catch (error) {
      console.error("Error in printOk:", error);
      throw error;
    }
  };

  const EDITGET = 'sales/edit_sale'

  const GetSaleEditDetails = (values) => {
    request.put(`${EDITGET}/${SaleRecord.id}/`, values)
      .then(function (response) {
        if (response.status === 201) {
          toast.success('Successfully Updated')
          form.resetFields();
          // if (SaleFormUpdate) {
          //     SaleFormUpdate()
          // }
          if (SaleFormUpdate) {
            SaleFormUpdate()
          }
        }
        if (response.status == 226) {
          toast.warn('This sale bill have transaction , Can not edit it');
        }
        if (response.status === 202) {
          toast.warn('Add Business Profile')
        }
        setBalanceChangeAmount(0)
      })
      .catch(function (error) {
        if (error.response.status === 410) {
          toast.warn("Your plan is not a subscription, it can't be edited")
        }
        else if (error.response.status === 302) {
          toast.warn("Sale Order limit exceeded!,so cant be Submited, Please subscribe your plan!")
        }
        else if (error.response.status === 401) {
          toast.warn("You plan has been blocked, So Please subscribe your plan!")
        }
        else {
          toast.error('Failed')
        }
      });
  }

  const onFinishFailed = (errorInfo) => {
    toast.warn('Please Fill the Details!')
  };


  // ==============  Add Row Component  ================

  const FooterComponent = () => {
    return (
      <div style={{ background: 'var(--light-color)', padding: '20px' }}>
        <CustomRow>
          <Col lg={4} sm={12} span={24}>
            <Button type="primary" style={{
              fontSize: '1rem',
              height: 'auto',
              fontFamily: 'Poppins',
              fontWeight: 500,
              letterSpacing: '1px',
            }}
              htmlType="button"
              onClick={AddRow}>
              Add Row
            </Button>
          </Col>
        </CustomRow>
      </div >
    )
  }

  const FooterComponentService = () => {
    return (
      <div style={{ background: 'var(--light-color)', padding: '20px' }}>
        <CustomRow>
          <Col lg={4} sm={12} span={24}>
            <Button type="primary" style={{
              fontSize: '1rem',
              height: 'auto',
              fontFamily: 'Poppins',
              fontWeight: 500,
              letterSpacing: '1px',
            }}
              htmlType="button"
              onClick={AddServiceRow}>
              Add Row
            </Button>
          </Col>
        </CustomRow>
      </div >
    )
  }


  // ==================  Table  ==================

  const onRest = () => {
    // form.resetFields();
    form.setFieldsValue({ invoice_no: invoiceNumber?.invoice_no })
    setSelectedSale(!selectedSale)
    setTrigger((trigger) => trigger + 1);
    GetSaleProduct()
    GetSaleService()
    setProTableData(ProductInitialData);
    setProTabSecondaryData(ProSecondaryData);

    // ======== service section =======

    setServiceTableData(ServiceInitialData)
    setServiceTabSecondaryData(ServiceSecondaryData)
    if (SaleFormUpdate) {
      SaleFormUpdate()
    }
  }


  const handleServiceSwitch = () => {
    if (serviceClick !== true) {
      form.resetFields();
      form.setFieldsValue({ invoice_no: invoiceNumber?.invoice_no });
      setServiceClick(true);
      setSelectedSale(true);
      setServiceTableData(ServiceInitialData)
      setServiceTabSecondaryData(ServiceSecondaryData)
    } else {
      form.resetFields();
      form.setFieldsValue({ invoice_no: invoiceNumber?.invoice_no });
      setSelectedSale(true);
      setServiceClick(false);
      setProTableData(ProductInitialData)
      setProTabSecondaryData(ProSecondaryData)
    }

  };
  const isDisabled = serviceClick ? balanceServiceChange : balanceChange;

  useEffect(() => {
    // if(SaleRecord.item_choice === 'Product'){
    if (SaleRecord?.sales) {
      const tableData = SaleRecord?.sales.map((value, index) => ({
        ...value,
        key: index
      }));

      setProTableData(tableData);
      setProCount(tableData.length)

    }
    // } 
    // if(SaleRecord.item_choice === 'Service'){
    if (serviceTableData) {
      if (SaleRecord?.sales) {
        const tableData = SaleRecord?.sales.map((value, index) => ({
          ...value,
          key: index
        }));

        setServiceTableData(tableData);
        setServiceCount(tableData.length)

      }
    }
    // }


  }, [SaleRecord, SaleTrigger]);


  return (
    <Fragment>
      <Form name="sales"
        labelCol={{
          span: 24,
        }}
        wrapperCol={{
          span: 24,
        }}
        form={form}
        initialValues={
          {
            invoice_date: dayjs(),
          }
        }
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}>
        <Flex alignCenter centervertically>
          <CustomSwitch onChange={handleServiceSwitch} leftLabel={'Product'} rightLabel={'Service'} checked={serviceClick} disabled={SaleRecord ? isDisable : null} />
        </Flex>
        <br />
        <SalesFormHeader trigger={trigger} setSale={setSale} setSelectedDate={setSelectedDate} SaleRecord={SaleRecord}
          setGetdata={setGetdata} selectedSale={selectedSale} setSelectedSale={setSelectedSale} gstChecked={gstChecked} />

        <Flex end={true} style={{ margin: '20px 0px' }}>
          <CustomSwitch leftLabel={'With GST'} rightLabel={'WithOut GST'} onChange={handleGSTSwitch} checked={gstChecked} />
        </Flex>

        <div style={{ margin: '20px 0' }}>

          {serviceClick ?
            (<CustomTandardTable columns={ServiceColumns.filter(Boolean)} data={serviceTableData} pagination={false} />)
            : (<CustomTandardTable columns={ProductColumns.filter(Boolean)} data={proTableData} pagination={false} />)
          }

          {serviceClick ?
            <FooterComponentService /> :
            <FooterComponent />
          }
        </div>

        <div style={{ margin: '20px 0' }}>
          <SalesFormFooter BalanceOnChange={BalanceOnChange} BalanceOnChangeService={BalanceOnChangeService} serviceClick={serviceClick} />
        </div>

        <Card>
          <Flex center gap={'10px'}>
            {SaleRecord ? <Button.Primary text={'Update'} htmlType="submit" disabled={isDisabled} />
              : <Button.Primary text={'Submit'} htmlType="submit" disabled={isDisabled} />}
            <Button.Danger text={'Cancel'} onClick={onRest} />
          </Flex>
        </Card>
      </Form>

      <CustomModal isVisible={isModalOpen} handleOk={handleOk} handleCancel={handleCancel} width={modalWidth} modalTitle={modalTitle} modalContent={modalContent} />
    </Fragment>
  )
}