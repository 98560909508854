import React, { Fragment } from 'react'
import { AddPartyForm } from './Partials/AddPartyForm'
import Flex from '../../../components/Flex'
import { CustomPageTitle } from '../../../components/CustomPageTitle'
import { CustomCardView } from '../../../components/CustomCardView'



export const Party = () => {
  return (
    <Fragment>
      <Flex alignCenter>
        <CustomPageTitle Heading={'Party'} />
      </Flex>
      <CustomCardView  width={'1000px'}>
        <AddPartyForm />
      </CustomCardView>
    </Fragment>
  )
}
