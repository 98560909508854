import React, { useEffect, useState, PureComponent } from 'react'
import { SalesDashData } from '../../Datas/DashboardData'
import { CustomRow } from '../../../components/CustomRow'
import { Card, Col, Progress, Table } from 'antd'
import Flex from '../../../components/Flex'
import {  StyledFromDash, StyledSaleCardDash, StyledSaleCardLeft, StyledSaleCardRight, StyledSaleCardRightPro } from '../style'
import { AreaChart, Area, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer, BarChart, Bar, Legend, Line, LineChart, ComposedChart } from 'recharts';
import { BsFillCaretDownFill, BsFillCaretUpFill } from 'react-icons/bs'
import { AiOutlinePlusCircle } from 'react-icons/ai'
import { CustomModal } from '../../../components/CustomModal'
import { AddFromDash, FrequentlySoldAmount } from './AddFromDash'
import { useLocation, useNavigate } from 'react-router-dom'
import request from '../../../utils/request'
import FrequentAmt from './FrequentAmt'

export const SalesDashboard = () => {

    const [salesdata, setSalesData] = useState(SalesDashData)
    const [dashdata, setDashData] = useState([])
    const [frequentpro, setFrequentPro] = useState([])
    const [prolossamt, setProLossAmt] = useState([])
    const [setproloss, setProLossStatus] = useState([])
    const [creditparty, SetCreditParty] = useState([])
    const [expensedata, setExpenseData] = useState([])
    const [saledata, setSaleData] = useState([])
    const [purchasedata, setPurchaseData] = useState([])
    const [peakHours, setPeakHours] = useState([])
    const [prolossdata, setProLossData] = useState([])
    // const [prolosscolor,setProLossColor] = useState([])

    const navigate = useNavigate();
    const location = useLocation();
    // ======  Modal Open ========
    const [isModalOpen, setIsModalOpen] = useState(false);

    // ======  Modal Title and Content ========
    const [modalTitle, setModalTitle] = useState("");
    const [modalContent, setModalContent] = useState(null);
    const [modelwith, setModelwith] = useState(0);

    useEffect(() => {
        GetDashboardDetailss()
    }, [])


    // const dispatch = useDispatch()

    // useEffect(() => {
    //     dispatch(getProfile())
    // }, [])


    const GetDashboardDetailss = () => {
        request.get('home/dashboard/')
            .then(function (response) {
                setPeakHours(response.data?.peaktime)
                setFrequentPro(response.data?.frequently_sale_product)
                setProLossAmt(response.data?.month_profit_loss_amount || '0')
                setProLossStatus(response.data?.month_profit_loss_status || '')
                setDashData(response?.data)
                SetCreditParty(response.data?.party_credit)
                setExpenseData(response.data?.Expense_graph)
                setSaleData(response.data?.sale_graph)
                setPurchaseData(response.data?.purchase_graph)
                setProLossData(response.data?.profit_loss_graph)

            })
            .catch(function (error) {
                if (error.code === 'ERR_NETWORK') {
                    navigate('/networkerror', {
                        state: {
                            previousUrl: location.pathname
                        }
                    })
                } else {
                    console.log(error, '');
                }
            });
    }

    // const NavigateToExpence = () => {
    //     navigate('/daybook')
    // }
    // ===== Modal Functions Start =====
    // if (value > 2500) {
    //     (
    //         <svg x={cx - 10} y={cy - 10} width={20} height={20} fill="red" viewBox="0 0 1024 1024">
    //             <path d="M512 1009.984c-274.912 0-497.76-222.848-497.76-497.76s222.848-497.76 497.76-497.76c274.912 0 497.76 222.848 497.76 497.76s-222.848 497.76-497.76 497.76zM340.768 295.936c-39.488 0-71.52 32.8-71.52 73.248s32.032 73.248 71.52 73.248c39.488 0 71.52-32.8 71.52-73.248s-32.032-73.248-71.52-73.248zM686.176 296.704c-39.488 0-71.52 32.8-71.52 73.248s32.032 73.248 71.52 73.248c39.488 0 71.52-32.8 71.52-73.248s-32.032-73.248-71.52-73.248zM772.928 555.392c-18.752-8.864-40.928-0.576-49.632 18.528-40.224 88.576-120.256 143.552-208.832 143.552-85.952 0-164.864-52.64-205.952-137.376-9.184-18.912-31.648-26.592-50.08-17.28-18.464 9.408-21.216 21.472-15.936 32.64 52.8 111.424 155.232 186.784 269.76 186.784 117.984 0 217.12-70.944 269.76-186.784 8.672-19.136 9.568-31.2-9.12-40.096z" />
    //         </svg>
    //     );
    // }

    // else {
    //     (
    //         <svg x={cx - 10} y={cy - 10} width={20} height={20} fill="green" viewBox="0 0 1024 1024">
    //             <path d="M517.12 53.248q95.232 0 179.2 36.352t145.92 98.304 98.304 145.92 36.352 179.2-36.352 179.2-98.304 145.92-145.92 98.304-179.2 36.352-179.2-36.352-145.92-98.304-98.304-145.92-36.352-179.2 36.352-179.2 98.304-145.92 145.92-98.304 179.2-36.352zM663.552 261.12q-15.36 0-28.16 6.656t-23.04 18.432-15.872 27.648-5.632 33.28q0 35.84 21.504 61.44t51.2 25.6 51.2-25.6 21.504-61.44q0-17.408-5.632-33.28t-15.872-27.648-23.04-18.432-28.16-6.656zM373.76 261.12q-29.696 0-50.688 25.088t-20.992 60.928 20.992 61.44 50.688 25.6 50.176-25.6 20.48-61.44-20.48-60.928-50.176-25.088zM520.192 602.112q-51.2 0-97.28 9.728t-82.944 27.648-62.464 41.472-35.84 51.2q-1.024 1.024-1.024 2.048-1.024 3.072-1.024 8.704t2.56 11.776 7.168 11.264 12.8 6.144q25.6-27.648 62.464-50.176 31.744-19.456 79.36-35.328t114.176-15.872q67.584 0 116.736 15.872t81.92 35.328q37.888 22.528 63.488 50.176 17.408-5.12 19.968-18.944t0.512-18.944-3.072-7.168-1.024-3.072q-26.624-55.296-100.352-88.576t-176.128-33.28z" />
    //         </svg>
    //     );
    // }

    const CustomizedDot = (props) => {
        const { cx, cy, stroke, payload, value } = props;
        {
            prolossdata.map((data, index) => (
                <div key={index}>
                    {data.color === 'green' ? (
                        <svg x={cx - 10} y={cy - 10} width={20} height={20} fill="green" viewBox="0 0 1024 1024">
                            <path d="M512 1009.984c-274.912 0-497.76-222.848-497.76-497.76s222.848-497.76 497.76-497.76c274.912 0 497.76 222.848 497.76 497.76s-222.848 497.76-497.76 497.76zM340.768 295.936c-39.488 0-71.52 32.8-71.52 73.248s32.032 73.248 71.52 73.248c39.488 0 71.52-32.8 71.52-73.248s-32.032-73.248-71.52-73.248zM686.176 296.704c-39.488 0-71.52 32.8-71.52 73.248s32.032 73.248 71.52 73.248c39.488 0 71.52-32.8 71.52-73.248s-32.032-73.248-71.52-73.248zM772.928 555.392c-18.752-8.864-40.928-0.576-49.632 18.528-40.224 88.576-120.256 143.552-208.832 143.552-85.952 0-164.864-52.64-205.952-137.376-9.184-18.912-31.648-26.592-50.08-17.28-18.464 9.408-21.216 21.472-15.936 32.64 52.8 111.424 155.232 186.784 269.76 186.784 117.984 0 217.12-70.944 269.76-186.784 8.672-19.136 9.568-31.2-9.12-40.096z" />
                        </svg>
                    ) : (
                        <svg x={cx - 10} y={cy - 10} width={20} height={20} fill="red" viewBox="0 0 1024 1024">
                            <path d="M517.12 53.248q95.232 0 179.2 36.352t145.92 98.304 98.304 145.92 36.352 179.2-36.352 179.2-98.304 145.92-145.92 98.304-179.2 36.352-179.2-36.352-145.92-98.304-98.304-145.92-36.352-179.2 36.352-179.2 98.304-145.92 145.92-98.304 179.2-36.352zM663.552 261.12q-15.36 0-28.16 6.656t-23.04 18.432-15.872 27.648-5.632 33.28q0 35.84 21.504 61.44t51.2 25.6 51.2-25.6 21.504-61.44q0-17.408-5.632-33.28t-15.872-27.648-23.04-18.432-28.16-6.656zM373.76 261.12q-29.696 0-50.688 25.088t-20.992 60.928 20.992 61.44 50.688 25.6 50.176-25.6 20.48-61.44-20.48-60.928-50.176-25.088zM520.192 602.112q-51.2 0-97.28 9.728t-82.944 27.648-62.464 41.472-35.84 51.2q-1.024 1.024-1.024 2.048-1.024 3.072-1.024 8.704t2.56 11.776 7.168 11.264 12.8 6.144q25.6-27.648 62.464-50.176 31.744-19.456 79.36-35.328t114.176-15.872q67.584 0 116.736 15.872t81.92 35.328q37.888 22.528 63.488 50.176 17.408-5.12 19.968-18.944t0.512-18.944-3.072-7.168-1.024-3.072q-26.624-55.296-100.352-88.576t-176.128-33.28z" />
                        </svg>
                    )}
                </div>
            ))
        }

        // if (value > 2500) {
        //     return (
        //         <svg x={cx - 10} y={cy - 10} width={20} height={20} fill="green" viewBox="0 0 1024 1024">
        //             <path d="M512 1009.984c-274.912 0-497.76-222.848-497.76-497.76s222.848-497.76 497.76-497.76c274.912 0 497.76 222.848 497.76 497.76s-222.848 497.76-497.76 497.76zM340.768 295.936c-39.488 0-71.52 32.8-71.52 73.248s32.032 73.248 71.52 73.248c39.488 0 71.52-32.8 71.52-73.248s-32.032-73.248-71.52-73.248zM686.176 296.704c-39.488 0-71.52 32.8-71.52 73.248s32.032 73.248 71.52 73.248c39.488 0 71.52-32.8 71.52-73.248s-32.032-73.248-71.52-73.248zM772.928 555.392c-18.752-8.864-40.928-0.576-49.632 18.528-40.224 88.576-120.256 143.552-208.832 143.552-85.952 0-164.864-52.64-205.952-137.376-9.184-18.912-31.648-26.592-50.08-17.28-18.464 9.408-21.216 21.472-15.936 32.64 52.8 111.424 155.232 186.784 269.76 186.784 117.984 0 217.12-70.944 269.76-186.784 8.672-19.136 9.568-31.2-9.12-40.096z" />
        //         </svg>
        //     );
        // }

        // return (
        //     <svg x={cx - 10} y={cy - 10} width={20} height={20} fill="red" viewBox="0 0 1024 1024">
        //         <path d="M517.12 53.248q95.232 0 179.2 36.352t145.92 98.304 98.304 145.92 36.352 179.2-36.352 179.2-98.304 145.92-145.92 98.304-179.2 36.352-179.2-36.352-145.92-98.304-98.304-145.92-36.352-179.2 36.352-179.2 98.304-145.92 145.92-98.304 179.2-36.352zM663.552 261.12q-15.36 0-28.16 6.656t-23.04 18.432-15.872 27.648-5.632 33.28q0 35.84 21.504 61.44t51.2 25.6 51.2-25.6 21.504-61.44q0-17.408-5.632-33.28t-15.872-27.648-23.04-18.432-28.16-6.656zM373.76 261.12q-29.696 0-50.688 25.088t-20.992 60.928 20.992 61.44 50.688 25.6 50.176-25.6 20.48-61.44-20.48-60.928-50.176-25.088zM520.192 602.112q-51.2 0-97.28 9.728t-82.944 27.648-62.464 41.472-35.84 51.2q-1.024 1.024-1.024 2.048-1.024 3.072-1.024 8.704t2.56 11.776 7.168 11.264 12.8 6.144q25.6-27.648 62.464-50.176 31.744-19.456 79.36-35.328t114.176-15.872q67.584 0 116.736 15.872t81.92 35.328q37.888 22.528 63.488 50.176 17.408-5.12 19.968-18.944t0.512-18.944-3.072-7.168-1.024-3.072q-26.624-55.296-100.352-88.576t-176.128-33.28z" />
        //     </svg>
        // );
    };

    const CustomTooltip = ({ active, payload, label }) => {
        if (active && payload && payload.length) {
            return (
                <div className="custom-tooltip">
                    <p style={{ color: 'black' }}>{`${label} : ${payload[0].value}`}</p>
                    <p style={{ color: 'black' }}>{getIntroOfPage(label)}</p>
                    <p style={{ color: 'black' }}>Total Sales</p>
                </div>
            );
        }

        return null;
    };
    const showModal = () => {
        setIsModalOpen(true);
    };

    const handleOk = () => {
        setIsModalOpen(false);
    };

    const handleCancel = () => {
        setIsModalOpen(false);
    };

    const OnClickDash = () => {
        setModelwith(500)
        setModalTitle("Amount Yet To Come !!!");
        setModalContent(<AddFromDash creditparty={creditparty} />);
        showModal();
    };
    const OnClickFreq = () => {
        setModelwith(500)
        setModalTitle("Frequently Sold Products !!!");
        setModalContent(<FrequentAmt Frequentpro={frequentpro} />);
        showModal();
    };

    const purchaseArray = [];

    const monthNames = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

    for (let i = 1; i <= 12; i++) {
        const purchaseEntry = purchasedata?.find((item) => item.month === monthNames[i - 1]);
        const purchaseAmount = purchaseEntry ? purchaseEntry.purchase_amount : 0;
        purchaseArray.push(purchaseAmount);
    }

    const saleArray = [];

    const monthName = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

    for (let i = 1; i <= 12; i++) {
        const saleEntry = saledata?.find(item => item.month === monthName[i - 1]);
        const saleAmount = saleEntry ? saleEntry.sale_amount : 0;
        saleArray.push(saleAmount)
    }


    const expenseArray = [];

    const monthNameExpense = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

    for (let i = 1; i <= 12; i++) {
        const expenseEntry = expensedata?.find(item => item.month === monthNameExpense[i - 1]);
        const expenseAmount = expenseEntry ? expenseEntry.expense : 0;
        expenseArray.push(expenseAmount)
    }

    const profitorlossArray = [];

    const monthNameProLoss = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

    for (let i = 1; i <= 12; i++) {
        const prolossEntry = prolossdata?.find(item => item.month === monthNameProLoss[i - 1]);
        const prolossAmount = prolossEntry ? prolossEntry.pro_loss_amt : 0;
        profitorlossArray.push(prolossAmount)
    }

    const purchaseandsales = [
        {
            name: 'Jan',
            sale: saleArray[0],
            purchase: purchaseArray[0],
        },
        {
            name: 'Feb',
            sale: saleArray[1],
            purchase: purchaseArray[1],
        },
        {
            name: 'Mar',
            sale: saleArray[2],
            purchase: purchaseArray[2],
        },
        {
            name: 'Apr',
            sale: saleArray[3],
            purchase: purchaseArray[3],
        },
        {
            name: 'May',
            sale: saleArray[4],
            purchase: purchaseArray[4],
        },
        {
            name: 'June',
            sale: saleArray[5],
            purchase: purchaseArray[5],
        },
        {
            name: 'July',
            sale: saleArray[6],
            purchase: purchaseArray[6],
        },
        {
            name: 'Aug',
            sale: saleArray[7],
            purchase: purchaseArray[7],
        },
        {
            name: 'Sept',
            sale: saleArray[8],
            purchase: purchaseArray[8],
        },
        {
            name: 'Oct',
            sale: saleArray[9],
            purchase: purchaseArray[9],
        },
        {
            name: 'Nov',
            sale: saleArray[10],
            purchase: purchaseArray[10],
        },
        {
            name: 'Dec',
            sale: saleArray[11],
            purchase: purchaseArray[11],
        }

    ];

    const expense = [
        {
            name: 'Jan',
            expense: expenseArray[0],
            
        },
        {
            name: 'Feb',
            expense: expenseArray[1],
            
        },
        {
            name: 'Mar',
            expense: expenseArray[2],
        },
        {
            name: 'April',
            expense: expenseArray[3],
        },
        {
            name: 'May',
            expense: expenseArray[4],
        },
        {
            name: 'June',
            expense: expenseArray[5],
        },
        {
            name: 'July',
            expense: expenseArray[6],
        },
        {
            name: 'Aug',
            expense: expenseArray[7],
        },
        {
            name: 'Sept',
            expense: expenseArray[8],
        },
        {
            name: 'Oct',
            expense: expenseArray[9],
        },
        {
            name: 'Nov',
            expense: expenseArray[10],
        },
        {
            name: 'Dec',
            expense: expenseArray[11],
        }
    ];

    const profitorlosss = [
        {
            name: 'Jan',
            profitorloss: profitorlossArray[0],
        },
        {
            name: 'Feb',
            profitorloss: profitorlossArray[1],
        },
        {
            name: 'March',
            profitorloss: profitorlossArray[2],
        },
        {
            name: 'Apr',
            profitorloss: profitorlossArray[3],
        },
        {
            name: 'May',
            profitorloss: profitorlossArray[4],
        },
        {
            name: 'June',
            profitorloss: profitorlossArray[5],
        },
        {
            name: 'July',
            profitorloss: profitorlossArray[6],
        },
        {
            name: 'Aug',
            profitorloss: profitorlossArray[7],
        },
        {
            name: 'Sep',
            profitorloss: profitorlossArray[8],
        },
        {
            name: 'Oct',
            profitorloss: profitorlossArray[9],
        },
        {
            name: 'Nov',
            profitorloss: profitorlossArray[10],
        },
        {
            name: 'Dec',
            profitorloss: profitorlossArray[11],
        },
    ];

    const peakhoursdata = [
        {
            name: 'Sun',
            PeakHours: peakHours?.hour,
            Totalsales: peakHours?.total_sales,
            amt: 2400,
        },
        {
            name: 'Mon',
            PeakHours: peakHours?.hour,
            Totalsales: peakHours?.total_sales,
            amt: 2210,
        },
        {
            name: 'Tue',
            PeakHours: peakHours?.hour,
            Totalsales: peakHours?.total_sales,
            amt: 2290,
        },
        {
            name: 'Wed',
            PeakHours: peakHours?.hour,
            Totalsales: peakHours?.total_sales,
            amt: 2000,
        },
        {
            name: 'Thurs',
            PeakHours: peakHours?.hour,
            Totalsales: peakHours?.total_sales,
            amt: 2181,
        },
        {
            name: 'Fri',
            PeakHours: peakHours?.hour,
            Totalsales: peakHours?.total_sales,
            amt: 2500,
        },
        {
            name: 'Sat',
            PeakHours: peakHours?.hour,
            Totalsales: peakHours?.total_sales,
            amt: 2100,
        },
    ];

    const getIntroOfPage = (label) => {
        if (label === 'Page A') {
            return "Page A is about men's clothing";
        }
        if (label === 'Page B') {
            return "Page B is about women's dress";
        }
        if (label === 'Page C') {
            return "Page C is about women's bag";
        }
        if (label === 'Page D') {
            return 'Page D is about household goods';
        }
        if (label === 'Page E') {
            return 'Page E is about food';
        }
        if (label === 'Page F') {
            return 'Page F is about baby food';
        }
        return '';
    };

    useEffect(() => {
        setSalesData((LastData) =>
            LastData.map((item, index) => {
                switch (item.p) {
                    case 'This Month Sale':
                        return {
                            ...item,
                            rate: dashdata?.month_sale_amont || 0,
                            key: index,
                        };
                    case 'This Month Purchase':
                        return {
                            ...item,
                            rate: dashdata?.month_purchase_amont || 0,
                            key: index,
                        };
                    case 'This Month Sale Return':
                        return {
                            ...item,
                            rate: dashdata?.month_sale_return_amont || 0,
                            key: index,
                        };
                    case 'This Month Purchase Return':
                        return {
                            ...item,
                            rate: dashdata?.month_purchase_return_amont || 0,
                            key: index,
                        };
                    default:
                        return {
                            ...item,
                            key: index,
                        };
                }
            })
        );
    }, [dashdata]);

    return (
        <div>
            <CustomRow space={[24, 24]}>
                {
                    salesdata.map((item, i) => (
                        <Col span={24} md={12} key={i} lg={6} sm={12}>
                            <StyledSaleCardDash>
                                <Flex spaceBetween={true}>
                                    <h1>{item.p} </h1>
                                    {item.icon}
                                </Flex>
                                <div>
                                    <h2>
                                        ₹ &nbsp;{item.rate}
                                    </h2>
                                </div>
                                <div>
                                    {/* {item.graph} */}
                                </div>
                            </StyledSaleCardDash>
                        </Col>
                    ))
                }
            </CustomRow>

            <CustomRow space={[24, 24]}>
                <Col span={24} md={12}>
                    <StyledSaleCardRight style={{ borderRadius: '1rem' }}>
                        <ResponsiveContainer width="100%" height="100%" >
                            <LineChart
                                width={500}
                                height={300}
                                data={purchaseandsales}
                                margin={{
                                    top: 40,
                                    right: 20,
                                    left: 20,
                                    bottom: 20,
                                }}
                            >
                                <CartesianGrid strokeDasharray="3 3" />
                                <XAxis dataKey="name" />
                                <YAxis />
                                <Tooltip />
                                <Legend />
                                <Line type="monotone" dataKey="sale" stroke="#8884d8" activeDot={{ r: 8 }} />
                                <Line type="monotone" dataKey="purchase" stroke="#82ca9d" />
                            </LineChart>
                        </ResponsiveContainer>
                    </StyledSaleCardRight >
                </Col>
                <Col span={24} md={12}>

                    <StyledSaleCardRight style={{ borderRadius: '1rem' }}>
                        <ResponsiveContainer width="100%" height="100%">
                            <LineChart
                                width={500}
                                height={300}
                                data={expense}
                                margin={{
                                    top: 40,
                                    right: 20,
                                    left: 20,
                                    bottom: 20,
                                }}
                            >
                                <CartesianGrid strokeDasharray="3 3" />
                                <XAxis dataKey="name" />
                                <YAxis />
                                <Tooltip />
                                <Legend />
                                <Line type="monotone" dataKey="expense" stroke="#9e1854" dot={<CustomizedDot />} />
                                
                            </LineChart>
                        </ResponsiveContainer>

                    </StyledSaleCardRight>
                </Col>
            </CustomRow>

            <CustomRow space={[24, 24]}>
                <Col span={24} md={12}>
                    <StyledSaleCardLeft>
                        <CustomRow space={[12, 12]}>
                            <Col span={24} md={24}>
                                <h3>Current Profit or Loss Status</h3><br /><br />
                                {(setproloss === 'Profit') ? (<div>
                                    <h2>₹ {prolossamt}&nbsp;<span style={{ color: '#52c41a' }}><BsFillCaretUpFill /></span></h2>
                                    <p style={{ marginTop: '10px', color: '#52c41a' }}>Profit</p>
                                </div>) : (<div>
                                    <h2 >₹ {prolossamt} <span style={{ color: 'red' }}><BsFillCaretDownFill /></span></h2>
                                    <p style={{ marginTop: '10px', color: 'red' }}>Loss</p>
                                </div>)
                                }
                                <StyledFromDash>
                                    <Flex style={{ marginTop: '20px' }}>
                                        <AiOutlinePlusCircle style={{ color: "#FF2B7A" }} onClick={OnClickDash} /> &nbsp;&nbsp;
                                        <h6 onClick={OnClickDash} style={{ color: "#FF2B7A" }}>Amount Yet To Come</h6>
                                    </Flex><br />
                                    <Flex>
                                        <AiOutlinePlusCircle style={{ color: "#9e1854" }} onClick={OnClickFreq} /> &nbsp;&nbsp;
                                        <h6 onClick={OnClickFreq} style={{ color: "#9e1854" }}>Frequently Sold Products</h6>
                                    </Flex>
                                </StyledFromDash>
                            </Col>

                        </CustomRow>

                    </StyledSaleCardLeft>
                </Col>
                <Col span={24} md={12}>
                    <StyledSaleCardRightPro style={{ borderRadius: '1rem' }}>

                        <ResponsiveContainer width="100%" height="100%">
                            <AreaChart
                                width={500}
                                height={400}
                                data={profitorlosss}
                                margin={{
                                    top: 40,
                                    right: 20,
                                    left: 20,
                                    bottom: 20,
                                }}
                            >
                                <CartesianGrid strokeDasharray="3 3" />
                                <XAxis dataKey="name" label={{ value: 'profit or loss', position: 'insideBottom', offset: -7, }} style={{ color: "green" }} />
                                <YAxis />
                                <Tooltip />

                                <Area type="monotone" dataKey="profitorloss" stroke="#6032ab" fill="green" />

                             
                            </AreaChart>

                        </ResponsiveContainer>

                    </StyledSaleCardRightPro>

                </Col>


            </CustomRow>

            <CustomModal isVisible={isModalOpen} handleOk={handleOk} handleCancel={handleCancel} width={modelwith} modalTitle={modalTitle} modalContent={modalContent} />

        </div>
    )
}