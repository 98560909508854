import { Col, Form } from 'antd';
import React, { Fragment, useEffect, useState } from 'react';
import request from '../../../utils/request';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { CustomRow } from '../../../components/CustomRow';
import { CustomTextArea } from '../../../components/Form/CustomTextArea';
import { CustomInput } from '../../../components/Form/CustomInput';
import { CustomModal } from '../../../components/CustomModal';
import { CustomSelect } from '../../../components/Form/CustomSelect';
import { CustomDatePicker } from '../../../components/Form/CustomDatePicker';
import { CustomAddSelect } from '../../../components/Form/CustomAddSelect';
import { AddPartyForm } from '../../AddParty/Party/Partials/AddPartyForm';


export const QuotationFormHeader = ({ gstChecked, setSelectedDate, setSelectedParty, trigger }) => {
    const [dataSource, setDataSource] = useState([]);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [modalTitle, setModalTitle] = useState("");
    const [modalContent, setModalContent] = useState(null);
    const [termsData, setTermsData] = useState([])
    const [contryData, setContryData] = useState([])
    const [countryget, setCountryget] = useState([])
    // const [choose, setChoose] = useState('');
    const [width, setWidth] = useState(0)
    const [triggers, setTriggers] = useState(0);
    const [payterms, setPayterms] = useState(0)
    //=========Find Select party Fields ============//
    const [findParty, setFindParty] = useState(false)
    //=======
    const dispatch = useDispatch()

    const onViewRow = () => {
        setTriggers(triggers + 1)
        setWidth(800)
        setModalTitle("Add Party");
        setModalContent(<AddPartyForm UpdateQuotationParty={handleSalesCustomer} okQuotation={handleOk} QuotationPartyTrigger={triggers} />);
        showModal();
    };

    const showModal = () => {
        setIsModalOpen(true);
    };

    const handleOk = () => {
        setIsModalOpen(false);
    };

    const handleCancel = () => {
        setIsModalOpen(false);
    };

    const handleSalesCustomer = () => {
        handleCancel();
        GetPartySelect();
    };

    useEffect(() => {
        if (trigger) {
            GetPartySelect();
        }
    }, [trigger]);

    useEffect(() => {
        GetPartySelect();
    }, []);
    const GetPartySelect = () => {
        request
            .get('party/add_party/')
            .then(function (response) {
                setDataSource(response.data);
            })
            .catch(function (error) {
                // toast.error('Failed')
            });
    };


    //=====================Customer name ===================================

    const QuotationOption = dataSource?.map((com) => ({ label: com.party_name, value: com.id }));

    const handleSelectparty = (value) => {
        const PartNameSelect = dataSource?.find((mem) => mem.id === value);
        setSelectedParty(PartNameSelect);
        setFindParty(value)
    };
    //===================Date onChange fn======================//
    const handleOnChange = (date) => {
        setSelectedDate(date);
    };


    return (
        <Fragment>
            <CustomRow space={[24, 24]} >
                <Col span={24} md={16}>
                    <CustomRow space={[24, 24]}>
                        <Col span={24} md={12}>
                            <CustomAddSelect options={QuotationOption} onButtonClick={onViewRow}
                                showSearch={true} buttonLabel="Party Name"
                                onChange={handleSelectparty} label={'Party Name'} name={'party_name'}
                                placeholder={'Party Name'} rules={[
                                    {
                                        required: true,
                                        message: 'Please Fill Details!',
                                    },
                                ]} />
                            <CustomInput name={'party'} display={'none'} />
                        </Col>
                        {findParty && <>
                            <Col span={24} md={12}>
                                <CustomInput label="Mobile Number" InputLabelProps={{ shrink: true }}
                                    name="mobile_number" placeholder="Mobile Number" rules={[
                                        {
                                            required: true,
                                            message: 'Please Fill Details!',
                                        },
                                    ]} />
                            </Col>
                            <Col span={24} md={12}>
                                <CustomTextArea label='Billing Address'
                                    name="billing_address" placeholder="Billing Address" />
                                {/* <CustomFloatingTextArea label='Billing Address'  name="shipping_address"  InputLabelProps={{ shrink: true }}/> */}

                            </Col>
                            <Col span={24} md={12}>
                                <CustomTextArea label='Shipping Address'
                                    name="shipping_address" placeholder="Shipping Address" />
                                {/* <CustomFloatingTextArea label='Billing Address'  name="shipping_address"  InputLabelProps={{ shrink: true }}/> */}
                            </Col>
                        </>
                        }
                        {gstChecked === false &&
                            <Col span={24} md={12}>
                                <CustomInput label={'GST IN'} name={'GSTIN'}
                                    InputLabelProps={{ shrink: true }} InputProps={{ readOnly: true, }} rules={[
                                        {
                                            required: true,
                                            message: 'Please Fill Details!',
                                        },
                                    ]}
                                    variant="filled"
                                />
                            </Col>
                        }
                    </CustomRow>
                </Col>

                <Col span={24} md={8}>
                    <CustomRow space={[24, 24]}>
                        <Col span={24} md={24}>
                            <CustomInput label={'Ref NO'}
                                disabled
                                name={'invoice_no'} rules={[
                                    {
                                        required: true,
                                        message: 'Please Fill Details!',
                                    },
                                ]} />
                        </Col>
                        <Col span={24} md={24}>
                            <CustomDatePicker label={'Invoice DATE'} onChange={handleOnChange} name={'invoice_date'} rules={[
                                {
                                    required: true,
                                    message: 'Please Fill Details!',
                                },
                            ]} />
                        </Col>
                        {findParty &&
                            <Col span={24} md={24}>
                                <CustomInput label={'State of Supply'} InputLabelProps={{ shrink: true }} placeholder={'State'} name={'state'} rules={[
                                    {
                                        required: true,
                                        message: 'Please Fill Details!',
                                    },
                                ]} />
                            </Col>
                        }
                    </CustomRow>
                </Col>

            </CustomRow>
            <CustomModal isVisible={isModalOpen} handleOk={handleOk} handleCancel={handleCancel} width={width} modalTitle={modalTitle} modalContent={modalContent} />
        </Fragment>
    );
};
