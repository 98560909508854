import { Col, Form, notification } from 'antd'
import React, { useEffect } from 'react'
import { CustomRow } from '../../../components/CustomRow'
import { CustomInput } from '../../../components/Form/CustomInput'
import Flex from '../../../components/Flex'
import Button from '../../../components/Form/CustomButton'
import request from '../../../utils/request'
import { toast } from 'react-toastify'


const AddProductUnit = ({ ViewUnitTrigger,GetUnit, UnitFormUpdate, UnithandleOk, handleClose, handleGetUnit, unitrecord, FormUpdateCall, formReset, UnitUpdate, SaleUnitTrigger, CloseUnit, unitOK, unitTrigger, QuotationUnitUpdate, QuotationCloseUnit, QuotationTrigger }) => {

    const [form] = Form.useForm()

    const UNITADD = 'product/add_unit/'
    const UNITEDIT = 'product/edit_unit'

    const onReset = () => {
        form.resetFields();
    }
    const [api, contextHolder] = notification.useNotification();


    const onFinish = (values) => {

        if (unitrecord) {
            EditUnitPost(values)
        }
        else {
            AddUnitPost(values)
        }
    };

    const onFinishFailed = (errorInfo) => {
        toast.warn("Please fill the details!")
    };

    useEffect(() => {
        form.resetFields();
    }, [formReset])

    useEffect(() => {
        form.resetFields();
    }, [SaleUnitTrigger, unitTrigger, QuotationTrigger,ViewUnitTrigger])


    useEffect(() => {
        form.setFieldsValue(unitrecord)
    }, [unitrecord,ViewUnitTrigger])

    const AddUnitPost = (values) => {
        request.post(`${UNITADD}`, values)
            .then(function (response) {
                if (handleGetUnit) {
                    handleGetUnit()
                }
                if (UnitFormUpdate) {
                    UnitFormUpdate()
                }
                if (unitOK) {
                    unitOK()
                }
                if (UnitUpdate) {
                    UnitUpdate()
                }
                if (QuotationUnitUpdate) {
                    QuotationUnitUpdate()
                }
                if (response.status == 201) {
                    form.resetFields();
                    toast.success('Unit Added Successfully')

                    if (handleGetUnit) {
                        handleGetUnit()
                    }
                    if (GetUnit) {
                        GetUnit();
                    }
                    if (handleClose) {
                        handleClose()
                    }
                    if (FormUpdateCall) {
                        FormUpdateCall()
                    }
                    if (UnitUpdate) {
                        UnitUpdate()
                    }
                    if (CloseUnit) {
                        CloseUnit()
                    }
                    if (QuotationCloseUnit) {
                        QuotationCloseUnit()
                    }
                }
                else if (response.status == 226) {
                    toast.warn('Unit Already Added')
                }

                else {
                    toast.error('Failed');
                }
            })
            .catch(function (error) {
                if (error.response.status === 406) {
                    toast.warn("Please Add business Profile!")
                } 
                else if(error.response.status === 302){
                    toast.warn("Limit Exceeded!")
                }
                else{
                    toast.error('Failed')
                }
            });
    }

    const EditUnitPost = (values, id) => {
        request.patch(`${UNITEDIT}/${unitrecord.id}/`, values)
            .then(function (response) {
                if (UnithandleOk) {
                    UnithandleOk()
                }
                if (UnitUpdate) {
                    UnitUpdate()
                }
                if (UnitFormUpdate) {
                    UnitFormUpdate()
                }
                if (QuotationUnitUpdate) {
                    QuotationUnitUpdate()
                }
                if (response.status == 201) {
                    form.resetFields();
                    toast.info('Unit Updated Successfully')
                    if (handleClose) {
                        handleClose()
                    }
                    if (FormUpdateCall) {
                        FormUpdateCall()
                    }
                    if (UnitUpdate) {
                        UnitUpdate()
                    }
                    if (CloseUnit) {
                        CloseUnit()
                    }
                    if (QuotationCloseUnit) {
                        QuotationCloseUnit()
                    }
                }
                else if (response.status == 226) {
                    toast.warn('Unit Already Added')
                }

                else {
                    toast.error('Failed');
                }
            })
            .catch(function (error) {
                if (error.response.status === 406) {
                    toast.warn("Please Add business Profile!")
                } 
                else if(error.response.status === 302){
                    toast.warn("Limit Exceeded!")
                }
                else{
                    toast.error('Failed')
                }
            });
    }


    return (

        <Form
            name='AddUnit'
            form={form}
            labelCol={{
                span: 24,
            }}
            wrapperCol={{
                span: 24,
            }}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            autoComplete="off">
            <CustomRow space={[24, 24]}>
                {contextHolder}
                <Col span={24} md={24}>
                    <CustomInput label={'Name'} placeholder={'Enter Name'} name={'unit_name'}
                        rules={[
                            {
                                required: true,
                                message: 'Please Enter Name!',
                            }
                        ]}
                    />
                </Col>

                <Col span={24} md={24}>
                    <CustomInput label={'Short Name'} placeholder={'Short Name'} name={'unit_short_name'}
                     rules={[
                        {
                            required: true,
                            message: 'Please Enter Short Name!',
                        }
                    ]}
                    />
                </Col>
                <CustomInput name={'id'} display={'none'} />
            </CustomRow>
            <Flex center gap={'20px'} style={{ margin: '30px' }}>

                {unitrecord ? <><Button.Primary text={'Save'} htmlType={'submit'} />
                    <Button.Danger text={'Reset'} onClick={onReset} /></>
                    : <> <Button.Primary text={'Add'} htmlType={'submit'} />
                        <Button.Danger text={'Reset'} onClick={onReset} /></>
                }

            </Flex>
        </Form>
    )
}

export default AddProductUnit